import { useEffect, useRef, useState } from "react";
import FormDrawer from "components/common/form-drawer";
import PinInput from 'react-pin-input';
import { toast } from "react-toastify";
import { ButtonLoader } from "components/common/form";
import Maybe from "components/common/Maybe";
import PageContent from "components/common/PageContent";
import {ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { Countdown } from "../common/Countdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const PinModal = (props) => {
    const {isOpenPin, setIsOpenPin, loading, resendEmailLoading, resendEmailResponse} = props;
    
    const pinLength = 6;
    const [pin, setPin] = useState("");
    const [timerShown, setTimerShown] = useState(true);
    let elementRef = useRef();

    const submitForm = async (e) => {
        e.preventDefault();
        if (pin !== "" && pin.length === pinLength){
            return (props.submit());
        }
        else{
            toast.error("Please enter a valid " + props.target);
        }
    }

    const resendOtp = () => {
        if (resendEmailLoading === false && loading === false){
            props.resendEmailClick();
        }
    }

    const clearInputPin = () => {
        if (elementRef.currentIndex !== undefined){
            elementRef.clear();
            setPin("");
        }
    }

    useEffect(() => {
        clearInputPin();
    }, [isOpenPin]);

    useEffect(() => {
        if (resendEmailResponse?.success === true){
            setTimerShown(true);
        }
        // eslint-disable-next-line
    }, [resendEmailResponse])

    return (
        <>
        
            <FormDrawer display="center" size="md" isOpen={isOpenPin} setIsOpen={setIsOpenPin}>
                <Maybe condition={props.modalLoading === true}>
                    <div className="p-12">
                        <PageContent loading={props.modalLoading} />
                    </div>
                </Maybe>

                <Maybe condition={!props.modalLoading || props.modalLoading === false}>
                    <form onSubmit={submitForm}>
                        <div className="p-8">
                            <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                                <div className="text-2xl font-ojah font-medium">
                                    {props.title}
                                </div>
                                <div onClick={() => setIsOpenPin(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                                    <IconClose className="m-auto text-white svg-stroke-gray" />
                                </div>
                            </div>

                            <div className="h-16 mt-10 flex justify-center">
                                <PinInput 
                                    length={pinLength} 
                                    initialValue={pin}
                                    onChange={(value, index) => {setPin(value); props.pin(value)}} 
                                    type="numeric" 
                                    inputMode="number"
                                    inputStyle={{width: '47px', height: '47px', background: "#FAFAFB", border: "2px solid transparent", marginRight: "6px", fontSize: "40px", fontWeight: "bold", paddingBottom: "6px", borderRadius: "1rem"}}
                                    inputFocusStyle={{border: "2px solid #343433"}}
                                    onComplete={(value, index) => {}}
                                    autoSelect={true}
                                    focus={true}
                                    secret={true}
                                    ref={(n) => (elementRef = n)}
                                />
                            </div>

                            <Maybe condition={props.showResendEmail === true}>
                                <div className="mt-3 mx-3 flex justify-end text-[#99999C]">
                                    <Maybe condition={timerShown === true}>
                                        Resend code in&nbsp;
                                        <span className="text-shiga-purple font-medium cursor-not-allowed">
                                            <Countdown seconds={59} onExpiry={() => setTimerShown(false)} />
                                        </span>
                                    </Maybe>
                                    <Maybe condition={timerShown === false}>
                                        Didn't receive OTP?&nbsp;
                                        <span onClick={resendOtp} className={"text-shiga-purple font-medium cursor-pointer border-b-2 border-transparent hover:border-shiga-purple " + (resendEmailLoading === true && "opacity-40")}>
                                            <Maybe condition={resendEmailLoading === true}>
                                                <FontAwesomeIcon icon="circle-notch" spin className="mr-2" />
                                            </Maybe>
                                            Resend OTP
                                        </span>
                                    </Maybe>
                                </div>
                            </Maybe>
                        </div>

                        <div className="border-t border-gray-100 p-8">
                            <ButtonLoader loading={props.loading} type="submit" disabled={!(pin !== "" && pin.length === pinLength)} className="btn btn-lg btn-block btn-shiga-black">
                                {props.submitText ?? "Continue"}
                            </ButtonLoader>
                        </div>
                    </form>
                </Maybe>
            </FormDrawer>
        </>
    )
}

export default PinModal;