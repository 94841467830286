import { useState } from "react";
import DeleteCardModal from "./DeleteCard";
import CardRenameModal from "./RenameCard";

import { useNavigate } from "react-router-dom";
import { DataTable } from "components/data-table/data-table";
import { CardColumns } from "./table/CardColumn";
import { useToggle } from "hooks/useToggle";
import CardDetailsModal from "./CardDetails";
import FundCardModal from "./FundCard";
import { DataTableHeader } from "components/data-table/data-table-header";


export const CardsTable: React.FC<{
  cards: any[]
}> = ({ cards }) => {
  const navigate = useNavigate();
  const [showDelete, setShowDelete] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [showFundCard, toggleShowFund] = useToggle();
  const [showDetails, toggleShowDetails] = useToggle();
  function handleSelected() {
    navigate("/cards/238ry823y9h238r982");
  }

  return (
    <>
      <div>
        <div className="mt-6">
          <DataTable
            data={cards}
            columns={CardColumns}
            renderToolbar={table => <DataTableHeader
              filterOptions={["Active", "Locked"]}
              placeholder="Search cards" table={table} />}
              meta={{
                handleShowDelete: () => setShowDelete(!showDelete),
                handleSelected,
                handleShowDetails: () => toggleShowDetails(),
                handleRename: () => setShowRename(!showRename),
                handleFund: () => toggleShowFund()
              }}
          />
        </div>
      </div>
      <DeleteCardModal isOpen={showDelete} setIsOpen={setShowDelete} />
      <CardRenameModal isOpen={showRename} setIsOpen={setShowRename} />
      <CardDetailsModal
        isOpen={showDetails}
        setIsOpen={toggleShowDetails}
      />
      <FundCardModal isOpen={showFundCard} setIsOpen={toggleShowFund} />
    </>
  );
};

export default CardsTable;
