import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthPageTitle from "components/common/AuthPageTitle";
import validate from "utils/validate";
import { ButtonLoader, FileUploadForm } from "components/common/form";
import { ReactComponent as IconArrowLeft } from "assets/images/icons/ArrowLeft.svg";
import useLoginInfo from "hooks/useLoginInfo";
import { toast } from "react-toastify";
import useMediaService from "hooks/useMediaService";
import * as businessVerificationActions from "store/entities/businessVerification/action";
import * as userActions from "store/auth/user/action";


const BusinessInformation = (props:any) => {
    const { goPrevious, goNext } = props;
    const dispatch = useDispatch();
    const { userInfo } = useLoginInfo();
    const { uploadFile, uploadedFileLoading } = useMediaService();
    const { businessVerificationUpdateLoading, businessVerificationUpdate, sampleDocumentsAll } = useSelector((s:any) => s.entities.businessVerification);

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [sampleDocuments, setSampleDocuments] = useState<any>({
        cac: "",
        memorandum: "",
        scuml: "",
    });
    const [form, setForm] = useState({
        cacCertificateDocument: "",
        memorandumDocument: "",
        scumlDocument: "",
        // utilityBillDocument: "",
    })

    const getSampleDocuments = () => {
        dispatch(businessVerificationActions.getSampleDocuments({type: "cac"}));
        dispatch(businessVerificationActions.getSampleDocuments({type: "memorandum"}));
        dispatch(businessVerificationActions.getSampleDocuments({type: "scuml"}));
        // dispatch(businessVerificationActions.getSampleDocuments({type: "utility"}));
    }

    const populateForm = () => {
        // setForm({
        //     cacCertificateDocument: "",
        //     memorandumDocument: "",
        //     scumlDocument: "",
        //     utilityBillDocument: "",
        // });
    }

    const processSampleDocuments = () => {
        let data = {};
        sampleDocumentsAll.map((document:string) => {
            if (document.includes("cac.pdf")){
                data = {...data, cac: document};
            }
            else if (document.includes("memorandum.pdf")){
                data = {...data, memorandum: document};
            }
            else if (document.includes("scuml.pdf")){
                data = {...data, scuml: document};
            }
            return null;
        })
        setSampleDocuments(data);
    }

    const checkFormValidation = (data:any = form, appendKey:any, appendValue:any) => {
        let params = {...data};
        if (appendKey){
            params = {
                ...params,
                [appendKey]: appendValue,
            };
        }
        const errors = validate(params);
        if (errors){
            setIsFormValidated(false);
        }
        else{
            setIsFormValidated(true);
        }
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        const errors:any = validate(form);
        if (errors){
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            if (isFormValidated === true){
                let params = {}

                if (typeof form.cacCertificateDocument !== "string"){
                    const response1:any = await uploadFile(form.cacCertificateDocument);
                    if(response1?.success === true) {
                        params = {...params, cac: response1?.data?.file?.link}
                    }
                }
                
                if (typeof form.memorandumDocument !== "string"){
                    const response2:any = await uploadFile(form.memorandumDocument);
                    if(response2?.success === true) {
                        params = {...params, memorandum: response2?.data?.file?.link}
                    }
                }
                
                if (typeof form.scumlDocument !== "string"){
                    const response3:any = await uploadFile(form.scumlDocument);
                    if(response3?.success === true) {
                        params = {...params, scuml: response3?.data?.file?.link}
                    }
                }
                
                if (Object.keys(params).length > 0){
                    dispatch(businessVerificationActions.updateBusinessDocuments(params));
                }
            }
        }
    }

    useEffect(() => {
        getSampleDocuments();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userInfo?.businessProfile !== undefined){
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (sampleDocumentsAll?.length > 0){
            processSampleDocuments();
        }
        // eslint-disable-next-line
    }, [sampleDocumentsAll]);

    useEffect(() => {
        if (businessVerificationUpdate?.success === true){
            dispatch(userActions.updateUserInfo({
                businessProfile: { ...businessVerificationUpdate?.data?.business ?? {} },
            }));
            dispatch(businessVerificationActions.resetUpdateBusinessVerification());
            goNext();
        }
        // eslint-disable-next-line
    }, [businessVerificationUpdate]);

    return (
        <div>
            <AuthPageTitle
                title="Upload documents for your business"
                description="Please provide us with your business registration documents"
            />

            <div className="pt-10 mt-10 border-t border-gray-100">
                <form onSubmit={submitForm}>

                    <div className="space-y-10">
                        <FileUploadForm
                            label="CAC certificate of your business"
                            required={true}
                            sampleFile={sampleDocuments.cac}
                            data={form.cacCertificateDocument}
                            onchange={(e:any) => setForm({...form, cacCertificateDocument: e})}
                            filetype={["pdf","csv"]}
                            filename="CAC Certificate"
                            validationName="cacCertificateDocument"
                            checkFormValidation={checkFormValidation}
                        />

                        <FileUploadForm
                            label="Memorandum of incoperation"
                            required={true}
                            sampleFile={sampleDocuments.memorandum}
                            data={form.memorandumDocument}
                            onchange={(e:any) => setForm({...form, memorandumDocument: e})}
                            filetype={["pdf","csv"]}
                            filename="Memorandum of incoperation"
                            validationName="memorandumDocument"
                            checkFormValidation={checkFormValidation}
                        />

                        <FileUploadForm
                            label="SCUML Document"
                            required={true}
                            sampleFile={sampleDocuments.scuml}
                            data={form.scumlDocument}
                            onchange={(e:any) => setForm({...form, scumlDocument: e})}
                            filetype={["pdf","csv"]}
                            filename="SCUML Document"
                            validationName="scumlDocument"
                            checkFormValidation={checkFormValidation}
                        />

                        {/* 
                        <FileUploadForm
                            label="Utility Bill"
                            required={true}
                            sampleFile=""
                            data={form.utilityBillDocument}
                            onchange={(e:any) => setForm({...form, utilityBillDocument: e})}
                            filetype={["pdf","csv"]}
                            filename="Utility Bill"
                            validationName="utilityBillDocument"
                            checkFormValidation={checkFormValidation}
                        />
                         */}
                    </div>

                    <div className="mt-10 flex space-x-2 items-center justify-between">
                        <ButtonLoader type="button" onClick={goPrevious} loading={false} className="max-w-[10rem] btn btn-block btn-lg btn-white">
                            <IconArrowLeft className="mr-3" />
                            <span>Back</span>
                        </ButtonLoader>
                        <ButtonLoader type="submit" loading={uploadedFileLoading || businessVerificationUpdateLoading} className="max-w-[16rem] btn btn-block btn-lg btn-primary">
                            Continue
                        </ButtonLoader>
                    </div>

                </form>
            </div>
        </div>
    );
}


export default BusinessInformation;