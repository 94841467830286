import axios from "axios";
import { apiBaseUrl } from "data/config";
import { getToken } from "lib";
import { PaginatedApiResponse } from "types/data-interface";
import { PaymentLink } from "types/payment/payments.interface";

export const getLinks = async () => {
  const response = await axios.get<PaginatedApiResponse<PaymentLink[]>>(
    apiBaseUrl + `/payments/links`,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
  return response.data;
};

export const createPaymentLink = async (body: CreatePaymentLinkRequest) => {
  try {
    const res = await axios.post(apiBaseUrl + "/payments/link", body, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    });
    return res.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

type AmountType = "FIXED" | "FLEXIBLE"
interface CreatePaymentLinkRequest {
  currency: {
    value: string;
    type: AmountType;
  };
  amountType: AmountType;
  name: string;
  description?: string;
  amount?: number;
  callbackUrl: string;
  logoUrl: string;
  acceptOtherCurrencies: boolean;
  slug?: string;
}

export const deletePaymentLink = async (id: string) => {
  try {
    const res = await axios.delete(
      apiBaseUrl + `/payments/link/${id}`,

      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    return res.data;
  } catch (error) {
    return { error: "An error occurred" };
  }
};

export const getPaymentLink = async (id: string) => {
  try {
    const res = await axios.get(
      apiBaseUrl + `/payments/link/${id}`,

      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    return res.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

export const updatePaymentLink = async (
  id: string,
  body: Pick<CreatePaymentLinkRequest, "amount" | "description" | "name">
) => {
  try {
    const res = await axios.put(
      apiBaseUrl + `/payments/link/${id}/update`,
      body,
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    return res.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message);
  }
};


export const togglePaymentLink = async (
  id: string,
  status: "ACTIVE" | "INACTIVE"
) => {
  try {
    const res = await axios.put(
      apiBaseUrl + `/payments/link/${id}/toggle`,
      {
        status
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      }
    );
    return res.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message || "an error occurred");
  }
};