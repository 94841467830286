// Main
export const projectName = "Shiga-business";
export const repoName = "Shiga-business";

// API - middleware
export const apiRequestStart = "API/REQUEST_START";
export const apiRequestSuccess = "API/REQUEST_SUCCESS"; // for logging purpose only
export const apiRequestFailed = "API/REQUEST_FAILED"; // for logging purpose only

// API - base url
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
if (!apiBaseUrl?.length) {
    throw new Error("BASE URL env is missing.")
}
if (!googleApiKey?.length) {
    throw new Error("Google API Key is needed")
}
// export const apiShigaBaseUrl = process.env.REACT_APP_API_SHIGA_BASE_URL;

// API - user auth
export const apiGetRefreshToken = "/auth/refresh-token";
export const apiLoginUser = "/auth/attempt";
export const apiLoginUserWithMFA = "/auth/verify";
export const apiForgotPassword = "/auth/password/request";
export const apiResetPassword = "/auth/password/reset";
export const apiSendOtp = "/otp/send";
export const apiResendOtp = "/otp/resend";
export const apiVerifyOtp = "/otp/verify";
export const apiRegisterUser = "/auth/register";
export const apiLogoutUser = "/auth/logout";

// API - user account
export const apiGetUserProfile = "/account/me";
export const apiGetBusinessProfile = "/business/profile";
export const apiRequestPasswordChange = "/account/password";

// API - business verification
export const apiUpdateBusinessInformation = "/business/verify/info";
export const apiGetBusinessStatus = "/business/status";
export const apiUpdateBusinessDocuments = "/business/verify/documents";
export const apiUpdateBusinessOwner = "/business/verify/owner";
export const apiAddDirectors = "/business/director/new";
export const apiBusinessDownloadSampleFile = "/business/document/sample";
export const apiBusinessGetWalletBalance = "/business/wallet"

// API - settings
export const apiGetSettlementAccounts = "/business/settings/accounts";
export const apiAddSettlementAccounts = "/business/settings/accounts/add";
export const apiUpdateSettlementAccounts = "/business/settings/accounts/";
export const apiDeleteSettlementAccounts = "/business/settings/accounts/";
export const apiGetTeamMembers = "/team/members";
export const apiGetTeamMembersInvited = "/invites";
export const apiGetRoles = "/team/roles";
export const apiMemberEnforce2FA = "/team/settings/mfa";
export const apiTeamMembers = "/team/members/";
export const apiAddTeamMember = "/team/members/new";
export const apiEditTeamMember = "/team/members/";
export const apiMemberInvite = "/invites/";
export const apiUpdateFeesEarnings = "/settings/fees";
export const apiUpdatePaymentMethod = "/settings/payment-methods";
export const apiUpdateNotificationPreference = "/settings/notifications";
export const apiGetKeys = "/business/keys";
export const apiUpdateWebhooks = "/settings/webhook";
export const apiInitializeMFA = "/account/mfa";
export const apiCompleteMFA = "/account/mfa/complete";
export const apiVerifyMFA = "/account/mfa/verify";
export const apiDisableMFA = "/account/mfa";

// API - misc
export const apiGetTransferLimit = "/limits/";
export const apiGetTransferFee = "/misc/calculate-fee";
export const apiGetCountries = "/countries";
export const apiGetIndustries = "/industries";
export const apiGetCurrencies = "/currencies";
export const apiGetBanks = "/misc/banks/";
export const apiFileUpload = "/file/upload";

// API - dashboard
export const apiGetDashboardOverview = "/dashboard/overview";
export const apiGetDashboardChart = "/dashboard/chart";
export const apiGetDashboardCards = "/dashboard/cards";
export const apiGetDashboardWallets = "/dashboard/wallets";
export const apiGetDashboardActivities = "/dashboard/activities";
export const apiFundGBP = "/dashboard/wallet/fund";
export const getWalletFundStatus = "/dashboard/wallet/fund-status"

// API - dashboard
export const apiGetTransactions = "/transaction/list";
export const getTransactionByReference = "/transaction/"




//External/
export const apiGetWalletAndBalnce = "/dashboard/wallets"

//Transfer api
export const apiNameEnquiry = "/misc/bank/name-enquiry"
export const apiGetTransferBeneficiary = "/transfer/beneficiary"
export const apiMakeTranssfer = "/transfer/internal"
export const apiGetTransferFields = "/transfer/fields?currency="

//Issued accounts
export const apiGetIssuedAccounts = "/account/issued"


// CARDS
export const apiCreateCard = "/cards/new";
export const apiGetCards = "/card/list";
export const getCardDetails = (id: string) => `/cards/${id}/details`;
export const patchCardName = "/cards/{id}/name";
export const topupCard = "/cards/topup";
export const lockCard = "/cards/{id}/lock";
export const unlockCard = "/cards/{id}/unlock"


// REFUNDS
export const apiGetRefunds = "/refunds";
export const apiCreateRefund = "/refunds/new"

// Bills Payment
export const apiGetAirtime = "/bills-payment/airtime-services";
export const apiGetInternetService = "/bills-payment/internet-services";
export const apiGetTVService = "/bills-payment/tv-services";
export const apiGetElectricity = "bills-payment/electricity-services"
export const apiGetDataPlans = "/bills-payment/internet-services"
export const apiGetTVPackages = "/bills-payment/tv-services"

// PAY BILLS
export const apiCreateAirtime = "/bills-payment/airtime/pay";
export const apiCreateInternetService = "/bills-payment/internet/pay";
export const apiCreateTVService = "/bills-payment/tv/pay";
export const apiCreateElectricity = "/bills-payment/electricity/pay";

// VALIDATE
export const apiValidateTV = "/bills-payment/validate-tv"
export const apiValidateElectricity = "/bills-payment/validate-electricity"


//Convert
export const apiGetRates = "/exchange-rate/quote"
export const apiPostConvert = "/transaction/wallet/convert"


// Payment
// [GIFTCARD]
export const getGiftCardsByCountry = "/giftcard"
export const apiOrderGiftCard = "/giftcard/buy"


// Invoice
export const apiUpdatePaidInvoice = (id: string) => `/invoice/${id}/mark-paid`
export const apiFindAllInvoice = `/invoice/list`
export const apiCreateInvoice = `/invoice`
export const apiFindInvoice = (id: string) => `/invoice/${id}`
export const apiDeleteInvoice = (id: string) => `/invoice/${id}`
export const apiSendReminderInvoice = (id: string) => `/invoice/${id}/reminder`