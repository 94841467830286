import errorReducer from "utils/errorReducer"
import * as actions from "./actionTypes"
import { RootState } from "store/store";
import { CurrencyType } from "types/misc";

interface InitialState {
    fromAmount: number;
    rate: number;
    receiveAmount: number;
    gettingRateLoading: boolean;
    convertNote: string;
    convertingLoading: boolean;
    conversionStatus: number;
    fromCurrency: CurrencyType;
    toCurrency: CurrencyType;
}

const initialState: InitialState = {
    fromAmount: 0.0,
    rate: 0.0,
    receiveAmount: 0.0,
    gettingRateLoading: false,
    convertNote: "",
    convertingLoading: false,
    conversionStatus: -1,
    fromCurrency: '',
    toCurrency: ''
}
export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case actions.CONVERT_GET_EXCHANGE_RATE_START:
            state = {
                ...state,
                gettingRateLoading: true
            }
            return state
        case actions.CONVERT_GET_EXCHANGE_RATE_FAILED:
            errorReducer(action.payload)
            state = {
                ...state,
                gettingRateLoading: false,
                rate: 1.0
            }
            return state
        case actions.CONVERT_GET_EXCHANGE_RATE:
            state = {
                ...state,
                gettingRateLoading: false,
                rate: action.payload.data.data.rate,
                receiveAmount: action.payload.data.data.receiveAmount,
            }
            return state
        case actions.CONVERT_MONEY_API_START:
            state = {
                ...state,
                convertingLoading: true
            }
            return state
        case actions.CONVERT_MONEY_API:
            state = {
                ...state,
                convertingLoading: false,
                conversionStatus: 0
            }
            return state
        case actions.CONVERT_MONEY_API_FAILED:
            errorReducer(action.payload)
            state = {
                ...state,
                convertingLoading: false,
                conversionStatus: 2
            }
            return state
        case actions.UPDATE_CONVERT:
            state = {
                ...state,
                fromAmount: action.payload.sendAmount,
                receiveAmount: action.payload.receiveAmount,
                rate: action.payload.rate,
                fromCurrency: action.payload.fromCurrency,
                toCurrency: action.payload.toCurrency
            }
            return state
        case actions.RESET_CONVERT_DATA:
            state = initialState
            return state
        default:
            return state
    }
}

export const getConvertStore = (s:RootState) => s.convert;