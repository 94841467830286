import * as actions from "./actionTypes";
import * as config from "data/config";


export const getTransferLimit = (currency: string, type: string) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetTransferLimit}${currency}?type=${type}`,
            method: "get",
            data: {},
            onStart: actions.GET_TRANSFER_LIMIT_START,
            onSuccess: actions.GET_TRANSFER_LIMIT_DONE,
            onError: actions.GET_TRANSFER_LIMIT_FAILED,
        },
    }
}

export const clearBankTransferForm = () => {
    return {
        type: actions.CLEAR_BANK_TRANSFER_FORM,
    }
}

export const saveBankTransferForm = (payload: BankTransferFormProps) => {
    return {
        type: actions.SAVE_BANK_TRANSFER_FORM,
        payload: payload
    }
}

export const getTransferFee = (params: any) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTransferFee,
            method: "post",
            data: params,
            onStart: actions.GET_TRANSFER_FEE_START,
            onSuccess: actions.GET_TRANSFER_FEE_DONE,
            onError: actions.GET_TRANSFER_FEE_FAILED,
        },
    }
}

export const getTransferBanks = (currency: string) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetBanks}${currency}`,
            method: "get",
            data: {},
            onStart: actions.TRANSFER_GET_BANK_START,
            onSuccess: actions.TRANSFER_GET_BANK,
            onError: actions.TRANSFER_GET_BANK_FAILED,
        },
    }
}

export const updateSelectedCurrency = (newBank: any) => {
    return {
        type: actions.UPDATE_TRANSFER_CURERENCY,
        payload: newBank
    }
}


export const updateDynamicField = (fieldName: string, fieldValue: any) => {
    return {
        type: actions.UPDATE_TRANSFER_DYNAMIC_FIELD,
        payload: {
            [fieldName]: fieldValue
        }
    }
}

export const transferNameEnquiry = (accountNumber: string, bankCode: string) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiNameEnquiry,
            method: "post",
            data: {
                accountNumber: accountNumber,
                bankCode: bankCode
            },
            onStart: actions.TRANSFER_NAME_ENQUIRY_START,
            onSuccess: actions.TRANSFER_NAME_ENQUIRY,
            onError: actions.TRANSFER_NAME_ENQUIRY_FAILED,
        },
    }
}

export const makeTransfer = (accountNo: string, amount: string,
    bankCode: string, currency: string, sourceCurrency: string, accountName: string,
    bankName: string, naration: string, mfaCode: string, saveBeneficiary: boolean) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiMakeTranssfer,
            method: "post",
            data: {
                accountNumber: accountNo,
                amount: amount,
                bankCode: bankCode,
                currency: currency,
                sourceCurrency: sourceCurrency,
                accountName: accountName,
                bankName: bankName,
                narration: naration,
                token: mfaCode,
                saveBeneficiary: saveBeneficiary
            },
            onStart: actions.TRANSFER_PAYMENT_START,
            onSuccess: actions.TRANSFER_PAYMENT,
            onError: actions.TRANSFER_PAYMENT_FAILED,
        }
    }
}

export const updateSelectedWaletForTransfer = (wallet: any) => {
    return {
        type: actions.UPDATE_SELECTED_WALLET_FOR_TRANSFER,
        payload: wallet
    }
}

export const getTransferBeneficiaryList = () => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTransferBeneficiary,
            method: "get",
            data: {},
            onStart: actions.TRANSFER_BENEFICIARY_START,
            onSuccess: actions.TRANSFER_BENEFICIARY,
            onError: actions.TRANSFER_BENEFICIARY_FAILED,
        },
    }
}

export const updateTransferAmount = (amount: string) => {
    return {
        type: actions.UPDATE_TRANSFER_AMOUNT,
        payload: amount
    }
}

export const updateTransferNote = (note: string) => {
    return {
        type: actions.UPDATE_TRANSFER_NOTE,
        payload: note
    }
}

export const updateMfa = (mfaCode: string) => {
    return {
        type: actions.UPDATE_TRANSFER_MFA_CODE,
        payload: mfaCode
    }
}
export const resetTransferData = () => {
    return {
        type: actions.RESET_TRANSFER_DATA,
    }
}

export const updateFromTransferFromBeneficaiary = (accountNo: string, accountName: string, accountBank: string, bankCode: string, currency: string, routingNo: string) => {
    return {
        type: actions.UPDATE_TRANSFER_FIELDS_FROM_SAVED_BENENEFIARY,
        payload: {
            accountNumber: accountNo,
            accountName: accountName,
            bankName: accountBank,
            bankCode: bankCode,
            currency: currency,
            routingNo: routingNo
        }
    }
}

export const getTransferFields = (currency: string) => {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiGetTransferFields}${currency}`,
            method: "get",
            data: {},
            onStart: actions.TRANSFER_GET_FIELDS_START,
            onSuccess: actions.TRANSFER_GET_FIELDS,
            onError: actions.TRANSFER_GET_FIELDS_FAILED,
        }
    }
}
