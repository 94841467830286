import { Transaction } from 'types/transaction';
import { ReactComponent as TrxAvatar } from "assets/images/icons/trx-avatar.svg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';


type Props = {
   transaction: Transaction;
   fromCurrency: string;
   toCurrency: string;
}

const CurrencySwapDetails = ({ transaction, fromCurrency, toCurrency }: Props) => {
   const { copyItem, showValue, formatCurrency } = useToolkit()
   const initiator = `${transaction?.initiatedByAccount?.firstName || 'N/A'} ${transaction?.initiatedByAccount?.lastName || ''}`

   return (
      <div className="w-full">

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Initiated by
            </div>

            <div className="flex items-center">
               <TrxAvatar />

               <div className="transaction-detail-inline-value capitalize ml-1.5">
                  {showValue(initiator)}
               </div>
            </div>
         </div>

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Amount Converted
            </div>

            <div className="transaction-detail-inline-value capitalize ml-1.5">
               {formatCurrency(transaction?.amount?.toString())} {fromCurrency}
            </div>
         </div>

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Rate
            </div>

            <div className="transaction-detail-inline-value capitalize ml-1.5">
               1 {fromCurrency} = {'0'} {toCurrency}
            </div>
         </div>

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Fees
            </div>

            <div className="transaction-detail-inline-value">
               {transaction?.fee} {fromCurrency}
            </div>
         </div>

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Transaction ID
            </div>

            <div className="transaction-detail-inline-value">
               <div className='flex items-center font-medium'>
                  {transaction.reference?.substring(0, 15)}
                  <button className='ml-1.5' onClick={() => copyItem(transaction?.reference, 'Transaction reference')}>
                     <CopyIcon width={20} />
                  </button>
               </div>
            </div>
         </div>

         <div className='transaction-detail-inline-wrapper-last'>
            <div className="transaction-detail-inline-key">
               You get
            </div>

            <div className="transaction-detail-inline-value">
               {transaction?.amount - Number(transaction?.fee)} {transaction?.currency}
            </div>
         </div>
      </div>


   )
}

export default CurrencySwapDetails