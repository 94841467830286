import { toast } from "react-toastify";
import { memberRoles } from "data/constants/common";
import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";

const initialState = {
  settlementAccountsLoading: false,
  settlementAccounts: {},
  settlementAccountAddedLoading: false,
  settlementAccountAdded: {},
  settlementAccountUpdatedLoading: false,
  settlementAccountUpdated: {},
  settlementAccountDeletedLoading: false,
  settlementAccountDeleted: {},

  rolesLoading: false,
  roles: {},
  teamMembersLoading: false,
  teamMembers: {},
  teamMembersInvitedLoading: false,
  teamMembersInvited: {},
  teamMemberFormLoading: false,
  teamMemberForm: {},
  inviteResendLoading: false,
  inviteResend: {},
  inviteCancelLoading: false,
  inviteCancel: {},
  mfaEnforceLoading: false,
  mfaEnforce: {},
  memberDeletedLoading: false,
  memberDeleted: {},
  inviteAcceptLoading: false,
  inviteAccept: {},

  feesEarningsUpdateLoading: false,
  feesEarningsUpdate: {},

  paymentMethodUpdateLoading: false,
  paymentMethodUpdate: {},

  notificationPreferenceUpdateLoading: false,
  notificationPreferenceUpdate: {},

  keysLoading: false,
  keys: {},
  webhooksUpdateLoading: false,
  webhooksUpdate: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_SETTLEMENT_ACCOUNTS:
      state = {
        ...state,
        settlementAccountsLoading: false,
        settlementAccounts: action.payload,
      };
      return state;

    case actions.GET_SETTLEMENT_ACCOUNTS_START:
      state = {
        ...state,
        settlementAccountsLoading: true,
      };
      return state;

    case actions.GET_SETTLEMENT_ACCOUNTS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        settlementAccountsLoading: false,
        settlementAccounts: action.payload,
      };
      return state;

    case actions.ADD_SETTLEMENT_ACCOUNTS:
      state = {
        ...state,
        settlementAccountAddedLoading: false,
        settlementAccountAdded: action.payload,
        payload: [
            ...state.settlementAccounts.data.payload,
            action.payload
          ]
      };
      return state;

    case actions.ADD_SETTLEMENT_ACCOUNTS_START:
      state = {
        ...state,
        settlementAccountAddedLoading: true,
      };
      return state;

    case actions.ADD_SETTLEMENT_ACCOUNTS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        settlementAccountAddedLoading: false,
        settlementAccountAdded: action.payload,
      };
      return state;

    case actions.ADD_SETTLEMENT_ACCOUNTS_RESET:
      state = {
        ...state,
        settlementAccountAddedLoading: false,
        settlementAccountAdded: action.payload,
      };
      return state;

    case actions.EDIT_SETTLEMENT_ACCOUNTS:
      state = {
        ...state,
        settlementAccountUpdatedLoading: false,
        settlementAccountUpdated: action.payload,
      };
      return state;

    case actions.EDIT_SETTLEMENT_ACCOUNTS_START:
      state = {
        ...state,
        settlementAccountUpdatedLoading: true,
      };
      return state;

    case actions.EDIT_SETTLEMENT_ACCOUNTS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        settlementAccountUpdatedLoading: false,
        settlementAccountUpdated: action.payload,
      };
      return state;

    case actions.EDIT_SETTLEMENT_ACCOUNTS_RESET:
      state = {
        ...state,
        settlementAccountUpdatedLoading: false,
        settlementAccountUpdated: action.payload,
      };
      return state;

    case actions.DELETE_SETTLEMENT_ACCOUNTS:
        const updatedPayload = state.settlementAccounts.data.payload.filter(
            (account) => account.id !== action.payload.accountId
          );
      state = {
        ...state,
        settlementAccountDeletedLoading: false,
        settlementAccountDeleted: action.payload,
        settlementAccounts: {
            ...state.settlementAccounts,
            data: {
              ...state.settlementAccounts.data,
              payload: updatedPayload
            }
          }
      };
      return state;

    case actions.DELETE_SETTLEMENT_ACCOUNTS_START:
      state = {
        ...state,
        settlementAccountDeletedLoading: true,
      };
      return state;

    case actions.DELETE_SETTLEMENT_ACCOUNTS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        settlementAccountDeletedLoading: false,
        settlementAccountDeleted: action.payload,
      };
      return state;

    case actions.DELETE_SETTLEMENT_ACCOUNTS_RESET:
      state = {
        ...state,
        settlementAccountDeletedLoading: false,
        settlementAccountDeleted: action.payload,
      };
      return state;

    case actions.GET_TEAM_MEMBERS:
      state = {
        ...state,
        teamMembersLoading: false,
        teamMembers: action.payload,
      };
      return state;

    case actions.GET_TEAM_MEMBERS_START:
      state = {
        ...state,
        teamMembersLoading: true,
      };
      return state;

    case actions.GET_TEAM_MEMBERS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        teamMembersLoading: false,
        teamMembers: action.payload,
      };
      return state;

    case actions.GET_TEAM_MEMBERS_INVITED:
      state = {
        ...state,
        teamMembersInvitedLoading: false,
        teamMembersInvited: action.payload,
      };
      return state;

    case actions.GET_TEAM_MEMBERS_INVITED_START:
      state = {
        ...state,
        teamMembersInvitedLoading: true,
      };
      return state;

    case actions.GET_TEAM_MEMBERS_INVITED_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        teamMembersInvitedLoading: false,
        teamMembersInvited: action.payload,
      };
      return state;

    case actions.GET_ROLES:
      state = {
        ...state,
        rolesLoading: false,
        // roles: action.payload,
        roles: {
          success: true,
          statusCode: 200,
          message: "Fetched team roles",
          data: memberRoles,
        },
      };
      return state;

    case actions.GET_ROLES_START:
      state = {
        ...state,
        rolesLoading: true,
      };
      return state;

    case actions.GET_ROLES_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        rolesLoading: false,
        roles: action.payload,
      };
      return state;

    case actions.TEAM_MEMBER_FORM:
      toast.success(action.payload.message);
      state = {
        ...state,
        teamMemberFormLoading: false,
        teamMemberForm: action.payload,
      };
      return state;

    case actions.TEAM_MEMBER_FORM_START:
      state = {
        ...state,
        teamMemberFormLoading: true,
      };
      return state;

    case actions.TEAM_MEMBER_FORM_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        teamMemberFormLoading: false,
        teamMemberForm: action.payload,
      };
      return state;

    case actions.TEAM_MEMBER_FORM_RESET:
      state = {
        ...state,
        teamMemberFormLoading: false,
        teamMemberForm: {},
      };
      return state;

    case actions.ENFORCE_MFA:
      toast.success(action.payload.message);
      state = {
        ...state,
        mfaEnforceLoading: false,
        mfaEnforce: action.payload,
      };
      return state;

    case actions.ENFORCE_MFA_START:
      state = {
        ...state,
        mfaEnforceLoading: true,
      };
      return state;

    case actions.ENFORCE_MFA_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        mfaEnforceLoading: false,
        mfaEnforce: action.payload,
      };
      return state;

    case actions.ENFORCE_MFA_RESET:
      state = {
        ...state,
        mfaEnforceLoading: false,
        mfaEnforce: {},
      };
      return state;

    case actions.DELETE_MEMBER:
      toast.success("Member deleted successfully");
      state = {
        ...state,
        memberDeletedLoading: false,
        // memberDeleted: action.payload,
        memberDeleted: {
          status: true,
        },
      };
      return state;

    case actions.DELETE_MEMBER_START:
      state = {
        ...state,
        memberDeletedLoading: true,
      };
      return state;

    case actions.DELETE_MEMBER_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        memberDeletedLoading: false,
        memberDeleted: action.payload,
      };
      return state;

    case actions.DELETE_MEMBER_RESET:
      state = {
        ...state,
        memberDeletedLoading: false,
        memberDeleted: {},
      };
      return state;

    case actions.RESEND_INVITE:
      toast.success(action.payload.message);
      state = {
        ...state,
        inviteResendLoading: false,
        inviteResend: action.payload,
      };
      return state;

    case actions.RESEND_INVITE_START:
      state = {
        ...state,
        inviteResendLoading: true,
      };
      return state;

    case actions.RESEND_INVITE_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        inviteResendLoading: false,
        inviteResend: action.payload,
      };
      return state;

    case actions.CANCEL_INVITE:
      toast.success("Invite cancelled successfully");
      state = {
        ...state,
        inviteCancelLoading: false,
        // inviteCancel: action.payload,
        inviteCancel: {
          status: true,
        },
      };
      return state;

    case actions.CANCEL_INVITE_START:
      state = {
        ...state,
        inviteCancelLoading: true,
      };
      return state;

    case actions.CANCEL_INVITE_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        inviteCancelLoading: false,
        inviteCancel: action.payload,
      };
      return state;

    case actions.ACCEPT_INVITE:
      toast.success(action.payload.message);
      state = {
        ...state,
        inviteAcceptLoading: false,
        inviteAccept: action.payload,
      };
      return state;

    case actions.ACCEPT_INVITE_START:
      state = {
        ...state,
        inviteAcceptLoading: true,
      };
      return state;

    case actions.ACCEPT_INVITE_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        inviteAcceptLoading: false,
        inviteAccept: action.payload,
      };
      return state;

    case actions.UPDATE_FEES_EARNINGS:
      state = {
        ...state,
        feesEarningsUpdateLoading: false,
        feesEarningsUpdate: action.payload,
      };
      return state;

    case actions.UPDATE_FEES_EARNINGS_START:
      state = {
        ...state,
        feesEarningsUpdateLoading: true,
      };
      return state;

    case actions.UPDATE_FEES_EARNINGS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        feesEarningsUpdateLoading: false,
        feesEarningsUpdate: action.payload,
      };
      return state;

    case actions.UPDATE_FEES_EARNINGS_RESET:
      state = {
        ...state,
        feesEarningsUpdateLoading: false,
        feesEarningsUpdate: {},
      };
      return state;

    case actions.UPDATE_PAYMENT_METHOD:
      state = {
        ...state,
        paymentMethodUpdateLoading: false,
        paymentMethodUpdate: action.payload,
      };
      return state;

    case actions.UPDATE_PAYMENT_METHOD_START:
      state = {
        ...state,
        paymentMethodUpdateLoading: true,
      };
      return state;

    case actions.UPDATE_PAYMENT_METHOD_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        paymentMethodUpdateLoading: false,
        paymentMethodUpdate: action.payload,
      };
      return state;

    case actions.UPDATE_PAYMENT_METHOD_RESET:
      state = {
        ...state,
        paymentMethodUpdateLoading: false,
        paymentMethodUpdate: {},
      };
      return state;

    case actions.UPDATE_NOTIFICATION_PREFERNCE:
      state = {
        ...state,
        notificationPreferenceUpdateLoading: false,
        notificationPreferenceUpdate: action.payload,
      };
      return state;

    case actions.UPDATE_NOTIFICATION_PREFERNCE_START:
      state = {
        ...state,
        notificationPreferenceUpdateLoading: true,
      };
      return state;

    case actions.UPDATE_NOTIFICATION_PREFERNCE_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        notificationPreferenceUpdateLoading: false,
        notificationPreferenceUpdate: action.payload,
      };
      return state;

    case actions.UPDATE_NOTIFICATION_PREFERNCE_RESET:
      state = {
        ...state,
        notificationPreferenceUpdateLoading: false,
        notificationPreferenceUpdate: {},
      };
      return state;

    case actions.GET_KEYS:
      state = {
        ...state,
        keysLoading: false,
        keys: action.payload,
      };
      return state;

    case actions.GET_KEYS_START:
      state = {
        ...state,
        keysLoading: true,
      };
      return state;

    case actions.GET_KEYS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        keysLoading: false,
        keys: action.payload,
      };
      return state;

    case actions.UPDATE_WEBHOOKS:
      toast.success(action.payload.message);
      state = {
        ...state,
        webhooksUpdateLoading: false,
        webhooksUpdate: action.payload,
      };
      return state;

    case actions.UPDATE_WEBHOOKS_START:
      state = {
        ...state,
        webhooksUpdateLoading: true,
      };
      return state;

    case actions.UPDATE_WEBHOOKS_FAILED:
      errorReducer(action.payload);
      state = {
        ...state,
        webhooksUpdateLoading: false,
        webhooksUpdate: action.payload,
      };
      return state;

    case actions.UPDATE_WEBHOOKS_RESET:
      state = {
        ...state,
        webhooksUpdateLoading: false,
        webhooksUpdate: {},
      };
      return state;

    default:
      return state;
  }
}
