import { createContext, useContext, useState, ReactNode, FC } from 'react';

interface ToastContextType {
   toasts: CustomToastItemProps[];
   addCustomToast: (type: CustomToastType, message: string, onClick?: () => void) => void;
   removeToast: (id: number) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

let toastId = 0;

export const CustomToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
   const [toasts, setToasts] = useState<CustomToastItemProps[]>([]);

   const addCustomToast = (type: CustomToastType, message: string, onClick?: () => void) => {
      const id = toastId++;
      setToasts([...toasts, { id, type, message, onClick }]);
      setTimeout(() => removeToast(id), 3500);
   };

   const removeToast = (id: number) => {
      setToasts(toasts.filter(toast => toast.id !== id));
   };

   return (
      <ToastContext.Provider value={{ toasts, addCustomToast, removeToast }}>
         {children}
      </ToastContext.Provider>
   );
};

export const useCustomToast = (): ToastContextType => {
   const context = useContext(ToastContext);
   if (!context) {
      throw new Error('useToast must be used within a ToastProvider');
   }
   return context;
};
