import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthPageTitle from "components/common/AuthPageTitle";
import validate from "utils/validate";
import { ButtonLoader, FileUploadForm, Input, SelectInput } from "components/common/form";
import { ReactComponent as IconArrowLeft } from "assets/images/icons/ArrowLeft.svg";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import { meansOfIdentication } from "data/constants/common";
import PinModal from "components/auth/PinModal";
import useLoginInfo from "hooks/useLoginInfo";
import useMediaService from "hooks/useMediaService";
import { toast } from "react-toastify";
import moment from "moment";
import { useSharedStore } from "zustand-store";
import Maybe from "components/common/Maybe";
import * as businessVerificationActions from "store/entities/businessVerification/action";
import * as userActions from "store/auth/user/action";
import * as countriesActions from "store/ui/countries/action";

const BusinessInformation = (props: any) => {
    const { goPrevious, goNext } = props;
    const dispatch = useDispatch();
    const { userInfo } = useLoginInfo();
    const { uploadFile, uploadedFileLoading } = useMediaService();
    const { countriesLoading, countries } = useSelector((s: any) => s.ui.countries);
    const { businessVerificationUpdateLoading, businessVerificationUpdate } = useSelector((s: any) => s.entities.businessVerification);
    const { otpEmailSendLoading, otpEmailSend, otpEmailResendLoading, otpEmailResend, otpEmailVerificationLoading, otpEmailVerification } = useSelector((s: any) => s.auth.user);

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [isOpenOTP, setIsOpenOTP] = useState(false);
    const [otp, setOtp] = useState("");
    const { currencies } = useSharedStore();
    const [form, setForm] = useState({
        phoneNumber: "",
        country: "",
        dateOfBirth: "",
        address: "",
        meansOfIdentification: "",
        bvn: "",
        nin: "",
        identityDocument: "",
    });

    const populateForm = () => {
        setForm({
            phoneNumber: userInfo?.userProfile?.phoneNumber ?? "",
            country: userInfo?.userProfile?.country ?? "",
            dateOfBirth: userInfo?.userProfile?.dob ?? "",
            address: userInfo?.userProfile?.address ?? "",
            meansOfIdentification: userInfo?.userProfile?.idType ?? "",
            bvn: userInfo?.userProfile?.bvn ?? "",
            nin: userInfo?.userProfile?.nin ?? "",
            identityDocument: userInfo?.userProfile?.document ?? "",
        });
    };

    const getCountries = () => {
        if (!countriesLoading && (Object.keys(countries).length === 0 || countries?.success === false)) {
            dispatch(countriesActions.getCountries());
        }
    };

    const checkFormValidation = (data: any = form, appendKey: any, appendValue: any) => {
        let params = { ...data };
        if (appendKey) {
            params = {
                ...params,
                [appendKey]: appendValue,
            };
        }
        const errors = validate(params);
        if (errors) {
            setIsFormValidated(false);
        } else {
            setIsFormValidated(true);
        }
    };

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        const errors: any = validate(form);
        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            }
            return;
        } else {
            if (isFormValidated === true) {
                const params = {
                    identifier: form.phoneNumber,
                    type: "SMS",
                };
                dispatch(userActions.sendOtp(params));
            }
        }
    };

    const processFormSubmission = async () => {
        let params: any = {
            account: {
                address: form.address,
                country: form.country,
                dob: form.dateOfBirth,
                phoneNumber: form.phoneNumber,
            },
            idNumber: form.bvn,
            idType: form.meansOfIdentification,
        };

        if (typeof form.identityDocument !== "string") {
            const response: any = await uploadFile(form.identityDocument);
            if (response?.success === true) {
                params = { ...params, document: response?.data?.file?.link };
            }
        }
        dispatch(businessVerificationActions.updateBusinessOwner(params));
    };

    const resendOtp = () => {
        if (isFormValidated === true) {
            const params = {
                identifier: form.phoneNumber,
                type: "SMS",
            };
            dispatch(userActions.resendOtp(params));
        }
    };

    const submitOtp = () => {
        const errors: any = validate({ otp });
        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            }
            return;
        } else {
            const params = {
                code: otp,
                identifier: form.phoneNumber,
            };
            dispatch(userActions.verifyOtp(params));
        }
    };

    useEffect(() => {
        getCountries();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userInfo?.businessProfile !== undefined) {
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (otpEmailSend?.success === true) {
            setIsOpenOTP(true);
            dispatch(userActions.resetSendOtp());
        }
        // eslint-disable-next-line
    }, [otpEmailSend]);

    useEffect(() => {
        if (otpEmailResend?.success === true) {
            dispatch(userActions.resetResendOtp());
        }
        // eslint-disable-next-line
    }, [otpEmailSend]);

    useEffect(() => {
        if (otpEmailVerification?.status === true) {
            dispatch(userActions.resetVerifyOtp());
            processFormSubmission();
        }
        // eslint-disable-next-line
    }, [otpEmailVerification]);

    useEffect(() => {
        if (businessVerificationUpdate?.success === true) {
            dispatch(userActions.updateUserInfo({
                businessProfile: { ...businessVerificationUpdate?.data?.business ?? {} },
            }));
            dispatch(businessVerificationActions.resetUpdateBusinessVerification());
            goNext();
        }
        // eslint-disable-next-line
    }, [businessVerificationUpdate]);

    return (
        <div>
            <AuthPageTitle
                title={`Let’s know more about ${userInfo?.userProfile?.firstName ?? "-"} ${userInfo?.userProfile?.lastName}`}
            />

            <PinModal
                target="OTP"
                title="Confirm your Phone"
                pin={(e: any) => setOtp(e)}
                submit={submitOtp}
                submitText="Confirm your Phone"
                showResendEmail={true}
                resendEmailClick={resendOtp}
                resendEmailLoading={otpEmailResendLoading}
                resendEmailResponse={otpEmailResend}
                loading={otpEmailVerificationLoading || uploadedFileLoading || businessVerificationUpdateLoading}
                modalLoading={false}
                isOpenPin={isOpenOTP}
                setIsOpenPin={setIsOpenOTP}
            />

            <div className="pt-10 mt-10 border-t border-gray-100">
                <form onSubmit={submitForm}>

                    <Input
                        type="tel"
                        label="Business Phone number"
                        placeholder="Phone number"
                        required={true}
                        value={form.phoneNumber}
                        onChange={(e: any) => {
                            setForm({ ...form, phoneNumber: e.target.value });
                            checkFormValidation(form, "phoneNumber", e.target.value);
                        }}
                        validationName="phoneNumber"
                        checkFormValidation={checkFormValidation}
                    />

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                        <Maybe condition={countriesLoading === true}>
                            <Input
                                type="text"
                                label="Nationality"
                                value="Loading countries..."
                                required={true}
                                disabled={true}
                            />
                        </Maybe>
                        <Maybe condition={countriesLoading === false}>
                            <SelectInput
                                label="Nationality"
                                required={true}
                                value={form.country}
                                onChange={(e: any) => {
                                    setForm({ ...form, country: e.target.value });
                                    checkFormValidation(form, "country", e.target.value);
                                }}
                                validationName="country"
                                checkFormValidation={checkFormValidation}
                            >
                                <option value="" disabled>Select a Country</option>
                                {currencies.map((curr) => (
                                    <option value={curr.countryCode} key={curr.countryCode}>
                                        {curr.country}
                                    </option>
                                ))}
                            </SelectInput>
                        </Maybe>

                        <Input
                            type="date"
                            label="Date of Birth"
                            placeholder="DD/MM/YY"
                            required={true}
                            value={form.dateOfBirth}
                            max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                            onChange={(e: any) => {
                                setForm({ ...form, dateOfBirth: e.target.value });
                                checkFormValidation(form, "dateOfBirth", e.target.value);
                            }}
                            validationName="dateOfBirth"
                            checkFormValidation={checkFormValidation}
                        />
                    </div>

                    <Input
                        type="text"
                        label="Your Address"
                        placeholder="Search address"
                        required={true}
                        value={form.address}
                        onChange={(e: any) => {
                            setForm({ ...form, address: e.target.value });
                            checkFormValidation(form, "address", e.target.value);
                        }}
                        leftIcon={<IconSearch />}
                        leftIconClass="px-2.5"
                        validationName="address"
                        checkFormValidation={checkFormValidation}
                    />

                    <SelectInput
                        label="Means of identification"
                        required={true}
                        value={form.meansOfIdentification}
                        onChange={(e: any) => {
                            setForm({ ...form, meansOfIdentification: e.target.value });
                            checkFormValidation(form, "meansOfIdentification", e.target.value);
                        }}
                        validationName="meansOfIdentification"
                        checkFormValidation={checkFormValidation}
                    >
                        <option value="" disabled>Select a Document</option>
                        {Object.keys(meansOfIdentication).map((key) => (
                            <option value={key} key={key}>
                                {meansOfIdentication[key]}
                            </option>
                        ))}
                    </SelectInput>

                    <Maybe condition={form.country == "Nigeria"}>
                        <>
                            <Input
                                type="number"
                                label="Bank verification number"
                                placeholder="Bank Verification Number"
                                required={true}
                                value={form.bvn}
                                onChange={(e: any) => {
                                    setForm({ ...form, bvn: e.target.value });
                                    checkFormValidation(form, "bvn", e.target.value);
                                }}
                                validationName="bvn"
                                checkFormValidation={checkFormValidation}
                            />

                            <Input
                                type="number"
                                label="National Identification Number"
                                placeholder="National Identification Number"
                                required={true}
                                value={form.nin}
                                onChange={(e: any) => {
                                    setForm({ ...form, nin: e.target.value });
                                    checkFormValidation(form, "nin", e.target.value);
                                }}
                                validationName="nin"
                                checkFormValidation={checkFormValidation}
                            />
                        </>
                    </Maybe>

                    <FileUploadForm
                        label="Upload Identification document"
                        required={true}
                        // sampleFile=""
                        data={form.identityDocument}
                        onchange={(e: any) => {
                            setForm({ ...form, identityDocument: e });
                            checkFormValidation(form, "identityDocument", e);
                        }}
                        filetype={["pdf", "csv"]}
                        filename="Identification document"
                        validationName="identityDocument"
                        checkFormValidation={checkFormValidation}
                    />

                    <div className="mt-10 flex space-x-2 items-center justify-between">
                        <ButtonLoader type="button" onClick={goPrevious} loading={false} className="max-w-[10rem] btn btn-block btn-lg btn-white">
                            <IconArrowLeft className="mr-3" />
                            <span>Back</span>
                        </ButtonLoader>
                        <ButtonLoader type="submit" loading={otpEmailSendLoading} className="max-w-[16rem] btn btn-block btn-lg btn-primary">
                            Continue
                        </ButtonLoader>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default BusinessInformation;
