import { useEffect, useMemo, useState } from "react";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import * as dashboardActions from "store/dashboard/action"
import * as transactionsActions from "store/transactions/action"
import BusinessVerification from "components/businessVerification/Layoutt";
import { getCurrentDate, getCurrentGreeting } from "utils/currentDate";
import { ReactComponent as IconCards } from "assets/images/icons/nav-cards.svg";
import { ReactComponent as IconPayments } from "assets/images/icons/nav-payments.svg";
import { PageContentCustom } from "components/common/PageContent";

import { GenerateStatementModal } from "./components/GenerateStatementModal";
import { SuccessRateModal } from "./components/SuccessRateModal";
import { AddMoneyOptionsModal } from "./components/AddMoneyOptionsModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import useLoginInfo from "hooks/useLoginInfo";
import PATHS from "NavigationRoute";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getDashboard } from "store/dashboard/reducer";
import { actionOptionsData, exportOptionsData, transactionsRangeData } from "./data";
import { ExportOption } from "./interface";
import { DashboardHeader } from "./components/DashboardHeader";
import { DashboardChart } from "./components/DashboardChart";
import { OverviewCard } from "./components/OverviewCard";
import { DashboardCard } from "./components/DashboardCard";
import { DashboardActivity } from "./components/DashboardActivity";
import { CurrencyType } from "types/misc";
import { getCurrencies } from "store/ui/currencies/action";
import { currencyFormat } from "lib/format.amount";
import { DashboardTrend } from "./components/DashboardTrend";
import { showBusinessStatus } from "./components/GetDashboardStatus";

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<CurrencyType>("NGN");
  const currentEnv = process.env.REACT_APP_ENVIRONMENT;
  const [isOpenBusinessVerification, setIsOpenBusinessVerification] =
    useState(false);

  const [isAddMoneyModalOpen, setIsAddMoneyModalOpen] = useState(false);

  const { userInfo } = useLoginInfo();

  const [transactionsRange, setTransactionsRange] = useState(transactionsRangeData);
  const [exportOptions, setExportOptions] = useState(exportOptionsData);

  const [actionOptions, setActionOptions] = useState(actionOptionsData);
  const {
    activities, cards, overview, chart, wallets, businessStatus } = useAppSelector(getDashboard);

  const walletTabs = useMemo(() => wallets?.data.map(item => item.currency) || [], [wallets]);
  const dashboardOverview = useMemo(() => overview?.data, [overview]);
  const chartData = useMemo(() => chart?.data, [chart]);

  const dashboardTrendOverview = useMemo(() => [
    {
      title: "Available Balance",
      heading: currencyFormat(dashboardOverview?.balance?.balance || 0, dashboardOverview?.balance.currency || "NGN"),
      currentTrend: dashboardOverview?.availableBalance?.today || 0,
      prevTrend: dashboardOverview?.availableBalance?.previous,
      tooltip: <span>Total wallet balance for a <br /> selected currency on <br /> your business account.</span>
    },
    {
      title: "Total Transactions",
      heading: dashboardOverview?.transactions?.total || 0,
      currentTrend: dashboardOverview?.transactions?.total || 0,
      prevTrend: dashboardOverview?.transactions?.total || 0,
      tooltip: <span>Count of transactions <br /> attempts initiated on <br /> your business account.</span>
    },
    {
      title: "Total Settlement",
      heading: currencyFormat(dashboardOverview?.settlement?.today || 0, dashboardOverview?.balance.currency || "NGN"),
      currentTrend: dashboardOverview?.settlement?.today || 0,
      prevTrend: dashboardOverview?.settlement?.previous,
      tooltip: <span>Total payouts made into <br /> your business settlement <br /> account.</span>
    }
  ] as const, [dashboardOverview]);

  useEffect(() => {
    dispatch(getCurrencies());
    dispatch(dashboardActions.getDashboardActivities());
    dispatch(dashboardActions.getDashboardCards());
    dispatch(transactionsActions.getTransactions());
    dispatch(dashboardActions.getDashboardWallets());
    dispatch(dashboardActions.getBusinessStatus());
  }, [])

  useEffect(() => {
    if (walletTabs.length) {
      setCurrentTab(walletTabs[0])
    }
    const fromCurrency = searchParams.get("currency");
    if (fromCurrency?.length) {
      setCurrentTab(fromCurrency.toUpperCase() as CurrencyType);
    }
  }, [searchParams, walletTabs])

  useEffect(() => {
    if (currentTab.length) {
      dispatch(dashboardActions.getDashboardOverview({
        currency: currentTab,
      }))
      dispatch(dashboardActions.getDashboardChart({
        currency: currentTab,
        dateFilter: transactionsRange.find(item => item.selected)?.value || "",
        type: exportOptions.find(item => item.selected)?.value as ExportOption<"CREDIT" | "DEBIT">['value']
      }))
    }
  }, [currentTab, dispatch, exportOptions, transactionsRange]);


  const showBusinessVerification = () => {
    setIsOpenBusinessVerification(true);
    // add shrink-class to body
  };

  const hideBusinessVerification = () => {
    setIsOpenBusinessVerification(false);
    // remove shrink-class from body
  };

  const updateExportOptions = (e: any) => {
    let data = exportOptions.map((option) => {
      return {
        ...option,
        selected: option.value === e,
      };
    });
    setExportOptions(data);
  };

  const updateTransactionsRange = (e: any) => {
    let data = transactionsRange.map((option) => {
      return {
        ...option,
        selected: option.value === e.value,
      };
    });
    setTransactionsRange(data);
  };

  const updateActionOptions = (selected: string) => {
    if (selected === "convert-money") {
      navigate(`/transactions/convert-money?fromCurrency=${currentTab}`)
      return;
    }

    const updatedActionOptions = actionOptions.map((action) => {
      if (selected !== action.value) return { ...action, selected: false };
      return { ...action, selected: !action.selected };
    });

    setActionOptions(updatedActionOptions);
  };
  const handleMoneyOnClick = () => {
    if (currentTab === "NGN") {
      setIsAddMoneyModalOpen(true)
    } else if (currentTab === "GBP") {
      navigate(`/dashboard/direct-deposit?currency=${currentTab}`)
    } else {
      navigate(`/transactions/convert-money?fromCurrency=${currentTab}`)
    }
  }
 
  const onSelectWallet = (newTab: string) => () => {
    const params = new URLSearchParams(window.location.search);
    params.set('currency', newTab);
    navigate(`${window.location.pathname}?${params.toString()}`, { replace: true })
  }
  return (
    <>
      {
        businessStatus.length ?
          showBusinessStatus(currentEnv ? currentEnv[0] : businessStatus, showBusinessVerification) : <></>
      }
      <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
        <DynamicHeadTag headerText="Dashboard" />

        <BusinessVerification
          isOpen={isOpenBusinessVerification}
          setIsOpen={hideBusinessVerification}
        />

        <div className="pb-5 mb-4 md:mb-8 lg:flex lg:items-center lg:justify-between border-b border-gray-100">
          <div className="page-title capitalize">
            {getCurrentGreeting()},{" "}
            {userInfo?.userProfile && userInfo.userProfile["firstName"]}
          </div>

          {/* todo: toggle before pusing to prod */}
          <div className="hidden sm:block md:hidden lg:block text-shiga-gray">
            {getCurrentDate()}
          </div>
          <AddMoneyOptionsModal
            currentTab={currentTab}
            open={isAddMoneyModalOpen}
            closeModal={() => setIsAddMoneyModalOpen(false)}
          />
          <GenerateStatementModal
            currentWallet={currentTab}
            wallets={wallets?.data || []}
            open={actionOptions[1].selected}
            closeModal={() => updateActionOptions(actionOptions[1].value)}
          />
          {
            dashboardOverview?.transactions ?
              <SuccessRateModal transactionRate={dashboardOverview!.transactions}
                open={actionOptions[2].selected}
                closeModal={() => updateActionOptions(actionOptions[2].value)}
              /> : <></>
          }
        </div>
        {
          walletTabs.length ?
            <div className="font-medium flex items-center justify-between border-b border-gray-50">
              <div className="flex overflow-auto">
                {
                  walletTabs.map((item, idx) => (
                    <div key={idx} onClick={onSelectWallet(item)}
                      className={`tab ${currentTab === item ? "active" : ""}`}>{item}</div>
                  ))
                }
              </div>
              <DashboardHeader
                actionOptions={actionOptions}
                handleAddMoney={handleMoneyOnClick}
                handleTransfer={() => navigate(PATHS.TRANSFER)}
                updateActionOptions={updateActionOptions}
              />
            </div> : <></>
        }

        <div className="mt-8 md:mt-12 w-full flex flex-shrink-0 overflow-auto">
          {
            dashboardTrendOverview.map((item, idx) => (
              <>
                <DashboardTrend key={idx} id={idx}
                  heading={item.heading as string}
                  currentTrend={item.currentTrend || 0}
                  prevTrend={item.prevTrend || 0} title={item.title}
                  tooltip={item.tooltip}
                />
                {
                  idx !== 2 ?
                    <div className="w-6 flex-shrink-0"></div> : <></>
                }
              </>
            ))
          }
        </div>

        <DashboardChart data={chartData || []}
          {...{ exportOptions, transactionsRange, updateExportOptions, updateTransactionsRange, currentTab }} />

        <div className="mt-8 lg:flex justify-between lg:border-t border-gray-100">
          <OverviewCard link="/cards" details={cards?.data || []} RenderComponent={DashboardCard} title="Cards"
            EmptyComponent={<PageContentCustom
              image={<IconCards className="w-24 h-24 mx-auto" />}
              text="You have no card on this account at this time"
              classes="max-w-[16rem] mx-auto"
            />} containerStyle="lg:w-1/2 lg:pr-10 lg:border-r border-gray-100" />

          <OverviewCard link="/transactions" details={activities?.data || []} RenderComponent={DashboardActivity} title="Recent Transactions"
            EmptyComponent={<PageContentCustom
              image={<IconPayments className="w-24 h-24 mx-auto" />}
              text="You have no transaction on this account at this time"
              classes="max-w-[16rem] mx-auto"
            />} containerStyle="lg:w-1/2 lg:pl-10 lg:border-l border-gray-100"
            onClick={transaction => navigate(`/transactions?transactionID=${transaction.id}`)}
            />
        </div>
      </div>
    </>
  );
};

export default Index;
