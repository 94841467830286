import { zodResolver } from "@hookform/resolvers/zod";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { PageHeading } from "components/common/PageHeading";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";

import { ReactComponent as Lock } from "assets/images/icons/payment/lock.svg";
import { CircleCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { University } from "types/shared/university.type";
import { useSharedStore } from "zustand-store";
import { useTuitionStore } from "zustand-store/payments/tution";
import { PAYMENT_PATHS } from "../paths";
import CommandSelect from "components/ui/command-select";
import { Input } from "components/common/form";

const FormSchema = z.object({
  country: z.string().min(2, {
    message: "country must be at least 2 characters.",
  }),
  university: z.string(),
  address: z.string().min(2, {
    message: "Address must be at least 2 characters.",
  }),
});

export const Paytution = () => {
  const navigate = useNavigate();
  const { currencies, fetchUniversities, universities } = useSharedStore();
  const [university, setUniversity] = useState<University | null>(null);
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      country: "GB",
    },
  });

  const { payload, setPayload } = useTuitionStore();

  const country = form.watch("country");
  const university_nme = form.watch("university");

  useEffect(() => {
    if (!!payload.university.id) {
      form.setValue("university", payload.university.universityName);
      form.setValue("address", payload.university.universityName);
      form.setValue("country", payload.university.country);
    }
  }, [payload, form]);

  useEffect(() => {
    fetchUniversities(country || "GB");
  }, [country, fetchUniversities]);

  useEffect(() => {
    if (university_nme) {
      const fUni =
        universities?.find((uni) => uni.universityName === university_nme) ||
        ({} as University);
      form.setValue("address", fUni?.universityName || "");
      setUniversity(fUni);
    }
  }, [form, university_nme, universities]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    if (university) {
      setPayload({ university });
    }
    navigate(PAYMENT_PATHS.TUITION_STEPS);
  }

  return (
    <div className="w-full p-10  space-y-4 flex flex-col justify-center items-center">
      <PageHeading title="Add Institution’s Information" />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] space-y-6"
        >
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="flex flex-col space-y-6">
                <CommandSelect
                  notFound="No institution found."
                  disableInput
                  label="Country"
                  handleSelect={(arg) => {
                    form.setValue(
                      "country",
                      arg.countryCode
                    );
                  }}
                  options={currencies.map(item => ({
                    ...item,
                    id: item.name,
                    value: item.id + ""
                  }))}
                  SelectTrigger={() => (
                    <span className="flex items-center gap-4">
                      <img
                        src={
                          currencies?.find(
                            (cur) => cur.countryCode === field.value
                          )?.icon || noisygradient
                        }
                        alt={field.value}
                        className="size-5"
                      />

                      {currencies?.find(
                        (cur) => cur.countryCode === field.value
                      )?.country || ""}
                    </span>
                  )}
                  RenderOption={(currency) => (
                    <>
                      <span className="flex items-center gap-1.5">
                        <img
                          src={currency.item.icon || noisygradient}
                          alt={currency.item.country}
                          className="size-5"
                        />
                        {currency.item.country}
                      </span>
                      {field.value === currency.item.country && (
                        <CircleCheck className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
                      )}
                    </>
                  )}
                />
                <FormMessage className="text-shiga-dark">More countries coming soon...</FormMessage>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="university"
            render={({ field }) => (
              <FormItem className="flex flex-col space-y-3">
                <CommandSelect
                  notFound="No university found."
                  label="Institution’s Name"
                  searchPlaceholder="Search for Institution."
                  options={universities.map(item => ({
                    ...item,
                    id: item.id,
                    value: item.universityName
                  }))}
                  SelectTrigger={() => (
                    <span className="flex items-center gap-4">
                      <img
                        src={noisygradient}
                        alt={field.value}
                        className="size-5"
                      />

                      {field.value}
                    </span>
                  )}
                  RenderOption={({ item }) => (
                    <span className="flex items-center gap-1.5">
                      <img
                        src={noisygradient}
                        alt={item.universityName}
                        className="size-5"
                      />
                      {item.universityName}
                    </span>
                  )}
                  handleSelect={(select) => {
                    form.setValue(
                      "university",
                      select.universityName
                    );
                  }}
                />
              </FormItem>
            )}
          />

          {university && (
            <>
              <Input
                labelClass="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight"
                placeholder="School address"
                label="Account Number"
                required
                disabled
                value={university?.accountNumber}
                rightIcon={<Lock />}
              />
                <Input
                  labelClass="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight"
                  placeholder="Sort code"
                  label="Sort Code"
                  required
                  disabled
                  value={university?.sortCode}
                  rightIcon={<Lock />}
                />
              </>
          )}
              <FormField
                control={form.control}
                name="address"
                render={() => (
                  <FormItem>
                    <FormControl>
                      <div className="relative">
                        <Input
                          label="School Address"
                          disabled
                          value={university?.universityName}
                          placeholder="School address"
                          rightIcon={<Lock />}
                        />
                      </div>
                    </FormControl>
                  </FormItem>
                )}
              />

              <Button className="w-full" type="submit">
                Continue
              </Button>
            </form>
      </Form>
    </div>
  );
};
