import { NavLink } from "react-router-dom";
import { ReactComponent as IconDashboard } from "assets/images/icons/nav-dashboard.svg";
import { ReactComponent as IconRefund } from "assets/images/icons/nav-refund.svg";
import { ReactComponent as IconTransactions } from "assets/images/icons/nav-transactions.svg";
import { ReactComponent as IconPayments } from "assets/images/icons/nav-payments.svg";
import { ReactComponent as IconCards } from "assets/images/icons/nav-cards.svg";
import { ReactComponent as IconBills } from "assets/images/icons/nav-bills.svg";
import { ReactComponent as IconAccount } from "assets/images/icons/nav-account.svg";
import { ReactComponent as IconEcommerce } from "assets/images/icons/nav-ecommerce.svg";
import { ReactComponent as IconSettings } from "assets/images/icons/nav-settings.svg";
import { ReactComponent as IconContact } from "assets/images/icons/nav-contact.svg";
import PATHS from "NavigationRoute";

export const NavigationLinks = () => {
  return (
    <div className="h-full flex flex-col justify-between">
      <div className="">
        <NavLink to="/dashboard" className="sidebar-links">
          <IconDashboard className="sidebar-links-icon" />
          <div>Dashboard</div>
        </NavLink>
        <NavLink to="/transactions" className="sidebar-links">
          <IconTransactions className="sidebar-links-icon" />
          <div>Transactions</div>
        </NavLink>
        <NavLink to="/refunds" className="sidebar-links">
          <IconRefund className="sidebar-links-icon" />
          <div>Refunds</div>
        </NavLink>
        <NavLink to="/payments" className="sidebar-links">
          <IconPayments className="sidebar-links-icon" />
          <div>Payments</div>
        </NavLink>
        <NavLink to="/cards" className="sidebar-links">
          <IconCards className="sidebar-links-icon" />
          <div>Cards</div>
        </NavLink>
        <NavLink to={PATHS.ISSUED_ACCOUNTS} className="sidebar-links">
          <IconAccount className="sidebar-links-icon" />
          <div>Accounts</div>
        </NavLink>
        <NavLink to="/manage-bills" className="sidebar-links">
          <IconBills className="sidebar-links-icon" />
          <div>Manage Bills</div>
        </NavLink>
        <NavLink to="/ecommerce" className="sidebar-links">
          <IconEcommerce className="sidebar-links-icon" />
          <div>Ecommerce</div>
        </NavLink>
      </div>

      <div className="">
        <NavLink to="/settings" className="sidebar-links">
          <IconSettings className="sidebar-links-icon" />
          <div>Settings</div>
        </NavLink>

        <NavLink to="mailto:support@payshiga.com" className="sidebar-links">
          <IconContact className="sidebar-links-icon" />
          <div>Contact us</div>
        </NavLink>
        <div className="h-4 hidden md:block"></div>
      </div>
    </div>
  );
};

export default NavigationLinks;
