import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { zodResolver } from "@hookform/resolvers/zod";
import { Label } from "@radix-ui/react-label";
import { Upload } from "components/common/Upload";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { Switch } from "components/ui/switch";
import { Textarea } from "components/ui/textarea";
import { uploadFile } from "lib/files";
import {
  createPaymentLink,
  getPaymentLink,
  updatePaymentLink,
} from "lib/payments";
import {
  CheckCircle2,
  Info,
  Loader,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { PaymentLink } from "types/payment/payments.interface";
import { z } from "zod";
import { useSharedStore } from "zustand-store";
import { Input, InputAmount } from "components/common/form";
import CommandSelect from "components/ui/command-select";

const PaymentDetailsStepFormSchema = z.object({
  name: z.string({ message: "name is required" }),
  amount: z.coerce.number().optional(),
  currency: z.string({ message: "currency is required" }),
  description: z.string().optional(),
  callbackUrl: z.string().optional(),
  slug: z.string().optional(),
  acceptOtherCurrencies: z.boolean(),
});

export const PersonalizeLink = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [link, setLink] = useState<PaymentLink | null>(null);

  const { currencies } = useSharedStore();

  const form = useForm<z.infer<typeof PaymentDetailsStepFormSchema>>({
    resolver: zodResolver(PaymentDetailsStepFormSchema),
    defaultValues: {
      name: link?.name,
      amount: link?.amount,
      currency: link?.currency || "NGN",
      description: link?.description,
      callbackUrl: link?.callbackUrl || "http://checkout.payshiga.com/",
      acceptOtherCurrencies: link?.acceptOthers || false,
    },
  });

  const slug = form.watch("slug");

  const [previewUrl, setPreviewUrl] = useState("");
  useEffect(() => {
    if (id) {
      getPaymentLink(id).then((res) => {
        const l = res.data.paymentLink;
        setLink(l);
        form.setValue("name", l.name);
        form.setValue("amount", l.amount);
        form.setValue("currency", l.currency);
        form.setValue("description", l.description);
        form.setValue("callbackUrl", l.callbackUrl);
        form.setValue("slug", l.slug);
        form.setValue("acceptOtherCurrencies", l?.acceptOthers);
        setPreviewUrl(l.logoUrl);
      });
    }
  }, [id, form]);

  const [type, setType] = useState<"FIXED" | "FLEXIBLE">("FIXED");

  const [file, setFile] = useState<File | null>(null);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const handleUpdatePaymentLink = (data: z.infer<typeof PaymentDetailsStepFormSchema>) => {
    return updatePaymentLink(link!.id, {
      description: data.description,
      amount: data?.amount,
      name: data.name,
    }).then((res) => {
      if (res.success) {
        toast("Payment link Updated Successfully.");

        navigate("/payments");
      } else {
        setDisabled(false);
      }
    }).catch(err => {
      toast.error(`Something went wrong in ${err.message}`)
    });
  }

  const handleUpload = (data: z.infer<typeof PaymentDetailsStepFormSchema>, file: File) => {
    return uploadFile(file).then((res) => res.data.file.link);
  }

  async function onSubmit(data: z.infer<typeof PaymentDetailsStepFormSchema>) {
    setDisabled(true);
    if (link) {
      handleUpdatePaymentLink(data)
    } else {
      let logoUrl = file ? await handleUpload(data, file) : "";
      createPaymentLink({
        ...data,
        amountType: type,
        currency: {
          value: data.currency || "NGN",
          type,
        },
        logoUrl,
        callbackUrl: data.callbackUrl || "",
        slug: data.slug,
        ...(type === "FIXED" ? { amount: data.amount } : {}),
        ...(data.amount ? {amount: data.amount * 100} : {})
      }).then((res) => {
        if (res.error) {
          toast.error(`Something went wrong ${res.error}`)
        }else if (res.success) {
          toast("Payment link Created Successfully.");

          navigate("/payments");
        }
      }).catch(err => {
        toast.error(`Something went wrong in ${err.message}`)
      }).finally(() => {
        setDisabled(false);
      });
    }
  }

  return (
    <section className="mt-12 px-8 lg:px-0 flex flex-col-reverse lg:flex-row lg:justify-around">
      <div className="space-y-7 mt-5 lg:mt-0 lg:w-[412px]">
        <Tabs defaultValue="money-in" className="w-full">
          <TabsList className="grid w-full grid-cols-2 rounded-3xl *:rounded-3xl">
            <TabsTrigger onClick={() => setType("FIXED")} value="money-in">
              Fixed Amount
            </TabsTrigger>
            <TabsTrigger onClick={() => setType("FLEXIBLE")} value="money-out">
              Flexible Amount
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Name of Payment Link
                    <span className="text-warn ml-0.5">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Enter a name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {type === "FLEXIBLE" ? (
              <FormField
                control={form.control}
                name="currency"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <CommandSelect
                      filterOption={(value, search) => {
                        if (value.includes(search)) return 1
                        return 0
                      }}
                      notFound="No currency found."
                      label="Accept payment in"
                      options={currencies.map(item => ({
                        ...item,
                        id: item.id + "",
                        value: item.currency
                      }))}
                      SelectTrigger={() => (
                        <span className="flex items-center">
                          <img
                            src={currencies.find(currency => currency.currency === field.value)?.icon || noisygradient}
                            alt={field.value}
                            className="size-5 mr-3"
                          />
                          {field.value}
                        </span>
                      )}
                      RenderOption={({ item }) => (
                        <>
                          <span className="flex">
                            <img
                              src={item.icon || noisygradient}
                              alt={item.country}
                              className="size-5 mr-3"
                            />
                            {item.country}

                          </span>
                          {field.value === item.country && (
                            <CheckCircle2 className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
                          )}
                        </>
                      )}
                      handleSelect={(select) => {
                        form.setValue("currency", select.value);
                      }}
                    />
                  </FormItem>
                )}
              />
            ) : (
              <div>
                <InputAmount
                  currencies={currencies}
                  form={form as any}
                  minAmount={0}
                />
                <FormField
                  control={form.control}
                  name="acceptOtherCurrencies"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex items-center w-full py-4 justify-between">
                          <div className="flex items-center gap-2">
                            <Info className="text-white fill-neutral-400" />
                            <Label htmlFor="acceptOtherCurrencies">
                              Accept payment in other currencies
                            </Label>
                          </div>
                          <Switch
                            disabled={link !== null || true}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                            id="acceptOtherCurrencies"
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Add a Description (optional)</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Say a bit more about this link"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Separator />
            <h4 className="font-medium">Advanced Customer Options:</h4>

            <FormField
              control={form.control}
              name="slug"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Custom URL
                  </FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        containerClass="mb-0"
                        disabled={link !== null}
                        {...field}
                        placeholder="abcd.xyz"
                      />
                      <span className="flex text-[#B4B4B4]">
                        http://checkout.payshiga.com/
                        <p className="text-[#1F1F1E]">{slug}</p>
                      </span>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button disabled={disabled} type="submit" className="w-full">
              {disabled ? (
                <Loader className="animate-spin" />
              ) : link ? (
                "Update Link"
              ) : (
                "Create Link"
              )}
            </Button>
          </form>
        </Form>
      </div>
      <div className=" flex justify-center">
        <Upload
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          file={file}
          setFile={setFile}
        />
      </div>
    </section>
  );
};
