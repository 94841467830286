import { Steps } from "types/payment/steps";
import user from "assets/images/icons/user_circle.png";
import { ReactComponent as Note } from "assets/images/icons/note.svg";

export const CREATE_INVOICE_STEPS: Steps[] = [
  {
    name: "Invoice Details",
    slug: "invoice_details",
    icon: <Note />,
  },
  {
    name: "Receipient",
    slug: "receipient",
    icon: <img src={user} alt="user" />,
  },
  {
    name: "Add Items",
    slug: "add_items",
    icon: <img src={user} alt="user" />,
  },
  {
    name: "Review & Send",
    slug: "review_send",
    icon: <img src={user} alt="user" />,
  },
];
