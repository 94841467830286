import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { NUMBER_REGEX } from "constants/number.regex";
import { CheckCircle2 } from "lucide-react";
import { toast } from "react-toastify";
import { Input } from "components/ui/input";
import { Currency } from "types/shared/countries.types";
import { useEffect, useState } from "react";
import { getCurrentBalance } from "lib/business";
import { Wallet, defaultWallet } from "types/wallet";
import CommandSelect from "components/ui/command-select";
import { cn } from "lib/utils";
import { currencyFormat } from "lib/format.amount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InputAmountHeader: React.FC<{
  currentWallet: Wallet;
  label?: string;
}> = ({ currentWallet: { currency, balance }, label }) => {
  return (
    <div className="flex mb-4 justify-between">
      <p className="truncate">
        {label ? label : "Amount"}
        <span className="form-input-required">*</span>
      </p>
      {balance && currency ? (
        <p className="text-shiga-dark truncate w-3/5">
          <span>
            <FontAwesomeIcon icon={faWallet} />
          </span>
          You have{" "}
          <span className="text-shiga-purple ">
            {currencyFormat(balance, currency)}
          </span>{" "}
          available
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

interface InputAmountProps<K extends FieldValues> {
  form: UseFormReturn<K, any, undefined>;
  minAmount: number;
  label?: string;
  header?: (arg: { currentWallet: Wallet; label?: string }) => React.ReactNode;
  footer?: (arg: { currentWallet: Wallet }) => React.ReactNode;
  currencies: Currency[];
  disableAmount?: boolean;
  disableCurrency?: boolean;
  formKey?: K;
}
export function InputAmount<K extends FieldValues>({
  form,
  label,
  disableAmount,
  currencies,
  disableCurrency,
  header,
  formKey,
  footer,
}: InputAmountProps<K>) {
  const formValues = Object.keys(
    formKey
      ? formKey
      : {
          currency: "",
          amount: "",
        }
  );
  const currency = formValues[0] as Path<K>;
  const amount = formValues[1] as Path<K>;
  const currentCurrency = form.watch(currency);
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);

  useEffect(() => {
    if (currentCurrency && header) {
      getCurrentBalance(currentCurrency)
        .then((data) => {
          setCurrentWallet(data.data);
          form.setValue("balance" as Path<K>, String(data.data.balance) as any);
        })
        .catch((err) => {
          form.setValue(currency, currentWallet.currency as any);
          toast.error(`Unable to get balance ${err.message}`);
        });
    }
  }, [currentCurrency, header]);

  return (
    <FormField
      control={form.control}
      name={amount}
      render={({ field }) => (
        <FormItem>
          {header && header({ currentWallet: currentWallet, label: label })}
          <FormControl>
            <div className="relative flex rounded-2xl border border-zinc-200 ">
              <Input
                // disabled={disableAmount}
                style={{ color: disableAmount ? 'black !important' : 'inherit', pointerEvents: disableAmount ? 'none' : 'auto' }}
                className={`h-[77px] text-[32px] text-black bg-white focus:border-0 border font-ojah rounded-2xl ${disableAmount ? "text-black" : "black"}`}
                placeholder={"0"}
                {...field}
                type="text"
                onChange={(e) => {
                  let inputValue = e.target.value;
                  if (NUMBER_REGEX.test(inputValue)) {
                    if (inputValue.length > 1 && inputValue[0] === "0") {
                      inputValue = inputValue.slice(1);
                    }
                    form.setValue(amount, Number(inputValue) as any);
                  }
                }}
              />
              <FormField
                control={form.control}
                name={currency}
                render={({ field }) => (
                  <FormItem className={`flex pr-4 items-center justify-center`}>
                    <CommandSelect
                      disableInput={disableCurrency}
                      filterOption={(value, search) => {
                        console.log({ value, search });
                        if (value.includes(search)) return 1;
                        return 0;
                      }}
                      notFound="No currency found."
                      options={currencies.map((item) => ({
                        ...item,
                        id: item.id + "",
                        value: item.currency,
                      }))}
                      buttonSelectClass={cn(
                        "max-w-32  w-full rounded-3xl text-black bg-white justify-between focus:ring ring-black data-[state=open]:ring aria-[invalid=true]:ring aria-[invalid=true]:ring-warn",
                        !field.value ? "text-black" : "text-black",
                        // disableCurrency ? "cursor-not-allowed" : ""
                      )}
                      SelectTrigger={() =>
                        field.value?.length ? (
                          <span className="flex items-center gap-1.5 text-[20px] text-shiga-black"  style={{ color: disableCurrency ? 'black' : 'inherit' }}>
                            {currencies?.find(
                              (cur) => cur.currency === field.value
                            ) && (
                              <img
                                src={
                                  currencies?.find(
                                    (cur) => cur.currency === field.value
                                  )?.icon
                                }
                                alt={field.value}
                                className="size-5"
                              />
                            )}
                            {field.value}
                          </span>
                        ) : (
                          <span className="flex items-center gap-1.5 text-[20px] text-shiga-black"    style={{ color: disableCurrency ? 'black' : 'inherit' }}>
                            {currencies?.find(
                              (cur) => cur.currency === "NGN"
                            ) && (
                              <img
                                src={
                                  currencies?.find(
                                    (cur) => cur.currency === "NGN"
                                  )?.icon
                                }
                                alt={field.value}
                                className="size-5"
                              />
                            )}
                            NGN
                          </span>
                        )
                      }
                      RenderOption={({ item }) => (
                        <>
                          <span className="flex">
                            <img
                              src={item.icon || noisygradient}
                              alt={item.country}
                              className="size-5 mr-3"
                            />
                            {item.country}
                          </span>
                          {field.value === item.country}
                        </>
                      )}
                      handleSelect={(select) => {
                        form.setValue(currency, select.value as any);
                      }}
                    />
                  </FormItem>
                )}
              />
            </div>
          </FormControl>
          {footer && footer({ currentWallet: currentWallet })}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export default InputAmount;
