import { Dispatch, SetStateAction, useState } from "react";
import { ReactComponent as IconNavigation } from "assets/images/icons/arrow-left.svg";
import { ReactComponent as IconProfile } from "assets/images/icons/profile.svg";
import { ReactComponent as Rectangle } from "assets/images/icons/rectangle.svg";
import { ReactComponent as IconArrowRight } from "assets/images/icons/arrow-right.svg";
import { ReactComponent as IconInfo } from "assets/images/icons/vital-info.svg";
import { ReactComponent as IconFileUpload } from "assets/images/icons/file-upload.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as FileType } from "assets/images/icons/FileType.svg";
import { ReactComponent as FileType2 } from "assets/images/icons/FileType2.svg";
import { ButtonLoader, FileUploadForm, Input, SelectInput } from "components/common/form";
import { useNavigate } from "react-router-dom";
import validate from "utils/validate";
import { FileUploader } from "react-drag-drop-files";
import { BulkTransferTable } from "./BulkTransferTable";
import { EditTransfer } from "./EditTransfer";
import { toast } from "sonner";

const instructions = [
    "You can upload .xlsx or .csv file",
    "Make sure your file has correct headers such as Bank Account No, Bank Name, Account Name and Amount",
    "Number of row should not exceed 1,000"
]

const transferContent = [
    {
        title: "Transaction Type",
        detail: "Bulk Payment",
    },
    {
        title: "Sent to",
        detail: "25 recipients",
    },
    {
        title: "From",
        detail: "NGN Wallet",
    },
    {
        title: "Fee",
        detail: "Free 😎",
    },
];

const UploadRecipient: React.FC<{
    goToNext:Dispatch<SetStateAction<number>>
}> = ({ goToNext }) => {
    return (
        <div className="max-w-md space-y-8 mx-auto">
            <div className="text-4xl font-ojah font-bold pb-5 border-b border-gray-100">
                Upload Bulk recipients
            </div>
            <div className="flex flex-col space-y-7">
                <div className="flex flex-col space-y-2">
                    <h6 className="text-shiga-dark font-medium">FOLLOW THE GUIDELINES BELOW</h6>
                    {
                        instructions.map((item, idx) => (
                            <span key={idx} className="flex items-center">
                                <div className="p-.5 mr-1.5">
                                    <IconInfo className="w-3 h-3" />
                                </div>
                                <p className="text-shiga-black">{item}</p>
                            </span>
                        ))
                    }
                </div>
                <SelectInput
                    type="text"
                    label="Pay from"
                    placeholder="Select a wallet"
                    required
                // onChange={(e: any) =>
                //     setForm((prev) => ({
                //         ...prev,
                //         cableProvider: e.target.value,
                //     }))
                // }
                >
                    {["Select a wallet"]
                        // .concat(utilityServiceProviders)
                        .map((provider, index) => (
                            <option value={provider} key={index}>
                                {provider}
                            </option>
                        ))}
                </SelectInput>
                <FileUploadForm
                    label={"Upload a bulk sheet"}
                    sampleFile={"bulkPayment.csv"}
                    required
                    checkFormValidation={validate}
                    filename=""
                    onchange={e => {
                    }}
                    filetype={["pdf", "csv"]}
                    data=""
                />
            </div>
            <button
                className="btn btn-block btn-lg btn-shiga-black"
                onClick={ () => goToNext((prevSt:number) => prevSt+1)}
            >
                Continue
            </button>
        </div>
    )
}

const CompleteUpload: React.FC<{
    confirm: () => void;
}> = ({ confirm }) => {
    return (
        <div className="max-w-md space-y-8 mx-auto">
            <div className="text-4xl font-ojah font-bold pb-5 border-b border-gray-100">
                Complete bulk payments to 24 recipients
            </div>
            <div className="max-w-md space-y-8 mx-auto">
                <div className="p-3 border-2 space-y-0.5 border-gray-100 rounded-3xl">
                    {transferContent.map((item, idx) => (
                        <div
                            className={`flex w-full py-2 px-3 justify-between ${idx < 3 ? "border-b border-gray-100" : ""
                                }`}
                            key={idx}
                        >
                            <div>{item.title}</div>
                            <div>{item.detail}</div>
                        </div>
                    ))}
                </div>
                <button
                    onClick={confirm}
                    className="btn btn-block btn-lg btn-shiga-black"
                >
                    Confirm & Pay
                </button>
            </div>
        </div>
    )
}

export interface BulkTransferType {
    name: string;
    amount: string;
    type: string;
    id: number;
}

const defaultBulkTransfer: BulkTransferType = {
    amount: "0",
    id: 0,
    name: "",
    type: ""
}

export const BulkTransfer = () => {
    const navigate = useNavigate();
    const [currentState, setCurrentState] = useState(1);
    const [selectedTransfer, setSelectedTransfer] = useState(defaultBulkTransfer)
    const [form, setForm] = useState({
        teamMember: "",
        cardLabel: "",
        email: "",
        password: "",
    });
    return (
        <div className="space-y-8 pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
            <div className="flex items-center justify-between pb-5 border-b border-gray-100">
                <div
                    onClick={() => {
                        if(currentState === 1){
                            navigate(-1)
                        }else{
                            setCurrentState(prevSt => prevSt - 1)
                        }
                    }}
                    className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
                >
                    <IconNavigation className="w-full h-full" />
                </div>
                <div className="flex items-center space-x-3">
                    <div className="flex space-x-2 items-center cursor-pointer">
                        <div
                            className={`w-6 h-6 rounded-full flex items-center justify-center ${currentState >= 1 ? "bg-black" : "bg-[#E7E7E7]"
                                }`}
                        >
                            <IconProfile />
                        </div>
                        <div className={currentState >= 1 ? "text-black" : "text-[#E7E7E7]"}>
                            Upload Recipients
                        </div>
                    </div>
                    <div>
                        <IconArrowRight />
                    </div>
                    <div className="flex space-x-2 items-center cursor-pointer">
                        <div
                            className={`w-6 h-6 rounded-full flex items-center justify-center ${currentState > 1 ? "bg-black" : "bg-[#E7E7E7]"
                                }`}
                        >
                            <Rectangle />
                        </div>
                        <div className={currentState > 1 ? "text-black" : "text-[#E7E7E7]"}>
                            Review & Pay
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => setCurrentState(1)}
                    className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
                >
                    <IconClose className="m-auto svg-stroke-gray" />
                </div>
            </div>
            {
                currentState === 1 &&
                <UploadRecipient goToNext={setCurrentState} />
            }{
                currentState === 2 &&
                <>
                    <BulkTransferTable handleSelect={setSelectedTransfer} />
                    <ButtonLoader
                        disabled={false}
                        type="submit"
                        onClick={() => setCurrentState(3)}
                        className="ml-auto btn btn-lg px-12 btn-shiga-black"
                    >
                        Continue
                    </ButtonLoader>
                </>
            }
            {
                currentState === 3 && <CompleteUpload confirm={() => {
                    setCurrentState(1)
                    navigate("/dashboard")
                    toast.success("Bulk Payment successful");
                }} />

            }
            <EditTransfer isOpen={String(selectedTransfer.name).length > 0}
                setIsOpen={() => setSelectedTransfer(defaultBulkTransfer)} />
        </div>
    );
};