

export const GET_TRANSFER_LIMIT_START = "GET_TRANSFER_LIMIT_START"
export const GET_TRANSFER_LIMIT_DONE = "GET_TRANSFER_LIMIT_DONE"
export const GET_TRANSFER_LIMIT_FAILED = "GET_TRANSFER_LIMIT_FAILED"

export const SAVE_BANK_TRANSFER_FORM = "SAVE_BANK_TRANSFER_FORM"
export const CLEAR_BANK_TRANSFER_FORM = "CLEAR_BANK_TRANSFER_FORM"

export const GET_TRANSFER_FEE_START = "GET_TRANSFER_FEE_START"
export const GET_TRANSFER_FEE_DONE = "GET_TRANSFER_FEE_DONE"
export const GET_TRANSFER_FEE_FAILED = "GET_TRANSFER_FEE_FAILED"

//GET BANK LiST
export const TRANSFER_GET_BANK = "TRANSFER_GET_BANKS"
export const TRANSFER_GET_BANK_START = "TRANSFER_GET_BANKS_START"
export const TRANSFER_GET_BANK_FAILED = "TRANSFER_GET_BANKS_FAILED"

//NAME VALIDATION
export const TRANSFER_NAME_ENQUIRY = "TRANSFER_NAME_ENQUIRY"
export const TRANSFER_NAME_ENQUIRY_START = "TRANSFER_NAME_ENQUIRY_START"
export const TRANSFER_NAME_ENQUIRY_FAILED = "TRANSFER_NAME_ENQUIRY_FAILED"

//GET BENEFICIARIES
export const TRANSFER_BENEFICIARY = "TRANSFER_BENEFICIARY"
export const TRANSFER_BENEFICIARY_START = "TRANSFER_BENEFICIARY_START"
export const TRANSFER_BENEFICIARY_FAILED = "TRANSFER_BENEFICIARY_FAILED"

//Updates Fields
export const UPDATE_TRANSFER_CURERENCY = "UPDATE_TRANSFER_CURERENCY"
export const UPDATE_SELECTED_WALLET_FOR_TRANSFER = "UPDATE_SELECTED_WALLET_FOR_TRANSFER"
export const UPDATE_TRANSFER_AMOUNT = "UPDATE_TRANSFER_AMOUNT"
export const UPDATE_TRANSFER_NOTE = "UPDATE_TRANSFER_NOTE"
export const UPDATE_TRANSFER_MFA_CODE = "UPDATE_TRANSFER_MFA_CODE"
export const RESET_TRANSFER_DATA = "RESET_TRANSFER_DATA"
export const UPDATE_TRANSFER_FIELDS_FROM_SAVED_BENENEFIARY = "UPDATE_TRANSFER_FIELDS_FROM_SAVED_BENENEFIARY"
export const UPDATE_TRANSFER_DYNAMIC_FIELD = "UPDATE_TRANSFER_DYNAMIC_FIELD"

//Make Transfer
export const TRANSFER_PAYMENT = "TRANSFER_PAYMENT_ENQUIRY"
export const TRANSFER_PAYMENT_START = "TRANSFER_PAYMENT_START"
export const TRANSFER_PAYMENT_FAILED = "TRANSFER_PAYMENT_FAILED"

export const TRANSFER_GET_FIELDS = "TRANSFER_GET_FIELDS"
export const TRANSFER_GET_FIELDS_START = "TRANSFER_GET_FIELDS_START"
export const TRANSFER_GET_FIELDS_FAILED = "TRANSFER_GET_FIELDS_FAILED"


