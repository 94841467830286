import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as accountActions from "store/auth/account/action";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import { getAuthAccount } from "store/auth/account/reducer";
import { toast } from "react-toastify";
import { useCustomToast } from "utils/context/custom-toast/ToastContext";

type CustomToastProps = {
	message: string;
	onClick?: () => void;
}

const useToolkit = () => {
	const dispatch = useDispatch();
	const { userLogin, userInfo } = useSelector(getAuthPersist);
	const { userProfile, businessProfile } = useSelector(getAuthAccount);
	const { addCustomToast } = useCustomToast();

	const isDevEnv = process.env.NODE_ENV === 'development'

	const refetchProfiles = () => {
		dispatch(accountActions.getUserProfile());
		dispatch(accountActions.getBusinessProfile());
	}

	const mfaEnabled = userInfo?.userProfile?.auth === "Y";

	const formatCurrency = (currency: string, floatValue?: number) => {
		if (!floatValue) {
			return Number(currency).toLocaleString('en-US')
		} else {
			return Number(currency).toLocaleString('en-US', {
				minimumFractionDigits: floatValue,
				maximumFractionDigits: floatValue
			})
		}
	}

	const toastError = (message: string) => toast.error(message);
	const toastInfo = (message: string) => toast.info(message);
	const toastSuccess = (message: string) => toast.success(message);

	const showCustomSuccessToast = ({ message, onClick }: CustomToastProps) => {
		addCustomToast('success', message, onClick);
	}

	const copyItem = (item: any, message: string) => {
		navigator.clipboard.writeText(item);
		toastSuccess(message + ' copied !');
	}

	const showValue = (value: any) => value || 'N/A';

	return {
		dispatch,
		useSelector,
		useEffect,
		useState,
		userLogin,
		userInfo,
		userProfile,
		businessProfile,
		refetchProfiles,
		mfaEnabled,
		formatCurrency,
		toastSuccess,
		toastInfo,
		toastError,
		copyItem,
		showValue,
		showCustomSuccessToast,
		isDevEnv
	};

}


export default useToolkit;