import { Steps } from "types/payment/steps";
import user from "assets/images/icons/user_circle.png";
import { ReactComponent as Note } from "assets/images/icons/note.svg";

const CREATE_PAYMENT_LINK_STEP: Steps[] = [
  {
    name: "Payment type",
    icon: <Note />,
    slug: "payment_type",
  },
  {
    name: "Personalize link",
    icon: <img src={user} alt="user" />,
    slug: "personalize_link",
  },
];

export const CREATE_TUTION_STEPS: Steps[] = [
  {
    name: "Student Info",
    icon: <Note />,
    slug: "student_info",
  },
  {
    name: "Amount to Send",
    icon: <img src={user} alt="user" />,
    slug: "amount",
  },
  {
    name: "Review & Pay",
    icon: <img src={user} alt="user" />,
    slug: "review_pay",
  },
];

export default CREATE_PAYMENT_LINK_STEP;
