import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import Pagination from "components/common/Pagination";
import Search from "components/common/Search";
import Table from "components/common/Table";
import Maybe from "components/common/Maybe";
import axios from "axios";
import { Currency } from "types/shared/countries.types";
import { apiBaseUrl } from "data/config";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import FormDrawer from "components/common/form-drawer";
import { ReactComponent as IconGTBank } from "assets/images/brands/GTBank.svg";
import IconBank from "assets/images/icons/Bank.png";
import { ReactComponent as IconStarCircle } from "assets/images/icons/StarCircle.svg";
import { ReactComponent as IconStar } from "assets/images/icons/Star.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconImportantNotice } from "assets/images/icons/important-notice.svg";
import {
  ButtonLoader,
  Checkbox,
  CheckboxGroupSelector,
  Input,
  SelectInput,
} from "components/common/form";
import CustomSwitch from "components/common/form/CustomSwitch";
import validate from "utils/validate";
import { toast } from "react-toastify";
import * as settingsActions from "store/entities/settings/action";
import * as countriesActions from "store/ui/countries/action";
import * as currenciesActions from "store/ui/currencies/action";
import * as banksActions from "store/ui/banks/action";
import * as dashboartActions from "store/dashboard/action";
import { useSharedStore } from "zustand-store";
import { TableContent } from "components/common/TableContent";
import DateFormat from "utils/dateFormat";
import EmptyUI from "components/common/EmptyUi";
import { transferNameEnquiry } from "store/transfer/action";
import { CurrencyRequiredFields } from "lib/currency-fields";
import { ReactComponent as IconInfoFillYellow } from "assets/images/icons/Info-Yellow.svg";
import CustomRadioGroup from "components/common/form/CustomRadioGroup";
// import IconNotification from "assets/images/icons/notification.svg";

interface FormState {
  // country: string;
  currency: string;
  bankCode: string;
  bankName: string;
  accountNumber: string;
  accountName: string;
  isPrimary: boolean;
}
interface UpdateFormState {
  action: string;
}
const SettlementAccounts = () => {
  const dispatch = useDispatch();
  const {
    settlementAccountsLoading,
    settlementAccountDeleted,
    settlementAccountAdded,
    settlementAccounts,
    settlementAccountUpdated,
  } = useSelector((s: any) => s.entities.settings);
  const meta = settlementAccounts?.data?.meta;

  const [isOpenAddAccount, setIsOpenAddAccount] = useState(false);
  const [accountIdToDelete, setAccountIdToDelete] = useState("");
  const [isOpenDeletePrimaryAccModal, setIsOpenDeletePrimaryAccModal] =
    useState(false);
  const getSettlementAccounts = () => {
    dispatch(settingsActions.getSettlementAccounts());
  };
  // console.log("settlement acc", settlementAccounts?.data?.payload);
  const deleteSettlementAccounts = (account: any) => {
    if (account.isDefault === true) {
      setIsOpenDeletePrimaryAccModal(true);
      setAccountIdToDelete(account?.id);
    } else {
      dispatch(settingsActions.deleteSettlementAccounts(account?.id));
    }
  };

  useEffect(() => {
    getSettlementAccounts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (settlementAccountAdded || settlementAccountDeleted  || settlementAccountUpdated) {
      getSettlementAccounts();
    }
    // eslint-disable-next-line
  }, [settlementAccountAdded, settlementAccountDeleted, settlementAccountUpdated]);

  return (
    <div className="">
      <DynamicHeadTag headerText="Settlement Accounts" />

      <AddAccount isOpen={isOpenAddAccount} setIsOpen={setIsOpenAddAccount} />
      <DeletePrimaryAccount
        isOpen={isOpenDeletePrimaryAccModal}
        setIsOpen={setIsOpenDeletePrimaryAccModal}
        accountIdToDelete={accountIdToDelete}
      />

      <div className="sm:flex md:block lg:flex sm:justify-between sm:items-center pb-6 mb-6 sm:mb-12 border-b border-gray-100">
        <div className="page-title">Settlement Accounts</div>
        <div className="mt-1 sm:mt-0 md:mt-1 lg:mt-0">
          <button
            type="button"
            onClick={() => setIsOpenAddAccount(true)}
            className="btn btn-md-lg btn-primary"
          >
            Add an Account
          </button>
        </div>
      </div>

      <div>
        <div className="w-full flex items-center justify-between">
          <Pagination meta={meta} />
          <Search placeholder="Search accounts" />
        </div>

        <div className="mt-6 ">
          <Table tableClass="pb-24">
            <Table.Head>
              <th>Account Information</th>
              <th>Currency</th>
              <th>Date Added</th>
              <th></th>
            </Table.Head>
            <Table.Body className="">
              <TableContent
                loading={settlementAccountsLoading}
                // data={settlementAccounts}
                data={settlementAccounts.data?.payload}
                total={settlementAccounts?.data?.payload?.length}
                colspan={4}
                loadingText="Loading settlement accounts"
                emptyUI={
                  <EmptyUI
                    icon={IconBank}
                    header="No Account Added"
                    subheader="You have not added a settlement account to this account yet. Why not create one?"
                  />
                }
              />

              {/* {settlementAccounts?.data?.count > 0 && settlementAccounts?.data?.accounts?.map((account:any, index:number) => */}
              {settlementAccounts.data?.payload
                ?.sort((a: any, b: any) =>
                  a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1
                )
                .map((account: any, index: number) => (
                  <tr key={index} className="">
                    <td className="">
                      <div className="flex items-center">
                        <div className="w-10 h-10 mr-4 bg-gray-200 rounded-full">
                          {/* <IconGTBank className="w-full h-full" /> */}
                        </div>
                        <div>
                          <div className="font-medium flex items-center">
                            <div>{account?.accountName ?? "-"} </div>
                            <Maybe condition={account?.isDefault === true}>
                              <IconStarCircle className="h-5 ml-1" />
                            </Maybe>
                          </div>
                          <div className="text-sm text-shiga-gray">
                            {account?.bankName ?? "-"}・
                            {account?.accountNumber ?? "-"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{account?.currency ?? "-"}</td>
                    <td>{DateFormat(account?.createdAt) ?? "-"}</td>
                    <td className="">
                      <Dropdown dropdownClass="dropdown-right">
                        <></>
                        <div>
                          <Menu.Item
                            as="div"
                            className="dropdown-item dropdown-item-icon"
                          >
                            <IconStar />
                            Assign Primary
                          </Menu.Item>
                          <Menu.Item
                            as="div"
                            className="dropdown-item dropdown-item-icon text-shiga-warn"
                            onClick={() => deleteSettlementAccounts(account)}
                          >
                            <IconDelete />
                            Delete Account
                          </Menu.Item>
                        </div>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};

const AddAccount = (props: any) => {
  const { isOpen, setIsOpen } = props;
  const dispatch = useDispatch();

  const { currenciesLoading, currencies } = useSelector(
    (s: any) => s.ui.currencies
  );
  const { accountName, loadingName } = useSelector((s: any) => s.transfer);
  const { settlementAccountAddedLoading, settlementAccountAdded } = useSelector(
    (s: any) => s.entities.settings
  );

  const { wallets } = useSelector((allState: any) => allState.dashboard);
  const currenciesInWallet = wallets?.data.map(
    (wallet: any) => wallet.currency
  );
  const walletCurrencies = currenciesInWallet || [];
  const matchingCurrencies = currencies?.data?.filter((currency: any) =>
    walletCurrencies.includes(currency.currency)
  );

  const matchingCurrenciesNGN =
    currencies?.data?.filter((currency: any) => currency?.currency === "NGN") ||
    [];

  const [form, setForm] = useState<FormState>({
    currency: "",
    bankCode: "",
    bankName: "",
    accountNumber: "",
    accountName: "",
    isPrimary: false,
  });

  useEffect(() => {
    if (form.accountNumber && form.bankCode) {
      if (form.accountNumber.length === 10) {
        dispatch(transferNameEnquiry(form.accountNumber, form.bankCode));
      }
    }
  }, [form.accountNumber, form.bankCode, dispatch]);

  useEffect(() => {
    if (accountName) {
      setForm((prevForm) => ({ ...prevForm, accountName }));
    }
  }, [accountName]);

  const getCurrencies = () => {
    if (
      !currenciesLoading &&
      (Object.keys(currencies).length === 0 || currencies?.success === false)
    ) {
      dispatch(currenciesActions.getCurrencies());
    }
  };

  const closeModal = () => {
    setForm((prevForm: any) => ({ ...prevForm, accountName: "" }));
    setForm({
      currency: "",
      bankCode: "",
      bankName: "",
      accountNumber: "",
      accountName: "",
      isPrimary: false,
    });
    setIsOpen(false);
  };
  const isFormValid = () => {
    return (
      form.currency &&
      form.bankCode &&
      form.bankName &&
      form.accountNumber &&
      form.accountName
    );
  };
  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: any = validate(form);
    if (errors) {
      for (var x in errors) {
        toast.error(errors[x]);
      }
      return;
    } else {
      dispatch(settingsActions.addSettlementAccounts(form));
      // setIsOpen(false)
      // form.accountName = "";
      setForm((prevForm: any) => ({ ...prevForm, accountName: "" }));
      closeModal();
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      getCurrencies();
      dispatch(dashboartActions.getDashboardWallets());
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (settlementAccountAdded?.status === false) {
      dispatch(settingsActions.resetAddSettlementAccounts());
      setIsOpen(false);
    }
    // eslint-disable-next-line
  }, [settlementAccountAdded]);

  return (
    <FormDrawer
      display="center"
      size="lg"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <form onSubmit={submitForm}>
        <div className="p-8">
          <div className="pb-6 flex items-center justify-between border-b border-gray-100">
            <div className="text-2xl font-ojah font-medium">Add an Account</div>

            <div
              onClick={closeModal}
              className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
            >
              <IconClose className="m-auto text-white svg-stroke-gray" />
            </div>
          </div>
          <div className="mt-8 rounded-3xl bg-gradient-to-t from-[#FFFBF5] to-[#FFFBF5] bg-[#FFF7EB] flex flex-col items-start gap-4 p-4 md:p-6 w-full">
            <div className="flex items-start gap-x-2">
              <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
              <p className="font-inter text-[#523000] text-[12px]  tracking-tighter">
                You'll receive USD settlements 7 working days after payment has
                been made.
              </p>
            </div>
            <div className="flex items-start gap-x-2">
              <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
              <p className="font-inter text-[#523000] text-[12px]  tracking-tighter">
                In case of fraudulent transactions involving international
                payments, Payshiga will reverse the transaction and deduct the
                amount from your balance.
              </p>
            </div>
            <div className="flex items-start gap-x-2">
              <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
              <p className="font-inter text-[#523000] text-[12px]  tracking-tighter">
                Settlement account name must match registered business name.
              </p>
            </div>
          </div>
          <div className="mt-10">
            <div
            // className="grid grid-cols-1 sm:grid-cols-2 gap-x-6"
            >
              <Maybe condition={currenciesLoading === true}>
                <Input
                  type="text"
                  label="Currency"
                  value="Loading currencies..."
                  required={true}
                  disabled={true}
                />
              </Maybe>
              <Maybe condition={currenciesLoading === false}>
                <SelectInput
                  label="Currency"
                  required={true}
                  value={form.currency}
                  onChange={(e: any) =>
                    setForm({ ...form, currency: e.target.value })
                  }
                >
                  <option value="" disabled>
                    Select a currency
                  </option>
                  {currencies?.data &&
                    matchingCurrenciesNGN.map((data: any) => (
                      <option value={data?.currency} key={data?.id}>
                        {data?.currency} - {data?.name}
                      </option>
                    ))}
                </SelectInput>
              </Maybe>
            </div>
            <CurrencyRequiredFields form={form} setForm={setForm} isOpen={isOpen} />

            <div className="flex items-center justify-between font-[500] space-x-8">
              <div>Make this my primary account</div>
              <div>
                <CustomSwitch
                  active={form.isPrimary}
                  onChange={(e: any) => setForm({ ...form, isPrimary: e })}
                  hideText={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-100 p-8">
          <ButtonLoader
            loading={settlementAccountAddedLoading}
            type="submit"
            className="btn btn-lg btn-block btn-shiga-black"
            disabled={!isFormValid() || settlementAccountAddedLoading}
          >
            Add Account
          </ButtonLoader>
        </div>
      </form>
    </FormDrawer>
  );
};

const options = [
  {
    value: "option1",
    label: "Option 1",
    description: "Description for Option 1",
  },
  {
    value: "option2",
    label: "Option 2",
    description: "Description for Option 2",
  },
  {
    value: "option3",
    label: "Option 3",
    description: "Description for Option 3",
  },
];

const DeletePrimaryAccount = (props: any) => {
  const { isOpen, setIsOpen, accountIdToDelete } = props;
  const dispatch = useDispatch();
  const [pageStep, setPageStep] = useState<number>(1);
  const [isPrimaryAccount, setIsPrimaryAccount] = useState<boolean>(false);
  const [isPrimaryAccountID, setIsPrimaryAccountID] = useState<string>("");
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null
  );
  const {
    settlementAccountsLoading,
    settlementAccountDeleted,
    settlementAccountAdded,
    settlementAccounts,
  } = useSelector((s: any) => s.entities.settings);

  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const handleChange = (value: any) => {
    setSelectedOption(value);
    // Handle any additional logic here
  };
  const [form, setForm] = useState<UpdateFormState>({
    action: "",
  });

  const closeModal = () => {
    setForm({
      action: "",
    });
    setIsOpen(false);
  };
  // const isFormValid = () => {
  //   return (
  //     form.currency &&
  //     form.bankCode &&
  //     form.bankName &&
  //     form.accountNumber &&
  //     form.accountName
  //   );
  // };
  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (pageStep === 1) {
      setPageStep(2);
    } else {
      closeModal();
      dispatch(settingsActions.deleteSettlementAccounts(accountIdToDelete));
      dispatch(
        settingsActions.changePrimarySettlementAccounts(selectedAccountId, {
          action: "SET_AS_PRIMARY",
        })
      );
    }
  };
  const getSettlementAccounts = () => {
    dispatch(settingsActions.getSettlementAccounts());
  };

  useEffect(() => {
    getSettlementAccounts();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (isOpen === true) {
  //     dispatch(dashboartActions.getDashboardWallets());
  //   }
  //   // eslint-disable-next-line
  // }, [isOpen]);

  // useEffect(() => {
  //   if (settlementAccountAdded?.status === false) {
  //     dispatch(settingsActions.resetAddSettlementAccounts());
  //     setIsOpen(false);
  //   }
  //   // eslint-disable-next-line
  // }, [settlementAccountAdded]);
  const handleCheckboxChange = (accountId: string) => {
    // setSelectedAccountId(accountId === selectedAccountId ? null : accountId);
    setSelectedAccountId(accountId || null);
   
  };

  return (
    <FormDrawer
      display="center"
      size="lg"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <form onSubmit={submitForm}>
        <div className="p-8">
          <div className="pb-6 flex items-center justify-between border-b border-gray-100">
            <div className="text-2xl font-ojah font-medium">
              Reassign Primary account
            </div>

            <div
              onClick={closeModal}
              className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
            >
              <IconClose className="m-auto text-white svg-stroke-gray" />
            </div>
          </div>
          <div className="mt-10">
            {pageStep === 1 && (
              <div className="text-center">
                {/* <img src={IconNotification} alt="notification-image"/> */}
                <div className="flex items-center">
                  <IconImportantNotice className=" w-[82.8px] h-[82.8px] mt-1" />
                </div>
                <h5 className="font-bold font-ojah text-2xl mb-3">
                  Before you continue,
                </h5>
                <p className="text-lg mb-3">
                  Please note that deleting this account will leave your
                  business account without an attached settlement account. As a
                  result, all settlements will be directed to your Payshiga
                  wallet balance.
                </p>
                <p className="text-lg">If you understand, Tap on “Continue”</p>
              </div>
            )}
            {pageStep >= 2 && (
              <div>
                <p className="text-center text-[#99999C] text-[16px] mb-[24px]">
                  Please choose an account from the list below to set as your
                  primary account.
                </p>
                <div className="border-2 border-[#F5F5F5] rounded-3xl p-[20px]">
                  {settlementAccounts?.data?.payload
                    ?.filter((account: any) => account.id !== accountIdToDelete)
                    .map((item: any, idx: any) => {
                      return (
                        <div key={item.id}>
                          <div className="border-b flex items-center justify-between font-[500] space-x-8">
                            <div className="flex items-center">
                              <div className=" py-6 px-2">
                                <div className="flex items-center">
                                  <div className="w-10 h-10 mr-4 bg-gray-200 rounded-full">
                                    {/* <IconGTBank className="w-full h-full" /> */}
                                  </div>
                                  <div>
                                    <div className="font-medium flex items-center">
                                      <div>{item?.accountName ?? "-"} </div>
                                    </div>
                                    <div className="text-sm text-shiga-gray">
                                      {item?.bankName}・{item?.accountNumber}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <Checkbox
                                type="checkbox"
                                label=""
                                containerClass="!items-start text-shiga-gray text-sm"
                                checked={selectedAccountId === item.id}
                                onChange={() => handleCheckboxChange(item.id)}
                                // checked={isPrimaryAccountID === item.Id}
                                // onChange={() => {

                            
                                //   handleCheckboxChange(item.id);
                                //   setIsPrimaryAccountID(item?.Id);
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="mt-8 rounded-2xl bg-gradient-to-t from-[#FFFBF5] to-[#FFFBF5] bg-[#FFF7EB] p-[12px]  w-full">
                    <div className="flex items-start gap-x-2">
                      <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
                      <p className="font-inter text-[#523000] text-[12px]  tracking-tighter">
                        Note that all settlements will be processed through this
                        account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="border-t border-gray-100 p-8 flex justify-between items center">
          {pageStep === 1 && (
            <button className="btn btn-lg border-2 border-shiga-black">
              Cancel
            </button>
          )}
          <ButtonLoader
            // loading={settlementAccountAddedLoading}
            type="submit"
            className={`btn btn-lg ${
              pageStep >= 2 && "btn-block"
            } btn-shiga-black`}
            // disabled={!isFormValid() || settlementAccountAddedLoading}
          >
            {pageStep >= 2 ? "Apply Changes" : "Continue"}
          </ButtonLoader>
        </div>
      </form>
    </FormDrawer>
  );
};

export default SettlementAccounts;
