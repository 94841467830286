import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Separator } from "components/ui/separator";
import { Switch } from "components/ui/switch";
import { Textarea } from "components/ui/textarea";
import useBackButton from "hooks/useBackButton";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { togglePaymentLink, getPaymentLink } from "lib/payments";
import { ArrowLeft, Copy, Globe, Loader } from "lucide-react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "sonner";
import { PaymentLink } from "types/payment/payments.interface";
import { LinkPayments } from "./LinkPayments";
import { Stat } from "./Stat";
import { PaymentLinkActions } from "./PaymentLinkAction";
import { DeletePaymentLinkModal } from "./DeletePaymentLinkModal";
import { useToggle } from "hooks/useToggle";
import { usePaymentLinkStore } from "zustand-store/payments/links";
import DivInput from "components/ui/div-input";

export const ViewOnePaymentLink = () => {
  const id = sessionStorage.getItem("view-link");
  const [showDelete, toggleDelete] = useToggle(false);
  const { currentPaymentLink, setCurrentPaymentLink } = usePaymentLinkStore();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copy] = useCopyToClipboard();

  useEffect(() => {
    if (id) {
      setLoading(true);
      getPaymentLink(id).then((res) => {
        setCurrentPaymentLink(res.data.paymentLink);
        setLoading(false);
      });
    }
  }, [id]);

  const handleUpdateStatus = (checked: boolean) => {
    const newStatus = checked ? "ACTIVE" : "INACTIVE";
    setCurrentPaymentLink({
      ...currentPaymentLink,
      status: newStatus
    })
    togglePaymentLink(currentPaymentLink.id, newStatus).catch(err => {
      toast.error(`Unable to update status`, err.message);
      setCurrentPaymentLink({
        ...currentPaymentLink,
        status: currentPaymentLink.status
      })
    })
  }

  if (!id) {
    return <Navigate to={"/payments"} />;
  }
  if (!currentPaymentLink || currentPaymentLink === undefined) {
    return null;
  }

  async function copyItem() {
    if (!currentPaymentLink) return;
    try {
      await copy(currentPaymentLink.link || currentPaymentLink.callbackUrl);
      toast.success(`Payment Link copied.`, {
        id: currentPaymentLink?.name,
      });
    } catch (error) {
      toast.success(`Error copying Link`, {
        id: currentPaymentLink?.name,
      });
    }
  }
  if (loading) {
    return (
      <div className="w-full min-h-full  flex justify-center items-center">
        <Loader className="animate-spin" />
      </div>
    );
  }
  return (
    <>
      <div className=" p-10 w-full space-y-4  ">
        <Header handleUpdateStatus={handleUpdateStatus} link={currentPaymentLink} handleShowDelete={toggleDelete} />
        <Separator />
        <div className="grid grid-cols-2 ">
          <div className="w-[210px] space-y-4">
            <div className=" h-[204px] ">
              {currentPaymentLink.logoUrl ? (
                <img
                  className="w-full h-full rounded-lg"
                  src={currentPaymentLink.logoUrl}
                  alt={currentPaymentLink.name}
                />
              ) : (
                <div className="flex bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-2xl border items-center h-full  justify-center" />
              )}
            </div>
            <div className="flex items-center gap-2  ">
              <div className="text-neutral-400 text-[13px] font-semibold font-['Inter']">
                STATS
              </div>
              <Separator />
            </div>
            <div className="max-h-[120px] ">
              <Stat link={currentPaymentLink} />
            </div>
          </div>

          <div className="px-10 space-y-6">
            <div className="flex w-full gap-4 justify-between ">
              <DivInput label="Link Name" value={currentPaymentLink.name} />
              <DivInput label="Amount" value={currentPaymentLink.amount} />
            </div>
            <div className="space-y-2 ">
              <Label className="space-x-2">
                Description
                <span className="text-red-500 text-sm font-normal font-['Inter'] leading-tight">
                  *
                </span>
              </Label>
              <Textarea
                className="disabled:opacity-100 text-neutral-950 px-5 text-base font-semibold"
                disabled
                defaultValue={currentPaymentLink.description}
              />
            </div>
            <div>
              <Label className="space-x-2">
                Payment Link
                <span className="text-red-500 text-sm font-normal font-['Inter'] leading-tight">
                  *
                </span>
              </Label>
              <div className="relative">
                <Globe
                  size={20}
                  className=" absolute left-2 top-3 text-zinc-400 "
                />
                <Input
                  disabled
                  className="px-12 disabled:opacity-100 text-neutral-950  text-base font-semibold"
                  defaultValue={currentPaymentLink.link || currentPaymentLink.callbackUrl}
                  type="url"
                  placeholder="e.g https://abcd.xyz"
                />
                <Copy
                  onClick={copyItem}
                  size={20}
                  className=" absolute right-2 top-3 text-zinc-400 cursor-pointer "
                />
              </div>
            </div>
          </div>
        </div>
        <Separator />
        <LinkPayments />
      </div>
      <DeletePaymentLinkModal
        paymentLink={currentPaymentLink}
        open={showDelete}
        closeModal={toggleDelete}
      />
    </>
  );
};

const Header = ({ link, handleShowDelete, handleUpdateStatus }: { link: PaymentLink; handleUpdateStatus: (checked: boolean) => void; handleShowDelete: () => void }) => {
  const { goBack } = useBackButton();

  return (
    <div className=" w-full grid grid-cols-2">
      <Button
        size="icon"
        onClick={goBack}
        variant="outline"
        className="rounded-full bg-neutral-100 border-none shadow-none"
      >
        <ArrowLeft size={18} />
      </Button>
      <div className="flex items-center gap-6 w-full justify-end">
        <div className="flex items-center gap-2">
          <p className="text-zinc-800 text-[15px] font-medium font-['Inter']">
            Status
          </p>
          <Switch checked={link.status === "ACTIVE"}
            onCheckedChange={handleUpdateStatus} />
        </div>
        <PaymentLinkActions handleDelete={handleShowDelete} paymentLink={link} />
      </div>
    </div>
  );
};
