import { RootState } from "store/store";
import * as actions from "./actionTypes";
import { PaginatedApiResponse } from "types/data-interface";
import { Transaction } from "types/transaction";

interface InitialState {
	isLoadingTransactions: boolean;
	transactions: PaginatedApiResponse<Transaction[]> | null
}

const initialState: InitialState = {
	isLoadingTransactions: false,
	transactions: null,
};

export default function reducer(state = initialState, action: any) {
	switch (action.type) {
		case actions.GET_TRANSACTIONS_START:
			state = {
				...state,
				isLoadingTransactions: true,
			};
			return state;

		case actions.GET_TRANSACTIONS:
			state = {
				...state,
				isLoadingTransactions: false,
				transactions: action.payload
			};
			return state;

		case actions.GET_TRANSACTIONS_FAILED:
			state = {
				...state,
				isLoadingTransactions: false,
				transactions: null
			};
			return state;

		default:
			return state;
	}
}

export const getTransactionsStore = (state: RootState) => state.transactions;