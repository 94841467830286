import { currencyFormat } from "lib/format.amount";
import { formatDate } from "lib/format.date";
import { findInvoice } from "lib/payments/invoices";
import { useEffect, useMemo, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { CurrencyType } from "types/misc";
import { ReviewItem } from "./forms/components/ReviewItem";
import { ReactComponent as IconEllipsisHorizontal } from "assets/images/icons/ellipsis-horizontal.svg";
import { Invoice, defaultInvoice } from "types/payment/invoice";
import { Separator } from "components/ui/separator";
import { ArrowLeft } from "lucide-react";
import { Button } from "components/ui/button";
import useBackButton from "hooks/useBackButton";
import { InvoiceDropdown, getStatusColor } from "./table/InvoiceColumns";
import { Badge } from "components/ui/badge";
import { removeUnderscore } from "lib/remove-underscore";

export const ViewInvoice = () => {
    const id = sessionStorage.getItem("view-invoice");
    const [invoice, setInvoice] = useState<Invoice>(defaultInvoice);
    const [loading, setLoading] = useState(false);

    const total = useMemo(() => {
        return invoice.items.length > 0
            ? invoice.items.reduce((acc, item) => acc + (item.price * item.qty), 0)
            : 0;
    }, [invoice.items]);

    const additionalCalc = useMemo(() => ({
        discount: total * ((invoice.discount || 0) / 100),
        vat: total * ((invoice.vat || 0) / 100)
    }), [invoice.discount, invoice.vat, total]);

    useEffect(() => {
        if (id) {
            setLoading(true);
            findInvoice(id).then((res) => {
                const date = new Date()
                setInvoice({
                    ...res.data,
                    status: date.getTime() > (new Date(res.data.endDate)).getTime() && res.data.status !== "PAID" ? "DUE" : res.data.status
                });
                setLoading(false);
            }).finally(() => {
                setLoading(false)
            });
        }
    }, [id]);

    if (!id) {
        return <Navigate to={"/payments"} />;
    } else if (loading) {
        return <div>loading....</div>
    }

    return (
        <div className=" p-10 w-full space-y-4  ">
            <Header invoice={invoice} />
            <Separator />
            <div className="w-full rounded-xl border border-neutral-100 flex-col justify-start items-center gap-4 inline-flex">
                <div className="h-[249px] w-full rounded-xl flex-col justify-start items-start flex">
                    <div className="self-stretch px-4 pt-6 pb-5 bg-neutral-50 border-b border-neutral-100 justify-start items-center gap-3 inline-flex">
                        {
                            invoice.imageUrl.length ?
                                <img
                                    alt="invoice"
                                    className="w-10 h-10 rounded"
                                    src={invoice.imageUrl}
                                /> : <></>
                        }
                        {
                            invoice.invoiceNumber ?
                                <div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="flex-col justify-start items-start gap-1 flex">
                                        <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                                            INVOICE NO
                                        </div>
                                        <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                                            {invoice.invoiceNumber}
                                        </div>
                                    </div>
                                </div> : <></>
                        }
                        <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                            <div className="flex-col justify-start items-start gap-1 flex">
                                <div className="self-stretch text-right text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                                    INVOICE DATE
                                </div>
                                <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                                    {`${formatDate(invoice.startDate)} - ${formatDate(invoice.endDate)}`}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch px-4 py-9 rounded-bl-xl rounded-br-xl justify-start items-start gap-6 inline-flex">
                        {/* <div className="grow shrink basis-0 flex-col justify-start items-start gap-[13px] inline-flex">
                            <div className="self-stretch h-[93px] flex-col justify-start items-start gap-2.5 flex">
                                <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter']">
                                    Business Address
                                </div>
                                <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                                </div>
                            </div>
                        </div> */}
                        <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                            <div className="self-stretch h-[93px] flex-col justify-start items-start gap-2.5 flex">
                                <div className="self-stretch text-right text-neutral-400 text-xs font-normal font-['Inter']">
                                    Billing Address
                                </div>
                                <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                                    {invoice.billingAddress?.address}
                                    <br />
                                    {invoice.billingAddress?.postCode}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-[445px] flex-col w-full justify-start items-start flex">
                    <div className="self-stretch px-4 py-3 bg-neutral-50 border-b border-neutral-100 justify-start items-start gap-3 inline-flex">
                        <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                            <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                                INVOICE NO
                            </div>
                        </div>
                        <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                            <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                                Cost
                            </div>
                        </div>
                        <div className="w-20 flex-col justify-start items-start gap-2.5 inline-flex">
                            <div className="text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                                QUANTITY
                            </div>
                        </div>
                        <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                            <div className="text-right text-neutral-400 text-xs font-medium font-['Inter'] uppercase">
                                PRICE
                            </div>
                        </div>
                    </div>
                    <div className="self-stretch h-[406px] px-4 py-9 rounded-bl-xl rounded-br-xl flex-col justify-start items-start gap-6 flex">
                        {
                            invoice.items.map((item, idx) => (
                                <ReviewItem key={idx}
                                    item={item}
                                    invoiceCurrency={invoice.currency as CurrencyType}
                                />
                            ))
                        }
                        <div className="self-stretch h-1 py-0.5 flex-col justify-start items-start gap-2.5 flex" />
                        <div className="self-stretch h-[136px] flex-col justify-start items-start gap-4 flex">
                            <div className="self-stretch justify-start items-start gap-3 inline-flex">
                                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                                        Product Name
                                    </div>
                                </div>
                                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                                        {`Subtotal (${invoice.items.length})`}
                                    </div>
                                </div>
                                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                                    <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                                        {currencyFormat(total || 0, invoice.currency || "")}
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch justify-start items-start gap-3 inline-flex">
                                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                                        Product Name
                                    </div>
                                </div>
                                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                                        {`Discount (${invoice?.discount}%)`}
                                    </div>
                                </div>
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                                        2
                                    </div>
                                </div>
                                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                                    <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                                        {currencyFormat(additionalCalc.discount, invoice.currency || "")}
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch justify-start items-start gap-3 inline-flex">
                                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                                        Product Name
                                    </div>
                                </div>
                                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                                        {`VAT (${invoice.vat}%)`}
                                    </div>
                                </div>
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                                        2
                                    </div>
                                </div>
                                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                                    <div className="self-stretch text-right text-zinc-800 text-sm font-normal font-['Inter']">
                                        {currencyFormat(additionalCalc.vat, invoice.currency || "")}
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch justify-start items-start gap-3 inline-flex">
                                <div className="w-60 self-stretch" />
                                <div className="grow shrink basis-0 py-0.5 flex-col justify-start items-start gap-2.5 inline-flex" />
                            </div>
                            <div className="self-stretch justify-start items-start gap-3 inline-flex">
                                <div className="w-60 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-white text-sm font-medium font-['Inter']">
                                        Product Name
                                    </div>
                                </div>
                                <div className="w-28 flex-col justify-start items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-zinc-800 text-sm font-medium font-['Inter']">
                                        Total
                                    </div>
                                </div>
                                <div className="grow shrink basis-0 flex-col justify-center items-start gap-2.5 inline-flex">
                                    <div className="self-stretch text-white text-sm font-normal font-['Inter']">
                                        2
                                    </div>
                                </div>
                                <div className="grow shrink basis-0 flex-col justify-center items-end gap-2.5 inline-flex">
                                    <div className="self-stretch text-right text-zinc-800 text-sm font-medium font-['Inter']">
                                        {currencyFormat(total - (additionalCalc.discount + additionalCalc.vat), invoice.currency || "NGN")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="self-stretch h-1 py-0.5 flex-col justify-start items-start gap-2.5 flex" />
                        <div className="self-stretch justify-start items-start gap-3 inline-flex">
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex">
                                <div className="flex-col justify-start items-start gap-1 flex">
                                    <div className="self-stretch text-neutral-400 text-xs font-normal font-['Inter'] uppercase">
                                        Note
                                    </div>
                                    <div className="self-stretch text-zinc-800 text-sm font-normal font-['Inter']">
                                        {invoice.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const Header = ({ invoice }: { invoice: Invoice }) => {
    const { goBack } = useBackButton();
    const navigate = useNavigate()

    return (
        <div className=" w-full grid grid-cols-2">
            <Button
                size="icon"
                onClick={goBack}
                variant="outline"
                className="rounded-full bg-neutral-100 border-none shadow-none"
            >
                <ArrowLeft size={18} />
            </Button>
            <div className="flex items-center gap-6 w-full justify-end">
                <div className="flex items-center gap-2">
                    <p className="text-zinc-800 text-[15px] font-medium font-['Inter']">
                        Status
                    </p>
                    <Badge
                        className={`rounded-md capitalize ${getStatusColor(invoice.status)}`}
                        variant="outline"
                    >
                        {removeUnderscore({ actionWord: invoice.status })}
                    </Badge>
                </div>
                <InvoiceDropdown DropdownTrigger={
                    <Button
                        variant="ghost"
                        className="flex bg-[#f5f5f5] size-8 p-0 data-[state=open]:bg-muted"
                    >
                        <IconEllipsisHorizontal className="size-4" />
                        <span className="sr-only">Open menu</span>
                    </Button>
                } invoice={invoice} onDelete={() => {
                    navigate("/payments/invoice")
                }} />
            </div>
        </div>
    );
};