import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";

import { ArrowLeft, X } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { Review } from "./Review";
import { AddInvoiceDetailsForm } from "./forms/AddInvoiceDetails";
import { AddItemForm } from "./forms/AddItemForm";
import { ReciepientForm } from "./forms/ReciepientForm";

import { StepHeading } from "components/common/StepHeading";
import { CREATE_INVOICE_STEPS } from "constants/payments/create.invoice.steps";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { PAYMENT_PATHS } from "../paths";
import useBackButton from "hooks/useBackButton";

export const CreateInvoice = () => {
  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab") || CREATE_INVOICE_STEPS[0].slug;
  return (
    <div className="space-y-4 w-full p-10 ">
      <Nav tab={tab} />
      <Separator />
      {getComp(tab)}
    </div>
  );
};

const Nav = ({ tab }: { tab: string }) => {
  const { completed } = useInvoiceStore();
  const { goBack } = useBackButton();

  return (
    <div className="flex  justify-between items-center ">
      <Button
        size="icon"
        variant="outline"
        onClick={goBack}
        className="rounded-full bg-neutral-100 border-none shadow-none"
      >
        <ArrowLeft size={18} />
      </Button>

      <StepHeading
        completed={completed}
        tab={tab}
        steps={CREATE_INVOICE_STEPS}
        path={PAYMENT_PATHS.CREATE_INVOICE}
      />
      <Button
        size="icon"
        variant="outline"
        className="rounded-full bg-neutral-100 border-none shadow-none"
      >
        <X size={18} />
      </Button>
    </div>
  );
};

const getComp = (tab: string) => {
  switch (tab) {
    case "receipient":
      return <ReciepientForm />;
    case "add_items":
      return <AddItemForm />;
    case "review_send":
      return <Review />;
    default:
      return <AddInvoiceDetailsForm />;
  }
};
