import { useEffect, useRef, useState } from 'react';
import { ReactComponent as IconDropdown } from 'assets/images/icons/vertical-dropdown-trigger.svg'


const TableDropdown = ({ onRowClick, options }) => {
   const [isVisible, setIsVisible] = useState(false);
   const dropdownRef = useRef(null);

   const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
         setIsVisible(false);
      }
   };

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
         document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line
   }, []);

   return (
      <div ref={dropdownRef} className="relative flex flex-col items-center group" onClick={() => {
         setIsVisible(!isVisible);
         onRowClick();
      }}>
         <div className="cursor-pointer px-2">
            <IconDropdown />
         </div>

         <div className={`space-y-4 absolute -left-[120px] transition-all duration-500 bottom-full mb-3 min-w-[150px] bg-shiga-dark-500 text-white text-sm rounded-xl p-4 ${isVisible ? 'opacity-100 inline absolute' : 'opacity-0 hidden'}`}>
            {options.map((option, idx) => option?.permission && (
               <div key={idx} onClick={option.onClick} className="flex items-center justify-start bg-shigas-gray-25 cursor-pointer">
                  <span className={option?.iconClass || ''}>
                     <option.Icon />
                  </span>
                  <span className={`ml-3 ${option?.titleClass}`}>
                     {option.title}
                  </span>
               </div>
            ))}
         </div>
      </div>
   );
};

export default TableDropdown;
