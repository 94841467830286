import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonLoader, Input, SelectInput } from "components/common/form";
import { toast } from "react-toastify";
import { Currency } from "types/shared/countries.types";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import validate from "utils/validate";
import Maybe from "components/common/Maybe";
import axios from "axios";
import { annualVolume, companySize } from "data/constants/common";
import * as userActions from "store/auth/user/action";
import * as industriesActions from "store/ui/industries/action";
import { useSharedStore } from "zustand-store";
import { apiBaseUrl } from "data/config";

const RegisterBusinessInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { registerLoading, registerForm, registerResponse } = useSelector(
    (s: any) => s.auth.user
  );
  const { userLogin, userInfo } = useSelector((s: any) => s.auth.userPersist);
  const { industriesLoading, industries } = useSelector(
    (s: any) => s.ui.industries
  );
  const { currencies, apiLoading } = useSharedStore();
  const [countries, setCountries] = useState<Currency[]>([]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/currencies`);
        setCountries(response?.data?.data);
      } catch (error) {
        // console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();
  }, []);

  const [form, setForm] = useState({
    location: "",
    locationCode: "",
    businessName: "",
    industry: "",
    companySize: "",
    annualVolume: "",
  });
  const getIndustries = () => {
    if (
      !industriesLoading &&
      (Object.keys(industries).length === 0 || industries?.success === false)
    ) {
      dispatch(industriesActions.getIndustries());
    }
  };
  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    // const errors: any = validate(form);
    const params = {
      firstName: registerForm.firstName,
      lastName: registerForm.lastName,
      email: registerForm.email,
      password: registerForm.password,
      accountType: registerForm.accountType,
      business: {
        country: form.locationCode,
        name: form.businessName,
        category: form.industry,
        size: form.companySize,
        annualVolume: form.annualVolume,
      },
    };
    try {
      dispatch(userActions.register(params));
      navigate("/login");
    } catch (error) {
      //   if (errors) {
      //     for (var x in errors) {
      //       toast.error(errors[x]);
      //     }
      //     return;
      //   }

      toast.error("Registration failed. Please try again.");
    }
  };

  useEffect(() => {
    if (registerResponse?.success === true) {
      toast.success("Business account created successfully");
    }
  }, [registerResponse]);

  useEffect(() => {
    if (Object.keys(registerForm).length === 0) {
      navigate("/register");
    } else {
      getIndustries();
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //     if (userLogin === true && userInfo?.token !== undefined) {
  //         toast.success("Your account has been created successfully");
  //         dispatch(userActions.saveRegisterForm({}));
  //         navigate("/login");
  //     }
  //     // eslint-disable-next-line
  // }, [userLogin, userInfo])

  return (
    <>
      <DynamicHeadTag headerText="Register - Business Info" />

      <AuthPageTitle
        title="Provide some info about your business"
        showBackButton={true}
        backButtonUrl="/register/account-type"
      />
      <div>
        <form onSubmit={submitForm}>
          <div className="mt-10">
            <Maybe condition={apiLoading === true}>
              <Input
                type="text"
                label="Where is your business located"
                value="Loading countries..."
                required={true}
                disabled={true}
              />
            </Maybe>
            <Maybe condition={apiLoading === false}>
              <SelectInput
                label="Where is your business located"
                required={true}
                value={form.location}
                onChange={(e: { target: { value: string } }) => {
                  const selectedCountry = countries.find(
                    (curr) => curr.country === e.target.value
                  );
                  setForm({
                    ...form,
                    location: selectedCountry?.country || "",
                    locationCode: selectedCountry?.countryCode || "",
                  });
                }}
              >
                <option value="">Select Location</option>
                {countries.map((curr) => (
                  <option value={curr.country} key={curr.countryCode}>
                    {curr.country}
                  </option>
                ))}
              </SelectInput>
            </Maybe>

            <Input
              type="text"
              label="What is your business name?"
              placeholder="Business Name"
              required={true}
              value={form.businessName}
              onChange={(e: any) =>
                setForm({ ...form, businessName: e.target.value })
              }
            />

            <div className="relative -top-4 text-right text-shiga-gray text-sm">
              Use the registered business name on your document
            </div>

            <Maybe condition={industriesLoading === true}>
              <Input
                type="text"
                label="Business industry"
                value="Loading industries..."
                required={true}
                disabled={true}
              />
            </Maybe>
            <Maybe condition={industriesLoading === false}>
              <SelectInput
                label="Business industry"
                required={true}
                value={form.industry}
                onChange={(e: any) =>
                  setForm({ ...form, industry: e.target.value })
                }
              >
                <option value="" disabled>
                  Select an option
                </option>
                {industries?.data?.industries &&
                  Object.keys(industries?.data?.industries)?.map(
                    (key: string) => (
                      <option value={key} key={key}>
                        {industries?.data?.industries[key]}
                      </option>
                    )
                  )}
              </SelectInput>
            </Maybe>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
              <SelectInput
                label="Company Size"
                required={true}
                value={form.companySize}
                onChange={(e: any) =>
                  setForm({ ...form, companySize: e.target.value })
                }
              >
                <option value="" disabled>
                  Select a size
                </option>
                {companySize.map((size) => (
                  <option value={size} key={size}>
                    {size}
                  </option>
                ))}
              </SelectInput>

              <SelectInput
                label="Estimated annual volume"
                required={true}
                value={form.annualVolume}
                onChange={(e: any) =>
                  setForm({ ...form, annualVolume: e.target.value })
                }
              >
                <option value="">Select an option</option>
                {annualVolume.map((volume) => (
                  <option value={volume} key={volume}>
                    {volume}
                  </option>
                ))}
              </SelectInput>
            </div>

            <ButtonLoader
              type="submit"
              loading={registerLoading}
              className="btn btn-lg btn-block btn-primary mt-10"
            >
              Create Business
            </ButtonLoader>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterBusinessInfo;
