import { ReactComponent as IconEmptyInvoice } from "assets/images/icons/invoice-empty.svg";
import { DataTable } from "components/data-table/data-table";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import { Link, useNavigate } from "react-router-dom";
import { InvoiceColumns } from "./table/InvoiceColumns";
import { PAYMENT_PATHS } from "../paths";
import { useEffect, useMemo } from "react";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { Invoice } from "types/payment/invoice";
import { DataTableHeader } from "components/data-table/data-table-header";
import { formatDate } from "lib/format.date";
import { currencyFormat } from "lib/format.amount";
import { removeUnderscore } from "lib/remove-underscore";

export const InvoiceContainer = () => {
  const { fetchInvoices, createdInvoice: invoices } = useInvoiceStore();
  useEffect(() => {
    fetchInvoices()
  }, [])
  const navigate = useNavigate();

  const handleOnClick = (invoice: Invoice) => {
    sessionStorage.setItem("view-invoice", invoice.id || "");
    navigate(`${PAYMENT_PATHS.INVOICE}/${invoice.invoiceNumber}`);
  };
  const formattedInvoice = useMemo(() => invoices.map((item, idx) => ({
    ...item,
    formattedDate: formatDate(item.createdAt as unknown as string),
    formattedAmount: currencyFormat(item.total || 0, "NGN"),
    status: removeUnderscore({ actionWord: item.status }),
    formattedHeader: `${item.invoiceNumber} ${item.recipientEmail}`
  })),[invoices])

  return (
    <div className="space-y-4 w-full p-10 ">
      <div className="flex justify-between">
        <div className="page-title">Invoices</div>
        <Button>
          <Link to={PAYMENT_PATHS.CREATE_INVOICE}>Create an Invoice</Link>
        </Button>
      </div>
      <Separator />
      {invoices.length < 1 ? (
        <div className="flex items-center flex-col py-20">
          <IconEmptyInvoice className="size-36" />
          <h4 className="font-ojah text-[26px] leading-8 mt-6 mb-2">
            No Invoice Yet
          </h4>
          <p className="text-subtext-gray">
            You have not created any Invoice from this account yet.
          </p>
        </div>
      ) : (
        <DataTable
          data={formattedInvoice}
          columns={InvoiceColumns}
          renderToolbar={table => <DataTableHeader
            filterOptions={["Paid", "Not Paid"]}
               placeholder="Search Invoice" table={table} />}
          meta={{
            handleOnClick
          }}
        />
      )}
    </div>
  );
};
