import { ReactComponent as IconClockFill } from "assets/images/icons/clock-fill.svg";
import { ReactComponent as IconInfoFillGreen } from "assets/images/icons/Info-Green.svg";
import { ReactComponent as IconInfoFillYellow } from "assets/images/icons/Info-Yellow.svg";
import { ReactComponent as IconTriangleExclamation } from "assets/images/icons/triangle-exclamation.svg";
import { ReactComponent as IconWarning } from "assets/images/icons/Warning.svg";
import ButtonLoader from "components/common/form/ButtonLoader";
import { BusinessStatus } from "types/business";


const VerificationAlert = () => (
    <div className="bg-shiga-warn p-6 flex items-center justify-center flex-wrap">
        <span className="flex items-start md:items-center text-white">
            <IconWarning className="hidden md:block" /> &nbsp;
            Your business verification request was rejected.
            &nbsp;
            <p className="underline underline-offset-4 decoration-[#FFFFFF66] decoration-2">
                Review your submission
            </p>
            &nbsp;
            and try again
        </span>
    </div>
)

const ReviewAlert = () => (
    <div className="bg-outline-orange p-6 flex items-center justify-center flex-wrap">
        <span className="flex items-start md:items-center text-shiga-orange">
            <IconClockFill className="hidden md:block" /> &nbsp;
            Your business documents are currently being reviewed.
        </span>
    </div>
)

const Sandbox = () => (
    <div className="bg-outline-orange p-6 flex items-center justify-center flex-wrap">
        <span className="flex items-start md:items-center text-shiga-orange">
            <IconInfoFillYellow className="hidden md:block" /> &nbsp;
            You are currently on sandbox mode.
        </span>
    </div>
)

const Devmode = () => (
    <div className="bg-[#F0FBF6] p-6 flex items-center justify-center flex-wrap">
        <span className="flex items-start md:items-center text-[#00B866]">
            <IconInfoFillGreen className="hidden md:block" /> &nbsp;
            You are currently on Dev-mode.
        </span>
    </div>
)

const SandboxModeAlert: React.FC<{
    verifyBusiness: () => void
}> = ({ verifyBusiness }) => (
    <div className="bg-[#FF9505] p-6 flex items-center justify-between flex-wrap" >
        <span className="flex items-start md:items-center text-white">
            <IconTriangleExclamation className="hidden md:block" /> &nbsp;
            <p>
                You are currently on sandbox mode.
                Verify your business account to start using Shiga Business in live mode
            </p>
        </span>
        <ButtonLoader
            type="button"
            onClick={verifyBusiness}
            loading={false}
            className="btn w-[132px] mt-2 md:mt-0 text-[14px] h-[36px] btn-md-lg cursor-none bg-white"
        >
            Verify Business
        </ButtonLoader>
    </div>
)

export const showBusinessStatus = (businessStatus: BusinessStatus | "dev" | "sandbox" | string, cb: () => void) => {
    switch (businessStatus) {
        case "dev":
            return <Devmode/>;
        case "sandbox":
            return <Sandbox/>
        case "PENDING":
            return <ReviewAlert />
        case "UNVERIFIED":
            return <SandboxModeAlert verifyBusiness={cb} />
        case "FAILED":
            return <VerificationAlert />;
        case "VERIFIED":
        default:
            return <></>
    }
}