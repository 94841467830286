import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "sonner";
import Pagination from "components/common/Pagination";
import Table from "components/common/Table";
import { ReactComponent as IconEdit } from "assets/images/icons/Edit2.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import Search from "components/common/Search";
import Maybe from "components/common/Maybe";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import { ButtonLoader } from "components/common/form";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { BulkTransferType } from "./BulkTransfer";

const filterOptions = [
    {
        label: "Invalid",
        value: "invalid",
    },
    {
        label: "Verified",
        value: "verified",
    },
];

const TableHeaderDropdown = () => {
    return (
        <Dropdown dropdownClass="min-w-[291px] bg-[#171819]">
            <button type="button" className="btn btn-md btn-white">
                Sort by
                <FontAwesomeIcon icon="angle-down" className="ml-2" />
            </button>
            <div className="p-2">
                {filterOptions.map((filterOption) => (
                    <Menu.Item key={filterOption.value}>
                        <div className="dropdown-item flex items-center justify-between">
                            <div className="whitespace-nowrap">
                                {filterOption.label}
                            </div>
                            <div className="w-7 text-right flex-shrink-0">
                                <Maybe condition={false}>
                                    <FontAwesomeIcon icon="check-circle" />
                                </Maybe>
                            </div>
                        </div>
                    </Menu.Item>
                ))}
                <div className="flex justify-between border-t border-[#232425] pt-3 mt-1">
                    <ButtonLoader type="button" loading={false} className="btn btn-transparent-black btn-md">
                        <span>Clear</span>
                    </ButtonLoader>
                    <ButtonLoader type="button" loading={false} className="btn btn-white btn-md">
                        <span>Apply</span>
                    </ButtonLoader>
                </div>
            </div>
        </Dropdown>
    )
}

const TableMoreDropdown: React.FC<{
    handleSelected: () => void
}> = ({ handleSelected }) => {
    return (
        <Dropdown dropdownClass="dropdown-right">
            <></>
            <div>
                <Menu.Item
                    as="div"
                    onClick={handleSelected}
                    className="dropdown-item dropdown-item-icon"
                >
                    <IconEdit />
                    Edit Recipient
                </Menu.Item>
                <Menu.Item
                    as="div"
                    //   onClick={toggleShowDelete}
                    className="dropdown-item dropdown-item-icon text-shiga-warn"
                >
                    <IconDelete />
                    Remove Recipient
                </Menu.Item>
            </div>
        </Dropdown>)
}

export const BulkTransferTable: React.FC<{
    handleSelect: (arg: BulkTransferType) => void;
}> = ({ handleSelect }) => {
    const [_copiedText, copy] = useCopyToClipboard();

    const copyItem = ({ link, name }: { link: string; name: string }) => async () => {
        try {
            await copy(link);
            toast.success(`Refund reference copied.`, {
                id: name,
            });
        } catch (error) {
            toast.success(`Error copying link`, {
                id: name,
            });
        }
    }

    const handleSelected = (arg: BulkTransferType) => () => {
        handleSelect(arg)
    }

    return (
        <>
            <div>
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <TableHeaderDropdown />
                    <div className="flex items-center">
                        <div className="mr-4">
                            <Pagination />
                        </div>
                        <Search placeholder="Search recipients" />
                    </div>
                </div>
                <div className="mt-6">
                    <Table tableClass=" !overflow-y-scroll">
                        <Table.Head>
                            <th>S/N</th>
                            <th>ACCOUNT NAME</th>
                            <th>BANK NAME</th>
                            <th>ACCOUNT NUMBER</th>
                            <th>AMOUNT</th>
                            <th>STATUS</th>
                            <th></th>
                        </Table.Head>
                        <Table.Body className="overflow-auto">
                            {[...Array(8)].map((data, index) => (
                                <tr key={index} className="cursor-pointer">
                                    <td>
                                        <span className="font-medium">01</span>
                                    </td>
                                    <td>
                                        <span className="font-medium">Odumodu Black</span>
                                    </td>
                                    <td>
                                        <span className="font-medium">Access Bank PLC</span>
                                    </td>
                                    <td>
                                        <span className="font-medium">01234567890</span>
                                    </td>
                                    <td onClick={copyItem({
                                        link: "d2nj98rad39re9",
                                        name: "Johnson Doe"
                                    })}>
                                        <span className="font-medium mr-1.5 truncate max-w-[60%]">12,000 NGN</span>
                                    </td>
                                    <td>
                                        <Maybe condition={index % 2 === 0}>
                                            <div className="label-border !text-[#00C46C] !border-[#00C46C] !p-1">
                                                <span className="font-medium">Verified</span>
                                            </div>
                                        </Maybe>
                                        <Maybe condition={index % 2 === 1}>
                                            <div className="label-border !text-[#DA2F20] !border-[#FFEEE8] !p-1">
                                                <span className="font-medium">Invalid</span>
                                            </div>
                                        </Maybe>
                                    </td>
                                    <td>
                                        <TableMoreDropdown handleSelected={handleSelected({
                                            name: "Johnson Doe",
                                            amount: "500 GBP",
                                            type: "Pending",
                                            id: index
                                        })} />
                                    </td>
                                </tr>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    );
};
