import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";

export function getTransactions(params?: any) {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetTransactions + ObjectToQueryString(params),
      method: "get",
      data: {},
      onStart: actions.GET_TRANSACTIONS_START,
      onSuccess: actions.GET_TRANSACTIONS,
      onError: actions.GET_TRANSACTIONS_FAILED,
    },
  };
}
