import { ReactNode } from "react";

export const BorderText = ({ children, onClick, containerClassName = "", textClassName = "" }: { children: ReactNode; containerClassName?: string; onClick?: () => void; textClassName?: string }) => {
  return (
    <div className={`px-[5px] py-1 rounded-md border border-zinc-200 justify-start items-center gap-1.5 inline-flex ${containerClassName}`}>
      <div className="flex-col justify-start items-start gap-2.5 inline-flex">
        <div className={`text-neutral-950 capitalize font-medium`}>
          {children}
        </div>
      </div>
    </div>
  );
};
