const PATHS = {
    TRANSFER: "/transactions/make-a-transfer",
    TRANSFER_BENEFICIARY: '/transactions/make-a-transfer/beneficiary',
    TRANSFER_AMOUNT: '/transactions/make-a-transfer/amount',
    TRANSFER_AMOUNT_TO: '/transactions/make-a-transfer/convert/',
    TRANSFER_CONFIRM: '/transactions/make-a-transfer/confirm',
    ISSUED_ACCOUNTS: "/accounts",
    TRANSFER_BULK_PAYMENT: '/transactions/make-a-transfer/bulk-payment',
    CONVERT_MONEY: '/transactions/convert-money',
    CONVERT_MONEY_CONFIRM: "/transactions/convert-money/confirm",
    TRANSACTION_HOME: "/transactions",

    TRANSFERS: {
        MAKE_A_TRANSFER: "/transactions/make-a-transfer",
    },

    TRANSACTIONS: {
        TRANSACTIONS_HOMEPAGE: "/transactions",
    },

    REFUNDS: {
        REFUNDS_HOMEPAGE: '/refunds'
    },

    TEAMS: {
        ACCEPT_INVITE: '/teams/accept-invite'
    },

    SETTINGS: {
        USER_PROFILE: "/settings/profile",
        BUSINESS_PROFILE: "/settings/business-profile",
        SETTLEMENT_ACCOUNTS: "/settings/settlement-accounts",
        TEAM: "/settings/team",
        FEES_EARNINGS: "/settings/fees-earnings",
        PAYMENT_METHODS: "/settings/payment-methods",
        NOTIFICATIONS: "/settings/notifications",
        KEYS_WEBHOOKS: "/settings/keys-webhooks",
    }
};

export default PATHS;