import { PaymentLink } from "types/payment/payments.interface";

import { formatDate, getTime } from "lib/format.date";
import { CalendarDays, Globe } from "lucide-react";
type Props = {
  link: PaymentLink;
};
export const Stat = ({ link }: Props) => {
  return (
    <div className="flex flex-col justify-between h-full text-[13px]">
      <div className="flex  items-center gap-2">
        <CalendarDays className="h-3 w-3 text-zinc-400 " />

        <div className="text-neutral-400 text-[13px] font-medium font-['Inter']">
          Created {formatDate(link.createdAt)} - {getTime(link.createdAt)}
        </div>
      </div>

      <div className="flex items-center gap-2">
        <Globe className=" h-3 w-3 text-zinc-400 " />

        <div className="text-neutral-400 text-[13px] font-medium font-['Inter']">
          Visited 0 times
        </div>
      </div>
      <div className="flex items-center gap-2">
        <CalendarDays className="  h-3 w-3 text-zinc-400 " />

        <span className="text-zinc-400 text-[13px] font-medium font-['Inter']">
          <span>Received</span>
          <span className="text-neutral-400 px-2">{link.amount} NGN</span>
        </span>
      </div>
    </div>
  );
};
