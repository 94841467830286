import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Input } from "components/common/form";
import { Tooltip } from "react-tooltip";
import { ReactComponent as IconBusinessProfile } from "assets/images/icons/Business.svg";
import { ReactComponent as IconEdit } from "assets/images/icons/Edit.svg";
import { ReactComponent as IconRemove } from "assets/images/icons/Close.svg";
import { ReactComponent as IconGlobe } from "assets/images/icons/Globe.svg";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import { countryIndex } from "data/constants/common";
import PageContent from "components/common/PageContent";
import Maybe from "components/common/Maybe";
import useLoginInfo from "hooks/useLoginInfo";


const BusinessProfile = () => {
    const { getBusinessProfile } = useLoginInfo();
    const { userInfo } = useSelector((s: any) => s.auth.userPersist);
    const { businessProfileLoading } = useSelector((s:any) => s.auth.account);

    useEffect(() => {
        if (userInfo?.businessProfile === undefined){
            getBusinessProfile();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            <DynamicHeadTag
                headerText="Business Profile"
            />

            <Tooltip id="dp-edit" className="custom-tooltip-ui" />
            <Tooltip id="dp-remove" className="custom-tooltip-ui" />

            <div className="page-title pb-6 mb-12 border-b border-gray-100">
                Business Profile
            </div>

            <PageContent
                loading={businessProfileLoading}
                data={userInfo?.businessProfile}
                loadingText="Loading business profile..."
            />

            <Maybe condition={businessProfileLoading === false}>
                <div className="lg:flex lg:space-x-20">
                    <div className="w-44">
                        <div className="w-44 h-44 bg-[#F3F4F5] relative rounded-3xl overflow-hidden">
                            <IconEdit data-tooltip-id="dp-remove" data-tooltip-content="Change Image" className="w-8 h-8 p-1.5 bg-black bg-opacity-50 absolute z-[1] bottom-2 left-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none" />
                            <IconRemove data-tooltip-id="dp-remove" data-tooltip-content="Remove Image" className="w-8 h-8 p-1 bg-black bg-opacity-50 absolute z-[1] bottom-2 right-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none svg-fill-white" />
                            <IconBusinessProfile className="w-full h-full relative top-1" />
                        </div>
                        <div className="mt-4 text-xs text-[#99999C]">
                            Min 400x400px, PNG or JPEG formats
                        </div>
                    </div>

                    <div className="mt-10 lg:mt-0 lg:flex-grow">
                        <div className="text-2xl font-ojah font-bold text-[#B4B4B4] pb-6 mb-6 border-b border-gray-100">
                            Business Information
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                            <Input
                                type="text"
                                label="Business Name"
                                value={userInfo?.businessProfile?.name ?? "-"}
                                readOnly={true}
                            />
                            <Input
                                type="text"
                                label="Merchant ID"
                                value={userInfo?.businessProfile?.businessId ?? "-"}
                                readOnly={true}
                            />
                            <div className="col-span-2">
                                <Input
                                    type="text"
                                    label="Business Website"
                                    value={userInfo?.businessProfile?.website ?? "-"}
                                    readOnly={true}
                                    leftIcon={<IconGlobe />}
                                    leftIconClass="px-2.5"
                                />
                            </div>
                            <Input
                                type="text"
                                label="Location"
                                value={countryIndex[userInfo?.businessProfile?.country ?? ""] ?? "-"}
                                readOnly={true}
                            />
                            <Input
                                type="tel"
                                label="Phone number"
                                value={userInfo?.businessProfile?.phoneNumber ?? "-"}
                                readOnly={true}
                            />
                            <Input
                                type="email"
                                label="Email Address"
                                value={userInfo?.businessProfile?.email ?? "-"}
                                readOnly={true}
                            />
                            <Input
                                type="email"
                                label="Support Email"
                                value={userInfo?.businessProfile?.supportEmail ?? "-"}
                                readOnly={true}
                            />
                            <div className="col-span-2">
                                <div className="form-group">
                                    <label>
                                        Business Address
                                    </label>
                                    <div className="relative">
                                        <textarea
                                            className="w-full !h-24 form-input resize-none"
                                            placeholder="What does your business do?"
                                            value={userInfo?.businessProfile?.address ?? "-"}
                                            readOnly={true}
                                        >
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Maybe>
        </div>
    )
}


export default BusinessProfile;