import { Button } from "components/ui/button";
import { AdvancedOptionType, Invoice, InvoiceAdvCustomer } from "types/payment/invoice";
import { Minus, Plus } from "lucide-react";
import { toast } from "react-toastify";

export const AdvancedOptions: React.FC<{
  option: AdvancedOptionType;
  editInvoiceField: (field: keyof InvoiceAdvCustomer, value: any) => void;
  invoice: Invoice;
}> = ({ option, editInvoiceField, invoice }) => {
  return (
    <div
      className="w-[412px] h-[41px] justify-center items-center gap-4 inline-flex"
    >
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
        <div className="self-stretch h-[19px] flex-col justify-start items-start gap-6 flex">
          <div className="flex-col justify-start items-start gap-2.5 flex">
            <div className="text-zinc-800 text-base font-medium font-['Inter']">
              {option.name}
            </div>
          </div>
        </div>
        <div className="self-stretch text-zinc-400 text-[13px] font-normal font-['Inter']">
          {option.desc}
        </div>
      </div>
      <div className="justify-end items-center gap-2 flex">
        <Button
          type="button"
          onClick={() => {
            const newValue = invoice.advancedCustomer[option.key] - 1
            if(newValue >= 0){
              editInvoiceField(
                option.key,
                newValue
              );
            }else{
              toast.info("Value cannot go lower than 0.")
            }
          }}
          className="w-8 h-8 px-[5px] py-[3.50px] bg-neutral-100 rounded-3xl justify-center items-center flex"
        >
          <Minus className="text-zinc-400" />
        </Button>

        <div className="text-zinc-400 text-lg font-normal font-['Ojah Display']">
          {invoice.advancedCustomer[option.key]}%
        </div>

        <Button
          type="button"
          onClick={() => {
            editInvoiceField(
              option.key,
              invoice.advancedCustomer[option.key] + 1
            );
          }}
          className="w-8 h-8 px-[5px] py-[3.50px] bg-neutral-100 rounded-3xl justify-center items-center flex"
        >
          <Plus className="text-zinc-400" />
        </Button>
      </div>
    </div>
  )
}
