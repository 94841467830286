import useBackButton from "hooks/useBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ComingSoon = () => {
    const { goBack } = useBackButton();

    return (
        <div className="h-full flex items-center justify-center pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
            <div className="px-8 py-20 text-center max-w-md">

                <div>
                    <FontAwesomeIcon icon="globe" className="text-8xl text-shiga-purple" />
                </div>
                <div className="mt-8 text-4xl font-ojah font-bold">
                    Coming Soon
                </div>
                <div className="mt-4 text-shiga-gray">
                    This module would be published soon.
                    <br />
                    Please check back after some time.
                </div>

                <div className="mt-8 space-y-2">
                    <button onClick={() => goBack()} className="btn btn-block btn-lg btn-shiga-black">
                        Back to previous page
                    </button>
                </div>

            </div>
        </div>
    )
}

export default ComingSoon
