export const shigaPurpleAccent = "#FAFAFF";
export const shigaDark = "#99999C";
export const inputBG = "#FAFAFB";
export const outlineGrey = "#F5F5F5";
export const outlineOrange = "#FFF0D9"
export const shigaDark2 = "#999999";
export const shigaGray = "#B4B4B4";
export const shigaBlack2 = "#1F1F1E";
export const shigaBlack = "#343433";


export const tailwindColors = {
  "shiga-black": shigaBlack,
  "shiga-black-2": shigaBlack2,
  "shiga-purple": "#625BF6",
  "shiga-purple-2": "#5540EB",
  "shiga-purple-dark": "#4F59DD",
  "shiga-purple-accent": shigaPurpleAccent,
  "shiga-dark": shigaDark,
  "shiga-dark-2": shigaDark2,
  "shiga-gray": shigaGray,
  "shiga-warn": "#FF4E4E",
  "shiga-orange": "#F29100",
  "shiga-green": "#00C46C",
  "card-grey": "#F3F4F5",
  "outline-grey": outlineGrey,
  "outline-grey-2": "#E2E3E5",
  "input-bg": inputBG,
  "outline-orange": outlineOrange,
  "shiga-gray-25": "#FAFAFA",
  "shiga-gray-40": "#FAFAFB",
  "shiga-gray-50": "#F5F5F5",
  "shiga-gray-75": "#E2E3E5",
  "shiga-gray-100": "#B4B4B4",
  "shiga-gray-200": "#6F6F6F",
  "shiga-gray-250": "#999999",
  "shiga-gray-300": "#99999C",
  "shiga-dark-50": "#252627",
  "shiga-dark-100": "#343433",
  "shiga-dark-200": "#1F1F1E",
  "shiga-dark-300": "#202122",
  "shiga-dark-400": "#1C1E1B",
  "shiga-dark-500": "#282828",
  "shiga-dark-600": "#222222",
  "shiga-dark-700": "#0C0C0C",
  "shiga-dark-800": "#171819",
  "shiga-dark-900": "#232425",
  "shiga-green-50": "#DCFAEC",
  "shiga-green-100": "#00C46C",
  "shiga-orange-50": "#FFF0D9",
  "shiga-orange-100": "#F29100",
  "shiga-purple-200": "#5E5CE6",
  "shiga-purple-300": "#5852DD",
  "shiga-purple-400": "#625BF6",
  "shiga-purple-500": "#5540EB",
  "shiga-red-25": "#FFF4F4",
  "shiga-red-40": "#FFEEE8",
  "shiga-red-50": "#FF4E4E",

  // shadcn colors
  border: "hsl(var(--border))",
  input: "hsl(var(--input))",
  ring: "hsl(var(--ring))",
  background: "hsl(var(--background))",
  primary: "hsl(var(--primary))",
  black: "hsl(var(--black))",
  white: "hsl(var(--white))",
  warn: "hsl(var(--warn))",
  green: "hsl(var(--green))",
  yellow: "hsl(var(--yellow))",
  "yellow-dark": "hsl(var(--yellow-dark))",
  "form-gray": "hsl(var(--form-gray))",
  "dashboard-gray": "hsl(var(--dashboard-gray))",
  "subtext-gray": "hsl(var(--subtext-gray))",
  "subtext-light-gray": "hsl(var(--subtext-light-gray))",
  "icon-container-gray": "hsl(var(--icon-container-gray))",

  popover: "hsl(var(--popover))",
}