import { ReactComponent as EmptyPaymentLinkIcon } from "assets/images/icons/payment/empty-payment-links.svg";
import { EmptyState } from "components/common/EmptyState";
import { PageHeading } from "components/common/PageHeading";
import { Loader } from "lucide-react";
import { useEffect } from "react";
import { PAYMENT_PATHS } from "../paths";
import { useSharedStore } from "zustand-store";
import { PaymentLinkTable } from "./table/PaymentLinkTable";
import { usePaymentLinkStore } from "zustand-store/payments/links";

export const PaymentLinks = () => {
    const { apiLoading: loading} = useSharedStore();
    const {fetchPaymentLinks, paymentLink} = usePaymentLinkStore();

  useEffect(() => {
    fetchPaymentLinks();
  }, []);


  return (
    <div className="w-full p-10">
      <PageHeading
        title="Payment Link"
        cta={{
          name: "Create a link",
          link: PAYMENT_PATHS.CREATE_PAYMENT_LINK,
        }}
      />
      {loading ? (
        <div className="w-full  flex items-center justify-center p-24">
          <Loader className="animate-spin" />
        </div>
      ) : paymentLink.length > 0 ? (
        <PaymentLinkTable paymentLink={paymentLink} />
      ) : (
        <EmptyState
          heading="No Payment Link"
          description="You have no payment link yet. Why not create one?"
          icon={<EmptyPaymentLinkIcon />}
        />
      )}
    </div>
  );
};
