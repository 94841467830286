import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonLoader, Checkbox, Input } from "components/common/form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import validate from "utils/validate";
import getPasswordStrength, {
  getPasswordStrengthLevel,
} from "utils/getPasswordStrength";
import PasswordStrengthIndicator from "components/common/PasswordStrengthIndicator";
import { ReactComponent as IconEyeOpen } from "assets/images/icons/eyeopen.svg";
import { ReactComponent as IconEyeClose } from "assets/images/icons/eyeclose.svg";
import PinModal from "components/auth/PinModal";
import * as userActions from "store/auth/user/action";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    registerForm,
    otpEmailSendLoading,
    otpEmailSend,
    otpEmailResendLoading,
    otpEmailResend,
    otpEmailVerificationLoading,
    otpEmailVerification,
  } = useSelector((s: any) => s.auth.user);

  const [showPassword, setShowPassword] = useState(false);
  const [acceptAcknowledgement, setAcceptAcknowledgement] = useState(false);
  const [isOpenOTP, setIsOpenOTP] = useState(false);
  const [passwordStrengthLevel, setShowPasswordLevel] = useState<any>({
    level: 0,
    bgColor: "bg-[#E2E3E5]",
    textColor: "text-[#B4B4B4]",
    text: "Password Strength",
  });
  const [passwordStrength, setPasswordStrength] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasSpecialCharacter: false,
    hasCharacterLength: false,
  });
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [otp, setOtp] = useState("");

  const updatePassword = (password: string) => {
    let newPasswordStrength = getPasswordStrength(password);
    let newPasswordLevel = getPasswordStrengthLevel(newPasswordStrength);
    setPasswordStrength(newPasswordStrength);
    setShowPasswordLevel(newPasswordLevel);
    setForm({ ...form, password });
  };

  const isFormValid = () => {
    let response = true;

    if (acceptAcknowledgement === false) {
      response = false;
      toast.error("Please read and acknowledge the terms");
    }
    if (
      !(
        passwordStrength.hasUpperCase === true &&
        passwordStrength.hasLowerCase === true &&
        passwordStrength.hasNumber === true &&
        passwordStrength.hasSpecialCharacter === true &&
        passwordStrength.hasCharacterLength === true
      )
    ) {
      response = false;
      toast.error("Please enter a strong password");
    }

    return response;
  };

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: any = validate(form);
    if (errors) {
      for (var x in errors) {
        toast.error(errors[x]);
      }
      return;
    } else {
      if (isFormValid() === true) {
        if (
          Object.keys(registerForm).length > 0 &&
          form.email === registerForm.email
        ) {
          dispatch(
            userActions.saveRegisterForm({
              ...registerForm,
              ...form,
            })
          );
          navigate("/register/account-type");
        } else {
          const params = {
            identifier: form.email,
            type: "EMAIL",
          };
          dispatch(userActions.sendOtp(params));
        }
      }
    }
  };

  const resendOtpEmail = () => {
    if (isFormValid() === true) {
      const params = {
        identifier: form.email,
        type: "EMAIL",
      };
      dispatch(userActions.resendOtp(params));
    }
  };

  const submitOtp = () => {
    const errors: any = validate({ otp });
    if (errors) {
      for (var x in errors) {
        toast.error(errors[x]);
      }
      return;
    } else {
      const params = {
        code: otp,
        identifier: form.email,
      };
      dispatch(userActions.verifyOtp(params));
    }
  };

  useEffect(() => {
    if (Object.keys(registerForm).length > 0) {
      updatePassword(registerForm.password);
      setAcceptAcknowledgement(true);
      setForm({
        ...form,
        firstName: registerForm.firstName,
        lastName: registerForm.lastName,
        email: registerForm.email,
        password: registerForm.password,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (otpEmailSend?.success === true) {
      setIsOpenOTP(true);
      dispatch(userActions.resetSendOtp());
    }
    // eslint-disable-next-line
  }, [otpEmailSend]);

  useEffect(() => {
    if (otpEmailResend?.success === true) {
      dispatch(userActions.resetResendOtp());
    }
    // eslint-disable-next-line
  }, [otpEmailSend]);

  useEffect(() => {
    if (otpEmailVerification?.success === true) {
      dispatch(userActions.resetVerifyOtp());
      dispatch(
        userActions.saveRegisterForm({
          ...registerForm,
          ...form,
        })
      );
      navigate("/register/account-type");
    }
    // eslint-disable-next-line
  }, [otpEmailVerification]);

  const submitDisabled = !getPasswordStrength(form.password).hasCharacterLength
    || !getPasswordStrength(form.password).hasLowerCase
    || !getPasswordStrength(form.password).hasUpperCase
    || !getPasswordStrength(form.password).hasNumber
    || !getPasswordStrength(form.password).hasSpecialCharacter

  return (
    <>
      <DynamicHeadTag headerText="Register" />

      <PinModal
        target="OTP"
        title="Confirm your Email"
        pin={(e: any) => setOtp(e)}
        submit={submitOtp}
        submitText="Confirm Email"
        showResendEmail={true}
        resendEmailClick={resendOtpEmail}
        resendEmailLoading={otpEmailResendLoading}
        resendEmailResponse={otpEmailResend}
        loading={otpEmailVerificationLoading}
        modalLoading={false}
        isOpenPin={isOpenOTP}
        setIsOpenPin={setIsOpenOTP}
      />

      <AuthPageTitle
        title="Open a Business Account"
        description={
          <>
            <span className="mr-1">If you already have an account</span>
            <Link
              to="/login"
              className="text-shiga-purple font-medium hover-border hover:border-shiga-purple"
            >
              Login
            </Link>
          </>
        }
      />

      <div>
        <form onSubmit={submitForm}>
          <div className="mt-10">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
              <Input
                type="text"
                label="First Name"
                placeholder="First Name"
                required={true}
                value={form.firstName}
                onChange={(e: any) =>
                  setForm({ ...form, firstName: e.target.value })
                }
              />
              <Input
                type="text"
                label="Last Name"
                placeholder="Last Name"
                required={true}
                value={form.lastName}
                onChange={(e: any) =>
                  setForm({ ...form, lastName: e.target.value })
                }
              />
            </div>

            <Input
              type="email"
              label="Email Address"
              placeholder="Email Address"
              required={true}
              value={form.email}
              onChange={(e: any) => setForm({ ...form, email: e.target.value })}
            />

            <Input
              type={showPassword ? "text" : "password"}
              autoComplete="false"
              label="Password"
              placeholder="Password"
              required={true}
              value={form.password}
              onChange={(e: any) => updatePassword(e.target.value)}
              rightIcon={showPassword ? <IconEyeOpen /> : <IconEyeClose />}
              rightIconClicked={() => setShowPassword(!showPassword)}
              rightIconClass="px-3"
            />

            <div className="-mt-2 mb-5">
              <PasswordStrengthIndicator
                password={form.password}
                passwordStrengthLevel={passwordStrengthLevel}
              />
            </div>

            <div className="mt-8">
              <Checkbox
                type="checkbox"
                label={
                  <>
                    I acknowledge and agree to&nbsp;
                    <Link
                      to="#"
                      target="_blank"
                      className="border-b border-shiga-gray hover:border-shiga-black hover:text-shiga-black"
                    >
                      Payshiga’s Merchant Services Agreement (MSA)
                    </Link>
                    ,&nbsp;
                    <Link
                      to="https://payshiga.notion.site/PAYSHIGA-TERMS-OF-USE-c14e881663ec40c7875e61ff0eebc40b?pvs=4"
                      target="_blank"
                      className="border-b border-shiga-gray hover:border-shiga-black hover:text-shiga-black"
                    >
                      Terms of Use
                    </Link>{" "}
                    &&nbsp;
                    <Link
                      to="https://payshiga.notion.site/PAYSHIGA-PRIVACY-POLICY-6165bd6474ad4297a2227a0f7c061a7b?pvs=4"
                      target="_blank"
                      className="border-b border-shiga-gray hover:border-shiga-black hover:text-shiga-black"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </>
                }
                checked={acceptAcknowledgement === true}
                onChange={() =>
                  setAcceptAcknowledgement(!acceptAcknowledgement)
                }
                readOnly={true}
                containerClass="!items-start text-shiga-gray text-sm"
              />
            </div>

            <ButtonLoader
              type="submit"
              loading={otpEmailSendLoading}
              className="btn btn-lg btn-block btn-primary mt-10"
              disabled={submitDisabled}>
              Continue
            </ButtonLoader>
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
