import noisygradient from "assets/images/icons/payment/noisy-gradients.png";

import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "components/data-table/data-table-column-header";
import { PaymentLink } from "types/payment/payments.interface";
import { BorderText } from "components/common/BorderText";
import { PaymentLinkActions } from "../PaymentLinkAction";

export const PaymentLinkColumns: ColumnDef<PaymentLink>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="NAME" />
        ),
        cell: ({ row, table }) => {
            const handleDetails = () => {
                (table as any).options.meta?.handleClick(row.original)
            }
            return (
                <div className="flex items-center gap-3 cursor-pointer" onClick={handleDetails}>
                    <img
                        className="w-8 h-8 rounded-md"
                        src={row.original.logoUrl || noisygradient}
                        alt={row.original.name}
                    />
                    <p className="text-zinc-800 text-base font-medium">
                        {row.original.name}
                    </p>
                </div>
            )
        },
    },
    {
        accessorKey: "formattedAmount",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="AMOUNT" />
        ),
        cell: ({ row, table }) => {
            const handleDetails = () => {
                (table as any).options.meta?.handleClick(row.original)
            }
            return (
                row.original.amount ? (
                    <div className="text-zinc-800 text-base font-medium cursor-pointer" onClick={handleDetails}>
                        {row.original.formattedAmount}
                    </div>
                ) : (
                    <BorderText onClick={handleDetails}>N/A</BorderText>
                )
            )
        },
    },
    {
        accessorKey: "formattedType",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="TYPE" />
        ),
        cell: ({ row, table }) => {
            const handleDetails = () => {
                (table as any).options.meta?.handleClick(row.original)
            }
            return (
                <BorderText onClick={handleDetails}>{row.original.formattedType}</BorderText>
            )
        },
    },
    {
        accessorKey: "status",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="STATUS" />
        ),
        cell: ({ row, table }) => {
            const handleDetails = () => {
                (table as any).options.meta?.handleClick(row.original)
            }
            return (
                <BorderText onClick={handleDetails}>
                    <p
                        className={`${row.original.status === "ACTIVE" ? "text-emerald-500" : "text-red-600"
                            }`}
                    >
                        {row.original.status.toLowerCase()}
                    </p>
                </BorderText>
            )
        },
    },
    {
        accessorKey: "createdAt",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="DATE CREATED" />
        ),
        cell: ({ row, table }) => {
            const handleDetails = () => {
                (table as any).options.meta?.handleClick(row.original)
            }
            return (
                <span className="font-medium cursor-pointer" onClick={handleDetails}>{row.original.createdAt}</span>)
        },
    },
    {
        accessorKey: "dropdown",
        header: ({ column }) => (<></>),
        cell: ({ row, table, getValue }) => {
            const handleDelete = () => {
                (table as any).options.meta?.handleShowDelete(row.original)
            }
            return (<PaymentLinkActions handleDelete={handleDelete} paymentLink={row.original} />)
        },
    },
];