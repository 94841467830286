import { ButtonLoader, Input } from "components/common/form";
import TransferHeader from "components/transfer/TransferHeader";
import React, { useState, ChangeEvent, useEffect } from "react";
import SavedBeneficiaryList from "./SavedBeneficiaryList";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCurrencies } from "store/ui/currencies/action";
import {
    getTransferBanks,
    getTransferFields,
    resetTransferData,
    transferNameEnquiry,
    updateDynamicField,
    updateSelectedCurrency,
    updateSelectedWaletForTransfer
} from "store/transfer/action";
import PATHS from "NavigationRoute";
import SimpleSelect from "components/common/SimpleSelect";
import { getWalletsAndBalance } from "store/ui/dashboard/action";
import usePillTabs from "components/common/usePillTabs";

interface State {
    currentTab: number;
    country: string;
    bankName: string;
    accountNumber: number;
    canContinue: boolean;
}

const NewBeneficiary: React.FC = () => {
    const [stateN, setState] = useState<State>({
        currentTab: 0,
        country: "NGN",
        bankName: "",
        accountNumber: 0,
        canContinue: false
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { activeTab, PillTabs } = usePillTabs();

    const tabOptions: PillTabProps[] = [
        {
            label: 'New Recipient',
            value: 0
        },
        {
            label: 'Beneficiary',
            value: 1
        }
    ]

    useEffect(() => {
        dispatch(resetTransferData())
        dispatch(getCurrencies())
        dispatch(getWalletsAndBalance())
        // eslint-disable-next-line
    }, []);



    const { currenciesLoading, currenciesList } = useSelector((allStates: any) => allStates.ui.currencies)
    const { walletList } = useSelector((allState: any) => allState.ui.dashboard)

    const { country, currency, formFields, bankList, loadingBank, loadingName, accountName, transferFieldStatus, transferFields } = useSelector((allStates: any) => allStates.transfer)

    useEffect(() => {
        if (currency?.length > 0) {
            dispatch(getTransferBanks(currency))
        }
        // eslint-disable-next-line
    }, [currency])

    useEffect(() => {
        if (formFields?.accountNumber?.length === 10 && formFields?.bankName?.bankCode?.length > 0) {
            dispatch(transferNameEnquiry(formFields.accountNumber, formFields?.bankName?.bankCode))
        }
        // eslint-disable-next-line
    }, [formFields?.accountNumber, formFields?.bankName?.bankCode])


    const updateFileds = (fieldName: string, value: string) => {
        dispatch(updateDynamicField(fieldName, value))

        const isFormValid = transferFields.fields.every((field: any) => {
            if (field.required) {
                const formFieldValue = formFields[field.name];
                if (formFieldValue === undefined || formFieldValue === null) {
                    return false;
                }
                if (typeof formFieldValue === 'object') {
                    return formFieldValue.name !== '';
                }
                return formFieldValue !== '';
            }
            return true;
        });
        setState({
            ...stateN,
            canContinue: isFormValid
        })
    }

    const clickContinue = (): void => {
        navigate(PATHS.TRANSFER_AMOUNT);
    };

    const nameValidationClassBase = "mt-1 w-full flex justify-end "
    const nameValidationShimer = loadingName ? "shimmer" : ""
    const nameValidationClassName = `${nameValidationClassBase} ${nameValidationShimer}`

    return (
        <div className="w-full transfer-container">
            <TransferHeader step={1} onClose={() => { }} />
            <div className="transfer-wrapper">
                <h2 className="transfer-page-title">Add a recipient</h2>

                <PillTabs tabs={tabOptions} />

                {activeTab === 0 && (
                    <div className="max-w-xl w-full mt-8 space-y-8">

                        <SimpleSelect
                            placeholder="Select Currency"
                            isRequired={true}
                            label="Currency"
                            list={currenciesList.data.data.filter((s: any) => walletList.some((curr: any) => curr.currency === s.currency)).map((e: any) => {
                                return {
                                    name: e.currency,
                                    key: e.currency,
                                    extra: e
                                }
                            }
                            )}
                            onChange={(info) => {
                                dispatch(updateSelectedCurrency({
                                    country: info.value,
                                    currency: info.key
                                }))
                                dispatch(updateSelectedWaletForTransfer(walletList.find((curr: any) => curr.currency === info.key)))
                                dispatch(getTransferFields(info.key))
                            }}
                            isLoading={currenciesLoading}
                            key={""}
                        />

                        {transferFieldStatus &&
                            <>
                                {transferFields.fields.sort((a: any, b: any) => a.position - b.position).map((field: any) =>
                                    <>
                                        {field.type === "list" &&
                                            <SimpleSelect
                                                label={field.title}
                                                isRequired={true}
                                                list={bankList.map((bank: any) => {
                                                    return {
                                                        name: bank.name,
                                                        key: bank.name,
                                                        extra: bank
                                                    }
                                                }).sort((a: any, b: any) => {
                                                    const nameA = a.name.toLowerCase();
                                                    const nameB = b.name.toLowerCase();

                                                    if (nameA < nameB) {
                                                        return -1;
                                                    } else if (nameA > nameB) {
                                                        return 1;
                                                    } else {
                                                        return 0;
                                                    }
                                                })}
                                                onChange={(info) => {
                                                    updateFileds(field.name, info.extra.extra)
                                                }}
                                                placeholder="Select a bank"
                                                isLoading={loadingBank}
                                                key={loadingBank}
                                            />
                                        }
                                        {field.type === "string" && <Input
                                            type="text"
                                            label={field.title}
                                            value={formFields[field.name]}
                                            required={field.required}
                                            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                                // Ensure only numeric values are entered
                                                const inputValue = e.target.value;
                                                if (/^\d*$/.test(inputValue)) {
                                                    updateFileds(field.name, inputValue)
                                                }

                                            }}
                                            disabled={loadingName}
                                            containerClass="mb-0"

                                        />}

                                        {field.type === "number" && <Input
                                            type="text"
                                            label={field.title}
                                            value={formFields[field.name]}
                                            required={field.required}
                                            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                                // Ensure only numeric values are entered
                                                const inputValue = e.target.value;
                                                if (/^\d*$/.test(inputValue)) {
                                                    updateFileds(field.name, inputValue)
                                                }

                                            }}
                                            disabled={loadingName}
                                            containerClass="mb-0"

                                        />}

                                        {field.type === "email" && <Input
                                            type="text"
                                            label={field.title}
                                            value={formFields[field.name]}
                                            required={field.required}
                                            onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                                                // Ensure only numeric values are entered
                                                const inputValue = e.target.value;
                                                if (/^\d*$/.test(inputValue)) {
                                                    updateFileds(field.name, inputValue)
                                                }
                                            }}
                                            disabled={loadingName}
                                            containerClass="mb-0"

                                        />}
                                    </>
                                )}

                                {transferFields.validateAccountName && <div className={nameValidationClassName}>
                                    <p className="text-shiga-black text-sm font-semibold">{accountName}</p>
                                </div>}
                            </>
                        }

                        <ButtonLoader
                            type="submit"
                            onClick={clickContinue}
                            className="btn bg-shiga-black text-white btn-lg btn-block mt-8"
                            disabled={country?.length < 1 || currency?.length < 1 || accountName?.length < 1 || loadingName || !stateN.canContinue}>
                            Continue
                        </ButtonLoader>

                    </div>
                )}
                {activeTab === 1 && (
                    <SavedBeneficiaryList
                        className="max-w-xl w-full mt-16"
                        onClick={clickContinue}
                    />
                )}
            </div>
        </div>
    );

};

export default NewBeneficiary;
