import axios from "axios";
import { apiBaseUrl } from "data/config";
import { getToken } from "lib";
import { University } from "types/shared/university.type";

type UniversityResp = {
  data: University[];
};
export const getUniversities = async (
  country: string
): Promise<UniversityResp> =>
  axios.get(
  apiBaseUrl + `/tuition/universities/${country}`,

  {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }
).then(res => res.data)

export const createTuition = async (tuitionRequest: any) => {
    return axios.post(apiBaseUrl + `/tuition/new`, tuitionRequest, {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }).then(res => res.data)
}