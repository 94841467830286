import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "data/config";

export function getSettlementAccounts() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetSettlementAccounts,
            method: "get",
            data: {},
            onStart: actions.GET_SETTLEMENT_ACCOUNTS_START,
            onSuccess: actions.GET_SETTLEMENT_ACCOUNTS,
            onError: actions.GET_SETTLEMENT_ACCOUNTS_FAILED,
        },
    }
}

export function addSettlementAccounts(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiAddSettlementAccounts,
            method: "post",
            data: params,
            onStart: actions.ADD_SETTLEMENT_ACCOUNTS_START,
            onSuccess: actions.ADD_SETTLEMENT_ACCOUNTS,
            onError: actions.ADD_SETTLEMENT_ACCOUNTS_FAILED,
        },
    }
}

export function resetAddSettlementAccounts() {
    return {
        type: actions.ADD_SETTLEMENT_ACCOUNTS_RESET,
        payload: {},
    }
}

export function changePrimarySettlementAccounts(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateSettlementAccounts + id,
            method: "put",
            data: params,
            onStart: actions.EDIT_SETTLEMENT_ACCOUNTS_START,
            onSuccess: actions.EDIT_SETTLEMENT_ACCOUNTS,
            onError: actions.EDIT_SETTLEMENT_ACCOUNTS_FAILED,
        },
    }
}

export function resetChangeSettlementAccounts() {
    return {
        type: actions.EDIT_SETTLEMENT_ACCOUNTS_RESET,
        payload: {},
    }
}

export function deleteSettlementAccounts(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDeleteSettlementAccounts + id,
            method: "delete",
            data: {},
            onStart: actions.DELETE_SETTLEMENT_ACCOUNTS_START,
            onSuccess: actions.DELETE_SETTLEMENT_ACCOUNTS,
            onError: actions.DELETE_SETTLEMENT_ACCOUNTS_FAILED,
        },
    }
}

export function resetDeleteSettlementAccounts() {
    return {
        type: actions.DELETE_SETTLEMENT_ACCOUNTS_RESET,
        payload: {},
    }
}

export function getTeamMembers(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTeamMembers + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_TEAM_MEMBERS_START,
            onSuccess: actions.GET_TEAM_MEMBERS,
            onError: actions.GET_TEAM_MEMBERS_FAILED,
        },
    }
}

export function getTeamMembersInvited(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetTeamMembersInvited + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_TEAM_MEMBERS_INVITED_START,
            onSuccess: actions.GET_TEAM_MEMBERS_INVITED,
            onError: actions.GET_TEAM_MEMBERS_INVITED_FAILED,
        },
    }
}

export function getRoles(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetRoles,
            method: "get",
            data: params,
            onStart: actions.GET_ROLES_START,
            onSuccess: actions.GET_ROLES,
            onError: actions.GET_ROLES_FAILED,
        },
    }
}

export function addTeamMember(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiAddTeamMember,
            method: "post",
            data: params,
            onStart: actions.TEAM_MEMBER_FORM_START,
            onSuccess: actions.TEAM_MEMBER_FORM,
            onError: actions.TEAM_MEMBER_FORM_FAILED,
        },
    }
}

export function resetAddTeamMember() {
    return {
        type: actions.TEAM_MEMBER_FORM_RESET,
        payload: {},
    }
}

export function editTeamMember(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiEditTeamMember + id,
            method: "put",
            data: params,
            onStart: actions.TEAM_MEMBER_FORM_START,
            onSuccess: actions.TEAM_MEMBER_FORM,
            onError: actions.TEAM_MEMBER_FORM_FAILED,
        },
    }
}

export function resetEditTeamMember() {
    return {
        type: actions.TEAM_MEMBER_FORM_RESET,
        payload: {},
    }
}

export function memberEnforce2FA(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiMemberEnforce2FA,
            method: "put",
            data: {},
            onStart: actions.ENFORCE_MFA_START,
            onSuccess: actions.ENFORCE_MFA,
            onError: actions.ENFORCE_MFA_FAILED,
        },
    }
}

export function resetMemberEnforce2FA() {
    return {
        type: actions.ENFORCE_MFA_RESET,
        payload: {},
    }
}

export function deleteMember(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiTeamMembers + id,
            method: "delete",
            data: {},
            onStart: actions.DELETE_MEMBER_START,
            onSuccess: actions.DELETE_MEMBER,
            onError: actions.DELETE_MEMBER_FAILED,
        },
    }
}

export function resetDeleteMember() {
    return {
        type: actions.DELETE_MEMBER_RESET,
        payload: {},
    }
}

export function memberResendInvite(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiMemberInvite + id + "/resend",
            method: "post",
            data: {},
            onStart: actions.RESEND_INVITE_START,
            onSuccess: actions.RESEND_INVITE,
            onError: actions.RESEND_INVITE_FAILED,
        },
    }
}

export function resetMemberResendInvite() {
    return {
        type: actions.RESEND_INVITE_RESET,
        payload: {},
    }
}

export function memberCancelInvite(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiMemberInvite + id,
            method: "delete",
            data: {},
            onStart: actions.CANCEL_INVITE_START,
            onSuccess: actions.CANCEL_INVITE,
            onError: actions.CANCEL_INVITE_FAILED,
        },
    }
}

export function resetMemberCancelInvite() {
    return {
        type: actions.CANCEL_INVITE_RESET,
        payload: {},
    }
}

export function memberAcceptInvite(id, form) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiMemberInvite + id + "/accept",
            method: "post",
            data: form,
            onStart: actions.ACCEPT_INVITE_START,
            onSuccess: actions.ACCEPT_INVITE,
            onError: actions.ACCEPT_INVITE_FAILED,
        },
    }
}

export function resetMemberAcceptInvite() {
    return {
        type: actions.ACCEPT_INVITE_RESET,
        payload: {},
    }
}

export function updateFeesEarnings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateFeesEarnings,
            method: "post",
            data: params,
            onStart: actions.UPDATE_FEES_EARNINGS_START,
            onSuccess: actions.UPDATE_FEES_EARNINGS,
            onError: actions.UPDATE_FEES_EARNINGS_FAILED,
        },
    }
}

export function resetUpdateFeesEarnings() {
    return {
        type: actions.UPDATE_FEES_EARNINGS_RESET,
        payload: {},
    }
}

export function updatePaymentMethod(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdatePaymentMethod,
            method: "post",
            data: params,
            onStart: actions.UPDATE_PAYMENT_METHOD_START,
            onSuccess: actions.UPDATE_PAYMENT_METHOD,
            onError: actions.UPDATE_PAYMENT_METHOD_FAILED,
        },
    }
}

export function resetUpdatePaymentMethod() {
    return {
        type: actions.UPDATE_PAYMENT_METHOD_RESET,
        payload: {},
    }
}

export function updateNotificationPreference(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateNotificationPreference,
            method: "post",
            data: params,
            onStart: actions.UPDATE_NOTIFICATION_PREFERNCE_START,
            onSuccess: actions.UPDATE_NOTIFICATION_PREFERNCE,
            onError: actions.UPDATE_NOTIFICATION_PREFERNCE_FAILED,
        },
    }
}

export function resetUpdateNotificationPreference() {
    return {
        type: actions.UPDATE_NOTIFICATION_PREFERNCE_RESET,
        payload: {},
    }
}

export function getKeys(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetKeys + (ObjectToQueryString(params) ?? ""),
            method: "get",
            data: {},
            onStart: actions.GET_KEYS_START,
            onSuccess: actions.GET_KEYS,
            onError: actions.GET_KEYS_FAILED,
        },
    }
}

export function updateWebhooks(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateWebhooks,
            method: "post",
            data: params,
            onStart: actions.UPDATE_WEBHOOKS_START,
            onSuccess: actions.UPDATE_WEBHOOKS,
            onError: actions.UPDATE_WEBHOOKS_FAILED,
        },
    }
}

export function resetUpdateWebhooks() {
    return {
        type: actions.UPDATE_WEBHOOKS_RESET,
        payload: {},
    }
}
