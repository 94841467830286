import axios from "axios";
import { apiBaseUrl } from "data/config";
import { Currency } from "types/shared/countries.types";
import { handleError } from "./format.error";

export const getCurrencies = async (): Promise<Currency[]> => {
  const curreniesStr = sessionStorage.getItem("currencies");
  if (!curreniesStr) {
    return axios.get(
      apiBaseUrl + "/currencies"
    ).then(res => {
      sessionStorage.setItem("curencies", JSON.stringify(res.data.data));
      return res.data.data;
    }).catch(handleError)
  }
  return JSON.parse(curreniesStr);
};

export const getCountries = async () => {
  const countriesString = sessionStorage.getItem("countries");
  if (!countriesString) {
    return axios.get(apiBaseUrl + "/countries").then(res => {
      sessionStorage.setItem(
        "countries",
        JSON.stringify(Object.keys(res.data.data.countries))
      );
      if (res?.data?.data?.data !== undefined) {
        return Object.keys(res.data.data.data)
      }
    }).catch(handleError);
  }
  return JSON.parse(countriesString);
};

