export interface PaymentLink {
  id: string;
  businessId: string;
  name: string;
  logoUrl: string;
  description: string;
  status: "ACTIVE" | "INACTIVE";
  callbackUrl: string;
  slug?: string;
  type: "ONE_TIME" | "REUSABLE";
  acceptOthers: boolean;
  amountType: "FIXED" | "FLEXIBLE";
  currency: string;
  currencyType: "FIXED" | "FLEXIBLE";
  channels: any | null;
  amount: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  link: string;

  formattedAmount?: string;
  formattedType?: string;
}

export const defaultPaymentLink: PaymentLink = {
  acceptOthers: false,
  amount: 0,
  amountType: 'FIXED',
  businessId: "",
  callbackUrl: "",
  channels: "",
  createdAt: "",
  currency: "",
  currencyType: "FIXED",
  deletedAt: "",
  description: "",
  id: "",
  link: "",
  logoUrl: "",
  name: "",
  slug: "",
  status: "INACTIVE",
  type: "ONE_TIME",
  updatedAt: ""
}