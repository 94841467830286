import { ButtonLoader } from "components/common/form";
import CustomAmountField from "components/custom/CustomAmountField";
import { getCurrentBalance } from "lib/business";
import React, { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Wallet, defaultWallet } from "types/wallet";
import validate from "utils/validate";
import NGN from "assets/images/flags/Nigeria (NG).svg";
import PurchaseInfo from "pages/bills/PurchaseInfo";
import { ReactComponent as IconInfoFillYellow } from "assets/images/icons/Info-Yellow.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import copy from "copy-to-clipboard";
import StepHeader from "pages/bills/StepHeader";
import AddMoneyModalHeader from "./AddMoneyModalHeader";

interface FormState {
  amount: string;
  currency: string;
  ourFee: string;
}

interface ModalProps {
  close: () => void;
}
const NGNAddMoneyForm = ({ close }: ModalProps) => {
  const [pageStep, setPageStep] = useState<number>(1);
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);
  const [amount, setAmount] = useState("");

  const copyText = (text: string, label?: string | undefined) => () => {
    copy(text);
    toast.success("Copied: " + (label ?? text));
  };

  const fieldRequired = amount === "" || parseFloat(amount) === 0 && Number(amount) < 100;
  const [form, setForm] = useState<FormState>({
    amount: "0",
    currency: "NGN",
    ourFee: "0",
  });
  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    setAmount(value);
    setForm((prevForm) => ({
      ...prevForm,
      amount: value,
    }));
  };

  const updateCurrentBalance = (currency: string) => {
    getCurrentBalance(currency)
      .then((data: any) => {
        setCurrentWallet(data.data);
      })
      .catch((err) => {
        toast.error(`Unable to get balance: ${err.message}`);
      });
  };

  const isFormValid = () => {
    return form.amount !== "";
  };

  useEffect(() => {
    updateCurrentBalance("NGN");
  }, []);
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const { amount, currency } = form;
    if (pageStep === 1) {
      setPageStep(2);
    } else {
      setForm({
        amount: "0",
        currency: "NGN",
        ourFee: "0",
      });
      close();
    }
  };
  return (
    <div>
      <AddMoneyModalHeader
        step={pageStep}
        goBack={() => {
          setPageStep((prev) => prev - 1);
        }}
        close={() => {
          setPageStep(1);
          close();
          setForm({
            amount: "0",
            currency: "NGN",
            ourFee: "0",
          });
        }}
      />
      <form onSubmit={onSubmit}>
        {pageStep === 1 && (
          <div>
            <div className="mb-[26px]">
              <CustomAmountField
                leftLabel="Amount to add to Shiga"
                isFieldRequired
                setInputAmount={updateAmount}
                inputError={fieldRequired}
                errorText={
                  fieldRequired
                    ? "This field is required"
                    : "The minimum amount you can purchase is 100 NGN"
                }
                flagIconUrl={NGN}
                inputAmount={amount.toString()}
                transactionCurrency={currentWallet?.currency || ""}
                // rightLabel={
                //   <p className="text-shiga-dark flex items-center font-inter">
                //     You have &nbsp;
                //     <span className="text-shiga-purple font-medium">
                //       {`${Number(currentWallet?.balance).toLocaleString()} ${
                //         currentWallet?.currency ?? ""
                //       }`}
                //     </span>
                //   </p>
                // }
              />
            </div>

            <div>
              <PurchaseInfo
                noTitle
                info={[
                  {
                    attribute: "Our fee",
                    value: `0  ${form.currency}`,
                  },
                  {
                    attribute: "Total you’ll pay",
                    value: `${Number(form.amount) + Number(form.ourFee)} ${
                      form.currency
                    }`,
                  },
                ]}
                className="mb-[26px]"
              />
            </div>
          </div>
        )}

        {pageStep >= 2 && (
          <div className="pt-7">
            <p className="text-center text-[16px] mb-[24px] px-[24]">
              Transfer NGN <span className="font-bold">{form.amount}</span> to
              the bank account provided below
            </p>
            <PurchaseInfo
              noTitle
              info={[
                {
                  attribute: "Account Number",
                  value: (
                    <div className="flex items-center">
                      01234567890
                      <IconCopy
                        onClick={copyText("02447567382", "Account Number")}
                        className="size-4 ml-2 cursor-pointer text-shiga-dark hover:text-shiga-black"
                      />
                    </div>
                  ),
                },
                {
                  attribute: "Bank Name",
                  value: "Wema Bank",
                },
                {
                  attribute: "Account Name",
                  value: "Payshiga Technologies",
                },
              ]}
              //   className="mb-[60px]"
            />

            <div className="mt-8 rounded-3xl bg-gradient-to-t from-[#FFFBF5] to-[#FFFBF5] bg-[#FFF7EB] flex flex-col items-end justify-end gap-4 p-4 md:p-6 w-full">
              <div className="flex items-start gap-x-2">
                <IconInfoFillYellow className="flex-none w-[12px] h-[12px] mt-1" />
                <p className="font-inter text-[#523000] text-[14px]  tracking-tighter">
                  Make sure you add your Business ID number as a narration when
                  making this transfer.
                </p>
              </div>
              <button className="bg-[#F29100] text-white px-[16px] py-[10px] rounded-xl">
                Copy Id
              </button>
            </div>
          </div>
        )}
        <div className="border-t border-gray-100 p-8">
          <ButtonLoader
            loading={false}
            type="submit"
            className="btn btn-lg btn-block btn-shiga-black"
            disabled={fieldRequired}
          >
            {pageStep >= 2 ? "I have made payment" : "Continue"}
          </ButtonLoader>
        </div>
      </form>
    </div>
  );
};

export default NGNAddMoneyForm;
