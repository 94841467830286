import { DOMAttributes, HTMLAttributes, InputHTMLAttributes, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validate from "utils/validate";
import { validationGenerics } from "data/validationGenerics";
import { NUMBER_REGEX, PHONE_NUMBER_REGEX } from "constants/number.regex";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { E164Number } from 'libphonenumber-js';

interface InputProps extends Pick<InputHTMLAttributes<HTMLInputElement>,
    "type" | "placeholder" | "value" | "onChange" | "onKeyUp" | "disabled" | "maxLength" |
    "onKeyDown" | "onBlur" | "required" | "multiple" | "accept" | "readOnly" | "autoComplete"
> {
    name?: string;
    min?: number | string;
    max?: number | string;
    label?: string;
    tooltip?: string;
    step?: number | string;
    leftIcon?: React.ReactNode;
    leftIconClass?: HTMLAttributes<HTMLDivElement>['className'];
    leftIconClicked?: DOMAttributes<HTMLDivElement>['onClick'];
    rightIcon?: React.ReactNode;
    rightIconClass?: HTMLAttributes<HTMLDivElement>['className'];
    rightIconClicked?: DOMAttributes<HTMLDivElement>['onClick'];
    labelClass?: string;
    defaultProps?: any;
    containerClass?: string;
    inputClass?: InputHTMLAttributes<HTMLInputElement>['className'];
    validationName?: any;
    checkFormValidation?: (data: any, appendKey: any, appendValue: any) => void;
    
}

export const Input: React.FC<InputProps> = (props) => {
    const {
        type: propType = "text", label = "", placeholder, min, max, step, value, onChange, labelClass = "", onKeyUp,
        onBlur, required, multiple, accept = "*", tooltip, readOnly, disabled, maxLength,
        leftIcon, leftIconClass = "", leftIconClicked, rightIcon, rightIconClass = "", rightIconClicked,
        defaultProps, containerClass = "", inputClass = "", autoComplete, validationName = "", checkFormValidation, name
    } = props;

    const [errors, setErrors] = useState<{
        [k: string]: any
    }>({});

    const doValidation = (value: string) => {
        if (validationName !== undefined) {
            const data = { [validationName]: value };
            const vErrors = validate(data);
            if (vErrors) {
                setErrors(vErrors);
            } else {
                setErrors({});
            }
            if (checkFormValidation) {
                checkFormValidation(undefined, validationName, value);
            }
        }
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        doValidation(e.target.value);
        onBlur && onBlur(e);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (propType === "tel") {
            if (PHONE_NUMBER_REGEX.test(e.currentTarget.value)) {
                onChange && onChange(e);
            }
        } else if (propType === "number") {
            if (NUMBER_REGEX.test(e.currentTarget.value)) {
                onChange && onChange(e);
            }
        } else {
            if (validationName) {
                validationName.length && doValidation(e.target.value);
            }
            onChange && onChange(e);
        }
    };

    const type = useMemo(() => propType === "tel" || propType === "number" ? "text" : propType, [propType]);

    return (
        <>
            <Maybe condition={tooltip !== undefined}>
                <Tooltip id="title-info" className="tooptip-size">
                    {tooltip}
                </Tooltip>
            </Maybe>

            <div className={`form-group ${containerClass ?? ""}`}>
                <Maybe condition={label !== "" && label !== " "}>
                    <label className={`font-light ${labelClass}`}>
                        {label}
                        <Maybe condition={required === true}>
                            <span className="form-input-required">*</span>
                        </Maybe>
                        <Maybe condition={tooltip !== undefined}>
                            <FontAwesomeIcon data-tip data-for="title-info" icon="info-circle" className="ml-2 tip-icon text-ep-primary" />
                        </Maybe>
                    </label>
                </Maybe>

                <div className="relative rounded-2xl">
                    <Maybe condition={leftIcon !== undefined}>
                        <div className={`w-max h-full flex justify-center items-center absolute top-0 left-0 text-gray-500 ${leftIconClass} ${(leftIconClicked && "cursor-pointer")}`} onClick={leftIconClicked}>
                            {leftIcon}
                        </div>
                    </Maybe>

                    {propType === "tel" ? (
                        <PhoneInput
                            defaultCountry="NG"
                            value={value as E164Number | undefined}
                            onChange={(value) => onChange && onChange({ target: { value: value ?? '' } } as React.ChangeEvent<HTMLInputElement>)}
                            className={`
                                ${((readOnly || readOnly || disabled) === true && "disabled")}
                                ${leftIcon ? "!pl-10" : ""}
                                ${rightIcon ? "!pr-10" : ""}
                                form-input ${inputClass ?? ""}
                                ${disabled && "active:outline-none focus:outline-none cursor-not-allowed"}
                            `}
                            {...defaultProps}
                            disabled={disabled}
                            placeholder={placeholder}
                            onKeyUp={onKeyUp}
                            onBlur={handleBlur}
                            readOnly={readOnly}
                            maxLength={maxLength}
                        />
                    ) : (
                        <input
                            onChange={handleChange}
                            {...defaultProps}
                            disabled={disabled}
                            className={`
                                ${((readOnly || readOnly || disabled) === true && "disabled")}
                                ${leftIcon ? "!pl-10" : ""}
                                ${rightIcon ? "!pr-10" : ""}
                                form-input ${inputClass ?? ""}
                                ${disabled && "active:outline-none focus:outline-none cursor-not-allowed"}
                            `}
                            {...{
                                value, type, placeholder, onKeyUp, onBlur: handleBlur, min, max,
                                step, multiple, accept, readOnly, autoComplete, maxLength, name
                            }}
                        />
                    )}

                    <Maybe condition={rightIcon !== undefined}>
                        <div className={`w-max h-full flex justify-center items-center absolute top-0 right-2 text-gray-500 ${rightIconClass} ${(rightIconClicked && "cursor-pointer")}`} onClick={rightIconClicked}>
                            {rightIcon}
                        </div>
                    </Maybe>
                </div>

                <Maybe condition={errors[validationName as any] !== undefined}>
                    <div className="form-input-message">
                        <Maybe condition={validationGenerics?.includes(validationName) === true}>
                            {label.toLowerCase()}&nbsp;
                        </Maybe>
                        {errors[validationName]}
                    </div>
                </Maybe>
            </div>
        </>
    );
}

export default Input;
