import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type' | "className" | "onClick"> {
    disabled?: boolean;
    loading?: boolean;
    children: any
}

export const ButtonLoader: React.FC<ButtonProps> = (props) => {
    const { type, loading, disabled, className, onClick, children } = props;

    return (
        <>

            <button type={type} disabled={loading || disabled} className={className + ' active:scale-90 transition-all'} onClick={onClick}>
                {
                    loading ?
                        <FontAwesomeIcon icon="circle-notch" spin className={"text-xl mr-2 " + (loading ? "inline" : "hidden")} /> : <></>
                }
                {children}
            </button>

        </>
    )
}

export default ButtonLoader
