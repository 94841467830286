import { CurrencyType, DateFilter } from "types/misc";
import * as actions from "./actionTypes";
import * as config from "data/config";

export function getDashboardOverview(
  { currency}:
    { currency: CurrencyType;}) {
  return {
    type: config.apiRequestStart,
    payload: {
      url: `${config.apiGetDashboardOverview}?currency=${currency}`,
      method: "get",
      data: {},
      onStart: actions.GET_DASHBOARD_OVERVIEW_START,
      onSuccess: actions.GET_DASHBOARD_OVERVIEW,
      onError: actions.GET_DASHBOARD_OVERVIEW_FAILED,
    },
  };
}

export function getDashboardChart(
  { currency, type, dateFilter }:
    { currency: CurrencyType; type: "DEBIT" | "CREDIT"; dateFilter: DateFilter }) {
  return {
    type: config.apiRequestStart,
    payload: {
      url: `${config.apiGetDashboardChart}/?currency=${currency}&type=${type}${dateFilter.length ? `&dateFilter=${dateFilter}` : ""}`,
      method: "get",
      data: {},
      onStart: actions.GET_DASHBOARD_CHART_START,
      onSuccess: actions.GET_DASHBOARD_CHART,
      onError: actions.GET_DASHBOARD_CHART_FAILED,
    },
  };
}


export function getDashboardActivities() {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetDashboardActivities,
      method: "get",
      data: {},
      onStart: actions.GET_DASHBOARD_ACTIVITIES_START,
      onSuccess: actions.GET_DASHBOARD_ACTIVITIES,
      onError: actions.GET_DASHBOARD_ACTIVITIES_FAILED,
    },
  };
}

export function getDashboardCards() {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetDashboardCards,
      method: "get",
      data: {},
      onStart: actions.GET_DASHBOARD_CARD_START,
      onSuccess: actions.GET_DASHBOARD_CARD,
      onError: actions.GET_DASHBOARD_CARD_FAILED,
    },
  };
}

export function getDashboardWallets() {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetDashboardWallets,
      method: "get",
      data: {},
      onStart: actions.GET_DASHBOARD_WALLET_START,
      onSuccess: actions.GET_DASHBOARD_WALLET,
      onError: actions.GET_DASHBOARD_WALLET_FAILED,
    },
  }
}

export function getBusinessStatus() {
  return {
    type: config.apiRequestStart,
    payload: {
      url: config.apiGetBusinessStatus,
      method: "get",
      data: {},
      onStart: actions.GET_BUSINESS_STATUS_START,
      onSuccess: actions.GET_BUSINESS_STATUS,
      onError: actions.GET_BUSINESS_STATUS_FAILED,
    },
  }
}