import * as actions from "./actionTypes";
import * as config from "data/config";

export function getCards () {
    return{
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCards,
            method: "get",
            data: {},
            onStart: actions.GET_CARDS_START,
            onSuccess: actions.GET_CARDS,
            onError: actions.GET_CARDS_FAILED
        }
    }
}

export function createCard (createBody: {
    amount: string;
    label: string;
    memberId: string
}) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateCard,
            method: "post",
            data: JSON.stringify(createBody),
            onStart: actions.CREATE_CARDS_START,
            onSuccess: actions.CREATE_CARDS,
            onError: actions.CREATE_CARDS_FAILED
        }
    }
}

export function lockCard () {
    return({
        type: config.apiRequestStart,
        payload: {
            url: config.lockCard,
            method: "patch",
            data: {},
            onStart: actions.LOCK_CARD_START,
            onSuccess: actions.LOCK_CARD,
            onError: actions.LOCK_CARD_FAILED
        }
    })
}

export function unlockCard () {
    return({
        type: config.apiRequestStart,
        payload: {
            url: config.unlockCard,
            method: "patch",
            data: {},
            onStart: actions.UNLOCK_CARD_START,
            onSuccess: actions.UNLOCK_CARD,
            onError: actions.UNLOCK_CARD_FAILED
        }
    })
}