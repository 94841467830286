import { NavLink } from "react-router-dom";

type Props = {
  title: string;
  cta?: {
    name: string;
    link: string;
  };
};
export const PageHeading = ({ title, cta }: Props) => {
  return (
    <div className="w-full   pb-6 border-b border-neutral-100 justify-between items-center  flex">
      <h1 className="text-zinc-800 font-ojah text-[32px] font-normal ">
        {title}
      </h1>
      {cta && (
        <NavLink
          to={cta.link}
          className="px-[15px] text-white whitespace-nowrap text-lg font-medium py-[13px] bg-zinc-800 rounded-2xl "
        >
          {cta.name}
        </NavLink>
      )}
    </div>
  );
};
