import React, { useState } from "react";
import { permissionsData, permissionsDetailsData } from "data/constants/common";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";

// Define types for the permissions data
interface Permission {
  label: string;
  active: boolean;
  permissions: string[];
}

interface PermissionDetails {
  permission: string;
  options: {
    label: string;
    value: string;
  }[];
}

interface PermissionModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const PermissionsModal: React.FC<PermissionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [permissions, setPermissions] = useState<Permission[]>(permissionsData);

  /**
   * Switch the active permission.
   * @param {string} permission
   */
  function switchActivePermission(permission: string) {
    const updatedPermissions = permissions.map((permItem) => {
      if (permItem.label !== permission) {
        return {
          ...permItem,
          active: false,
        };
      }

      return { ...permItem, active: true };
    });

    setPermissions(updatedPermissions);
  }

  /**
   * Toggle the permission value.
   * @param {string} permissionValue
   * @param {boolean} checked
   */
  function togglePermissionValue(permissionValue: string, checked: boolean) {
    const updatedPermissions = permissions.map((permItem) => {
      if (!permItem.active) return permItem;
      return {
        ...permItem,
        permissions: checked
          ? [...permItem.permissions, permissionValue]
          : permItem.permissions.filter(
              (permValue) => permValue !== permissionValue
            ),
      };
    });

    setPermissions(updatedPermissions);
  }

  return (
    <div>
      {/* Modal content */}
      <div className="bg-white rounded-2xl shadow-xl transform transition-all  max-h-[95vh]">
        {/* content container */}
        <div className="h-full flex flex-col  px-6 pb-8">
          {/* Modal header */}
          <div className="bg-[#ffffff] border-b border-[#F5F5F5] pl-6 pr-5 py-6 flex items-center justify-between">
            <h2 className="font-[400px] font-ojah text-semiblack text-xl font-medium font-shiga">
              Manage Roles
            </h2>
            <div
              onClick={onClose}
              className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
            >
              <IconClose className="m-auto text-white svg-stroke-gray" />
            </div>
          </div>

          {/* Modal body */}
          <div className="">
            <div className="flex-1 pt-8 pb-4 mb-4 text-text-semiblack flex flex-col justify-between border-b border-[#F5F5F5] ">
              <div className="flex overflow-hidden">
                <div className="border-r border-faint overflow-auto ">
                  <ul className="grid gap-1 font-shiga text-[16px]">
                    {permissions.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => switchActivePermission(item.label)}
                        className={`pr-10 py-1.5 pl-2 cursor-pointer hover:bg-deep-blue/5 ${
                          item.active
                            ? "bg-[#FAFAFF] text-text-semiblack border-r-4 border-shiga-purple-2"
                            : "bg-transparent text-shiga-gray"
                        }`}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="flex-1 space-y-6 overflow-y-scroll custom-scrollbar h-[300px] w-auto pl-4 pb-4">
                  {permissionsDetailsData.map(({ permission, options }) => (
                    <div key={permission} className="space-y-4 mb-8">
                      <h3 className="text-shiga-gray text-lg font-medium">
                        {permission} Permissions
                      </h3>
                      <div className="space-y-3">
                        {options.map(({ label, value }) => (
                          <div
                            key={value}
                            className="text-sm flex items-center gap-3"
                          >
                            <input
                              className="w-6 h-6  border rounded-md border-[#E2E3E5]"
                              type="checkbox"
                              checked={
                                permissions
                                  ?.find((permItem) => permItem.active)
                                  ?.permissions.includes(value) || false
                              }
                              onChange={(e) =>
                                togglePermissionValue(value, e.target.checked)
                              }
                            />
                            {label}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* Assign button */}
          <button
            className="my-0 bg-shiga-black text-white px-6 py-3 w-full rounded-[16px]"
            onClick={onClose}
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );
};

export default PermissionsModal;
