import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import noisygradient from "assets/images/icons/payment/noisy-gradients.png";
import { z } from "zod";

import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Separator } from "components/ui/separator";
import { CheckCircle2 } from "lucide-react";
import { useNavigate } from "react-router-dom";

import { PAYMENT_PATHS } from "pages/payments/paths";
import { useSharedStore } from "zustand-store";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { Input, DateForm } from "components/common/form";
import CommandSelect from "components/ui/command-select";
import TextArea from "components/common/form/TextArea";

const formSchema = z.object({
  title: z.string().min(2, {
    message: "Title must be at least 2 characters.",
  }),
  country: z.string().min(2, {
    message: "country must be at least 2 characters.",
  }),
  note: z
    .string()

    .optional(),
  startDate: z.date({
    required_error: "Start Date  is required.",
  }),
  endDate: z.date({
    required_error: "End Date is required.",
  }),
});

export function AddInvoiceDetailsForm() {
  const { invoice, setCompleted, setInvoice } = useInvoiceStore();

  const { currencies } = useSharedStore();
  console.log("currencies", currencies);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: invoice?.title || "",
      country: invoice?.country || "",
      note: invoice?.description || "",
    },
  });
  const navigate = useNavigate();

  const startDate = form.watch("startDate");

  function onSubmit({
    country,
    endDate,
    startDate,
    title,
    note,
  }: z.infer<typeof formSchema>) {
    setInvoice({
      ...invoice,
      country,
      title,
      description: note || "",
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
    setCompleted("invoice_details");
    navigate(`${PAYMENT_PATHS.CREATE_INVOICE}?tab=receipient`);
  }

  const handleDateDisable = (date: Date) => {
    if (startDate) {
      return date.getTime() < startDate?.getTime();
    }
    return date < new Date();
  };

  return (
    <div className="flex  justify-center">
      <div className="py-6 w-[412px] space-y-4 ">
        <div className="page-title text-[32px] font-normal">
          Add invoice details
        </div>
        <Separator />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      label="Invoice title"
                      required
                      placeholder="Enter a name"
                      {...field}
                    />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem className="flex flex-col space-y-3">
                  <CommandSelect
                    searchPlaceholder="Search for Country"
                    filterOption={(value, search) => {
                      if (value.includes(search)) return 1;
                      return 0;
                    }}
                    notFound="No country found."
                    label="Country"
                    options={currencies.map((item) => ({
                      ...item,
                      id: item.id + "",
                      value: item.country,
                    }))}
                    SelectTrigger={() => (
                      <span className="flex items-center gap-4">
                        {field.value}
                      </span>
                    )}
                    RenderOption={({ item }) => (
                      <>
                        <span className="flex">
                          <img
                            src={item.icon || noisygradient}
                            alt={item.country}
                            className="size-5 mr-3"
                          />
                          {item.country}
                        </span>
                        {field.value === item.country && (
                          <CheckCircle2 className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
                        )}
                      </>
                    )}
                    handleSelect={(select) => {
                      form.setValue("country", select.value);
                    }}
                  />
                </FormItem>
              )}
            />
            <TextArea
              placeholder="more information about this invoice"
              label="Description (Optional)"
              form={form as any}
            />
            <div className="flex space-x-4 justify-between">
              <DateForm
                handleDisable={handleDateDisable}
                form={form as any}
                field="startDate"
                label="Start Date"
              />
              <DateForm form={form as any} field="endDate" label="End Date" />
            </div>
            <Button className="w-full" type="submit">
              Continue
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}
