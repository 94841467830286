import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { University } from "types/shared/university.type";

export interface TutionPayload {
  university: University;
  currency?: string;
  amount?: number;
  paymentTo?: string;
  student?: {
    email: string;
    address: string;
    invoiceNumber: string;
    firstName: string;
    lastName: string;
    note: string;
  };
}

export const defaultPayload = {
  university: {} as University,
  currency: "",
  amount: 0,
  paymentTo: "",
}

interface TuitionState {
  completed: string[];

  setCompleted: (slug: string) => void;
  payload: TutionPayload;
  setPayload: (payload: TutionPayload) => void;
}

export const useTuitionStore = create(
  persist<TuitionState>(
    (set, get) => ({
      completed: ["one"],
      payload: defaultPayload,
      setPayload: (payload) => set({ payload }),
      setCompleted: (slug) => set({ completed: [...get().completed, slug] }),
    }),
    {
      name: "tution-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
