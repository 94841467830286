import { Switch } from '@headlessui/react';
import { ButtonLoader } from 'components/common/form';
import TransferHeader from 'components/transfer/TransferHeader';
import PaymentSuccessful from './status/PaymentSuccessful';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { makeTransfer, resetTransferData } from 'store/transfer/action';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PATHS from 'NavigationRoute';
import useToolkit from 'hooks/useToolkit';
import TwoFacInputModal from 'components/auth/TwoFacInputModal';
import { sendOtp } from 'store/auth/user/action';
import { RootState } from 'store/store';

interface TransactionDetailProps {
    label: string;
    value: string;
}

export const TransactionDetail: React.FC<TransactionDetailProps> = ({ label, value }) => (
    <>
        <div className="flex gap-0 px-3 py-2 text-base tracking-tight leading-6">
            <div className="flex-1 text-neutral-400">{label}</div>
            <div className="flex-1 font-medium text-right text-stone-900 capitalize">{value}</div>
        </div>
        <div className="shrink-0 self-center mt-1.5 max-w-full h-px bg-neutral-100 w-[376px]" />
    </>
);

function TransferDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [saveBeneficiary, setSaveBeneficiary] = useState(false)
    const [showStatusAlert, setShowStatusAlert] = useState(false)
    const [twoFacModalOpen, setTwoFacModalOpen] = useState(false);

    const { mfaEnabled, refetchProfiles, showCustomSuccessToast, userInfo } = useToolkit();
    const { otpEmailSendLoading } = useSelector((state: RootState) => state.auth.user)
    const [twoFacCode, setTwoFacCode] = useState('');

    const {
        accountName,
        formFields,
        selectedWallat,
        amount,
        transferNote,
        transferLoading,
        transferStatus,
        currency,
        transferFeeData,
        transferResponse
    } = useSelector((allStates: any) => allStates.transfer)

    const authorizeTransfer = () => dispatch(makeTransfer(
        formFields.accountNumber, amount, formFields.bankName.bankCode, selectedWallat.currency, currency, accountName, formFields.bankName.name, transferNote, twoFacCode, saveBeneficiary
    ))

    const requestEmailOtp = () => {
        dispatch(sendOtp({
            identifier: userInfo?.userProfile?.email || '',
            type: 'EMAIL'
        }))
    }

    console.log('userInfo', userInfo)
    const sendTrasfer = () => {
        if (mfaEnabled) {
            setTwoFacModalOpen(true);
        } else {
            setTwoFacModalOpen(true);
            requestEmailOtp();
        }
    }

    //Redirect to beginng if accountNumber or bankNameIs missing
    useEffect(() => {
        if (formFields.bankName?.bankCode?.isEmpty() || amount < 0 || accountName.isEmpty()) {
            dispatch(resetTransferData())
            navigate(PATHS.TRANSFER)
        }
        // eslint-disable-next-line
    }, [])

    const transactionDetails = [
        { label: "Transaction Type", value: "Bank Transfer" },
        { label: "Amount", value: `${Number(amount)?.toLocaleString()} ${currency}` },
        { label: "Account Number", value: formFields?.accountNumber },
        { label: "Bank Name", value: formFields?.bankName?.name?.toLowerCase() || '' },
        { label: "Fee", value: `${transferFeeData?.data?.feeAmount || 0} ${currency}` },
        { label: "Note", value: transferNote },
    ];

    useEffect(() => {
        if (transferStatus > -1) {
            if (twoFacModalOpen) {
                setTwoFacModalOpen(false);
            }
            setShowStatusAlert(true)
        }
        // eslint-disable-next-line
    }, [transferStatus])

    useEffect(() => {
        refetchProfiles();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (transferResponse?.success === true && transferResponse?.message === 'Transfer In Progress') {
            showCustomSuccessToast({
                message: `You have successfully transferred ${Number(amount)?.toLocaleString()} ${currency} to ${accountName}`,
                onClick: () => navigate(PATHS.TRANSACTIONS.TRANSACTIONS_HOMEPAGE)
            })
        }
        // eslint-disable-next-line
    }, [transferResponse])


    return (
        <div className='w-full flex flex-col items-center justify-center'>

            <TwoFacInputModal
                modalLoading={mfaEnabled ? false : otpEmailSendLoading}
                showResend={!mfaEnabled}
                title={mfaEnabled ? "Enter 2FA Code" : "Enter Email OTP"}
                target="2FA Code"
                buttonText="Confirm Transfer"
                isOpen={twoFacModalOpen}
                setIsOpen={setTwoFacModalOpen}
                loading={transferLoading}
                onSubmit={authorizeTransfer}
                onPinChange={(value: string) => setTwoFacCode(value)}
                onResendClick={() => {
                    if (!mfaEnabled) {
                        requestEmailOtp()
                    }
                }}
            />

            <PaymentSuccessful
                onDone={() => {
                    if (transferStatus !== 0) {
                        setShowStatusAlert(false)
                    } else {
                        dispatch(resetTransferData())
                        navigate(PATHS.TRANSACTION_HOME)
                    }
                }}
                isOpen={showStatusAlert}
                amount={`${amount} ${currency}`}
                recipient={accountName}
                isSuccessful={transferStatus === 0}
                successfullmessage='Payment Successful'
                errorMessage='This transaction cannot be completed at this time.'
                succesPreText='You paid'
            />

            <div className="transfer-container">

                <TransferHeader step={3} onClose={() => { }} />
                <div className="transfer-wrapper">
                    <header className="transfer-page-title">
                        Confirm your transaction to <span className='capitalize'>{accountName.toLowerCase()}</span>
                    </header>
                    <main>
                        <section className="flex flex-col justify-center pt-3 mt-6 w-full rounded-3xl border border-solid border-neutral-100">
                            {transactionDetails.map(({ label, value }) => (
                                <TransactionDetail key={label} label={label} value={value} />
                            ))}
                            <div className="flex gap-2 justify-between px-5 py-6 w-full bg-neutral-50">
                                <div className="text-base font-medium tracking-tight text-zinc-800">
                                    Save as a beneficiary
                                </div>
                                <div className="flex items-center">
                                    <Switch
                                        as="button"
                                        checked={saveBeneficiary}
                                        disabled={false}
                                        onChange={(status) => setSaveBeneficiary(status)}
                                        className={`relative inline-flex w-11 h-6 items-center rounded-full transition-all duration-300 ${false && "cursor-not-allowed"} ${saveBeneficiary ? 'bg-[#00C46C]' : 'bg-[#C8C8C8]'}`}
                                    >
                                        <span className={`inline-block w-5 h-5 transform rounded-full bg-white ${saveBeneficiary ? 'absolute right-0.5' : 'ml-0.5'}`} />
                                    </Switch>
                                </div>

                            </div>
                        </section>

                        <ButtonLoader
                            type="submit"
                            onClick={sendTrasfer}
                            className="btn bg-shiga-black text-white btn-lg btn-block mt-11">
                            Confirm & Send
                        </ButtonLoader>

                    </main>
                </div>

            </div>

        </div>
    );
}

export default TransferDetails