import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getIssuedAccounts } from "store/accounts/action";
import { toast } from 'react-toastify';

const EmptyIssuedAccount = () => {
    const dispatch = useDispatch()
    const { accounttList, loadingAccounts } = useSelector((s: any) => s.accounts)
    const [searchQuery, setSearchQuery] = useState<string>('');

    const filteredAccountList = accounttList.filter((account: any) =>
        account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.reference.toLowerCase().includes(searchQuery.toLowerCase()) ||
        account.bank.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleCopyClick = (textToCopy: string) => {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                // Optional: Add any additional feedback (e.g., showing a notification)
                console.log('Text copied to clipboard:', textToCopy);
                toast.info("Reference copied")
            })
            .catch((err) => {
                console.error('Failed to copy text to clipboard:', err);
                toast.error("Could not copy reference")
            });
    };


    const pageSize = 8;

    // State for the current page
    const [currentPage, setCurrentPage] = useState(1);

    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredAccountList.length / pageSize);

    // Calculate the range of records to display
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentPageData = filteredAccountList.slice(startIndex, endIndex);

    // Functions to handle pagination navigation
    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };


    useEffect(() => {
        dispatch(getIssuedAccounts())
    }, [])
    useEffect(() => {
        console.log(accounttList)
    }, loadingAccounts)
    return (
        <div className="frame-container">
            {/* Header section */}
            <div className="settings flex items-center self-stretch pb-6 border-b border-b-[#f5f5f5]">
                <div className="header-text mt-[45px] ml-[80px] flex flex-col items-start gap-2.5 text-[#343433] font-ojah text-[32px] leading-normal">
                    Accounts
                </div>
            </div>

            {/* Empty state section */}
            {accounttList.length < 1 && <div className="empty_state flex flex-col justify-center items-center self-stretch pt-[5.75rem] pb-12 px-0 bg-white">
                <div className="side-bar-icons flex justify-center items-center p-6">
                    <svg width={84} height={84} viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {/* Add descriptive comments for each path */}
                        <path d="M82.139 30.4588L44.8556 1.75081C43.1884 0.416395 40.8116 0.416397 39.1444 1.75081L1.86101 30.4588C-0.315498 32.2008 0.923226 35.6957 3.71717 35.6957H10.1196H19.2283H28.337H37.4458H46.5545H55.6632H64.7719H73.8806H80.2828C83.0768 35.6957 84.3155 32.2008 82.139 30.4588Z" fill="#99999C" />
                        <path d="M55.6632 68.5295V35.6957H46.5545V68.5295H55.6632Z" fill="#E2E3E5" />
                        <path d="M28.337 35.6957V68.5295H37.4458V35.6957H28.337Z" fill="#E2E3E5" />
                        <path d="M10.1196 35.6957V68.5295H19.2283V35.6957H10.1196Z" fill="#E2E3E5" />
                        <path d="M73.8806 68.5295V35.6957H64.7719V68.5295H73.8806Z" fill="#E2E3E5" />
                        <path d="M10.1196 68.5295H8.27153C5.75622 68.5295 3.71717 70.5571 3.71717 73.0583V80.9837C3.71717 82.2343 4.73669 83.2481 5.99435 83.2481H78.0057C79.2633 83.2481 80.2828 82.2343 80.2828 80.9837V73.0583C80.2828 70.5571 78.2438 68.5295 75.7285 68.5295H73.8806H64.7719H55.6632H46.5545H37.4458H28.337H19.2283H10.1196Z" fill="#99999C" />
                    </svg>
                </div>
                <div className="text-container flex flex-col justify-center items-center w-[400px]">
                    <div className="text-content flex flex-col justify-center items-center self-stretch">
                        <div className="no-accounts-text self-stretch text-[#343433] text-center  text-[1.625rem] leading-normal">
                            No Issued Accounts Yet
                        </div>
                        <div className="no-accounts-label self-stretch text-[#99999c] text-center font-['Inter'] leading-normal">
                            No bank account has currently been issued at this time.
                        </div>
                    </div>
                </div>
            </div>}
            {/* Table Section */}
            {accounttList.length > 1 && <div>
                <div className="flex justify-between items-center self-stretch p-4 ml-[80px]">
                    <div className="flex items-center">
                        <div className="flex justify-center items-center gap-0.5 pt-[0.5625rem] pb-[0.5625rem] pl-4 pr-1 rounded-[0.625rem] border border-[#e2e3e5]">
                            <div className="flex flex-col items-start gap-2.5 label text-[#343433] font-['Inter'] font-medium leading-[normal]">
                                Filter by
                            </div>
                            <div className="flex flex-col items-start gap-2.5">
                                <div className="flex justify-center items-center gap-2.5 pt-[0.4375rem] pb-[0.4375rem] pl-[0.3125rem] pr-[0.3125rem] w-6 h-6">
                                    <svg width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.25049 1.12568L4.99981 4.875L8.74981 1.125" stroke="#99999C" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center">
                        <div className="flex items-center gap-0.5 font-ojah">
                            <div
                                className="flex justify-center items-center gap-2.5 pt-[0.3125rem] pb-[0.3125rem] pl-[0.4375rem] pr-[0.4375rem] w-6 h-6"
                                onClick={goToPreviousPage}
                            >
                                <svg width={6} height={10} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.74937 8.5L1.25 5.00063L4.75 1.50063" stroke="#B4B4B4" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="flex flex-col items-start gap-2.5 label-1 text-[#343433] text-center font-['Inter'] text-[.9375rem] font-medium leading-[normal]">
                                {startIndex + 1}-{Math.min(endIndex, filteredAccountList.length)} of {filteredAccountList.length}
                            </div>
                            <div
                                className="flex justify-center items-center gap-2.5 pt-[0.3125rem] pb-[0.3125rem] pl-[0.4375rem] pr-[0.4375rem] w-6 h-6"
                                onClick={goToNextPage}
                            >
                                <svg width={6} height={10} viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.25063 8.5L4.75 5.00063L1.25 1.50063" stroke="#343433" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className="flex flex-col flex-shrink-0 items-start gap-2 ml-[20px]">
                            <div className="input flex justify-center items-center self-stretch py-3 px-5 h-12 rounded-2xl bg-[#fafafb]">
                                <div className="flex justify-center items-center pt-[0.9px] pb-[0.9000000357627869px]  pl-[21px] pr-[0.9000000357627869px]">
                                    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.23509 3.24039C5.48016 3.24039 3.24009 5.48236 3.24009 8.25634C3.24009 11.0303 5.48016 13.2723 8.23509 13.2723C9.62947 13.2723 10.8893 12.7 11.7973 11.7725C12.6842 10.8666 13.2301 9.62694 13.2301 8.25634C13.2301 5.48236 10.99 3.24039 8.23509 3.24039ZM0.900085 8.25634C0.900085 4.1975 4.18034 0.900391 8.23509 0.900391C12.2898 0.900391 15.5701 4.1975 15.5701 8.25634C15.5701 9.84618 15.0662 11.3198 14.2106 12.5232L16.7631 15.1083C17.2172 15.5681 17.2125 16.3089 16.7527 16.7629C16.2929 17.2169 15.5521 17.2122 15.0981 16.7524L12.5692 14.1913C11.3556 15.0838 9.85641 15.6123 8.23509 15.6123C4.18034 15.6123 0.900085 12.3152 0.900085 8.25634Z" fill="#B4B4B4" />
                                    </svg>
                                </div>
                                <div className="placeholder text-[#b4b4b4] font-['Inter'] font-medium leading-[1.375rem]">
                                    <input
                                        className="appearance-none border-none bg-transparent focus:outline-none"
                                        style={{
                                            fontSize: 'inherit',
                                            color: 'inherit',
                                            outline: 'none',
                                            '--tw-ring-color': 'transparent'
                                        } as Record<string, string>}
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        placeholder="Search accounts..."

                                    />
                                </div>
                                <div className="flex items-start gap-2.5">
                                    <div className="clear_close flex flex-col justify-center items-center p-1 w-[1.125rem] h-[1.125rem] rounded-full"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-[99%] mx-auto sm:m-[12px] lg:ml-[80px] overflow-x-auto">
                    <table className="rounded-lg w-full lg:w-auto table-fixed">
                        <thead className="p-[24px] bg-[#fafafb]">
                            <tr>
                                <th className="text-[#99999c] font-medium text-sm p-3 uppercase w-1/5 text-left">Account Information</th>
                                <th className="text-[#99999c] font-medium text-sm uppercase w-1/5 text-left">Reference</th>
                                <th className="text-[#99999c] font-medium text-sm uppercase w-1/5 text-left">Status</th>
                                <th className="text-[#99999c] font-medium text-sm uppercase w-1/5 text-left">Balance</th>
                                <th className="text-[#99999c] font-medium text-sm uppercase w-1/5 text-left">Date Issued</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ height: '32px' }}></tr>
                            {filteredAccountList.map((data: any, index: number) => (
                                <>
                                    <tr key={index} className="bg-white pb-3 px-4 rounded-md">
                                        <td className="text-left flex items-center gap-2">
                                            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M28.6492 0H3.35079C1.5002 0 0 1.49238 0 3.33333V28.6667C0 30.1217 0.93721 31.3591 2.24388 31.8138C4.27749 26.1889 9.68781 22.1667 16.0425 22.1667C22.387 22.1667 27.7901 26.176 29.8313 31.7867C31.0986 31.3113 32 30.0937 32 28.6667V3.33333C32 1.49238 30.4998 0 28.6492 0ZM16.1263 20C19.9662 20 23.0791 16.9033 23.0791 13.0833C23.0791 9.26336 19.9662 6.16667 16.1263 6.16667C12.2863 6.16667 9.17339 9.26336 9.17339 13.0833C9.17339 16.9033 12.2863 20 16.1263 20Z" fill="#F3F4F5" />
                                                <path d="M23.0791 13.0833C23.0791 16.9033 19.9662 20 16.1263 20C12.2863 20 9.17339 16.9033 9.17339 13.0833C9.17339 9.26336 12.2863 6.16667 16.1263 6.16667C19.9662 6.16667 23.0791 9.26336 23.0791 13.0833Z" fill="#99999C" />
                                                <path d="M3.35079 32H28.6492C29.0653 32 29.4637 31.9246 29.8313 31.7867C27.7901 26.176 22.387 22.1667 16.0425 22.1667C9.68781 22.1667 4.27749 26.1889 2.24388 31.8138C2.5904 31.9344 2.96289 32 3.35079 32Z" fill="#99999C" />
                                            </svg>
                                            <div className="flex flex-col">
                                                <div className="font-medium text-[#343433]">{data.bank}</div>
                                                <div className="text-[#99999c] text-sm">{data.name}</div>
                                            </div>
                                        </td>
                                        <td className="text-left">
                                            <div className="flex">
                                                <div className="font-medium text-[#343433]">
                                                    {data.reference.length > 15 ? `${data.reference.slice(0, 15)}` : data.reference}
                                                </div>
                                                <div className="flex justify-center items-center ml-[8px] cursor-pointer" onClick={() => {
                                                    handleCopyClick(data.reference)
                                                }}>
                                                    <svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.12001 3.87922V2.81922C8.12001 1.64838 7.17086 0.699219 6.00001 0.699219H2.82001C1.64917 0.699219 0.700012 1.64838 0.700012 2.81922V5.99922C0.700012 7.17006 1.64917 8.11922 2.82001 8.11922H3.88001M8.12001 3.87922H6.00001C4.82917 3.87922 3.88001 4.82838 3.88001 5.99922V8.11922M8.12001 3.87922H9.18001C10.3509 3.87922 11.3 4.82838 11.3 5.99922V9.17922C11.3 10.3501 10.3509 11.2992 9.18001 11.2992H6.00001C4.82917 11.2992 3.88001 10.3501 3.88001 9.17922V8.11922" stroke="#99999C" strokeWidth="1.4" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-left">
                                            <div className="flex items-center gap-1.5 p-1 rounded-md">
                                                <div className={`text-sm font-medium ${data.isVirtual ? 'text-[#00c46c]' : 'text-[#da2f20]'}`}>
                                                    {data.isVirtual ? 'Active' : 'Inactive'}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-left">
                                            {/* Replace with actual balance value  from API*/}
                                            {data.balance || "0.0 NGN"}
                                        </td>
                                        <td className="text-left">
                                            {/* Replace with actual date value from API*/}
                                            {data.createdAt ? new Date(data.createdAt).toLocaleDateString() : "Jan 01, 2024"}
                                        </td>
                                    </tr>

                                    <tr style={{ height: '8px' }}></tr>

                                </>

                            ))}

                        </tbody>
                    </table>
                </div>
            </div>}



        </div>
    )
}

export default EmptyIssuedAccount;