import { ButtonLoader, Input, SelectInput } from "components/common/form";
import {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import PurchaseInfo from "./PurchaseInfo";
import StepHeader from "./StepHeader";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { LoaderCircleIcon } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import InputAmount, {
  InputAmountHeader,
} from "components/common/form/InputAmount";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getCurrenciesStore } from "store/ui/currencies/reducer";
import { getCountries } from "store/ui/countries/action";
import { getBillPaymentServices } from "store/bills-payment/reducer";
import { getTVPackages, getTVServices } from "store/bills-payment/actions";
import { ValidateBill } from "types/billPayment";
import { validateTV } from "lib/bill-payments";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FormHeader from "components/common/form/FormHeader";
import InfoIcon from "assets/images/icons/Info";
import CustomAmountField from "components/custom/CustomAmountField";
import { useSharedStore } from "zustand-store";
import { Wallet, defaultWallet } from "types/wallet";
import { getCurrentBalance } from "lib/business";
import NGN from "assets/images/flags/Nigeria (NG).svg";
import { AnyAaaaRecord, AnyMxRecord } from "dns";
import useBillsPayment from "lib/bill-payments/useBillsPayment";

const formSchema = z.object({
  currency: z.string(),
  cableProvider: z.string(),
  subscriptionPlan: z.string().min(1, "Please select a network provider"),
  smartCardNumber: z.string().min(10, "Smart card is incomplete"),
  amount: z.string(),
});

type FormType = z.infer<typeof formSchema>;

const CableTV = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [pageStep, setPageStep] = useState<number>(1);
  const [validating, setValidating] = useState(false);
  const [validatedTV, setValidatedTV] = useState<ValidateBill>();
  const [amount, setAmount] = useState("");
  const [cableProviderID, setCableProviderID] = useState("");
  const [currentWallet, setCurrentWallet] = useState<Wallet>(defaultWallet);
  const { loadingBuyCable, buyCablePackage } = useBillsPayment();

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      amount: "0",
      cableProvider: "",
      currency: "NGN",
      smartCardNumber: "",
      subscriptionPlan: "",
    },
    context: "onChange",
    criteriaMode: "all",
    mode: "onChange",
  });

  const cableProvider = form.watch("cableProvider");
  const cablePackage = form.watch("subscriptionPlan");
  const smartCard = form.watch("smartCardNumber");

  const { currenciesList: currenciesListStore } =
    useAppSelector(getCurrenciesStore);
  const { tvService: tvServiceStore, cablePackages: cablePackagesStore } =
    useAppSelector(getBillPaymentServices);

  const cablePackages = useMemo(
    () => cablePackagesStore?.data || [],
    [cablePackagesStore]
  );
  const currenciesList = useMemo(
    () => currenciesListStore?.data.data || [],
    [currenciesListStore]
  );
  const foundPackage = useMemo(
    () => cablePackages.find((item) => item.name === cablePackage),
    [cablePackage, cablePackages]
  );
  const tvService = useMemo(() => tvServiceStore?.data || [], [tvServiceStore]);
  const updateAmount = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > 0) {
      const value = event.target.value;
      // console.log("amount update", value);
      setAmount(value);
      form.setValue("amount", value);
    }
  };

  useEffect(() => {
    const curr = form.getValues("currency");
    if (curr) {
      getCurrentBalance(curr)
        .then((data: any) => {
          setCurrentWallet(data.data);
          // form.setValue("balance" as Path<K>, String(data.data.balance) as any);
        })
        .catch((err) => {
          form.setValue("currency", currentWallet.currency as any);
          toast.error(`Unable to get balance ${err.message}`);
        });
    }
  }, [currentWallet.currency, form]);

  console.log("found packages", foundPackage);
  // const amount = form.getValues("amount")
  const inputExceedsTrxAmount =
    currentWallet?.balance !== undefined &&
    Number(amount) > currentWallet?.balance;
  const insufficientAmount = amount.length > 0 && Number(amount) < 100;

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getTVServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getTVPackages(cableProvider));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cableProvider]);

  useEffect(() => {
    if (foundPackage?.amount) {
      form.setValue("amount", String(foundPackage.amount));
      setAmount(String(foundPackage.amount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cablePackage]);

  const handleValidateSmartCard = () => {
    const selectedProvider = tvService.filter(
      (item) => item.identifier === cableProvider
    );
    console.log("selected cable provider", selectedProvider);
    setCableProviderID(selectedProvider[0]._id);

    setValidating(true);
    validateTV({
      entityNumber: Number(smartCard),
      provider: cableProvider,
      serviceCategoryId: selectedProvider[0]._id,
    })
      .then((data) => {
        setValidatedTV(data.data);
      })
      .catch((err) => {
        toast.error("Something went wrong", err.message);
      })
      .finally(() => {
        setValidating(false);
      });
  };

  useEffect(() => {
    if (smartCard && smartCard.length > 9) {
      if (!cableProvider.length) {
        toast.info("Select a Cable Provider");
      } else {
        handleValidateSmartCard();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartCard, cableProvider]);

  const handleSmartCardChange: ChangeEventHandler<HTMLInputElement> = (evt: {
    target: { value: any };
  }) => {
    const inputValue = evt.target.value;
    const pattern = /^[0-9 ()+-]*$/;
    if (pattern.test(inputValue) || inputValue === "") {
      form.setValue("smartCardNumber", inputValue);
    } else {
      form.setError("smartCardNumber", {
        message: "Only numbers are allowed",
      });
    }
  };
  console.log("cablePackages", cableProvider);
  const onSubmit = ({
    amount,
    cableProvider,
    currency,
    smartCardNumber,
    subscriptionPlan,
  }: FormType) => {
    if (pageStep === 1) {
      setPageStep(2);
    } else {
      if (!foundPackage) return;
      return buyCablePackage({
        amount,
        currency,
        cardNumber: smartCardNumber,
        packageName: foundPackage?.name,
        plan_id: foundPackage.id,
        provider: cableProvider,
        utilityBillName: cableProvider,
        serviceCategoryId: cableProviderID,
        debitAccountNumber: "0112496416",
        bundleCode: foundPackage.bundleCode,
      })
        .then((data) => {
          toast.success(
            `Purchase of ${foundPackage.name} for ${cableProvider} is successful. `
          );
          navigate("/dashboard");
        })
        .catch((err) => {
          toast.error(`Error: ${err.message}`);
        });
    }
  };

  return (
    <div>
      <StepHeader
        step={pageStep}
        goBack={() => setPageStep((prev) => prev - 1)}
        close={() => {
          setPageStep((prev) => prev - 1);
          form.reset();
        }}
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] mx-auto"
        >
          <FormHeader>
            {pageStep >= 2
              ? `Confirm your purchase for ${cableProvider} Subscription`
              : "Cable TV"}
          </FormHeader>
          {pageStep === 1 && (
            <>
              {/* <div className="mb-[26px]">
                <FormField
                  control={form.control}
                  name="country"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <SelectInput
                          type="text"
                          label="Select a Country"
                          placeholder="Select a country"
                          required={true}
                          {...field}
                        >
                          {countries
                            .map((currency, index) => (
                              <option value={currency} key={index}>
                                {currency}
                              </option>
                            ))}
                        </SelectInput>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div> */}
              <div className="mb-[26px]">
                <FormField
                  control={form.control}
                  name="cableProvider"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <SelectInput
                          type="text"
                          label="Select a Cable provider"
                          placeholder="Select a provider"
                          required={true}
                          {...field}
                        >
                          {tvService.map((provider, index) => (
                            <option value={provider.identifier} key={index}>
                              <div className="flex items-center gap-1.5">
                                <img
                                  src={provider.logoUrl}
                                  alt={provider.name}
                                  className="size-5"
                                />
                                {provider.identifier}
                              </div>
                            </option>
                          ))}
                        </SelectInput>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mb-[26px]">
                <FormField
                  control={form.control}
                  name="subscriptionPlan"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <SelectInput
                          type="text"
                          label="Subscription Plan"
                          placeholder="Select a plan"
                          required={true}
                          {...field}
                        >
                          {cablePackages.map((plan, index) => (
                            <option value={plan.name} key={index}>
                              {plan.name}
                            </option>
                          ))}
                        </SelectInput>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mb-[26px] relative">
                {validating ? (
                  <LoaderCircleIcon className="absolute top-0 right-0 animate-spin" />
                ) : validatedTV?.data.name ? (
                  <p className="text-sm font-light text-shiga-dark absolute top-0 right-0 uppercase">
                    {validatedTV?.data.name}
                  </p>
                ) : (
                  <></>
                )}
                <FormField
                  control={form.control}
                  name="smartCardNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Input
                          type="number"
                          label="Smartcard Number"
                          placeholder="081012345678"
                          required={true}
                          {...field}
                          onChange={handleSmartCardChange}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="pb-12">
                <CustomAmountField
                  leftLabel="Amount"
                  isFieldRequired
                  setInputAmount={updateAmount}
                  inputError={insufficientAmount || inputExceedsTrxAmount}
                  disabled
                  errorText={
                    insufficientAmount
                      ? "The minimum amount you can purchase is 100 NGN"
                      : inputExceedsTrxAmount
                      ? "You cannot make a purchase more than your available balance"
                      : "The minimum amount you can purchase is 100 NGN"
                  }
                  flagIconUrl={NGN}
                  inputAmount={amount}
                  transactionCurrency={currentWallet?.currency || ""}
                  rightLabel={
                    <p className="text-shiga-dark flex items-center font-inter">
                      Transaction amount:&nbsp;
                      <span className="text-shiga-purple font-medium">
                        {`${Number(currentWallet?.balance).toLocaleString()} ${
                          currentWallet?.currency ?? ""
                        }`}
                      </span>
                    </p>
                  }
                />
                {/* <InputAmount
                  currencies={currenciesList}
                  form={form as any}
                  minAmount={0}
                  disableAmount
                  disableCurrency
                  header={InputAmountHeader}
                />
                <div className="flex justify-start items-center mt-2">
                  <InfoIcon className="m-[3px]" />
                  <p className="text-[13px] font-light text-shiga-dark">
                    The minimum amount you can purchase is 100 NGN
                    <span className="font-normal text-shiga-black">
                      100 NGN
                    </span>
                  </p>
                </div> */}
              </div>
            </>
          )}
          {pageStep >= 2 && (
            <PurchaseInfo
              transactionType="Bills Payment"
              info={[
                {
                  attribute: "Amount",
                  value: `${form.getValues("amount")} Naira`,
                },
                {
                  attribute: "Plan",
                  value: form.getValues("subscriptionPlan"),
                },
                {
                  attribute: "Smartcard Number",
                  value: form.getValues("smartCardNumber"),
                },
                {
                  attribute: "Cable Provider",
                  value: form.getValues("cableProvider"),
                },
                {
                  attribute: "Fee",
                  value: "Free 😎",
                },
              ]}
              className="mb-[60px]"
            />
          )}
          <ButtonLoader
            type="submit"
            disabled={!form.formState.isValid}
            loading={loadingBuyCable}
            className="btn btn-lg btn-block btn-primary mt-3"
          >
            {pageStep >= 2 ? "Confirm & Purchase" : "Continue"}
          </ButtonLoader>
        </form>
      </Form>
    </div>
  );
};

export default CableTV;
