import { Button } from "components/ui/button";
import { ChevronRight } from "lucide-react";
import { NavLink } from "react-router-dom";
import { Steps } from "types/payment/steps";

type Props = {
  steps: Steps[];
  tab: string;
  completed?: string[];
  path?: string;
};
export const StepHeading = ({ steps, tab, completed, path }: Props) => {
  return (
    <div className="flex space-x-10">
      {steps?.map((step, index) => {
        const complete = completed?.includes(step.slug);
        return (
          <NavLink
            onClick={e => {
              if(!complete){
                e.preventDefault()
              }
            }}
            to={complete ? `${path}?tab=${step.slug}` : "#"}
            className="flex items-center gap-3"
            key={index}
          >
            <Button
              size="icon"
              variant="outline"
              className={`rounded-full ${complete || step.slug === tab ? "bg-zinc-800" : "bg-neutral-200"
                }  h-8 w-8 border-none shadow-none`}
            >
              {step.icon}
            </Button>
            {complete || step.slug === tab ? (
              <p className="text-zinc-800 text-sm font-medium font-['Inter'] leading-tight">
                {step.name}
              </p>
            ) : (
              <p className="text-center text-zinc-400 text-sm font-normal font-['Inter'] leading-tight">
                {step.name}
              </p>
            )}
            {index !== steps?.length - 1 && (
              <div className="ml-6">
                <ChevronRight className="text-zinc-200 w-4 h-8" />
              </div>
            )}
          </NavLink>
        );
      })}
    </div>
  );
};
