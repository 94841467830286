import { useEffect } from "react";
import { NoRefund } from "./NoRefund";
import { RefundsTable } from "./RefundsTable";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getRefundsStore } from "store/refund/reducer";
import { getRefunds } from "store/refund/action";


export const Refund = () => {
    const dispatch = useAppDispatch();
    const { refunds } = useAppSelector(getRefundsStore);

    useEffect(() => {
        dispatch(getRefunds());
    }, []);

    return (
        <div className="pt-6 px-6 pb-24 sm:pt-10 sm:px-14">
            <div className="flex items-center justify-between pb-5 mb-5 border-b border-gray-100">
                <div className="page-title !text-3xl font-ojah font-bold border-gray-100">
                    Refunds
                </div>
            </div>
            {
                refunds?.data.payload.length ?
                    <RefundsTable refunds={refunds.data.payload} /> :
                    <NoRefund />
            }
        </div>
    )
}