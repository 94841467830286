import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import * as userActions from "store/auth/user/action";
import * as accountActions from "store/auth/account/action";
import { useNavigate } from "react-router-dom";
import { getAuthPersist } from "store/auth/user/reducerPersist";
import { getAuthAccount, getAuthUser } from "store/auth/account/reducer";


const useLoginInfo = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { logoutLoading } = useSelector(getAuthUser);
	const { userLogin, userInfo } = useSelector(getAuthPersist);
	const { userProfile, businessProfile } = useSelector(getAuthAccount);

	const [hasLoggedOut, setHasLogout] = useState(false);



	let userInfoDecoded: any = {};
	if (userInfo?.token) {
		userInfoDecoded = jwtDecode(userInfo.token);
	}


	let logoutClicked = 0;
	let logoutUrl = "/login";

	// let userInfo:any = {
	//     userType: "",
	// }


	const checkTokenExpiry = (userInfoDecoded: any) => {
		const tokenExpiryValue = (parseInt(userInfoDecoded.exp) * 1000);
		const currentTime = Date.now();
		if (!hasLoggedOut && currentTime > tokenExpiryValue) {
			logoutUser();
			setHasLogout(true)
		}
	}


	const getUserProfile = () => {
		dispatch(accountActions.getUserProfile());
	}


	const getBusinessProfile = () => {
		dispatch(accountActions.getBusinessProfile());
	}


	const logoutUser = () => {
		logoutClicked++;
		if (logoutClicked === 1 && logoutLoading === false) {
			dispatch(userActions.logoutUser());
			dispatch(userActions.resetLoginData())
		}
	}

	useEffect(() => {
		if (!userLogin && logoutClicked > 0) {
			navigate(logoutUrl)
		}
	}, [logoutLoading])


	useEffect(() => {
		if (logoutClicked > 0) {
			if (userLogin === false) {
				navigate(logoutUrl)
			}
		}
		// eslint-disable-next-line
	}, [userLogin]);


	// Move out to dashboard
	useEffect(() => {
		if (userProfile?.success) {
			dispatch(accountActions.resetGetUserProfile());
			dispatch(userActions.updateUserInfo({
				userProfile: {
					...userProfile?.data ?? {},
				},
			}));
		}
		// eslint-disable-next-line
	}, [userProfile?.success]);


	useEffect(() => {
		if (businessProfile?.success) {
			dispatch(accountActions.resetGetBusinessProfile());
			dispatch(userActions.updateUserInfo({
				businessProfile: {
					...businessProfile?.data ?? {},
				},
			}));
		}
		// eslint-disable-next-line
	}, [businessProfile]);


	if (userInfo?.token) {
		checkTokenExpiry(userInfoDecoded);
		// userInfo = {
		//     _id: "",
		//     firstName: "Fela",
		//     lastName: "Anikulapo",
		//     name: "Fela Anikulapo",
		//     email: "ee@ee.cc",
		//     phoneNumber: "83983232",
		// }
	}


	return { userLogin, userInfo, getUserProfile, getBusinessProfile, logoutUser };

}


export default useLoginInfo;