import React from "react";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface ListTypeProps {
    name: string;
    key: string;
    extra: any;
}

interface SimpleSelectProps {
    label: string;
    isRequired: boolean;
    list: ListTypeProps[];
    placeholder: string;
    isLoading: boolean;
    key: any;
    onChange: (info: { key: string; value: string; extra: any }) => void;

}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
    label,
    isRequired,
    list,
    placeholder,
    isLoading,
    key,
    onChange,

}) => {
    const options = list.map((item) => ({
        label: item.name,
        value: item.key,
    }));

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            padding: '5px 4px',
            border: state.isFocused ? "none" : "none",
            backgroundColor: "#F7FAFC",
            borderRadius: "0.75rem",
            boxShadow: state.isFocused ? "0 0 0 2px #1f1f1e" : "none",
            "&:hover": {
                boxShadow: "0 0 0 2px #1f1f1e",
            },

        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#E5E5E5" : "transparent",
            color: state.isSelected ? "#4F46E5" : "#000",
            cursor: "pointer",
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: "#0C0C0C",
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: "#B4B4B4",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            color: "#99999C",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        menu: (provided: any) => ({
            ...provided,
        }),
    };




    const handleChange = (selectedOption: any) => {
        const selectedItem: any = list.find((item) => item.key === selectedOption.value);
        onChange({ key: selectedItem.key, value: selectedItem.name, extra: selectedItem });
    };

    // Custom dropdown indicator with FontAwesome spinner
    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                {isLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin /> // Show spinner while loading
                ) : (
                    props.getStyles('dropdownIndicator', props).styles // Show default dropdown indicator
                )}
            </components.DropdownIndicator>
        );
    };



    const lodindClass = isLoading ? "shimmer" : ""


    return (
        <div className={`flex flex-col self-stretch w-full mb-[16px] ${lodindClass}`}>
            <label htmlFor="countryInput" className="w-full text-sm tracking-normal leading-5 text-shiga-gray">
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
            <div className={lodindClass}>
                <Select
                    id="countryInput"
                    options={options}
                    placeholder={placeholder}
                    isLoading={isLoading}
                    onChange={handleChange}
                    styles={customStyles}
                    className={lodindClass}
                    key={`${isLoading}`}
                    components={{ DropdownIndicator }}

                />
            </div>
        </div>
    );
};

export default SimpleSelect;
