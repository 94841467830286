import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";

import { useNavigate } from "react-router-dom";
import { PAYMENT_PATHS } from "../paths";
import { useTuitionStore } from "zustand-store/payments/tution";
import { useEffect } from "react";
import { Input } from "components/common/form";
import FormHeader from "components/common/form/FormHeader";
import TextArea from "components/common/form/TextArea";

const FormSchema = z.object({
  email: z.string().email(),
  address: z.string().min(2, {
    message: "Address must be at least 2 characters.",
  }),
  invoiceNumber: z.string().min(2, {
    message: "Invoice must be at least 2 characters.",
  }),
  firstName: z.string().min(2, {
    message: "First Name must be at least 2 characters.",
  }),
  lastName: z.string().min(2, {
    message: "Last Name must be at least 2 characters.",
  }),
  note: z.string().optional()
});

export const StudentInfo = () => {
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      address: "",
      invoiceNumber: "",
    },
  });
  const { setCompleted, payload, setPayload } = useTuitionStore();
  useEffect(() => {
    payload.student?.email && form.setValue("email", payload.student?.email);
    payload.student?.address &&
      form.setValue("address", payload.student?.address);
    payload.student?.invoiceNumber &&
      form.setValue("invoiceNumber", payload.student?.invoiceNumber);
    payload.student?.firstName &&
      form.setValue("firstName", payload.student?.firstName);
    payload.student?.lastName &&
      form.setValue("lastName", payload.student?.lastName);
    payload.student?.note && form.setValue("note", payload.student?.note);
  }, [payload, form]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    setCompleted("student_info");
    setPayload({ ...payload, student: { ...data, note: data.note || "", invoiceNumber: data.invoiceNumber || ""} });
    form.reset();
    navigate(`${PAYMENT_PATHS.TUITION_STEPS}?tab=amount`);
  }

  return (
    <div className="w-full p-10  space-y-4 flex flex-col justify-center items-center">
    <div className=" w-[412px] flex-col justify-start items-start gap-2.5 inline-flex">
      <FormHeader className="text-zinc-800 text-[32px] font-normal font-ojah">Add Student's Information</FormHeader>
    </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] space-y-6"
        >
          <div className="flex justify-between">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input label="Student First Name"
                     placeholder="First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input label="Student Last Name" placeholder="Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input label="Student Email address" placeholder="Email address" {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="invoiceNumber"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input label="Invoice Number" placeholder="Invoice Number" {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input label="Student's Address" placeholder="School address" {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <TextArea form={form as any} />
          <Button className="w-full" type="submit">
            Continue
          </Button>
        </form>
      </Form>
    </div>
  );
};
