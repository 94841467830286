import { Menu } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonLoader } from "components/common/form";
import Dropdown from "utils/dropdown";
import { ActionOption } from "../interface";

export const DashboardHeader: React.FC<{
  handleAddMoney: () => void;
  handleTransfer: () => void;
  updateActionOptions: (selected: string) => void
  actionOptions: ActionOption[];
}> = ({ actionOptions, handleAddMoney, handleTransfer, updateActionOptions }) => {
  return (
    <div className="mt-2 lg:mt-0 lg:flex lg:justify-end">
      <div className="w-max p-1 flex gap-5">
        <ButtonLoader
          type="button"
          onClick={handleAddMoney}
          loading={false}
          className="btn bg-shiga-purple text-white"
        >
          <span>Add Money</span>
        </ButtonLoader>
        <ButtonLoader
          type="button"
          onClick={handleTransfer}
          loading={false}
          className="btn border border-outline-grey-2"
        >
          <span>Send Money</span>
        </ButtonLoader>
        <Dropdown dropdownClass="min-w-[150px] dropdown-right">
          <ButtonLoader
            type="button"
            loading={false}
            className="btn border border-outline-grey-2"
          >
            <FontAwesomeIcon
              icon="ellipsis-vertical"
              className="sizee-6"
            />
          </ButtonLoader>
          <div>
            {actionOptions.map((action) => (
              <Menu.Item key={action.value}>
                {({ active }) => (
                  <div
                    onClick={() => updateActionOptions(action.value)}
                    className={`${active && "bg-[#444444]"
                      } dropdown-item flex items-center gap-3`}
                  >
                    <action.icon />

                    <div className="space-y-1">
                      <h5 className="whitespace-nowrap font-medium text-[16px]">
                        {action.label}
                      </h5>
                      <p className="text-shiga-dark text-[13px] tracking-tight">
                        {action.desc}
                      </p>
                    </div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Dropdown>
      </div>
    </div>
  )
}