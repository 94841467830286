const Table = (props) => {
  return (
    <div className="table-container relative pb-24">
      <table className={"table table-auto table-border " + props.tableClass}>
        {props.children}
      </table>
    </div>
  );
};

const TableHead = (props) => {
  return (
    <thead>
      <tr>{props.children}</tr>
    </thead>
  );
};

const TableBody = (props) => {
  return <tbody>{props.children}</tbody>;
};

Table.Head = TableHead;
Table.Body = TableBody;
export default Table;
