import { useCopyToClipboard } from "hooks/useCopyToClipboard";

import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import {
  Copy,
  EllipsisVertical,
  EyeIcon,
  GlobeLockIcon,
  Pencil,
  Trash2,
} from "lucide-react";
import { toast } from "sonner";
import { NavLink } from "react-router-dom";
import { PaymentLink } from "types/payment/payments.interface";
import { PAYMENT_PATHS } from "../paths";
import { usePaymentLinkStore } from "zustand-store/payments/links";
import { togglePaymentLink } from "lib/payments";

export function PaymentLinkActions({ paymentLink, handleDelete }: { paymentLink: PaymentLink; handleDelete: () => void; }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, copy] = useCopyToClipboard();
  const { updatePaymentLink } = usePaymentLinkStore();

  async function copyItem() {
    try {
      await copy(paymentLink.link);
      toast.success(`Payment Link copied.`, {
        id: paymentLink.name,
      });
    } catch (error) {
      toast.success(`Error copying link`, {
        id: paymentLink.name,
      });
    }
  }

  const handleDisableLink = async () => {
    const newStatus = paymentLink.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
    updatePaymentLink({
      ...paymentLink,
      status: newStatus
    })
    return togglePaymentLink(paymentLink.id, newStatus).then((res) => {
      if (res.success) {
        toast.success(`Payment link ${newStatus === "ACTIVE" ? "activated" : "deactivated"} successfully`);
      }
    }).catch(err => {
      toast.error(`Unable to change payment link status`);
      updatePaymentLink({
        ...paymentLink
      })
    })
  };

  function previewLink() {
    window?.open(paymentLink.link, "_blank")?.focus();
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="flex size-8 p-0 data-[state=open]:bg-muted"
        >
          <EllipsisVertical className="size-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="">
        <DropdownMenuItem>
          <NavLink
            to={`${PAYMENT_PATHS.CREATE_PAYMENT_LINK}?tab=personalize_link&id=${paymentLink.id}`}
            className=" flex gap-2.5"
          >
            <Pencil className="size-3.5" />
            Edit this Link
          </NavLink>
        </DropdownMenuItem>
        <DropdownMenuItem className="gap-2.5" onClick={handleDisableLink}>
          <GlobeLockIcon className="size-3.5" />
          {`${paymentLink.status === "ACTIVE" ? "Disable" : "Enable"} this Link`}
        </DropdownMenuItem>
        <DropdownMenuItem className="gap-2.5" onClick={() => previewLink()}>
          <EyeIcon className="size-3.5" />
          Preview this Link
        </DropdownMenuItem>
        <DropdownMenuItem className="gap-2.5" onClick={() => copyItem()}>
          <Copy className="size-3.5" />
          Copy this Link
        </DropdownMenuItem>
        <DropdownMenuItem
          className="text-warn gap-2.5 focus:text-warn"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete()
          }}
        >
          <Trash2 className="size-3.5" />
          Delete Link
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
