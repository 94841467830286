import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader, Input } from "components/common/form";
import { Tooltip } from "react-tooltip";
import CustomSwitch from "components/common/form/CustomSwitch";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import FormDrawer from "components/common/form-drawer";
import validate from "utils/validate";
import { toast } from "react-toastify";
import getPasswordStrength, { getPasswordStrengthLevel } from "utils/getPasswordStrength";
import PasswordStrengthIndicator from "components/common/PasswordStrengthIndicator";
// import ImageQRCode from "assets/images/icons/QRCode.png";
import copy from "copy-to-clipboard";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import { ReactComponent as IconEdit } from "assets/images/icons/Edit.svg";
import { ReactComponent as IconRemove } from "assets/images/icons/Close.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconEyeOpen } from "assets/images/icons/eyeopen.svg";
import { ReactComponent as IconEyeClose } from "assets/images/icons/eyeclose.svg";
import { ReactComponent as IconKey } from "assets/images/icons/Key.svg";
import { ReactComponent as IconScanner } from "assets/images/icons/Scanner.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { ReactComponent as IconCheck } from "assets/images/icons/Check-Circle.svg";
import { ReactComponent as IconDownload } from "assets/images/icons/Download.svg";
import PinInput from "react-pin-input";
import useLoginInfo from "hooks/useLoginInfo";
import Maybe from "components/common/Maybe";
import ContentLoading from "components/common/ContentLoading";
import QRCode from "react-qr-code";
import * as accountActions from "store/auth/account/action";
import * as userActions from "store/auth/user/action";
import useToolkit from "hooks/useToolkit";


const MyProfile = () => {
    const dispatch = useDispatch();
    const { userInfo } = useLoginInfo();
    const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
    const [isOpenTwoFactorAuthentication, setIsOpenTwoFactorAuthentication] = useState(false);
    const [twoFactorAuthentication, setTwoFactorAuthentication] = useState(false);
    const { passwordChangeRequestLoading, passwordChangeRequest, mfaInitializeLoading, mfaInitialize, mfaDisableLoading, mfaDisable } = useSelector((s: any) => s.auth.account);
    const { refetchProfiles, mfaEnabled } = useToolkit();

    const changePassword = () => {
        dispatch(accountActions.requestPasswordChange());
    }

    const toggleTwoFactorAuthentication = (e: any) => {
        setTwoFactorAuthentication(e);
        if (e === true) {
            dispatch(accountActions.initializeMFA());
        }
        else if (e === false) {
            dispatch(accountActions.disableMFA())
            setTimeout(() => refetchProfiles(), 1500);
        }
    }


    useEffect(() => {
        if (mfaEnabled) {
            setTwoFactorAuthentication(true);
        } else {
            setTwoFactorAuthentication(false);
        }
        //eslint-disable-next-line
    }, [mfaEnabled])

    useEffect(() => {
        if (passwordChangeRequest?.success === true) {
            dispatch(accountActions.resetRequestPasswordChange());
            setIsOpenChangePassword(true);
        }
        //eslint-disable-next-line
    }, [passwordChangeRequest])

    useEffect(() => {
        if (mfaInitialize?.success === true) {
            setIsOpenTwoFactorAuthentication(true);
        }
        //eslint-disable-next-line
    }, [mfaInitialize])

    useEffect(() => {
        if (mfaDisable?.success === true) {
            dispatch(accountActions.resetDisableMFA());
            refetchProfiles();
        }
        //eslint-disable-next-line
    }, [mfaDisable])

    useEffect(() => {
        if (isOpenTwoFactorAuthentication === false) {
            dispatch(accountActions.resetInitializeMFA());
        }
        //eslint-disable-next-line
    }, [isOpenTwoFactorAuthentication])

    useEffect(() => {
        refetchProfiles();
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <DynamicHeadTag
                headerText="My Profile"
            />

            <Tooltip id="dp-edit" className="custom-tooltip-ui" />
            <Tooltip id="dp-remove" className="custom-tooltip-ui" />

            <ChangePassword
                isOpen={isOpenChangePassword}
                setIsOpen={setIsOpenChangePassword}
            />

            <TwoFactorAuthentication
                isOpen={isOpenTwoFactorAuthentication}
                setIsOpen={setIsOpenTwoFactorAuthentication}
                mfaInitialize={mfaInitialize}
                refetchProfiles={refetchProfiles}
            />

            <div className="page-title pb-6 mb-12 border-b border-gray-100">
                My Profile
            </div>

            <div className="lg:flex lg:space-x-20">
                <div className="w-44">
                    <div className="w-44 h-44 bg-[#F3F4F5] relative rounded-3xl overflow-hidden">
                        <IconEdit data-tooltip-id="dp-remove" data-tooltip-content="Change Image" className="w-8 h-8 p-1.5 bg-black bg-opacity-50 absolute z-[1] bottom-2 left-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none" />
                        <IconRemove data-tooltip-id="dp-remove" data-tooltip-content="Remove Image" className="w-8 h-8 p-1 bg-black bg-opacity-50 absolute z-[1] bottom-2 right-2 rounded-full hover:bg-opacity-100 cursor-pointer outline-none svg-fill-white" />

                        <Maybe condition={userInfo?.userProfile?.profilePic !== null}>
                            <img src={userInfo?.userProfile?.profilePic} alt="User Profile" className="w-full h-full object-cover" />
                        </Maybe>
                        <Maybe condition={userInfo?.userProfile?.profilePic === null}>
                            <IconUserProfile className="w-full h-full relative top-1" />
                        </Maybe>
                    </div>
                    <div className="mt-4 text-xs text-[#99999C]">
                        Min 400x400px, PNG or JPEG formats
                    </div>
                </div>

                <div className="mt-10 lg:mt-0 lg:flex-grow">
                    <div className="text-2xl font-ojah font-bold text-[#B4B4B4] pb-6 mb-6 border-b border-gray-100">
                        Profile Information
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                        <Input
                            type="text"
                            label="First Name"
                            value={userInfo?.userProfile?.firstName ?? "-"}
                            readOnly={true}
                        />
                        <Input
                            type="text"
                            label="Last Name"
                            value={userInfo?.userProfile?.lastName ?? "-"}
                            readOnly={true}
                        />
                        <Input
                            type="email"
                            label="Email Address"
                            value={userInfo?.userProfile?.email ?? "-"}
                            readOnly={true}
                        />
                        <Input
                            type="tel"
                            label="Phone number"
                            value={userInfo?.userProfile?.phoneNumber ?? "-"}
                            readOnly={true}
                        />
                    </div>

                    <div className="text-2xl font-ojah font-bold text-[#B4B4B4] py-6 border-t border-b border-gray-100">
                        Security Settings
                    </div>

                    <div>
                        <div className="py-6 sm:flex items-center justify-between">
                            <div className="sm:flex-grow">
                                <div className="font-medium">
                                    Change Password
                                </div>
                                <div className="mt-1 text-sm text-[#99999C]">
                                    Change your old password to a new one
                                </div>
                            </div>
                            <div className="sm:w-1/2 mt-3 sm:mt-0 sm:flex-shrink-0 sm:flex sm:justify-end">
                                <ButtonLoader type="button" loading={passwordChangeRequestLoading} onClick={changePassword} className="btn btn-white btn-md">
                                    Change Password
                                </ButtonLoader>
                            </div>
                        </div>
                        <div className="w-full py-6 sm:flex items-center justify-between border-t border-gray-100">
                            <div className="sm:flex-grow">
                                <div className="font-medium">
                                    Two-Factor Authentication
                                </div>
                                <div className="mt-1 text-sm text-[#99999C]">
                                    Protect your Business account from unauthorized access with a software token.
                                </div>
                            </div>
                            <div className="sm:w-1/2 mt-3 sm:mt-0 sm:flex-shrink-0 sm:flex sm:justify-end sm:items-center">
                                <div className="relative sm:-left-3 flex items-center">
                                    <CustomSwitch
                                        active={twoFactorAuthentication}
                                        onChange={(e: any) => toggleTwoFactorAuthentication(e)}
                                    />
                                    <div className="w-5 text-xl ml-3">
                                        <ContentLoading loading={mfaInitializeLoading || mfaDisableLoading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const ChangePassword = (props: any) => {
    const { isOpen, setIsOpen } = props;
    const dispatch = useDispatch();
    const { resetPasswordLoading, resetPassword } = useSelector((s: any) => s.auth.user);

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [passwordStrengthLevel, setShowPasswordLevel] = useState<any>({
        level: 0,
        bgColor: "bg-[#E2E3E5]",
        textColor: "text-[#B4B4B4]",
        text: "Password Strength",
    });

    const [passwordStrength, setPasswordStrength] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialCharacter: false,
        hasCharacterLength: false,
    });
    const [form, setForm] = useState({
        token: "",
        password: "",
        confirmPassword: "",
    })

    const updatePassword = (password: string) => {
        let newPasswordStrength = getPasswordStrength(password);
        let newPasswordLevel = getPasswordStrengthLevel(newPasswordStrength);
        setPasswordStrength(newPasswordStrength);
        setShowPasswordLevel(newPasswordLevel);
        setForm({ ...form, password });
    }

    const isFormValid = () => {
        let response = true;
        if (!(
            passwordStrength.hasUpperCase === true &&
            passwordStrength.hasLowerCase === true &&
            passwordStrength.hasNumber === true &&
            passwordStrength.hasSpecialCharacter === true &&
            passwordStrength.hasCharacterLength === true
        )) {
            response = false;
            toast.error("Please enter a strong password")
        }
        return response;
    }

    const submitForm = async (e: any) => {
        e.preventDefault();
        const errors: any = validate(form);
        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else {
            if (isFormValid() === true) {
                dispatch(userActions.resetPassword(form));
            }
        }
    }

    useEffect(() => {
        if (resetPassword?.success === true) {
            dispatch(userActions.resetResetPassword());
            setIsOpen(false);
        }
        // eslint-disable-next-line
    }, [resetPassword])

    const submitDisabled = !getPasswordStrength(form.password).hasCharacterLength
        || !getPasswordStrength(form.password).hasLowerCase
        || !getPasswordStrength(form.password).hasUpperCase
        || !getPasswordStrength(form.password).hasNumber
        || !getPasswordStrength(form.password).hasSpecialCharacter
        || form.confirmPassword !== form.password;

    return (
        <FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={submitForm}>
                <div className="p-8 pb-2">
                    <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                        <div className="text-2xl font-ojah font-medium">
                            Change Password
                        </div>
                        <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                            <IconClose className="m-auto text-white svg-stroke-gray" />
                        </div>
                    </div>

                    <div className="mt-10">
                        <Input
                            type="number"
                            label="Enter OTP sent to Email"
                            placeholder="6 Digit Code"
                            required={true}
                            value={form.token}
                            onChange={(e: any) => setForm({ ...form, token: e.target.value })}
                        />

                        <Input
                            type={showPassword1 ? "text" : "password"}
                            autoComplete="false"
                            label="Enter new password"
                            placeholder="Password"
                            required={true}
                            value={form.password} onChange={(e: any) => updatePassword(e.target.value)}
                            rightIcon={showPassword1 ? <IconEyeOpen /> : <IconEyeClose />}
                            rightIconClicked={() => setShowPassword1(!showPassword1)}
                            rightIconClass="px-3"
                        />

                        <div className="-mt-2 mb-5">
                            <PasswordStrengthIndicator
                                password={form.password}
                                passwordStrengthLevel={passwordStrengthLevel}
                            />
                        </div>

                        <Input
                            type={showPassword2 ? "text" : "password"}
                            autoComplete="false"
                            label="Confirm new password"
                            placeholder="Password"
                            required={true}
                            value={form.confirmPassword} onChange={(e: any) => setForm({ ...form, confirmPassword: e.target.value })}
                            rightIcon={showPassword2 ? <IconEyeOpen /> : <IconEyeClose />}
                            rightIconClicked={() => setShowPassword2(!showPassword2)}
                            rightIconClass="px-3"
                        />
                    </div>
                </div>

                <div className="border-t border-gray-100 p-8">
                    <ButtonLoader disabled={submitDisabled} loading={resetPasswordLoading} type="submit" className="btn btn-lg btn-block btn-shiga-black">
                        Change Password
                    </ButtonLoader>
                </div>
            </form>
        </FormDrawer>
    )
}


const TwoFactorAuthentication = (props: any) => {
    const { isOpen, setIsOpen, mfaInitialize, refetchProfiles } = props;
    const { mfaCompleteLoading, mfaComplete } = useSelector((s: any) => s.auth.account);
    const pinLength = 6;

    let elementRef: any = useRef();
    const dispatch = useDispatch();
    const [pin, setPin] = useState("");
    const [step, setStep] = useState(1);
    const backupCodesSample = ["hde37d382v", "znjw9s8ced", "nch923fh39", "9hcur48brc", "hde37d382v", "znjw9s8ced", "nch923fh39", "9hcur48brc", "nch923fh39", "9hcur48brc"];

    const copyText = (text: string) => {
        copy(text);
        toast.success("Copied: " + text);
    }

    const clearInputPin = () => {
        if (elementRef.currentIndex !== undefined) {
            elementRef.clear();
            setPin("");
        }
    }

    const submitForm = async (e: any) => {
        e.preventDefault();
        const params = {
            token: pin
        }
        dispatch(accountActions.completeMFA(params));
        dispatch(accountActions.verifyMFA(params));

        refetchProfiles();
    }

    const downloadBackupCode = () => {
        copy(backupCodesSample.join(" "));
        toast.success("Backup codes copied. Please keep these safe.");
    }

    useEffect(() => {
        if (isOpen === true) {
            clearInputPin();
            setStep(1);
        }
    }, [isOpen]);

    useEffect(() => {
        if (mfaComplete?.success === true) {
            clearInputPin();
            setStep(2);
        }
    }, [mfaComplete]);

    return (
        <FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={setIsOpen}>
            {mfaInitialize?.data &&
                <form onSubmit={submitForm}>
                    {step === 1 &&
                        <>
                            <div className="p-8">
                                <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                                    <div className="text-2xl font-ojah font-medium ellipsis">
                                        Enable Two-Factor Authentication
                                    </div>
                                    <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                                        <IconClose className="m-auto svg-stroke-gray" />
                                    </div>
                                </div>

                                <div className="mt-10">
                                    <div className="mx-4 flex">
                                        <div className="w-14 flex-shrink-0">
                                            <IconKey className="w-8 h-8" />
                                        </div>
                                        <div className="text-sm">
                                            Before you continue, Install an authenticator app like Authy or Google Authenticator.
                                        </div>
                                    </div>
                                    <div className="mt-4 mx-4 flex">
                                        <div className="w-14 flex-shrink-0">
                                            <IconScanner className="w-8 h-8" />
                                        </div>
                                        <div className="text-sm">
                                            <span className="mr-1">Scan the QR Code or enter the text code below if you can't scan it </span>
                                            <span onClick={() => copyText(mfaInitialize?.data?.secret ?? "-")} className="w-max pl-1.5 pr-7 py-px text-xs relative whitespace-nowrap items-center border-[1px] border-[#E2E3E5] rounded-md hover:bg-gray-200 cursor-pointer">
                                                {mfaInitialize?.data?.secret}
                                                <IconCopy className="h-3.5 absolute top-px right-0.5" />
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-52 h-52 mt-6 bg-white mx-auto overflow-hidden">
                                    {/* <img src={ImageQRCode} alt="QRCode" /> */}
                                    <QRCode
                                        value={mfaInitialize?.data?.otpUrl}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    />
                                </div>

                                <div className="mt-6">
                                    <div className="mb-2 text-shiga-gray text-sm">
                                        Enter Code generated by App
                                        <span className="form-input-required">*</span>
                                    </div>
                                    <PinInput
                                        length={pinLength}
                                        initialValue={pin}
                                        onChange={(value) => setPin(value)}
                                        type="numeric"
                                        inputMode="number"
                                        inputStyle={{ width: '48px', height: '48px', background: "#FAFAFB", border: "2px solid transparent", marginRight: "6px", fontSize: "40px", fontWeight: "bold", paddingBottom: "6px", borderRadius: "1rem" }}
                                        inputFocusStyle={{ border: "2px solid #343433" }}
                                        onComplete={(value, index) => { }}
                                        autoSelect={true}
                                        focus={true}
                                        secret={true}
                                        ref={(n) => (elementRef = n)}
                                    />
                                </div>
                            </div>

                            <div className="border-t border-gray-100 p-8">
                                <ButtonLoader loading={mfaCompleteLoading} disabled={pin.length !== 6} type="submit" className="btn btn-lg btn-block btn-shiga-black">
                                    Enable 2FA
                                </ButtonLoader>
                            </div>
                        </>
                    }

                    {step === 2 &&
                        <div className="p-8">
                            <div className="pb-6 flex items-center justify-between">
                                <div className="text-2xl font-ojah font-medium ellipsis"></div>
                                <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                                    <IconClose className="m-auto svg-stroke-gray" />
                                </div>
                            </div>

                            <div className="text-center">
                                <div>
                                    <IconCheck className="w-24 h-24 mx-auto" />
                                </div>
                                <div className="mt-8 text-3xl font-ojah font-bold">
                                    Two-Factor Enabled
                                </div>
                                <div className="mt-8 pt-8 border-t border-gray-100 text-sm text-shiga-gray">
                                    Download and keep these backup code safe. If you lose access to your Authentication device, you can use any of these codes to log in to your account.
                                </div>
                                <div className="mt-8 pt-8 border border-gray-100 rounded-2xl overflow-hidden">
                                    <div className="grid grid-cols-2 gap-4">
                                        {backupCodesSample.map((code: string) =>
                                            <div className="font-medium">
                                                {code}
                                            </div>
                                        )}
                                    </div>
                                    <div onClick={downloadBackupCode} className="p-3 mt-8 bg-gray-100 hover:bg-gray-200 flex items-center justify-center cursor-pointer">
                                        <IconDownload className="mr-2" />
                                        Download backup codes
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </form>
            }
        </FormDrawer>
    )
}


export default MyProfile;