import { Transaction } from 'types/transaction';
import { ReactComponent as TrxAvatar } from "assets/images/icons/trx-avatar.svg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';
import Maybe from 'components/common/Maybe';


type Props = {
   transaction: Transaction;
   isCardTransaction: boolean;
}

const BankTransferDetails = ({ transaction, isCardTransaction }: Props) => {
   const { copyItem } = useToolkit()

   const initiator = `${transaction?.initiatedByAccount?.firstName || ''} ${transaction?.initiatedByAccount?.lastName || ''}`
   const beneficiaryName = `${transaction?.bankTransfer?.beneficiaryAccountName?.toLowerCase() || ''}`


   return (
      <div className="w-full">

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Status
            </div>

            <div className="transaction-detail-inline-value capitalize">
               {transaction?.status?.toLowerCase()}
            </div>
         </div>

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Initiated by
            </div>

            <div className="flex items-center">
               <TrxAvatar />

               <div className="transaction-detail-inline-value capitalize ml-1.5">
                  {initiator}
               </div>
            </div>
         </div>

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Fee
            </div>

            <div className="transaction-detail-inline-value">
               {transaction?.fee} {transaction?.currency}
            </div>
         </div>

         <div className={`transaction-detail-inline-wrapper ${isCardTransaction && '-last'}`}>
            <div className="transaction-detail-inline-key">
               Transaction ID
            </div>

            <div className="transaction-detail-inline-value">
               <div className='flex items-center font-medium'>
                  {transaction.reference?.substring(0, 15)}
                  <button className='ml-1.5' onClick={() => copyItem(transaction?.reference, 'Transaction reference')}>
                     <CopyIcon width={20} />
                  </button>
               </div>
            </div>
         </div>

         <Maybe condition={!isCardTransaction}>
            <div className='transaction-detail-inline-wrapper-last'>
               <div className="transaction-detail-inline-key capitalize">
                  {beneficiaryName} gets
               </div>

               <div className="transaction-detail-inline-value">
                  {transaction?.amount - Number(transaction?.fee)} {transaction?.currency}
               </div>
            </div>
         </Maybe>

      </div>
   )
}

export default BankTransferDetails