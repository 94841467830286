import React, { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { Separator } from "components/ui/separator";

import { Plus } from "lucide-react";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { InvoiceAdvCustomer } from "types/payment/invoice";
import { PAYMENT_PATHS } from "pages/payments/paths";
import { NUMBER_REGEX } from "constants/number.regex";
import { Input } from "components/common/form";
import { ADVANCED } from "./data";
import { toast } from "react-toastify";
import { AdvancedOptions } from "./components/AdvancedOptions";
import { ItemList } from "./components/ItemList";

const formSchema = z.object({
  name: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),

  price: z.coerce.number().gte(1, "price must be 1 and above"),

  qty: z.coerce.number().gte(1, "quantity must be 11 and above"),
});

export function AddItemForm() {
  const navigate = useNavigate();
  const { invoice, editIndex, setCompleted, addItem, setEditIndex, editAdvanceFields } =
    useInvoiceStore();
    const itemToEdit = useMemo(() => editIndex > -1 ?
    invoice.items[editIndex] : null, [editIndex, invoice.items]);

    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
        name: itemToEdit?.name || "",
        price: itemToEdit?.price || 0,
        qty: itemToEdit?.qty || 0,
      },
    });

  useEffect(() => {
    form.setValue("name", itemToEdit?.name || "");
    form.setValue("price", itemToEdit?.price || 0);
    form.setValue("qty", itemToEdit?.qty || 0)
  },[form, itemToEdit])

  useEffect(() => {
    setEditIndex(-1)
  }, [setEditIndex])

  const goToNextStep = () => {
    setCompleted("add_items");
    navigate(`${PAYMENT_PATHS.CREATE_INVOICE}?tab=review_send`);
  }

  function onSubmit(values: z.infer<typeof formSchema>) {
    handleAddItem();
    goToNextStep()
  }

  const handleAddItem = () => {
    const { name, price, qty } = form.getValues();
    if (invoice.items.find((i) => i.name === name)) {
      toast.info("Item already exist in invoice")
      return;
    }

    form.reset();
    addItem({
      name,
      price: Number(price),
      qty: Number(qty),
    });
    setCompleted("add_items");
    if(editIndex > -1){
      setEditIndex(-1)
    }
  };
  const editInvoiceField = (field: keyof InvoiceAdvCustomer, value: any) => {
    editAdvanceFields(field, value);
  };
  return (
    <div className="flex flex-col-reverse items-center lg:items-start lg:flex-row p-6 lg:justify-between">
      <div className="mt-5 lg:mt-0 w-full lg:w-[412px] space-y-4 ">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input required label="Enter a Name" placeholder="Enter a name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-between gap-4 w-full">
              <FormField
                control={form.control}
                name="qty"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Enter quantity"
                        label="Item quantity"
                        required
                        {...field}
                        value={field.value}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (NUMBER_REGEX.test(inputValue))
                            form.setValue("qty", Number(inputValue));
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="price"
                render={({ field }) => (
                  <FormItem itemType="number">
                    <FormControl>
                      <Input
                        placeholder="Enter price number"
                        label="Price per quantity"
                        required
                        {...field}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (NUMBER_REGEX.test(inputValue))
                            form.setValue("price", Number(inputValue));
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={handleAddItem}
                size="sm"
                variant="outline"
              >
                <Plus />
                Add Item
              </Button>
            </div>
            <Separator />
            <div className="space-y-6">
              <p className="text-zinc-800 text-base font-medium font-['Inter']">
                Advanced Customer (Optional)
              </p>
              {ADVANCED.map((v, idx) => (
                <React.Fragment key={idx}>
                  <AdvancedOptions option={v} editInvoiceField={editInvoiceField} invoice={invoice} />
                  {idx === 0 && <Separator />}
                </React.Fragment>
              ))}
            </div>
            <Button className="w-full" onClick={goToNextStep} type="button">
              <Link to="/payments/invoice/create?tab=add_items">Continue</Link>
            </Button>
          </form>
        </Form>
      </div>
      <ItemList invoice={invoice} />
    </div>
  );
}