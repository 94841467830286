import { useMemo, useState } from "react";

import { DataTable } from "components/data-table/data-table";
import { DataTableHeader } from "components/data-table/data-table-header";
import { useToggle } from "hooks/useToggle";
import { PaymentLink, defaultPaymentLink } from "types/payment/payments.interface";
import { DeletePaymentLinkModal } from "../DeletePaymentLinkModal";
import { PaymentLinkColumns } from "./PaymentColumn";
import { formatDate } from "lib/format.date";
import { currencyFormat } from "lib/format.amount";
import { useNavigate } from "react-router-dom";
import { PAYMENT_PATHS } from "pages/payments/paths";

export const PaymentLinkTable: React.FC<{
    paymentLink: PaymentLink[];
}> = ({ paymentLink: data }) => {
    const navigate = useNavigate();
    const [currentPaymentLink, setCurrentPaymentLink] = useState<PaymentLink>(defaultPaymentLink)
    const [showDelete, toggleDelete] = useToggle(false);


    const handleShowDelete = (arg: PaymentLink) => {
        setCurrentPaymentLink(arg)
        toggleDelete()
    }

    const handleClick = (paymentLink: PaymentLink) => {
        sessionStorage.setItem("view-link", paymentLink.id);
        navigate(`${PAYMENT_PATHS.PAYMENT_LINK}/${paymentLink.name.replaceAll(" ", "-")}`);
      };

    const paymentLink = useMemo(() => data.map(item => ({
        ...item,
        formattedAmount: item.amountType === "FIXED" ? currencyFormat(item.amount, item.currency) : "N/A",
        formattedType: item.type.replace("_", " ").toLowerCase(),
        createdAt: formatDate(item.createdAt),
      })),[data])

    return (
        <>
            <div className="mt-6">
                <DataTable
                    data={paymentLink}
                    columns={PaymentLinkColumns}
                    renderToolbar={table => <DataTableHeader
                    filterOptions={["Active", "Inactive"]}
                        placeholder="Search for payment link..."
                         table={table} />}
                    meta={{
                        handleShowDelete,
                        handleClick
                    }}
                />
            </div>

            <DeletePaymentLinkModal
                paymentLink={currentPaymentLink}
                open={showDelete}
                closeModal={toggleDelete}
            />
        </>
    );
};
