import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ReactComponent as IconUser } from "assets/images/icons/user-profile.svg";

import { Button } from "components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "components/ui/form";

import { getCurrencies } from "lib/constants";
import { Minus } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Currency } from "types/shared/countries.types";
import { PAYMENT_PATHS } from "../paths";
import { useTuitionStore } from "zustand-store/payments/tution";
import { SelectInput } from "components/common/form";
import InputAmount from "components/common/form/InputAmount";
import { CurrencyType } from "types/misc";
import { currencyFormat } from "lib/format.amount";
import { zodValidation } from "utils/zodValidate";

const FormSchema = z.object({
  paymentTo: z.string({ message: "currency is required" }),
  amount: z.coerce.number().gte(1, "Amount must be more than 0"),
  currency: z.string({ message: "currency is required" }),
  balance: zodValidation.balance,
});

const AmountHeader: React.FC<{
  balance: number;
  currency: CurrencyType
}> = ({ balance, currency }) => {
  return (
    <FormLabel className="grid grid-cols-2 width-full">
      <div className="w-[159px]">
        <span className="text-zinc-400 text-sm font-normal font-['Inter']">
          You send
        </span>
        <span className="text-zinc-400 text-sm font-normal font-['Inter']">
          {" "}
        </span>
        <span className="text-red-500 text-sm font-normal font-['Inter']">
          *
        </span>
      </div>
      <div className="text-right">
        <span className="text-neutral-400 text-sm font-normal font-['Inter']">
          You have{" "}
        </span>
        <span className="text-indigo-500 text-sm font-medium font-['Inter']">
          {(balance && currency) ? currencyFormat(balance, currency) : "0.00"}
        </span>
        <span className="text-neutral-400 text-sm font-normal font-['Inter']">
          {" "}
          available
        </span>
      </div>
    </FormLabel>
  )
}

const MORE_MONEY = 2000
const DEFAULT_TUITION_CURRENCY = "GBP"

export const Amount = () => {
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      currency: DEFAULT_TUITION_CURRENCY,
      amount: 0,
      paymentTo: "",
    },
    criteriaMode: "all",
    context: "onChange",
    mode: "onChange",
  });

  const amount = form.watch("amount");
  const currency = form.watch("currency");
  const { payload, setCompleted, setPayload } = useTuitionStore();

  const [currencies, setCurrencies] = useState<Currency[]>();

  useEffect(() => {
    payload.currency && form.setValue("currency", payload.currency);
    payload.amount && form.setValue("amount", payload.amount);
    payload.paymentTo && form.setValue("paymentTo", payload.paymentTo);
  }, [payload, form]);
  useEffect(() => {
    getCurrencies().then((res) => setCurrencies(res));
  }, []);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    if(Number(data.amount) > Number(data.balance)){
      form.setError("amount", {
        message: "Amount is more than balance in wallet"
      })
    }else{
      if (data.amount <= MORE_MONEY) {
        form.setError("amount", {
          message: "Amount cannot be less than 2000",
        })
      } else {
        setCompleted("amount");
        setPayload({ ...payload, ...data });
        form.reset();
        navigate(`${PAYMENT_PATHS.TUITION_STEPS}?tab=review_pay`);
      }
    }
  }


  return (
    <div className="w-full p-10  space-y-4 flex flex-col justify-center items-center">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-[412px] space-y-6"
        >
          <FormField
            control={form.control}
            name="paymentTo"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <SelectInput
                    label="Payment for"
                    placeholder="Select an option"
                    required
                    {...field}
                  >
                    {["School Tuition", "Accomodation"]
                      .map((payment, index) => (
                        <option value={payment} key={index}>
                          {payment}
                        </option>
                      ))}
                  </SelectInput>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="relative">
            <InputAmount
              disableCurrency
              currencies={currencies || []}
              form={form as any}
              minAmount={MORE_MONEY}
              header={({ currentWallet }) =>
                <AmountHeader balance={currentWallet.balance} currency={currentWallet.currency} />}
            />

            <div className=" relative">
              <div className="px-4">
                <div className="bg-neutral-50 ml-[5px] -mb-1  h-7 w-1" />
                <div className="flex items-center space-x-4">
                  <div className="w-4 h-4 p-1 bg-neutral-50 rounded-[100px] justify-center items-center flex">
                    <Minus />
                  </div>
                  <p className="text-zinc-800 text-sm font-medium font-['Inter']">
                    £0.00
                  </p>
                  <div className="text-neutral-400 text-sm font-normal font-['Inter']">
                    Transfer fee
                  </div>
                </div>
                <div className="bg-neutral-50 ml-[5px] -mt-1  h-7 w-1" />
              </div>
              <div className="flex gap-3 ">
                <IconUser className="rounded-full" />
                <div>
                  <span className="font-semibold ">
                    {payload.university.universityName} {""}
                    <span className="text-neutral-400">gets</span>
                  </span>
                  <p className="font-bold">
                    {currencyFormat(amount, currency)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Button className="w-full" type="submit">
            Continue
          </Button>
        </form>
      </Form>
    </div>
  );
};
