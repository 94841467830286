import PATHS from "NavigationRoute";
import { ButtonLoader } from "components/common/form";
import ConvertMoneyHeader from "components/transfer/CovertMoneyHeader";
import { currencyFormat } from "lib/format.amount";
import PurchaseInfo from "pages/bills/PurchaseInfo";
import PaymentSuccessful from "pages/transfer/status/PaymentSuccessful";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertMoneyApi, resetConvertData } from "store/convert/action";
import { getConvertStore } from "store/convert/reducer";

const ConvertMoneyConfirm = () => {

    const { fromCurrency, toCurrency, rate, receiveAmount, fromAmount, convertingLoading, conversionStatus } = useSelector(getConvertStore)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = () => {
        dispatch(convertMoneyApi(
            String(fromAmount),
            fromCurrency,
            toCurrency
        ))
    }

    const convertingDetail = [
        { attribute: "You converted", value: `${currencyFormat(fromAmount, fromCurrency)}` },
        { attribute: "Rate", value: `1 ${fromCurrency} = ${rate} ${toCurrency}` },
        { attribute: "You get", value: currencyFormat(receiveAmount, toCurrency) },
        { attribute: "Fee", value: "Free 😎" },
    ];
    return (
        <div className="w-full justify-center items-center font-inter  pt-[80px] pl-6 pr-6">

            <PaymentSuccessful
                onDone={() => {
                    dispatch(resetConvertData())
                    navigate(PATHS.TRANSACTION_HOME)
                }}
                isOpen={conversionStatus != -1}
                amount={`${fromAmount} ${fromCurrency}`}
                recipient={`${receiveAmount} ${toCurrency}`}
                isSuccessful={conversionStatus == 0}
                successfullmessage='Covert Successful'
                errorMessage='This transaction cannot be completed at this time.'
                succesPreText='You conerted'
            />

            <ConvertMoneyHeader step={2} onClose={() => { navigate(PATHS.TRANSACTION_HOME) }} />


            <div className="flex flex-col max-w-[412px] mt-[69px] mx-auto">
                <PurchaseInfo header={`Confirm your conversion from ${fromCurrency} to ${toCurrency}`}
                    info={convertingDetail} transactionType="Conversion"
                />
                <ButtonLoader
                    type="submit"
                    onClick={handleSubmit}
                    className="btn bg-shiga-black text-white btn-lg btn-block mt-11"
                    loading={convertingLoading}
                >
                    Confirm & Convert
                </ButtonLoader>
            </div>


        </div>
    )
}

export default ConvertMoneyConfirm;