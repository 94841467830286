import axios from "axios";
import { apiBaseUrl, apiCreateInvoice, apiSendReminderInvoice, apiDeleteInvoice, apiFindAllInvoice, apiFindInvoice, apiUpdatePaidInvoice } from "data/config";
import { getToken } from "lib";
import { PaginatedApiResponse, PaginatedApiResponseNoMeta } from "types/data-interface";
import { BillingAddress, Invoice, InvoiceItem } from "types/payment/invoice";

export const updatePaidInvoice = async (invoiceID: any) =>
  axios.patch(apiBaseUrl + apiUpdatePaidInvoice(invoiceID), {}, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }).then(res => res.data).catch((error: any) => {
    throw new Error(error.response?.data?.message || error.message);
  })

export const findAllInvoice = async (): Promise<PaginatedApiResponse<Invoice[]>> =>
  axios.get(apiBaseUrl! + apiFindAllInvoice, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }).then(res => res.data)
interface CreateInvoiceOption extends Pick<InvoiceItem, 'name' | 'price'> {
  qty: number
}
export const createInvoice = async (createInvoiceBody: {
  billingAddress: BillingAddress;
  items: CreateInvoiceOption[];
  title: string;
  imageUrl: string;
  recipientName: string;
  recipientEmail: string;
  recipientPhone: string;
  note: string;
  currency: {};
  description: string;
  startDate: string;
  endDate: string;
  discount: number;
  vat: number
}) => axios.post(apiBaseUrl + apiCreateInvoice, createInvoiceBody, {
  headers: {
    Authorization: "Bearer " + getToken(),
  },
}).then(res => res.data).catch((error: any) => {
  throw new Error(error.response?.data?.message || error.message);
})

export const findInvoice = async (findID: string): Promise<PaginatedApiResponseNoMeta<Invoice>> =>
  axios.get(apiBaseUrl! + apiFindInvoice(findID), {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }).then(res => res.data);

export const removeInvoice = async (findID: string) =>
  axios.delete(apiBaseUrl! + apiDeleteInvoice(findID), {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }).then(res => res.data).catch((error: any) => {
    throw new Error(error.response?.data?.message || error.message);
  })


export const sendReminderInvoice = async (findID: string) =>
  axios.post(apiBaseUrl! + apiSendReminderInvoice(findID), {}, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  }).then(res => res.data);