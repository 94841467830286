import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from 'components/common/Header.jsx';
import Sidebar from 'components/common/Sidebarr.jsx';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "components/common/Maybe";
import useLoginInfo from "hooks/useLoginInfo";
import { useAppDispatch } from "store/hooks";
import { getBanks } from "store/ui/banks/action";
import { IdleTimerProvider } from "react-idle-timer";
import IdleCheckerModal from "components/custom/IdleCheckerModal";
import { showIdleTimer } from "store/auth/user/action";
import { RootState } from "store/store";



const AppLayout = () => {
    const dispatch = useAppDispatch()
    const { getUserProfile, getBusinessProfile } = useLoginInfo();
    const { userInfo, } = useSelector((s: RootState) => s.auth.userPersist);
    const { isIdleModalOpen } = useSelector((s: RootState) => s.auth.user);
    const isDevEnv = process.env.NODE_ENV === 'development';


    useEffect(() => {
        dispatch(getBanks());
        if (userInfo?.userProfile === undefined) {
            getUserProfile();
        }
        if (userInfo?.businessProfile === undefined) {
            getBusinessProfile();
        }
        // eslint-disable-next-line
    }, []);

    const onPrompt = () => { };

    const onIdle = () => {
        if (isIdleModalOpen === false && !isDevEnv) {
            dispatch(showIdleTimer());
        }
    };


    return (
        <IdleTimerProvider
            timeout={300000}
            onPrompt={onPrompt}
            onIdle={onIdle}>
            <div>

                <LogoutCover />

                <div className="flex min-h-screen">
                    <Sidebar />
                    <div className="h-screen flex-none hidden md:block" style={{ width: "250px" }}></div>
                    <div className="flex-grow overflow-hidden">
                        <Header />
                        <div className="h-full">
                            <Outlet />
                        </div>
                    </div>
                </div>

                <Maybe condition={isIdleModalOpen}>
                    <IdleCheckerModal />
                </Maybe>

            </div>
        </IdleTimerProvider>

    )
}


const LogoutCover = () => {
    const { logoutLoading } = useSelector((s: any) => s.auth.user);
    return (
        <Maybe condition={logoutLoading === true}>
            <div className="absolute z-[9999]">
                <div className="w-screen min-h-screen relative z-[52] flex items-center justify-center">
                    <div className="h-full px-10 text-center text-white">
                        <div>
                            <FontAwesomeIcon icon="circle-notch" spin className="text-8xl" />
                        </div>
                        <div className="mt-8 text-4xl font-ojah font-bold">
                            Logging out...
                        </div>
                    </div>
                </div>
                <div className="w-full h-full inset-0 fixed z-[51] bg-black bg-opacity-80"></div>
            </div>
        </Maybe>
    )
}


export default AppLayout
