import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthPageTitle from "components/common/AuthPageTitle";
import { ButtonLoader, Input } from "components/common/form";
import { ReactComponent as IconGlobe } from "assets/images/icons/Globe.svg";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import useLoginInfo from "hooks/useLoginInfo";
import { toast } from "react-toastify";
import validate from "utils/validate";
import * as businessVerificationActions from "store/entities/businessVerification/action";
import * as userActions from "store/auth/user/action";


const BusinessInformation = (props:any) => {
    const { goNext } = props;
    const dispatch = useDispatch();
    const { userInfo } = useLoginInfo();
    const { businessVerificationUpdateLoading, businessVerificationUpdate } = useSelector((s:any) => s.entities.businessVerification);

    const [form, setForm] = useState({
        website: "",
        phoneNumber: "",
        email: "",
        tin: "",
        address: "",
        description: "",
    })

    const populateForm = () => {
        setForm({
            website: userInfo?.businessProfile?.website ?? "",
            phoneNumber: userInfo?.businessProfile?.phoneNumber ?? "",
            email: userInfo?.businessProfile?.email ?? "",
            tin: userInfo?.businessProfile?.tin ?? "",
            address: userInfo?.businessProfile?.address ?? "",
            description: userInfo?.businessProfile?.description ?? "",
        });
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        const errors:any = validate(form);
        if (errors){
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            dispatch(businessVerificationActions.updateBusinessInformation(form));
        }
    }

    useEffect(() => {
        if (userInfo?.businessProfile !== undefined){
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (businessVerificationUpdate?.success === true){
            dispatch(userActions.updateUserInfo({
                businessProfile: { ...businessVerificationUpdate?.data?.business ?? {} },
            }));
            dispatch(businessVerificationActions.resetUpdateBusinessVerification());
            goNext();
        }
        // eslint-disable-next-line
    }, [businessVerificationUpdate]);

    return (
        <div>
            <AuthPageTitle
                title={<>Add more details about<br />Payshiga Technlogies</>}
                description="This information would be used to validate your business."
            />
            <div className="pt-10 mt-10 border-t border-gray-100">
                <form onSubmit={submitForm}>

                    <Input
                        type="text"
                        label="Business website"
                        placeholder="www.abc.def"
                        required={true}
                        value={form.website}
                        onChange={(e:any) => setForm({...form, website: e.target.value})}
                        leftIcon={<IconGlobe />}
                        leftIconClass="px-2.5"
                    />

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                        <Input
                            type="tel"
                            label="Business Phone number"
                            placeholder="Phone number"
                            required={true}
                            value={form.phoneNumber}
                            onChange={(e:any) => setForm({...form, phoneNumber: e.target.value})}
                        />

                        <Input
                            type="email"
                            label="Support email"
                            placeholder="Email address"
                            required={true}
                            value={form.email}
                            onChange={(e:any) => setForm({...form, email: e.target.value})}
                        />
                    </div>

                    <Input
                        type="text"
                        label="Tax Identification Number (TIN)"
                        placeholder="Tax Identification Number"
                        required={true}
                        value={form.tin}
                        onChange={(e:any) => setForm({...form, tin: e.target.value})}
                    />

                    <Input
                        type="text"
                        label="Official Business Address"
                        placeholder="Search address"
                        required={true}
                        value={form.address}
                        onChange={(e:any) => setForm({...form, address: e.target.value})}
                        leftIcon={<IconSearch />}
                        leftIconClass="px-2.5"
                    />
                    
                    <div className="form-group">
                        <label>
                            Tell us about your business
                            <span className="form-input-required">*</span>
                        </label>
                        <div className="relative">
                            <textarea
                                className="w-full !h-32 form-input resize-none"
                                placeholder="What does your business do?"
                                value={form.description}
                                onChange={(e:any) => setForm({...form, description: e.target.value})}
                            >
                            </textarea>
                        </div>
                    </div>

                    <div className="flex space-x-2 items-center justify-end">
                        <ButtonLoader type="submit" loading={businessVerificationUpdateLoading} className="max-w-[16rem] btn btn-block btn-lg btn-primary">
                            Continue
                        </ButtonLoader>
                    </div>

                </form>
            </div>
        </div>
    );
}


export default BusinessInformation;