import NavigationLinks from "components/common/NavigationLinks.jsx";
import logo from "assets/images/icons/logo-black.svg";
import { ReactComponent as IconArrowUpDown } from "assets/images/icons/arrow-up-down.svg";
import { ReactComponent as IconPlus } from "assets/images/icons/plus-icon.svg";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const Sidebar = () => {
  return (
    <div
      className="h-screen fixed z-10 bg-[#FAFAFA] hidden md:flex flex-col border-r border-outline-grey"
      style={{ width: "250px" }}
    >
      <BusinessProfile />
      <div className="h-full mt-10 py-0.5 overflow-auto">
        <NavigationLinks view="desktop" />
      </div>
    </div>
  );
};

const BusinessProfile = () => {
  const { userInfo } = useSelector((s) => s.auth.userPersist);
  return (
    <>
      <div className="px-3">
        <Menu as="div" className="relative">
          <Menu.Button className="w-full p-3 mt-3 bg-white border border-[#E2E3E5] rounded-2xl cursor-pointer flex space-x-3 justify-between items-center">
            <div
              // className="w-12 h-12 flex-shrink-0 flex items-center rounded-lg overflow-hidden"
              className="flex-shrink-0 flex items-center"
            >
              {userInfo?.businessProfile?.logo ? (
                <div className="w-12 h-12">

                  <img
                    src={userInfo?.businessProfile?.logo}
                    alt="logo"
                    className="m-auto w-full h-full dp-cover"
                  />
                </div>
              ) : (
              <div className="border-4 rounded-2xl w-12 h-12 flex items-center text-center justify-center">
                <p className="font-bold">
                  {userInfo?.businessProfile?.name
                    .split(" ")
                    .map((word) => word[0])
                    .join("")}
                </p>
              </div>
               )} 
            </div>
            <div className="w-24 flex-grow flex-wrap">
              <div className="font-medium ellipsis text-[16px]">
                {userInfo?.businessProfile?.name}
              </div>
              <div className="mt-0.5 text-[#99999C] text-xs ellipsis">
                Merchant ID: {userInfo?.businessProfile?.merchantId}
              </div>
            </div>
            <div className="w-4 flex items-center flex-shrink-0">
              <IconArrowUpDown />
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 top-0 z-10 w-full origin-top-right divide-y divide-gray-100 rounded-2xl bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <Menu.Item as="div" className="p-1">
                {({ active }) => (
                  <div
                    className={`w-full p-2 cursor-pointer rounded-2xl flex space-x-3 justify-between items-center ${
                      active && "bg-[#FAFAFA]"
                    }`}
                  >
                    <div
              // className="w-12 h-12 flex-shrink-0 flex items-center rounded-lg overflow-hidden"
              className="flex-shrink-0 flex items-center"
            >
              {userInfo?.businessProfile?.logo ? (
                <div className="w-12 h-12">

                  <img
                    src={userInfo?.businessProfile?.logo}
                    alt="logo"
                    className="m-auto w-full h-full dp-cover"
                  />
                </div>
              ) : (
              <div className="border-4 rounded-2xl w-12 h-12 flex items-center text-center justify-center">
                <p className="font-bold">
                  {userInfo?.businessProfile?.name
                    .split(" ")
                    .map((word) => word[0])
                    .join("")}
                </p>
              </div>
               )} 
            </div>
                    <div className="w-24 flex-grow flex-wrap">
                      <div className="font-medium ellipsis text-[16px]">
                        {userInfo?.businessProfile?.name}
                      </div>
                      <div className="mt-0.5 text-[#99999C] text-xs ellipsis">
                        Merchant ID: {userInfo?.businessProfile?.merchantId}
                      </div>
                    </div>
                    <div className="w-4 flex items-center flex-shrink-0">
                      <IconArrowUpDown />
                    </div>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item as="div" className="p-1">
                {({ active }) => (
                  <div
                    className={`p-2 rounded-2xl flex items-center gap-3 cursor-pointer ${
                      active && "bg-[#FAFAFA]"
                    }`}
                  >
                    <div className="flex items-center justify-center p-2 rounded-lg bg-[#FAFAFA] size-10">
                      <IconPlus />
                    </div>
                    <p className="font-medium text-shiga-black text-[16px]">
                      Add a Business
                    </p>
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

export default Sidebar;
