import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import { ReactComponent as IconClock } from "assets/images/icons/AlarmClock.svg";
import addition from "assets/images/icons/addition.png";
import { ButtonLoader, InputAmount } from "components/common/form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as BackButton } from "assets/images/icons/ArrowLeft.svg";
import CloseButton from "assets/images/icons/close.png";
import { fundGBPWallet, getGBPWalletFundStatus } from "lib/add-money";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "components/ui/form";
import { useSharedStore } from "zustand-store";
import { currencyFormat } from "lib/format.amount";
import { Wallet } from "types/wallet";
import { toast } from "react-toastify";
import { CurrencyType } from "types/misc";
import { useEffect, useMemo, useState } from "react";
import PaymentSuccessful from "pages/transfer/status/PaymentSuccessful";

const ConvertMoneyInputHeader = ({ currentWallet: { balance, currency } }: { currentWallet: Wallet }) => (
    <div className="flex mb-4 justify-between">
        <p className="">You Add<span className="form-input-required">*</span></p>
        {/* {
            balance && currency ?
                <p className="text-shiga-dark">
                    <span><FontAwesomeIcon icon={faWallet} /></span>
                    You have <span className="text-shiga-purple">{currencyFormat(balance, currency)}</span> available
                </p> : <></>
        } */}
    </div>
)

const formSchema = z.object({
    "from-amount": z.number(),
    "from-currency": z.string(),
})

type FormType = z.infer<typeof formSchema>;

const DirectDeposit: React.FC = () => {
    const [showStatus, setShowStatus] = useState<"success" | "error" | "">('');
    const [searchParams] = useSearchParams();
    const [fundingReference, setFundingReference] = useState("");
    const [polling, setPolling] = useState(false);
    const [intervalID, setIntervalID] = useState<NodeJS.Timer | null>(null);
    const { currencies } = useSharedStore();
    const form = useForm<FormType>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            "from-amount": 0,
            "from-currency": searchParams.get("currency") || "GBP",
        },
        mode: "onSubmit"
    });
    const fetchData = async () =>
        getGBPWalletFundStatus(fundingReference).then((data) => {
            if(data.data.status === "SUCCESSFUL"){
                setPolling(false);
                setShowStatus("success");
            }else if(data.data.status === "FAILED"){
                setShowStatus("error");
                setPolling(false);
            }
        }).catch(err => {
            console.log('this is the errir', err.message)
        })

    useEffect(() => {
        console.log({
            intervalID,
            polling
        })
        if(polling && intervalID === null){
            const id = setInterval(fetchData, 5000);
            setIntervalID(id);
        }else {
            if(intervalID) {
                clearInterval(intervalID);
                setIntervalID(null)
            }
        }
        return () => {
            if(intervalID){
                clearInterval(intervalID);
            }
        }
    },[polling])

    useEffect(() => {
        return () => {
            setPolling(false);
            if(intervalID){
                clearInterval(intervalID);
            }
        }
    },[])

    const navigate = useNavigate()
    const amount = form.watch("from-amount");
    const currency = form.watch("from-currency")
    const convertedAmount = useMemo(() => (amount * 0.025) + amount, [amount]);
    const onSubmit = (data: FormType) => {
        return fundGBPWallet({
            amount: data["from-amount"],
            currency: data["from-currency"] as CurrencyType,
        }).then((data) => {
            window.open(data.data.url, "_blank");
            setFundingReference(data.data.reference);
            setPolling(true);
            // setShowStatus('success');
        }).catch(err => {
            // setShowStatus("error")
            toast.error(`Something went wrong ${err.message}`)
        })
    }
    const handleCompleteClick = () => {
        if (showStatus === "success") {
            navigate(`/dashboard/?currency=${currency}`)
        } else {
            form.reset();
            form.setValue("from-currency", searchParams.get("currency") || "");
            setShowStatus("")
        }
    }
    return (
        <>
            <PaymentSuccessful
                isOpen={!!showStatus.length}
                successfullmessage="Payment Successful"
                errorMessage="This transaction was not successful at this time. Tap “Retry” to try again."
                onDone={handleCompleteClick}
                recipient=""
                succesPreText=""
                amount={`You have successfully made a deposit of ${currencyFormat(amount, currency)}`}
                isSuccessful={showStatus === "success"}
                buttonLabel={showStatus === "success" ? "Back to Dashboard" : "Retry"}
                buttonClass={showStatus === "success" ? 'mt-[12px]' : 'mt-[12px] text-shiga-black !bg-outline-grey'}
                bodyClass="mt-12 mb-10"
                iconClass="mb-2.5"
            />
            <div className="w-full justify-center items-center font-inter pt-[44px] px-[80px]">
                <div className="flex w-full justify-between items-center pb-[28px] max-w-full border-b border-gray-100">
                    <div onClick={() => { navigate(-1) }}>
                        <BackButton />
                    </div>

                    <div>
                        <img src={CloseButton} alt="Close Button" className="cursor-pointer" onClick={() => {
                            navigate("/dashboard")
                        }} />
                    </div>
                </div>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="flex justify-center">
                            <div className="w-full sm:max-w-lg md:max-w-xl mt-16">
                                <InputAmount
                                    form={form as any}
                                    disableAmount={polling}
                                    currencies={currencies}
                                    minAmount={0}
                                    disableCurrency
                                    header={ConvertMoneyInputHeader}
                                    formKey={{
                                        "from-currency": "",
                                        "from-amount": 0,
                                    } as Partial<FormType>}
                                />
                                <div className="mb-7">
                                    <div className="flex gap-2 items-center px-8 relative">
                                        <span className="border h-24 bg-[#FAFAFA]"></span>
                                        <div className="absolute flex gap-2 left-[22px] items-center">
                                            <img src={addition} alt="subtract icon" className="w-4 h-4" />
                                            <p className={`text-shiga-gray text-sm`}><span className="text-shiga-black font-medium">
                                                2.5%</span> Funding fee
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 items-center px-8 relative">
                                        <span className="border h-16 bg-[#FAFAFA]"></span>
                                        <div className="absolute flex gap-2 left-[7px] items-center">
                                            <div className="bg-[#f5f5f5] rounded-full p-2">
                                                <IconUserProfile className="size-9" />
                                            </div>
                                            <div>
                                                <p className={`text-shiga-gray text-sm`}>
                                                    <span className="text-shiga-black font-medium">
                                                        You</span> Pay
                                                </p>
                                                <p className="text-shiga-black text-lg">
                                                    {currencyFormat(convertedAmount, "GBP")}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex gap-2 items-center relative">
                                    <span className="border bg-[#FAFAFA]"></span>
                                    <IconClock />
                                    <p className={`text-shiga-gray text-sm`}>
                                        Payment would be received&nbsp;
                                        <span className="text-shiga-black font-medium">
                                            instantly
                                        </span>
                                    </p>
                                </div>

                                <ButtonLoader
                                    type="submit"
                                    loading={polling}
                                    className="btn bg-shiga-black text-white btn-lg btn-block mt-11"
                                    disabled={amount < 1}
                                >
                                    Pay
                                </ButtonLoader>
                            </div>
                        </div>
                    </form>
                </Form>
            </div>
        </>
    );
};

export default DirectDeposit;