import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DynamicHeadTag from "components/common/DynamicHeadTag";
// import Pagination from "components/common/Pagination";
import Search from "components/common/Search";
import Table from "components/common/Table";
import Maybe from "components/common/Maybe";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";

import PermissionsModal from "components/common/PermissionsModal";
import FormDrawer from "components/common/form-drawer";
import { ButtonLoader, Input, SelectInput } from "components/common/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import { ReactComponent as IconKey} from "assets/images/icons/Key.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import { ReactComponent as IconRole } from "assets/images/icons/Role.svg";
import { ReactComponent as IconSecurity } from "assets/images/icons/Security.svg";
import { ReactComponent as IconMail } from "assets/images/icons/Mail.svg";
import { ReactComponent as IconMailSendEnvelope } from "assets/images/icons/Mail-Send-Envelope.svg";
import { ReactComponent as IconCancelInvite } from "assets/images/icons/CancelInvite.svg";
import { ReactComponent as IconCalendar } from "assets/images/icons/Calendar.svg";
import validate from "utils/validate";
import { toast } from "react-toastify";
import { TableContent } from "components/common/TableContent";
import useLoginInfo from "hooks/useLoginInfo";
import { memberRoles } from "data/constants/common";
import DateFormat, { DateTimeFormat } from "utils/dateFormat";
import useUrlNavigation from "hooks/useUrlNavigation";
import * as settingsActions from "store/entities/settings/action";


const Team = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { userInfo } = useLoginInfo();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { urlQuery } = useUrlNavigation();
    const { teamMembersLoading, teamMembers: teamMembersBE, teamMembersInvitedLoading, teamMembersInvited: teamMembersInvitedBE,
        mfaEnforce, inviteResend, inviteCancel, roles } = useSelector((s: any) => s.entities.settings);

    const [isOpenMemberForm, setIsOpenMemberForm] = useState({
        isOpen: false,
        type: "add",
        member: {},
    });

    const [isOpenPermission, setIsOpenPermission] = useState({
        isOpen: false
    });

    const [isOpenMemberDelete, setIsOpenMemberDelete] = useState({
        isOpen: false,
        member: {},
    });
    const [teamMembers, setTeamMembers] = useState<any>({});
    const [teamMembersInvited, setTeamMembersInvited] = useState<any>({});




    const getRoles = () => {
        dispatch(settingsActions.getRoles());
    }

    const getTeamMembers = () => {
        let params: any = {};
        if (searchParams.get("authStatus") !== null) {
            params = { ...params, authStatus: searchParams.get("authStatus")?.toUpperCase() }
        }
        if (searchParams.get("role") !== null) {
            params = { ...params, role: searchParams.get("role")?.toUpperCase() }
        }
        dispatch(settingsActions.getTeamMembers(params));
        dispatch(settingsActions.getTeamMembersInvited(params));
    }

    const searchSubmit = (e: any) => {
        const searchTerm = e.toString().toLowerCase();
        const data1 = teamMembersBE?.data?.filter((member: any) => {
            if (
                (member?.firstName)?.toString()?.toLowerCase()?.includes(searchTerm) ||
                (member?.lastName)?.toString()?.toLowerCase()?.includes(searchTerm) ||
                (member?.email)?.toString()?.toLowerCase()?.includes(searchTerm) ||
                (memberRoles[member?.role])?.toString()?.toLowerCase()?.includes(searchTerm) ||
                (DateFormat(member?.joined)?.toString()?.toLowerCase()?.includes(searchTerm))
            ) {
                return member;
            }
            return null;
        })

        const data2 = teamMembersInvitedBE?.data?.filter((member: any) => {
            if (
                (member?.email)?.toString()?.toLowerCase()?.includes(searchTerm) ||
                (memberRoles[member?.role])?.toString()?.toLowerCase()?.includes(searchTerm)
            ) {
                return member;
            }
            return null;
        })

        const result1 = {
            ...teamMembers,
            data: data1,
        }

        const result2 = {
            ...teamMembers,
            data: data2,
        }

        setTeamMembers(result1);
        setTeamMembersInvited(result2);
    }

    const addMember = () => {
        setIsOpenMemberForm({
            ...isOpenMemberForm,
            isOpen: true,
            type: "add",
            member: {},
        })
    }

    const togglePermission = () => {
        setIsOpenPermission({
            ...isOpenPermission,
            isOpen: true,
        });
    };

    const enforce2FA = (member: any) => {
        toast("Enforcing 2FA for " + member?.firstName + " " + member?.lastName);
        dispatch(settingsActions.memberEnforce2FA(member?.id));
    }

    const editMember = (member: any) => {
        setIsOpenMemberForm({
            ...isOpenMemberForm,
            isOpen: true,
            type: "edit",
            member,
        })
    }

    const deleteMember = (member: any) => {
        setIsOpenMemberDelete({
            ...isOpenMemberDelete,
            isOpen: true,
            member,
        })
    }

    const resendInvite = (member: any) => {
        toast("Resending invite to " + member?.email);
        dispatch(settingsActions.memberResendInvite(member?.id));
    }

    const cancenInvite = (member: any) => {
        toast("Cancelling invite of " + member?.email);
        dispatch(settingsActions.memberCancelInvite(member?.id));
    }

    useEffect(() => {
        getRoles();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getTeamMembers();
        // eslint-disable-next-line
    }, [location]);

    useEffect(() => {
        setTeamMembers(teamMembersBE);
        // eslint-disable-next-line
    }, [teamMembersBE]);

    useEffect(() => {
        setTeamMembersInvited(teamMembersInvitedBE);
        // eslint-disable-next-line
    }, [teamMembersInvitedBE]);

    useEffect(() => {
        if (inviteResend?.success !== undefined || inviteResend?.status !== undefined) {
            dispatch(settingsActions.resetMemberCancelInvite());
            getTeamMembers();
        }
        // eslint-disable-next-line
    }, [inviteResend]);

    useEffect(() => {
        if (inviteCancel?.success !== undefined || inviteCancel?.status !== undefined) {
            dispatch(settingsActions.resetMemberCancelInvite());
            getTeamMembers();
        }
        // eslint-disable-next-line
    }, [inviteCancel]);

    useEffect(() => {
        if (mfaEnforce?.success !== undefined || mfaEnforce?.status !== undefined) {
            dispatch(settingsActions.resetMemberEnforce2FA());
            getTeamMembers();
        }
        // eslint-disable-next-line
    }, [mfaEnforce]);

    return (
        <div>
            <DynamicHeadTag
                headerText="Team"
            />

            <MemberForm
                isOpen={isOpenMemberForm.isOpen}
                setIsOpen={(e: boolean) => setIsOpenMemberForm({ ...isOpenMemberForm, isOpen: e })}
                data={isOpenMemberForm}
                getTeamMembers={getTeamMembers}
            />

            <MemberDelete
                isOpen={isOpenMemberDelete.isOpen}
                setIsOpen={(e: boolean) => setIsOpenMemberDelete({ ...isOpenMemberDelete, isOpen: e })}
                data={isOpenMemberDelete}
                getTeamMembers={getTeamMembers}
            />

            <Permission
                isOpen={isOpenPermission.isOpen}
                setIsOpen={(e: boolean) => setIsOpenPermission({ ...isOpenPermission, isOpen: e })}
            />

            <div className="sm:flex md:block lg:flex sm:justify-between sm:items-center pb-6 mb-6 sm:mb-12 border-b border-gray-100">
                <div className="page-title">
                    Team
                </div>
                <div className="mt-1 sm:mt-0 md:mt-1 lg:mt-0">
                    <button type="button" onClick={() => addMember()} className="btn btn-md-lg btn-primary">
                        Add a Member
                    </button>
                </div>
            </div>

            <div>
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <>
                        {/* <Dropdown dropdownClass="w-64">
                            <button type="button" className="btn btn-md btn-white">
                                Filter by
                                <FontAwesomeIcon icon="angle-down" className="ml-2" />
                            </button>
                            <div className="p-3">
                                <SelectInput label="2FA Status" required={true} onChange={(e: any) => navigate(urlQuery("authStatus=" + e.target.value))}
                                    selectClass="!text-sm !bg-transparent !text-white !form-input-md !border !border-gray-200 !pt-2" containerClass="!mb-3">
                                    <option value="">Show all</option>
                                    <option value="ON">ON</option>
                                    <option value="OFF">OFF</option>
                                </SelectInput>
                                <SelectInput label="Team Role" required={true} onChange={(e: any) => navigate(urlQuery("role=" + e.target.value))}
                                    selectClass="!text-sm !bg-transparent !text-white !form-input-md !border !border-gray-200 !pt-2" containerClass="!mb-0">
                                    <option value="">Show all</option>
                                    {roles?.data && Object.keys(roles?.data)?.map((key: string) =>
                                        <option value={key} key={key}>{roles?.data[key]}</option>
                                    )}
                                </SelectInput>
                            </div>
                        </Dropdown> */}
                        <button type="button" className="btn btn-md btn-white" onClick={() => togglePermission()}>
                        <IconKey  className="w-[18px] h-[18px] mr-2"/>
                            Manage roles
                        </button>

                    </>
                    <div className="flex items-center">
                        {/* 
                        <div className="mr-4">
                            <Pagination />
                        </div>
                        */}
                        <Search
                            placeholder="Search Team"
                            searchSubmit={searchSubmit}
                        />
                    </div>
                </div>

                <div className="mt-6">
                    <Table >
                        <Table.Head>
                            <th>Account Name</th>
                            <th>Team Role</th>
                            <th>2FA Status</th>
                            <th>Date Joined</th>
                            <th></th>
                        </Table.Head>
                        <Table.Body >

                            <TableContent
                                loading={teamMembersLoading}
                                data={teamMembers?.data}
                                total={teamMembers?.data?.length}
                                colspan={5}
                                loadingText="Loading team members"
                                emptyUI=""
                                
                            />

                            {teamMembersLoading === false && teamMembers?.data?.map((member: any) =>
                                <tr key={member?.id}>
                                    <td>
                                        <div className="flex items-center">
                                            <div className="w-9 h-9 mr-4 bg-gray-200 rounded-md">
                                                <IconUserProfile className="w-full h-full" />
                                            </div>
                                            <div>
                                                <div className="font-medium flex items-center">
                                                    <div>
                                                        {member?.firstName ?? "-"} {member?.lastName}
                                                    </div>
                                                    <Maybe condition={member?.email === userInfo?.userProfile?.email}>
                                                        <div className="ml-2 px-1.5 text-[0.625rem] border-[1px] border-[#E2E3E5] rounded-lg">
                                                            YOU
                                                        </div>
                                                    </Maybe>
                                                </div>
                                                <div className="text-sm text-shiga-gray">
                                                    {member?.email ?? "-"}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {memberRoles[member?.role] ?? "-"}
                                    </td>
                                    <td>
                                        <div className={"label-border " + (member?.authStatus === "ON" ? "!text-[#00C46C] !border-[#00C46C]" : "!text-[#99999C]")}>
                                            {member?.authStatus}
                                        </div>
                                    </td>
                                    <td>
                                        {DateFormat(member?.joined) ?? "-"}
                                    </td>
                                    <td>
                                        <Dropdown dropdownClass="dropdown-right">
                                            <></>
                                            <div>
                                                <Menu.Item as="div" onClick={() => editMember(member)} className="dropdown-item dropdown-item-icon">
                                                    <IconRole />
                                                    Change Role
                                                </Menu.Item>
                                                <Maybe condition={member?.authStatus === "OFF"}>
                                                    <Menu.Item as="div" onClick={() => enforce2FA(member)} className="dropdown-item dropdown-item-icon">
                                                        <IconSecurity />
                                                        Enforce 2FA
                                                    </Menu.Item>
                                                </Maybe>
                                                {/* <Maybe condition={member?.email !== userInfo?.userProfile?.email}> */}
                                                <Menu.Item as="div" onClick={() => deleteMember(member)} className="dropdown-item dropdown-item-icon text-shiga-warn">
                                                    <IconDelete />
                                                    Delete Member
                                                </Menu.Item>
                                                {/* </Maybe> */}
                                            </div>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )}

                            {/* 
                            <Maybe condition={teamMembersInvitedLoading === true}>
                                <TableContent
                                    loading={teamMembersLoading === false && teamMembersInvitedLoading === true}
                                    data={teamMembersInvited}
                                    total={teamMembersInvited?.data?.length}
                                    colspan={5}
                                    loadingText="Loading invited team members"
                                />
                            </Maybe>
                             */}

                            {teamMembersInvitedLoading === false && teamMembersInvited?.data?.map((member: any) =>
                                <tr key={member?.id}>
                                    <td className="opacity-40">
                                        <div className="flex items-center">
                                            <div className="w-9 h-9 mr-4 bg-gray-200 rounded-md">
                                                <IconUserProfile className="w-full h-full" />
                                            </div>
                                            <div>
                                                <div className="font-medium flex items-center">
                                                    <div>
                                                        {member?.email ?? "-"}
                                                    </div>
                                                </div>
                                                <div className="hidden text-sm text-shiga-gray">
                                                    {member?.email ?? "-"}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="opacity-40">
                                        {memberRoles[member?.role] ?? "-"}
                                    </td>
                                    <td className="opacity-40">
                                        <div className="label-border !text-[#99999C]">
                                            OFF
                                        </div>
                                    </td>
                                    <td className="opacity-40">
                                        --.--
                                    </td>
                                    <td>
                                        <Dropdown dropdownClass="dropdown-right">
                                            <></>
                                            <div>
                                                <Menu.Item as="div" onClick={() => resendInvite(member)} className="dropdown-item dropdown-item-icon">
                                                    <IconMail />
                                                    Resend Invite
                                                </Menu.Item>
                                                <Menu.Item as="div" onClick={() => cancenInvite(member)} className="dropdown-item dropdown-item-icon text-shiga-warn">
                                                    <IconCancelInvite />
                                                    Cancel Invite
                                                </Menu.Item>
                                            </div>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )}

                        </Table.Body>
                    </Table>
                </div>
            </div>
        </div>
    )
}

const Permission = (props: any) => {
    const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false)
    const { isOpen, setIsOpen } = props;

    return (
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen} size="lg" display="center">
            <PermissionsModal isOpen={isOpen}
             onClose={setIsOpen}
             />
        </FormDrawer>
    )
}

const MemberForm = (props: any) => {
    const dispatch = useDispatch();
    const { teamMemberFormLoading, teamMemberForm, rolesLoading, roles } = useSelector((s: any) => s.entities.settings);
    const { isOpen, setIsOpen, getTeamMembers, data } = props;
    const { type, member } = data;


    const [form, setForm] = useState({
        email: "",
        role: "",
    })

    const getRoles = () => {
        dispatch(settingsActions.getRoles());
    }

    const populateForm = () => {
        if (type === "edit") {
            setForm({ email: member?.email, role: member?.role })
        }
        else {
            setForm({ email: "", role: "" })
        }
    }


    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        const errors: any = validate(form);
        if (errors) {
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else {
            if (type === "add") {
                dispatch(settingsActions.addTeamMember(form));
            }
            else if (type === "edit") {
                dispatch(settingsActions.editTeamMember(member?.id, form.role));
            }
        }
    }

    useEffect(() => {
        if (isOpen === true) {
            getRoles();
            populateForm();
        }
        // eslint-disable-next-line
    }, [isOpen])

    useEffect(() => {
        if (teamMemberForm?.success === true) {
            if (type === "add") {
                dispatch(settingsActions.resetAddTeamMember());
            }
            else if (type === "edit") {
                dispatch(settingsActions.resetEditTeamMember());
            }
            setForm({ email: "", role: "" })
            setIsOpen(false);
            getTeamMembers();
        }
        // eslint-disable-next-line
    }, [teamMemberForm])


    return (
        <>
            <FormDrawer display="center" size="lg" isOpen={isOpen} setIsOpen={setIsOpen}>
                <form onSubmit={submitForm}>
                    <div className="p-8 pb-6">
                        <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                            <div className="text-2xl font-ojah font-medium">
                                <Maybe condition={type === "add"}>
                                    Add a Team Member
                                </Maybe>
                                <Maybe condition={type === "edit"}>
                                    Edit Team Member
                                </Maybe>

                            </div>

                            <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                                <IconClose className="m-auto text-white svg-stroke-gray" />
                            </div>
                        </div>

                        <div className="mt-10 flex items-center space-x-8">
                            <div className="w-44 h-44 rounded-2xl overflow-hidden">
                                <IconUserProfile className="w-full h-full" />
                            </div>
                            <div>
                                <Input
                                    type="email"
                                    label="Email Address"
                                    placeholder="Email Address"
                                    value={form.email}
                                    onChange={(e: any) => setForm({ ...form, email: e.target.value })}
                                    required={true}
                                />

                                <Maybe condition={rolesLoading === true}>
                                    <Input
                                        type="text"
                                        label="Team Role"
                                        value="Loading roles..."
                                        required={true}
                                        disabled={true}
                                    />
                                </Maybe>
                                <Maybe condition={rolesLoading === false}>
                                    <SelectInput label="Team Role" required={true} value={form.role} onChange={(e: any) => setForm({ ...form, role: e.target.value })} containerClass="!mb-0">
                                        <option value="" disabled>Select a role</option>
                                        {roles?.data && Object.keys(roles?.data)?.map((key: string) =>
                                            <option value={key} key={key}>{roles?.data[key]}</option>
                                        )}
                                    </SelectInput>
                                </Maybe>
                            </div>
                        </div>
                    </div>

                    <div className="border-t border-gray-100 pt-6 p-8">
                        <ButtonLoader loading={teamMemberFormLoading} type="submit" className="btn btn-lg btn-block btn-shiga-black">
                            <Maybe condition={type === "add"}>
                                Send Invite
                            </Maybe>
                            <Maybe condition={type === "edit"}>
                                Save Changes
                            </Maybe>
                        </ButtonLoader>
                    </div>
                </form>
            </FormDrawer>
        </>

    )
}






const MemberDelete = (props: any) => {
    const dispatch = useDispatch();
    const { isOpen, setIsOpen, getTeamMembers, data } = props;
    const { member } = data;

    const { memberDeletedLoading, memberDeleted } = useSelector((s: any) => s.entities.settings);

    const submitForm = async (e: any) => {
        e.preventDefault();
        dispatch(settingsActions.deleteMember(member?.id));
    }

    useEffect(() => {
        if (memberDeleted?.success !== undefined || memberDeleted?.status !== undefined) {
            dispatch(settingsActions.resetDeleteMember());
            getTeamMembers();
            setIsOpen(false);
        }
        // eslint-disable-next-line
    }, [memberDeleted]);

    return (
        <FormDrawer display="center" size="lg" isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={submitForm}>
                <div className="p-8 pb-6">
                    <div className="pb-6 flex items-center justify-between border-b border-gray-100">
                        <div className="text-2xl font-ojah font-medium">
                            Remove Team Member
                        </div>
                        <div onClick={() => setIsOpen(false)} className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                            <IconClose className="m-auto text-white svg-stroke-gray" />
                        </div>
                    </div>

                    <div className="mt-10 mx-4 flex items-center space-x-4">
                        <div className="w-32 h-32 rounded-2xl overflow-hidden">
                            <IconUserProfile className="w-full h-full" />
                        </div>
                        <div>
                            <div className="font-medium">
                                {member?.firstName ?? "-"} {member?.lastName}
                            </div>
                            <div className="mt-2 flex text-[#99999C]">
                                <IconMailSendEnvelope className="w-5 mt-1 mr-3" />
                                {member?.email ?? '-'}
                            </div>
                            <div className="flex">
                                <IconRole className="w-5 mt-0.5 mr-3 opacity-30" />
                                <div>
                                    <span className="text-shiga-gray mr-2">Role</span>
                                    <span className="text-[#99999C]">{memberRoles[member?.role] ?? "-"}</span>
                                </div>
                            </div>
                            <div className="flex">
                                <IconCalendar className="w-5 mt-0.5 mr-3" />
                                <div>
                                    <span className="text-shiga-gray mr-2">Added</span>
                                    <span className="text-[#99999C]">{DateTimeFormat(member?.joined) ?? "-"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-100 pt-6 p-8">
                    <ButtonLoader loading={memberDeletedLoading} type="submit" className="btn btn-lg btn-block btn-shiga-warn">
                        Yes, Remove
                    </ButtonLoader>
                </div>
            </form>
        </FormDrawer>


    )
}


export default Team;