import { Transaction } from 'types/transaction';
import { ReactComponent as TrxAvatar } from "assets/images/icons/trx-avatar.svg";
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';


type Props = {
   transaction: Transaction;
   isGiftCard: boolean;
}

const BillPaymentDetails = ({ transaction, isGiftCard }: Props) => {
   const { copyItem, showValue } = useToolkit()

   const initiator = `${transaction?.initiatedByAccount?.firstName || ''} ${transaction?.initiatedByAccount?.lastName || ''}`

   return (
      <div className="w-full">

         <div className="w-full flex flex-col">

            <div className={`transaction-detail-inline-wrapper ${isGiftCard ? 'order-2' : 'order-1'}`}>
               <div className="transaction-detail-inline-key">
                  Initiated by asdfasdf
               </div>

               <div className="flex items-center">
                  <TrxAvatar />

                  <div className="transaction-detail-inline-value capitalize ml-1.5">
                     {initiator}
                  </div>
               </div>
            </div>

            <div className={`transaction-detail-inline-wrapper ${isGiftCard ? 'order-1' : 'order-2'}`}>
               <div className="transaction-detail-inline-key">
                  Status
               </div>

               <div className="transaction-detail-inline-value capitalize">
                  {transaction?.status?.toLowerCase()}
               </div>
            </div>

         </div>

         <div className='transaction-detail-inline-wrapper'>
            <div className="transaction-detail-inline-key">
               Sent to
            </div>

            <div className="transaction-detail-inline-value capitalize">
               {showValue(isGiftCard ? transaction?.billPayment?.email : transaction?.billPayment?.cardNumber)}
            </div>
         </div>

         <div className='transaction-detail-inline-wrapper-last'>
            <div className="transaction-detail-inline-key">
               Transaction ID
            </div>

            <div className="transaction-detail-inline-value">
               <div className='flex items-center font-medium'>
                  {transaction.reference?.substring(0, 15)}
                  <button className='ml-1.5' onClick={() => copyItem(transaction?.reference, 'Transaction reference')}>
                     <CopyIcon width={20} />
                  </button>
               </div>
            </div>
         </div>

      </div>
   )
}

export default BillPaymentDetails