
export const UPDATE_BUSINESS_VERIFICATION = "UPDATE_BUSINESS_VERIFICATION";
export const UPDATE_BUSINESS_VERIFICATION_START = "UPDATE_BUSINESS_VERIFICATION_START";
export const UPDATE_BUSINESS_VERIFICATION_FAILED = "UPDATE_BUSINESS_VERIFICATION_FAILED";
export const UPDATE_BUSINESS_VERIFICATION_RESET = "UPDATE_BUSINESS_VERIFICATION_RESET";

export const ADD_DIRECTOR = "ADD_DIRECTOR";
export const ADD_DIRECTOR_START = "ADD_DIRECTOR_START";
export const ADD_DIRECTOR_FAILED = "ADD_DIRECTOR_FAILED";
export const ADD_DIRECTOR_RESET = "ADD_DIRECTOR_RESET";

export const DOWNLOAD_SAMPLE_DOCUMENTS = "DOWNLOAD_SAMPLE_DOCUMENTS";
export const DOWNLOAD_SAMPLE_DOCUMENTS_START = "DOWNLOAD_SAMPLE_DOCUMENTS_START";
export const DOWNLOAD_SAMPLE_DOCUMENTS_FAILED = "DOWNLOAD_SAMPLE_DOCUMENTS_FAILED";
