import SavedBeneficiaryItem from "components/transfer/SavedBeneficiaryItem";
import { Input } from "components/common/form";
import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { getTransferBeneficiaryList, updateFromTransferFromBeneficaiary, updateSelectedWaletForTransfer } from "store/transfer/action";
import { useSelector } from "react-redux";
import { ReactComponent as IconSearch } from "assets/images/icons/Search.svg";
import { getCurrencies } from "store/ui/currencies/action";
import { useNavigate } from "react-router-dom";
import PATHS from "NavigationRoute";
import userIcon from "assets/images/icons/user_empty.svg"



interface SavedBeneficiaryListProps {
    onClick: () => void;
    className: string
}

const SavedBeneficiaryList: React.FC<SavedBeneficiaryListProps> = ({ onClick, className }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { beneficiaryList, beneficairyListLoading, currency, formFields } = useSelector((allStates: any) => allStates.transfer)
    const { currenciesList } = useSelector((allStates: any) => allStates.ui.currencies)
    const { walletList } = useSelector((allState: any) => allState.ui.dashboard)




    useEffect(() => {
        dispatch(getTransferBeneficiaryList())
        dispatch(getCurrencies())
    }, [])
    const [data, setData] = useState([]);
    const [canGoNext, setCanGoNext] = useState(false)

    useEffect(() => {
        if (canGoNext && formFields.accountNumber.length > 0 && currency.length > 0) {
            navigate(PATHS.TRANSFER_AMOUNT)
        }
    }, [canGoNext, currency, formFields.accountNumber])

    useEffect(() => {
        setData(!beneficairyListLoading && Object.keys(beneficiaryList).length > 0 ? beneficiaryList.data : [])
    }, [beneficiaryList, beneficairyListLoading])


    const handleSearchSubmit = (value: string) => {
        if (!beneficairyListLoading) {
            setData(beneficiaryList.data.filter((item: any) => item.accountName.toLowerCase().includes(value.toLowerCase())
                || item.accountNo.toLowerCase().includes(value.toLowerCase())
                || item.currency.toLowerCase().includes(value.toLowerCase())
            ))
        }
    };

    return (
        <div className={`space-y-8 max-w-xl w-full mt-16 ${className}`}>
            {!beneficairyListLoading && Object.keys(beneficiaryList).length > 0 && beneficiaryList?.data?.length === 0 &&
                <div className="w-full h-64 py-9 bg-white flex-col justify-center items-center gap-1 inline-flex">
                    <div className="p-6 justify-center items-center gap-14 inline-flex">
                    </div>
                    <div className="h-9 flex-col justify-center items-center gap-2 flex">
                        <div className="self-stretch h-9 flex-col justify-center items-center gap-2.5 flex">
                            <img src={userIcon} />

                            <div className="self-stretch text-center text-neutral-400 text-base font-normal font-['Inter']">You currently have no beneficiary saved on your business account.</div>
                        </div>
                    </div>
                </div>
            }
            {!beneficairyListLoading && Object.keys(beneficiaryList).length > 0 && beneficiaryList?.data?.length > 0 && <>
                <Input
                    type="search"
                    autoComplete="false"
                    label=" "
                    placeholder="Search beneficiaries"
                    required={true}
                    onChange={(e: any) => handleSearchSubmit(e.target.value)}
                    containerClass="!mb-0"
                    leftIcon={<IconSearch />}
                    leftIconClass="px-2.5"
                />

                {data.length > 0 && data.map((item: any) =>
                    <SavedBeneficiaryItem
                        name={item.accountName}
                        image={currenciesList.data.data.find((cur: any) => cur.currency === item.currency).icon}
                        detail={`${item.currency} account ending in ${item.accountNo.slice(-4)}`}
                        onClick={() => {
                            dispatch(updateFromTransferFromBeneficaiary(item.accountNo, item.accountName, item.accountBank, item.bankCode, item.currency, item.routingNo))
                            dispatch(updateSelectedWaletForTransfer(walletList.find((curr: any) => curr.currency === item.currency)))
                            setCanGoNext(true)
                        }}
                    />)
                }
            </>}


        </div>
    );
};

export default SavedBeneficiaryList;
