import { PopoverClose } from "@radix-ui/react-popover"
import { Button } from "components/ui/button"
import { Calendar } from "components/ui/calendar"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "components/ui/form"
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover"
import { format } from "date-fns"
import { cn } from "lib/utils"
import { CalendarIcon } from "lucide-react"
import { Matcher } from "react-day-picker"
import { UseFormReturn } from "react-hook-form"

export const DateForm: React.FC<{
    field: string;
    label: string;
    handleDisable?: Matcher | Matcher[]
    form: UseFormReturn<{
        [k: string]: any
    }, any, undefined>
}> = ({ form, field, label, handleDisable }) => {

    return (
        <FormField
            control={form.control}
            name={field}
            render={({ field, fieldState }) => (
                <FormItem className="flex flex-col ">
                    <FormLabel className="flex">
                        {label}
                        {
                            <p className="text-red-500">*</p>
                        }
                    </FormLabel>
                    <Popover>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button
                                    variant={"outline"}
                                    className={cn(
                                        "pl-3 w-[200px] border-none bg-input-bg text-left font-normal",
                                        !field.value && "text-muted-foreground"
                                    )}
                                >
                                    {field.value ? (
                                        format(field.value, "PPP")
                                    ) : (
                                        <span className="text-shiga-dark">Pick a date</span>
                                    )}
                                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>
                        <PopoverClose>
                            <PopoverContent className="w-auto p-0" align="start">
                                <Calendar
                                    mode="single"
                                    selected={field.value}
                                    onSelect={field.onChange}
                                    disabled={handleDisable}
                                    initialFocus
                                />
                            </PopoverContent>
                        </PopoverClose>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    )
}

export default DateForm;