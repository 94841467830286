export const GET_SETTLEMENT_ACCOUNTS = "GET_SETTLEMENT_ACCOUNTS";
export const GET_SETTLEMENT_ACCOUNTS_START = "GET_SETTLEMENT_ACCOUNTS_START";
export const GET_SETTLEMENT_ACCOUNTS_FAILED = "GET_SETTLEMENT_ACCOUNTS_FAILED";

export const ADD_SETTLEMENT_ACCOUNTS = "ADD_SETTLEMENT_ACCOUNTS";
export const ADD_SETTLEMENT_ACCOUNTS_START = "ADD_SETTLEMENT_ACCOUNTS_START";
export const ADD_SETTLEMENT_ACCOUNTS_FAILED = "ADD_SETTLEMENT_ACCOUNTS_FAILED";
export const ADD_SETTLEMENT_ACCOUNTS_RESET = "ADD_SETTLEMENT_ACCOUNTS_RESET";

export const EDIT_SETTLEMENT_ACCOUNTS = "EDIT_SETTLEMENT_ACCOUNTS";
export const EDIT_SETTLEMENT_ACCOUNTS_START = "EDIT_SETTLEMENT_ACCOUNTS_START";
export const EDIT_SETTLEMENT_ACCOUNTS_FAILED = "EDIT_SETTLEMENT_ACCOUNTS_FAILED";
export const EDIT_SETTLEMENT_ACCOUNTS_RESET = "EDIT_SETTLEMENT_ACCOUNTS_RESET";

export const DELETE_SETTLEMENT_ACCOUNTS = "DELETE_SETTLEMENT_ACCOUNTS";
export const DELETE_SETTLEMENT_ACCOUNTS_START = "DELETE_SETTLEMENT_ACCOUNTS_START";
export const DELETE_SETTLEMENT_ACCOUNTS_FAILED = "DELETE_SETTLEMENT_ACCOUNTS_FAILED";
export const DELETE_SETTLEMENT_ACCOUNTS_RESET = "DELETE_SETTLEMENT_ACCOUNTS_RESET";

export const UPDATE_FEES_EARNINGS = "UPDATE_FEES_EARNINGS";
export const UPDATE_FEES_EARNINGS_START = "UPDATE_FEES_EARNINGS_START";
export const UPDATE_FEES_EARNINGS_FAILED = "UPDATE_FEES_EARNINGS_FAILED";
export const UPDATE_FEES_EARNINGS_RESET = "UPDATE_FEES_EARNINGS_RESET";

export const GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS";
export const GET_TEAM_MEMBERS_START = "GET_TEAM_MEMBERS_START";
export const GET_TEAM_MEMBERS_FAILED = "GET_TEAM_MEMBERS_FAILED";

export const GET_TEAM_MEMBERS_INVITED = "GET_TEAM_MEMBERS_INVITED";
export const GET_TEAM_MEMBERS_INVITED_START = "GET_TEAM_MEMBERS_INVITED_START";
export const GET_TEAM_MEMBERS_INVITED_FAILED = "GET_TEAM_MEMBERS_INVITED_FAILED";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_START = "GET_ROLES_START";
export const GET_ROLES_FAILED = "GET_ROLES_FAILED";

export const TEAM_MEMBER_FORM = "TEAM_MEMBER_FORM";
export const TEAM_MEMBER_FORM_START = "TEAM_MEMBER_FORM_START";
export const TEAM_MEMBER_FORM_FAILED = "TEAM_MEMBER_FORM_FAILED";
export const TEAM_MEMBER_FORM_RESET = "TEAM_MEMBER_FORM_RESET";

export const RESEND_INVITE = "RESEND_INVITE";
export const RESEND_INVITE_START = "RESEND_INVITE_START";
export const RESEND_INVITE_FAILED = "RESEND_INVITE_FAILED";
export const RESEND_INVITE_RESET = "RESEND_INVITE_RESET";

export const CANCEL_INVITE = "CANCEL_INVITE";
export const CANCEL_INVITE_START = "CANCEL_INVITE_START";
export const CANCEL_INVITE_FAILED = "CANCEL_INVITE_FAILED";
export const CANCEL_INVITE_RESET = "CANCEL_INVITE_RESET";

export const ENFORCE_MFA = "ENFORCE_MFA";
export const ENFORCE_MFA_START = "ENFORCE_MFA_START";
export const ENFORCE_MFA_FAILED = "ENFORCE_MFA_FAILED";
export const ENFORCE_MFA_RESET = "ENFORCE_MFA_RESET";

export const DELETE_MEMBER = "DELETE_MEMBER";
export const DELETE_MEMBER_START = "DELETE_MEMBER_START";
export const DELETE_MEMBER_FAILED = "DELETE_MEMBER_FAILED";
export const DELETE_MEMBER_RESET = "DELETE_MEMBER_RESET";

export const ACCEPT_INVITE = "ACCEPT_INVITE";
export const ACCEPT_INVITE_START = "ACCEPT_INVITE_START";
export const ACCEPT_INVITE_FAILED = "ACCEPT_INVITE_FAILED";
export const ACCEPT_INVITE_RESET = "ACCEPT_INVITE_RESET";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_START = "UPDATE_PAYMENT_METHOD_START";
export const UPDATE_PAYMENT_METHOD_FAILED = "UPDATE_PAYMENT_METHOD_FAILED";
export const UPDATE_PAYMENT_METHOD_RESET = "UPDATE_PAYMENT_METHOD_RESET";

export const UPDATE_NOTIFICATION_PREFERNCE = "UPDATE_NOTIFICATION_PREFERNCE";
export const UPDATE_NOTIFICATION_PREFERNCE_START = "UPDATE_NOTIFICATION_PREFERNCE_START";
export const UPDATE_NOTIFICATION_PREFERNCE_FAILED = "UPDATE_NOTIFICATION_PREFERNCE_FAILED";
export const UPDATE_NOTIFICATION_PREFERNCE_RESET = "UPDATE_NOTIFICATION_PREFERNCE_RESET";

export const GET_KEYS = "GET_KEYS";
export const GET_KEYS_START = "GET_KEYS_START";
export const GET_KEYS_FAILED = "GET_KEYS_FAILED";

export const UPDATE_WEBHOOKS = "UPDATE_WEBHOOKS";
export const UPDATE_WEBHOOKS_START = "UPDATE_WEBHOOKS_START";
export const UPDATE_WEBHOOKS_FAILED = "UPDATE_WEBHOOKS_FAILED";
export const UPDATE_WEBHOOKS_RESET = "UPDATE_WEBHOOKS_RESET";
