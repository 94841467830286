import FormDrawer from "components/common/form-drawer";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconGTBank } from "assets/images/brands/GTBank.svg";
import { ReactComponent as IconNigeriaFlag } from "assets/images/flags/Nigeria (NG).svg";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { ReactComponent as IconDirectDeoposit } from "assets/images/icons/direct-deposit.svg";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { CurrencyType } from "types/misc";
import NGNAddMoneyForm from "./NGNAddMoneyForm";
import BackBtnIcon from "assets/images/icons/BackBtn";

interface SuccessRateProps {
  open: boolean;
  closeModal: () => void;
  currentTab: CurrencyType;
}

export const AddMoneyOptionsModal = ({
  open,
  closeModal,
  currentTab,
}: SuccessRateProps) => {
  const navigate = useNavigate();
  const copyText = (text: string, label?: string | undefined) => () => {
    copy(text);
    toast.success("Copied: " + (label ?? text));
    closeModal();
  };

  // const goBack = ()=>{
  //   navigate("")
  // }
  return (
    <FormDrawer
      display="center"
      size="lg"
      isOpen={open}
      setIsOpen={() => closeModal()}
    >
      <div className="p-8">
        {currentTab !== "NGN" && (
          <div className="pb-6 flex items-center justify-between border-b border-gray-100">
            <div className="text-2xl font-ojah font-medium">Add Money</div>
            <div
              onClick={() => closeModal()}
              className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
            >
              <IconClose className="m-auto text-white svg-stroke-gray" />
            </div>
          </div>
        )}
        {currentTab === "NGN" ? (
          <div className="flex justify-between items-start mt-7">
            <NGNAddMoneyForm close={closeModal} />
            {/* <div className="space-y-2.5">
                <p className="text-shiga-black tracking-tight">
                  GUARANTEE TRUST BANK PLC
                </p>
                <div className="flex items-center gap-3.5">
                  <h3 className="font-ojah text-4xl">02447567382</h3>
                  <IconCopy onClick={copyText("02447567382", "Account Number")} className="size-4 cursor-pointer text-shiga-dark hover:text-shiga-black" />
                </div>
              </div>
              <div className="relative">
                <IconGTBank className="size-16" />
                <div className="absolute p-1 -top-2 -right-2 bg-white rounded-full">
                  <IconNigeriaFlag className="size-5" />
                </div>
              </div> */}
          </div>
        ) : (
          <></>
        )}

        {/* <div className="relative my-8">
          <div className="w-full h-px bg-[#F5F5F5]"></div>
          <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-3 font-medium text-[#99999C] text-[16px]">
            OR
          </p>
        </div> */}
        {currentTab === "GBP" ? (
          <div
            className="flex items-center border gap-5 p-5 rounded-2xl hover:border-shiga-black cursor-pointer transition"
            onClick={() => navigate("/dashboard/add-money")}
          >
            <IconDirectDeoposit />
            <div className="space-y-1">
              <div className="flex items-center gap-2">
                <h5 className="text-shiga-black font-semibold tracking-tight">
                  Fund with Direct Deposit
                </h5>
                <p className="text-xs text-shiga-dark border rounded-md p-0.5">
                  Instant
                </p>
              </div>
              <p className="text-shiga-dark text-sm">
                Transfer money directly from your bank account to your business
                account.
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </FormDrawer>
  );
};
