import { ReactComponent as IconUS } from "assets/images/flags/US.svg";
import Fuse from "fuse.js";
import { PageHeading } from "components/common/PageHeading";
import { Search } from "lucide-react";
import { NavLink, useSearchParams } from "react-router-dom";
import { PAYMENT_PATHS } from "../paths";
import { useGiftCardStore } from "zustand-store/payments/gift-cards";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { GiftCard as GiftCardType } from "types/giftcard";
import { Input } from "components/common/form";

export const GiftCard = () => {
  const [searchParams] = useSearchParams();
  const { getGiftCards, giftCards, payload, setPayload } = useGiftCardStore();
  const [displayGiftCard, setDisplayGiftCard] = useState<GiftCardType[]>([]);
  const active = searchParams.get("category");

  useEffect(() => {
    getGiftCards("US");
  }, [active, getGiftCards])

  const fuse = new Fuse(giftCards, {
    shouldSort: true,
    threshold: 0.2,
    // distance: 100,
    minMatchCharLength: 3,
    keys: ["productName"],
  });

  const handleSearch = (searchStr: string) => {
    const resultSearch = fuse.search(searchStr);
    setDisplayGiftCard(resultSearch.map(item => item.item))
  }


  return (
    <div className="w-full py-10 space-y-4">
      <PageHeading title="Buy Gift Card" />
      <Nav handleSearch={handleSearch} />
      <div className="flex gap-8">
        {/* <SideBar active={active} /> */}
        <div>
          <div className=" text-zinc-400 text-2xl font-normal font-ojah">
            All Giftcards
          </div>
          <div className="flex flex-wrap gap-4 overflow-auto max-h-[65vh] custom-scrollbar">
            {(displayGiftCard.length ? displayGiftCard : giftCards).map((name, index) => (
              <NavLink onClick={() => {
                setPayload({
                  ...payload,
                  note: "",
                  giftCard: giftCards[index]
                })
              }}
                to={`${PAYMENT_PATHS.GIFT_CARD_PURCHASE}?item=${name}`}
                key={index}
                className="w-[136px] h-[132px] py-2 flex-col justify-start items-start gap-2 inline-flex"
              >
                <img
                  className="w-[136px] h-[92px] rounded-lg border border-neutral-200"
                  src={giftCards[index]?.logoUrls[0]}
                  alt={giftCards[index]?.brand?.brandName}
                />
                <div className="flex-col justify-start items-start gap-2.5 flex">
                  <div className="text-zinc-800 text-[13px] font-medium font-['Inter']">
                    {giftCards[index]?.productName}
                  </div>
                </div>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const giftCardCountry = [
  {
    icon: <IconUS className="rounded-full w-full h-full" />,
    item: "US",
    disabled: false
  },
  // {
  //   icon: <IconNG className="rounded-full w-full h-full " />,
  //   item: "NG",
  //   disabled: true,
  // },
  // {
  //   icon: <IconUK className="rounded-full  w-full h-full " />,
  //   item: "UKEU",
  //   disabled: true
  // }
]

const Nav: React.FC<{
  handleSearch: (arg: string) => void;
}> = ({ handleSearch }) => {
  const [currentCountry, setCurrentCountry] = useState("UK")

  return (
    <nav className="grid grid-cols-2">
      <div className="flex gap-3">
        {giftCardCountry.map(item =>
          <>
            {
              item.disabled &&
              <Tooltip anchorSelect={`#giftcard-country-${item.item}`} place="top" className="custom-tooltip-ui">
                Coming Soon...
              </Tooltip>
            }
            <div key={item.item} id={`giftcard-country-${item.item}`} onClick={() => setCurrentCountry(item.item)}
              className={`w-8 h-8 p-0.5 cursor-pointer rounded-full
              ${currentCountry === item.item ? "border-2 border-indigo-500" : ""}
              ${item.disabled ? "opacity-70" : "opacity-100"}
              `}>
              {item.icon}
            </div>
          </>
        )}
      </div>
      <div className="flex justify-end">
        <div className="relative">
          <Input placeholder="Search giftcards..."
            leftIcon={<Search color="#B4B4B4" className="absolute left-2 top-2 " />}
            inputClass="pl-12" onChange={evt => {
              handleSearch(evt.currentTarget.value)
            }} />
        </div>
      </div>
    </nav>
  );
};

// For late usage (or not)
// const SideBar = ({ active }: { active: string }) => {
//   return (
//     <aside className="ovrflow-auto w-[170px] max-h-[50vh] flex flex-col ">
//       {categories.map((category, index) => (
//         <NavLink
//           to={`${PAYMENT_PATHS.GIFT_CARD}?category=${category}`}
//           key={index}
//           className={` py-2 w-full whitespace-nowrap   px-10 flex-col border-l-[3px] transition-all ${active === category
//             ? "text-zinc-800 border-zinc-800"
//             : "text-neutral-400"
//             } text-sm font-medium`}
//         >
//           {category}
//         </NavLink>
//       ))}
//     </aside>
//   );
// };
