import { findAllInvoice } from "lib/payments/invoices";
import { Invoice, InvoiceItem, defaultInvoice } from "types/payment/invoice";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export const initialState = {
  invoice: defaultInvoice as Invoice,
  createdInvoice: [] as Invoice[],
  completed: [] as string[],
  editIndex: 0,
  invoices: [] as Invoice[],
};
interface ActionsState {
  setInvoice: (invoice: Invoice) => void;
  setCreatedInvoice: (invoice: Invoice[]) => void;
  setInvoiceItem: (item: InvoiceItem, index: number) => void;
  setInvoiceItems: (items: InvoiceItem[]) => void;
  setInvoiceItemsFromInvoice: (invoice: Invoice) => void;
  setEditIndex: (index: number) => void;
  setInvoices: (invoices: Invoice[]) => void;
  resetInvoice: () => void;
  addItem: (item: InvoiceItem) => void;
  editAdvanceFields: (
    field: keyof Invoice["advancedCustomer"],
    value: number
  ) => void;
  removeItem: (index: number) => void;
  setCompleted: (slug: string) => void;
  fetchInvoices: () => Promise<void>;
  updateInvoice: (arg: Invoice, action?: string) => void
}
export const useInvoiceStore = create(persist<typeof initialState & ActionsState>((
  (set, get) => ({
    ...initialState,
    setInvoice: (invoice) => set({ invoice }),
    setInvoiceItem: (item, index) =>
      set((state) => {
        state.invoice.items[index] = item;
        return { invoice: { ...state.invoice } };
      }),
    setInvoiceItems: (items) =>
      set((state) => ({ invoice: { ...state.invoice, items } })),
    setInvoiceItemsFromInvoice: (invoice) => set((state) => ({ invoice })),

    setEditIndex: (index) => set({ editIndex: index }),
    setInvoices: (invoices) => set({ invoices }),
    resetInvoice: () => set({ ...initialState }),
    addItem: (item) =>
      set((state) => ({
        invoice: { ...state.invoice, items: [...state.invoice.items, item] },
      })),
    editAdvanceFields: (field, value) =>
      set((state) => ({
        invoice: {
          ...state.invoice,
          advancedCustomer: {
            ...state.invoice.advancedCustomer,
            [field]: value,
          },
        },
      })),
    removeItem: (index) =>
      set((state) => {
        state.invoice.items.splice(index, 1);
        return { invoice: { ...state.invoice } };
      }),
    setCompleted: (slug) =>
      set((state) => ({ completed: [...state.completed, slug] })),
    fetchInvoices: async () => {
      const { data } = await findAllInvoice();
      const date = new Date()
      set({ createdInvoice: data.payload.map(item => ({
        ...item,
        status: date.getTime() > (new Date(item.endDate)).getTime() && item.status !== "PAID" ? "DUE" : item.status
      })) });
    },
    setCreatedInvoice: (invoices) => {
      set({ createdInvoice: invoices })
    },
    updateInvoice: (arg: Invoice, action?: string) => {
      const {createdInvoice} = get()
      const newInvoices = [...createdInvoice]
      const foundInvoiceIDX = createdInvoice.findIndex(item => item.id === arg.id);
      if (foundInvoiceIDX > -1) {
        if (action === "delete") {
          newInvoices.splice(foundInvoiceIDX, 1);
        } else {
          newInvoices.splice(foundInvoiceIDX, 1, arg);
        }
        set({createdInvoice: newInvoices})
      }
    }
  })
), {
  name: "invoice-storage",
  storage: createJSONStorage(() => sessionStorage)
}))
