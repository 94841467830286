import { Menu } from "@headlessui/react";
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Tooltip, CartesianGrid } from "recharts";
import Maybe from "components/common/Maybe";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroupSelector } from "components/common/form";
import Dropdown from "utils/dropdown";
import { ContentType } from "recharts/types/component/Tooltip";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { ReactComponent as IconNoChart } from "assets/images/icons/nochart.svg";
import { shigaPurpleAccent } from "utils/colorsConst";
import { ExportOption } from "../interface";
import { Period } from "types/overview";
import { currencyFormat } from "lib/format.amount";
import { useMemo } from "react";

const NoChart = () => {
    return (
        <div className="absolute text-center flex items-center justify-center flex-col space-y-6"
            style={{
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'none',
                textAlign: 'center',
            }}
        >
            <IconNoChart />
            <span className="text-sm text-shiga-dark">There is currently no graph data to
                <br />display for your account at this time </span>
        </div>
    );
};

export const DashboardChart: React.FC<{
    exportOptions: ExportOption[];
    currentTab: string;
    data: Period[]
    updateExportOptions: (e: any) => void;
    updateTransactionsRange: (e: any) => void
    transactionsRange: ExportOption<string>[];
}> = ({ exportOptions, updateExportOptions, data, transactionsRange, updateTransactionsRange, currentTab }) => {
    const CustomTooltip: ContentType<ValueType, NameType> = (props) => {
        const foundValue = props.payload?.length ? props.payload[0].payload : { period: "", amount: 0 };
        return (
            <div className="bg-shiga-black rounded-xl p-2.5">
                <p className="text-shiga-dark">{foundValue.period}</p>
                <p className="text-white">{currencyFormat(foundValue.amount, currentTab)}</p>
            </div>
        )
    }

    const renderCustomizedLabel = (props: any) => {
        const { x, y } = props;

        return (
            <svg x={x} y={y - 2} >
                <rect width={46} rx={1.5} ry={1.5} height={4} fill="#5540EB" />
            </svg>
        );
    };

    const availableData = useMemo(() => !!data.find(item => item.amount > 0), [data]);


    return (
        <>
            <div className="pt-8 mt-8 flex items-center justify-between border-t border-gray-100">
                <div className="w-56 p-1 bg-gray-50 rounded-full">
                    <ButtonGroupSelector
                        selectOptions={exportOptions}
                        onValueChange={(e: any) => updateExportOptions(e)}
                        design="slide-tab-toggle"
                    />
                </div>
                <div>
                    <Dropdown dropdownClass="min-w-[150px] dropdown-right">
                        <div className="w-full form-input !h-10 !text-sm flex items-center justify-between !bg-white !border !border-gray-200 !rounded-xl">
                            {
                                transactionsRange.filter((e: any) => e.selected === true)[0]
                                    ?.label
                            }
                            <FontAwesomeIcon
                                icon="angle-down"
                                className="ml-2 text-shiga-dark"
                            />
                        </div>
                        <div>
                            {transactionsRange.map((range) => (
                                <Menu.Item key={range.value}>
                                    <div
                                        onClick={() => updateTransactionsRange(range)}
                                        className="dropdown-item flex items-center justify-between"
                                    >
                                        <div className="whitespace-nowrap">{range.label}</div>
                                        <div className="w-7 text-right flex-shrink-0">
                                            <Maybe condition={range.selected === true}>
                                                <FontAwesomeIcon icon="check-circle" />
                                            </Maybe>
                                        </div>
                                    </div>
                                </Menu.Item>
                            ))}
                        </div>
                    </Dropdown>
                </div>
            </div>
            <div className="relative">
                <ResponsiveContainer width="100%" height={391}>
                    <BarChart data={data}
                        margin={{ top: 24, right: 0, left: 45, bottom: 20 }}

                    >
                        {
                            availableData ?
                                <>
                                    <CartesianGrid vertical={false} strokeWidth={4} stroke="#F5F5F5" />
                                    <YAxis tickLine={false} axisLine={false} tickFormatter={(props) => currencyFormat(props, currentTab, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })} />
                                </>
                                : <></>
                        }
                        <XAxis tickLine={false}
                            axisLine={{
                                stroke: "#F5F5F5",
                                strokeWidth: 2,
                            }}
                            dataKey="period" color="#99999C" />
                        {
                            availableData ?
                                <>
                                    <Bar dataKey="amount" barSize={46} fill={shigaPurpleAccent}
                                        label={renderCustomizedLabel} activeBar={false}
                                    />
                                    <Tooltip content={CustomTooltip} />
                                </>
                                : <></>
                        }
                    </BarChart>
                </ResponsiveContainer>
                {
                    !availableData ?
                        <NoChart /> : <></>
                }
            </div>
        </>
    )
}