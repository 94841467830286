import { useState } from "react";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import Maybe from "components/common/Maybe";
import getFileSize from "utils/getFileSize";
import validate from "utils/validate";
import { ReactComponent as IconFileUpload } from "assets/images/icons/file-upload.svg";
import { ReactComponent as IconTimes } from "assets/images/icons/project-icons/times.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type FileType = "jpg" |
  "jpeg" |
  "png" |
  "gif" |
  "pdf" |
  "doc" |
  "docx" |
  "xls" |
  "xlsx" |
  "csv"
export const FileUploadForm: React.FC<{
  label: string;
  required?: boolean;
  sampleFile?: string;
  data?: any;
  onchange: (arg: any) => void;
  filetype: FileType[];
  maxFileSize?: number;
  filename: string;
  validationName?: string;
  validationGenerics?: string[];
  checkFormValidation: (data?: any, appendKey?: any, appendValue?: any, index?: any) => void;
}> = (props) => {
  const {
    label,
    required,
    sampleFile,
    data,
    onchange,
    filetype,
    maxFileSize,
    filename,
    validationName = "",
    validationGenerics,
    checkFormValidation,
  } = props;
  const types = filetype ?? [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "pdf",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "csv",
  ];
  const maxSize = maxFileSize ?? 5;
  const [errors, setErrors] = useState<{
    [k:string]: any
  }>({});

  const doValidation = (e:any) => {
    if (validationName !== undefined) {
      const data = { [validationName]: e };
      const vErrors = validate(data);
      if (vErrors) {
        setErrors(vErrors);
      } else {
        setErrors({});
      }
      checkFormValidation(undefined, validationName, e);
    }
  };

  const displayError = (errorMessage: any) => {
    const errorObject = {
      "File type is not supported":
        "File format is not supported. Acceptable formats: " +
        filetype.join(", "),
      "File size is too big": "File size is more than " + maxSize + "mb",
    } as {[k:string]: string};
    setErrors({ [validationName]: errorObject[errorMessage] ?? errorMessage });
  };

  return (
    <div>
      <div className="mb-2 flex items-center justify-between text-shiga-gray">
        <div>
          {label}
          <Maybe condition={required === true}>
            <span className="form-input-required">*</span>
          </Maybe>
        </div>
        <Maybe condition={sampleFile !== undefined}>
          <a
            href={sampleFile}
            target="_blank"
            rel="noreferrer"
            download
            className="text-shiga-purple font-medium border-b-2 border-transparent hover:border-shiga-purple cursor-pointer"
          >
            See sample file
          </a>
        </Maybe>
      </div>

      <Maybe condition={data === null || data === ""}>
        <FileUploader
          handleChange={(e: any) => {
            onchange(e);
            doValidation(e);
          }}
          multiple={false}
          types={types}
          maxSize={maxSize}
          onTypeError={(err: any) => displayError(err)}
          onSizeError={(err: any) => displayError(err)}
        >
          <div className="h-40 box flex flex-col items-center justify-center border border-gray-100 cursor-pointer rounded-2xl">
            <div className="w-14 h-14 flex">
              <IconFileUpload className="m-auto w-full h-full" />
            </div>
            <div className="mt-4 text-[#B4B4B4]">
              Drag and drop files or click here to upload
            </div>
            {/*
                        <div className="text-fade text-sm">
                            (Formats: {filetype.join(", ")}. Maximum size: {maxSize}mb)
                        </div>
                         */}
          </div>
        </FileUploader>
      </Maybe>

      <Maybe condition={data !== null && data !== ""}>
        <Maybe
          condition={
            typeof data === "object" && data?.lastModified !== undefined
          }
        >
          <div className="p-4 flex items-center justify-between space-x-8 box border border-gray-100 rounded-2xl overflow-hidden">
            <div className="flex items-center space-x-4 ellipsis-2-lines">
              <div className="w-10 h-12 flex flex-shrink-0 bg-[#D9D9D9] border border-[#D9D9D9] rounded-md overflow-hidden"></div>
              <div>
                <div className="w-full ellipsis">{data?.name}</div>
                <div className="mt-0.5 text-shiga-gray text-sm">
                  {getFileSize(data?.size)}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end">
              <div
                onClick={() => {
                  onchange(null);
                  doValidation(null);
                }}
                className="w-6 h-6 rounded flex group hover:bg-red-500 cursor-pointer"
              >
                <IconTimes className="w-3 h-3 m-auto group-hover:svg-fill-white" />
              </div>
            </div>
          </div>
        </Maybe>

        <Maybe
          condition={
            typeof data === "string"
            //  && data?.lastModified === undefined
          }
        >
          <div className="p-4 flex items-center justify-between space-x-8 box border border-gray-100 rounded-2xl overflow-hidden">
            <div className="flex items-center space-x-4 ellipsis-2-lines">
              <div className="w-10 h-12 flex flex-shrink-0 items-center justify-center bg-[#D9D9D9] border border-[#D9D9D9] rounded-md overflow-hidden">
                {/* <IconFile /> */}
                <FontAwesomeIcon icon="file" className="text-2xl text-white" />
              </div>
              <Link
                to={data}
                target="_blank"
                className="text-ep-primary ellipsis hover:underline"
              >
                {filename ?? data}
              </Link>
            </div>
            <div className="flex-shrink-0 flex items-center justify-end space-x-2">
              <Link
                to={data}
                target="_blank"
                className="text-ep-primary hover:underline"
              >
                View file
              </Link>
              <div
                onClick={() => {
                  onchange(null);
                  doValidation(null);
                }}
                className="w-6 h-6 rounded flex hover:bg-red-500 group cursor-pointer"
              >
                <IconTimes className="w-3 h-3 m-auto group-hover:svg-fill-white" />
              </div>
            </div>
          </div>
        </Maybe>
      </Maybe>

      <Maybe condition={errors[validationName] !== undefined}>
        <div className="form-input-message mt-2 text-right">
          <Maybe
            condition={validationGenerics?.includes(validationName) === true}
          >
            {filename?.toLowerCase() ?? ""}&nbsp;
          </Maybe>
          <FontAwesomeIcon icon="exclamation-circle" className="mr-2" />
          {errors[validationName]}
        </div>
      </Maybe>

      <Maybe condition={errors[validationName] === undefined}>
        <div className="text-[#B4B4B4] mt-2 text-right text-[13px]">
          Upload only {filetype.join(" or ")} files. {maxSize}MB and below in
          size
        </div>
      </Maybe>
    </div>
  );
};

export default FileUploadForm;
