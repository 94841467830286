import { getLinks } from "lib/payments";
import { PaymentLink, defaultPaymentLink } from "types/payment/payments.interface";
import { create } from "zustand";
interface PaymentLinksState {
  refetch: boolean;
  completed: string[];
  selectedType: "one-time" | "tuition" | null;
  setSelectedType: (type: "one-time" | "tuition") => void;
  setCompleted: (slug: string) => void;
  paymentLink: PaymentLink[];
  fetchPaymentLinks: () => void;
  updatePaymentLink: (arg: PaymentLink, action?: string) => void;
  currentPaymentLink: PaymentLink;
  setCurrentPaymentLink: (arg: PaymentLink) => void;
}

export const usePaymentLinkStore = create<PaymentLinksState>()((set, get) => ({
  refetch: false,
  paymentLink: [],
  selectedType: "one-time",
  setSelectedType: (type) => set({ selectedType: type }),
  completed: ["one"],
  setCompleted: (slug) =>
    set((state) => ({ completed: [...state.completed, slug] })),
  updatePaymentLink: (arg: PaymentLink, action?: string) => {
    const {paymentLink} = get()
    const newPaymentLinks = [...paymentLink]
    const foundPaymentLink = paymentLink.findIndex(item => item.id === arg.id);
    if (foundPaymentLink > -1) {
      if (action === "delete") {
        newPaymentLinks.splice(foundPaymentLink, 1);
      } else {
        newPaymentLinks.splice(foundPaymentLink, 1, arg);
      }
      set({paymentLink: newPaymentLinks})
    }
  },
  fetchPaymentLinks: async () => {
    const { data } = await getLinks();
    set({ paymentLink: data.payload.map(item => ({
      ...item,
    })) });
  },
  currentPaymentLink: defaultPaymentLink,
  setCurrentPaymentLink: (arg:PaymentLink) => {
    set({currentPaymentLink: arg})
  }
}));
