
export const SHOW_IDLE_TIMER = "SHOW_IDLE_TIMER";
export const HIDE_IDLE_TIMER = "HIDE_IDLE_TIMER";

export const GET_REFRESH_TOKEN_START = "GET_REFRESH_TOKEN_START";
export const GET_REFRESH_TOKEN_DONE = "GET_REFRESH_TOKEN_DONE";
export const GET_REFRESH_TOKEN_FAILED = "GET_REFRESH_TOKEN_FAILED";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_START = "LOGIN_USER_START";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const SAVE_LOGIN_USER_FORM = "SAVE_LOGIN_USER_FORM";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED";
export const FORGOT_PASSWORD_RESET = "FORGOT_PASSWORD_RESET";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";
export const RESET_PASSWORD_RESET = "RESET_PASSWORD_RESET";

export const SEND_OTP_EMAIL = "SEND_OTP_EMAIL";
export const SEND_OTP_EMAIL_START = "SEND_OTP_EMAIL_START";
export const SEND_OTP_EMAIL_FAILED = "SEND_OTP_EMAIL_FAILED";
export const SEND_OTP_EMAIL_RESET = "SEND_OTP_EMAIL_RESET";

export const RESEND_OTP_EMAIL = "RESEND_OTP_EMAIL";
export const RESEND_OTP_EMAIL_START = "RESEND_OTP_EMAIL_START";
export const RESEND_OTP_EMAIL_FAILED = "RESEND_OTP_EMAIL_FAILED";
export const RESEND_OTP_EMAIL_RESET = "RESEND_OTP_EMAIL_RESET";

export const VERIFY_OTP_EMAIL = "VERIFY_OTP_EMAIL";
export const VERIFY_OTP_EMAIL_START = "VERIFY_OTP_EMAIL_START";
export const VERIFY_OTP_EMAIL_FAILED = "VERIFY_OTP_EMAIL_FAILED";
export const VERIFY_OTP_EMAIL_RESET = "VERIFY_OTP_EMAIL_RESET";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_START = "REGISTER_USER_START";
export const REGISTER_USER_FAILED = "REGISTER_USER_FAILED";
export const REGISTER_USER_RESET = "REGISTER_USER_RESET";

export const SAVE_REGISTER_USER_FORM = "SAVE_REGISTER_USER_FORM";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const UPDATE_LOGIN_USER = "UPDATE_LOGIN_USER";
export const LOGOUT_USER_START = "LOGOUT_USER_START";
export const LOGOUT_USER = "LOGOUT_USER";
export const RESET_LOGIN_DATA = "RESTE_LOGIN_DATA"