import { FilterFormatValues } from "components/data-table/interface";

interface Address {
  address: string;
  city: string;
  country: string;
  postCode: string;
  state: string
}
export interface Invoice extends FilterFormatValues {
  id?:string
  title: string;
  country: string;
  description: string;
  startDate: string;
  endDate: string;
  reciepient: InvoiceReciepient;
  items: InvoiceItem[];
  status: "DUE" | "PAID" | "NOT_PAID" | string;
  advancedCustomer: InvoiceAdvCustomer;
  imageUrl: string;
  invoiceNumber?: string;
  total?: number;
  createdAt?: string;
  recipientEmail?: string;
  recipientPhone?: number;
  recipientName?: string;
  currency?: string;
  billingAddress?: Address;
  discount?: number;
  vat?: number
}

export interface InvoiceReciepient {
  name: string;
  email: string;
  phone: string;
  address: {
    address: string;
    city: string;
    country: string;
    postCode: string;
    state: string
  };
  note: string;
}

export interface InvoiceItem {
  name: string;
  price: number;
  qty: number;
}

export interface InvoiceAdvCustomer {
  discount: number;
  vat: number;
}

export interface AdvancedOptionType {
  name: string;
  desc: string;
  value: number;
  key: keyof InvoiceAdvCustomer;
}

export interface BillingAddress {
  address: string;
  state: string;
  city: string;
  postCode: string;
  country: string
}


export const defaultInvoice: Invoice = {
  items: [] as InvoiceItem[],
  advancedCustomer: {
    discount: 0,
    vat: 0,
  },
  imageUrl: "",
  title: "",
  country: "",
  description: "",
  endDate: "",
  reciepient: {
    address: {
      address: "",
      city: "",
      country: "",
      postCode: "",
      state: ""
    },
    email: "",
    name: "",
    note: "",
    phone: ""
  },
  startDate: "",
  status: "NOT_PAID",
  currency: "NGN",
}