import { ColumnDef } from "@tanstack/react-table";

import { Badge } from "components/ui/badge";

import { ReactComponent as IconDelete } from "assets/images/icons/Delete.svg";
import { ReactComponent as IconMailSend } from "assets/images/icons/Mail-Send-Envelope.svg";
import { ReactComponent as IconDownload } from "assets/images/icons/Download.svg";
import { ReactComponent as IconMarkPaid } from "assets/images/icons/mark-paid.svg";
import { Invoice } from "types/payment/invoice";
import { DataTableColumnHeader } from "components/data-table/data-table-column-header";
import Dropdown from "utils/dropdown";
import { Menu } from "@headlessui/react";
import { removeInvoice, sendReminderInvoice, updatePaidInvoice } from "lib/payments/invoices";
import { toast } from "react-toastify";
import { useInvoiceStore } from "zustand-store/payments/invoice";

export const InvoiceDropdown: React.FC<{
  DropdownTrigger?: React.ReactNode;
  invoice:Invoice;
  onDelete?: () => void;
}> = ({  DropdownTrigger = <></>, onDelete, invoice }) => {
  const {updateInvoice} = useInvoiceStore();

  const handleMarkPaid = () => {
    updateInvoice({
      ...invoice,
      status: "PAID"
    })
    toast.success(`Invoice with ID ${invoice?.invoiceNumber} has been successfully marked as paid`)
    return updatePaidInvoice(invoice.id).catch(err => {
      updateInvoice(invoice);
      toast.error(`Something went wrong while marking ${invoice?.invoiceNumber} as paid`)
    })
  }

  const handleSendReminder = () => {
    toast.success(`${invoice?.recipientName} has been successfully reminded`)
    return sendReminderInvoice(invoice.id || "").catch(err => {
      toast.error(`Something went wrong: ${err.message}`)
    })
  }
  const handleDownload = () => {
    toast.success(`${invoice?.invoiceNumber} has been successfully downloaded`)
  }
  const handleDelete = () => {
    updateInvoice(invoice, "delete")
    toast.success(`${invoice?.invoiceNumber} has been successfully deleted`)
    return removeInvoice(invoice.id || "").then(() => {
      if(onDelete) onDelete();
    }).catch(err => {
      updateInvoice(invoice)
      toast.error(`Something went wrong: ${err.message}`)
    })
  }
  return (
    <Dropdown dropdownClass="dropdown-right">
      {DropdownTrigger}
      <div>
        {
          invoice.status !== "PAID" ?
            <Menu.Item
              as="div" onClick={handleMarkPaid}
              className="dropdown-item dropdown-item-icon"
            >
              <IconMarkPaid />
              Mark as Paid
            </Menu.Item> : <></>
        }
        <Menu.Item
          as="div" onClick={handleSendReminder}
          className="dropdown-item dropdown-item-icon"
        >
          <IconMailSend />
          Send a reminder
        </Menu.Item>
        <Menu.Item
          as="div" onClick={handleDownload}
          className="dropdown-item dropdown-item-icon"
        >
          <IconDownload />
          Download
        </Menu.Item>
        <Menu.Item
          as="div" onClick={handleDelete}
          className="dropdown-item dropdown-item-icon text-shiga-warn"
        >
          <IconDelete />
          Delete Invoice
        </Menu.Item>
      </div>
    </Dropdown>
  )
}
export const InvoiceColumns: ColumnDef<Invoice>[] = [
  {
    accessorKey: "formattedHeader",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Invoice ID" />
    ),
    cell: ({ row, table }) => {
      const handleOnClick = () => {
        (table as any).options.meta.handleOnClick(row.original)
      }
      return (
        <div onClick={handleOnClick} className=" cursor-pointer flex-col justify-start items-start gap-2.5 inline-flex">
          <div className="flex-col justify-start items-start gap-0.5 flex">
            <div className=" text-zinc-800 text-base font-medium font-['Inter']">
              {row.original.invoiceNumber}
            </div>
            <div className=" text-neutral-400 text-xs font-normal font-['Inter']">
              {row.original.recipientEmail}
            </div>
          </div>
        </div>
      )
    },
    filterFn: "includesString"
  },
  {
    accessorKey: "formattedAmount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount" />
    ),
    cell: ({ row, table }) => {
      const handleOnClick = () => {
        (table as any).options.meta.handleOnClick(row.original)
      }
      return (
        <span onClick={handleOnClick} className="text-shiga-black font-medium text-base cursor-pointer">
          {row.original.formattedAmount}
        </span>
      )
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row, table }) => {
      const type = row.original.status;
      const handleOnClick = () => {
        (table as any).options.meta.handleOnClick(row.original)
      }
      return (
        <>
          {type ? (
            <Badge onClick={handleOnClick}
              className={`rounded-md cursor-pointer capitalize ${getStatusColor(type)}`}
              variant="outline"
            >
              {row.original.status}
            </Badge>
          ) : (
            ""
          )}
        </>
      );
    },
  },
  {
    accessorKey: "formattedData",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Date Created" />
    ),
    cell: ({ row }) => (
      <p className="font-medium  max-w-36  line-clamp-1 overflow-hidden text-ellipsis cursor-pointer">
        {row.original.formattedDate}
      </p>
    ),
  },
  {
    accessorKey: "dropdown",
    header: ({ column }) => (<></>),
    cell: ({ row, table, getValue }) => {
      return (
        <InvoiceDropdown invoice={row.original}  />
      )
    },
  }
];

export const getStatusColor = (type: string) => {
  switch (type) {
    case "paid":
      return "text-emerald-500 border-[#DCFAEC]";
    case "not paid":
      return "text-amber-500 border-outline-orange";
    default:
      return "text-red-400 border-[#FFEEE8]";
  }
};
