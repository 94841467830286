import { Transaction, getMethodLabel } from "types/transaction";
import { ReactComponent as IconWarn } from "assets/images/icons/Warn.svg";
import { ReactComponent as IconGiftCard } from "assets/images/icons/Gift-Card.svg";
import { ReactComponent as IconArrowLeft } from "assets/images/icons/ArrowLeftDown.svg";
import { ReactComponent as GTBank } from "assets/images/brands/GTBank.svg";
import { ReactComponent as ArrowUp } from "assets/images/icons/ArrowUp.svg";
import store from "store/store";
import { airtimeProvider, cableProvider, electricityProvider } from "utils/data";
import { formatDateWTime } from "lib/format.date";
import { currencyFormat } from "lib/format.amount";

export const getTransactionAmountStyle = (transaction: Transaction) => {
    let style = '';
    switch (transaction.status) {
        case "FAILED":
            style += "line-through "
    }
    switch (transaction.type) {
        case "CREDIT":
            if (transaction.status === "FAILED") {
                style += "text-shiga-dark "
            } else {
                style += "text-shiga-green "
            }
            break;
        default:
            style += "text-shiga-dark"
    }
    return style
}

export const getCurrencyIcon = (icon: string) => {
    const currentState = store.getState();
    const currenciesIcon = currentState.ui.currencies.currenciesList?.data.data.map(item => ({
        icon: item.icon,
        currency: item.currency
    }));
    return currenciesIcon?.find(item => item.currency === icon)
}

export const getBankIcon = (bank: string) => {
    const currentState = store.getState();
    return currentState.ui.banks.banks?.data?.find((item: any) => item.name === bank);
}

export const getSwapIcons = (transaction: Transaction) => {
    const currencySwap = transaction.description.split(" > ");
    if (currencySwap.length > 1) {
        const foundCurrency1 = getCurrencyIcon(currencySwap[0]);
        const foundCurrency2 = getCurrencyIcon(currencySwap[1]);

        return (
            <div className="relative w-12 h-12">
                <div className="w-8 h-8 rounded-full">
                    <img src={foundCurrency1?.icon} className="w-full h-full" alt="base currency" />
                </div>
                <div className=" absolute -right-1 -bottom-1 w-8 h-8 rounded-full border-[3px] border-white overflow-hidden">
                    <img src={foundCurrency2?.icon} className="w-full h-full" alt="transfer currency" />
                </div>
            </div>)
    } else {
        return (
            <div className="relative w-12 h-12">
                <div className="w-8 h-w-8 rounded-full">
                    {/* <IconGhana className="w-full h-full" /> */}
                </div>
                <div className=" absolute -right-1 -bottom-1 w-8 h-8 rounded-full border-[3px] border-white overflow-hidden">
                    {/* <IconUK className="w-full h-full" /> */}
                </div>
            </div>)
    }
}

export const getBillPaymentIcon = (transaction: Transaction, style = "") => {
    const billPayment = transaction.billPayment
    switch (transaction.billPayment?.paymentType) {
        case "ELECTRICITY":
            return (
                <div className={`bg-[#F5F5F5] flex items-center justify-center rounded-full overflow-hidden ${style}`}>
                    <img alt={billPayment?.meta.utilityBillName} className="w-full h-full"
                        src={electricityProvider.find(item => item.provider === billPayment?.meta.utilityBillName)?.providerLogoUrl || ""} />
                </div>)
        case "AIRTIME":
            return (<div className={`bg-[#F5F5F5] flex items-center justify-center overflow-hidden rounded-full ${style}`}>
                <img alt={billPayment?.meta.networkType} className="h-full w-full"
                    src={airtimeProvider.find(item => item.provider === billPayment?.networkType)?.providerLogoUrl || ""} />
            </div>)
        case "INTERNET":
            return (<div className={`bg-[#F5F5F5] flex items-center justify-center overflow-hidden rounded-full ${style}`}>
                <img alt={billPayment?.meta.networkType} className="h-full w-full"
                    src={airtimeProvider.find(item => item.provider === billPayment?.networkType)?.providerLogoUrl || ""} />
            </div>)
        case "CABLE_TV":
            return (<div className={`bg-[#F5F5F5] flex items-center justify-center overflow-hidden rounded-full ${style}`}>
                <img alt={billPayment?.meta.networkType} className="h-full w-full"
                    src={cableProvider.find(item => item.provider === billPayment?.meta.paymentType)?.providerLogoUrl || ""} />
            </div>)
    }
}

export const getTransactionIcon = (transaction: Transaction, style = "size-2.5") => {
    switch (transaction.status) {
        // Check status
        case "FAILED":
            return <div className={`flex items-center justify-center bg-[#FFEEE8] rounded-full ${style}`}>
                <IconWarn className="w-3 h-3" />
            </div>
        default:
            switch (transaction.method) {
                case "SWAP":
                    return getSwapIcons(transaction);
                case "DEPOSIT":
                    return <div className={`flex items-center justify-center bg-[#F5F5F5] rounded-full ${style}`}>
                        <IconArrowLeft className="w-3 h-3" />
                    </div>
                case "SPENDINGS":
                case "BANK_TRANSFER":
                    const bank = getBankIcon(transaction.bankTransfer?.beneficiaryBankName || "")
                    return <div className={`flex items-center justify-center bg-[#F5F5F5] rounded-full ${style}`}>
                        <ArrowUp className="w-3 h-3" />
                    </div>
                case "BILL_PAYMENT":
                    return getBillPaymentIcon(transaction, style);
                case "GIFT_CARD":
                    return <div className={`bg-[#F5F5F5] rounded-full ${style}`}>
                        <IconGiftCard className="w-full h-full" />
                    </div>
                default:
                    // Check type
                    switch (transaction.type) {
                        case "CREDIT":
                            return <div className={`flex items-center justify-center bg-[#F5F5F5] rounded-full ${style}`}>
                                <IconArrowLeft className="w-3 h-3" />
                            </div>;
                        case "DEBIT":
                            return <div className={`flex items-center justify-center bg-[#F5F5F5] rounded-full ${style}`}>
                                <ArrowUp className="w-3 h-3" />
                            </div>
                    }
            }
    }
}

export const getTransactionHeading = (transaction: Transaction, limitInfo = false) => {
    switch (transaction.method) {
        case "SWAP":
            return "Currency swap from";
        case "BILL_PAYMENT":
            const paymentType = transaction.billPayment?.paymentType
            let billDetail = ""
            switch (paymentType) {
                case "AIRTIME":
                    billDetail = transaction.billPayment?.networkType || "";
                    break;
                case "CABLE_TV":
                    break;
                case "ELECTRICITY":
                    billDetail = transaction.billPayment?.meta?.vendType || "";
                    break;
                case "INTERNET":
                    billDetail = transaction.billPayment?.meta?.vendType || "";
                    break;
            }
            return `Bills Payment for ${billDetail}`
        // return `Bills Payment for ${!limitInfo ? ` ${transaction.billPayment?.paymentType} (${billDetail})` : ""} `;
        case "REFUND":
            return "Refund for";
        case "TUITION":
            return "Payment for school";
        case "SPENDINGS":
            return "Payment for Card";
        case "GIFT_CARD":
            return "Gift Card Purchase";
        case "BANK_TRANSFER":
            return `Payment to ${transaction.bankTransfer?.beneficiaryAccountName} via ${transaction.bankTransfer?.beneficiaryBankName}`;
        case "DEPOSIT":
            return ""
        // return `Payent made to ${transaction.paymentCustomer?.firstName} ${transaction.paymentCustomer?.lastName}`
        case "CARD":
            return `${transaction.initiatedByAccount?.firstName} ${transaction.initiatedByAccount?.lastName}`
        default:
            switch (transaction.type) {
                case "CREDIT":
                    return `Received from ${getMethodLabel[transaction.channel]}`
                default:
                    return `Payment to ${getMethodLabel[transaction.channel]}`
            }
    }
}

export const creditHeader = (transaction: Transaction) =>
    <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
            {
                transaction.paymentCustomer ?
                    <GTBank /> : <></>
            }
            <div className="font-medium tracking-tight">
                {
                    transaction.paymentCustomer ?
                        <h5 className="text-shiga-black text-[18px]">
                            {`${transaction.paymentCustomer?.firstName} ${transaction.paymentCustomer?.lastName}`}
                        </h5> : <></>
                }
                <p className="text-[#99999C] text-[15px]">Bank Transfer</p>
            </div>
        </div>
        <div className="tracking-tight flex flex-col items-end">
            <h5 className="font-medium text-shiga-green text-[18px]">{currencyFormat(transaction.amount, transaction.currency)}</h5>
            <p className="text-[#99999C] text-[15px]">
                {formatDateWTime(transaction.createdAt)}
            </p>
        </div>
    </div>

export const debitHeader = (transaction: Transaction) =>
(
    <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
            {
                transaction.paymentCustomer ?
                    <GTBank /> : <></>
            }
            <div className="font-medium tracking-tight">
                {
                    transaction.paymentCustomer ?
                        <h5 className="text-shiga-black text-[18px]">
                            {`${transaction.paymentCustomer?.firstName} ${transaction.paymentCustomer?.lastName}`}
                        </h5> : <></>
                }
                <p className="text-[#99999C] text-[15px]">Bank Transfer</p>
            </div>
        </div>
        <div className="tracking-tight flex flex-col items-end">
            <h5 className="font-medium text-shiga-black text-[18px]">{currencyFormat(transaction.amount, transaction.currency)}</h5>
            <p className="text-[#99999C] text-[15px]">
                {formatDateWTime(transaction.createdAt)}
            </p>
        </div>
    </div>
)
