import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import NavigationLinks from "components/common/NavigationLinks.jsx";
import logo from "assets/images/icons/logo-black.svg";
import useLoginInfo from "hooks/useLoginInfo";
import { Menu } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";
import { ReactComponent as IconEllipse } from "assets/images/icons/Ellipse.svg";
import { ReactComponent as IconLogout } from "assets/images/icons/Sign-out.svg";
import IconSettings from "assets/images/icons/Account-Settings.png";
import IconNotification from "assets/images/icons/notification.svg";

const Header = () => {
  const { logoutUser } = useLoginInfo();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNavOpen = () => {
    setIsMobileNavOpen((data) => !data);
  };

  useEffect(() => {
    if (isMobileNavOpen === true) {
      setIsMobileNavOpen(false);
    }
    // eslint-disable-next-line
  }, [location]);


  return (
    <div className="border-b-[1px] border-gray-100">
      <header className="h-24 bg-white px-6 hidden md:flex">
        <div className="w-full flex space-x-6 items-center justify-between">
          <Breadcrumbs />

          <div className="flex space-x-4 items-center justify-end">
            <Notification />

            <Menu as="div" className="relative">
              <Menu.Button
                as="div"
                className="w-10 h-10 flex bg-[#F3F4F5] rounded-md hover:bg-gray-100 cursor-pointer overflow-hidden"
              >
                <IconUserProfile />
              </Menu.Button>
              <Menu.Items as="div" className="dropdown dropdown-right">
                <Menu.Item as="div">
                  <NavLink
                    to="/settings"
                    className="dropdown-item dropdown-item-icon whitespace-nowrap"
                  >
                    <img src={IconSettings} alt="settings" />
                    Account Settings
                  </NavLink>
                </Menu.Item>
                <Menu.Item
                  as="div"
                  onClick={logoutUser}
                  className="dropdown-item dropdown-item-icon !text-shiga-warn"
                >
                  <IconLogout />
                  Logout
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </header>

      <header className="w-full header-navigation-links bg-white md:hidden">
        <div className="h-14 px-6 flex items-center justify-between">
          <div>
            <Link to="/" className="h-7 inline-block">
              <img src={logo} alt="logo" className="h-full object-contain" />
            </Link>
          </div>

          <div className="flex-grow flex space-x-4 justify-end">
            <Menu as="div">
              <Menu.Button
                as="button"
                onClick={toggleMobileNavOpen}
                className="w-10 h-10 text-ep-gray-600 flex rounded cursor-pointer"
              >
                <FontAwesomeIcon icon="bars" className="text-xl m-auto" />
              </Menu.Button>
              {isMobileNavOpen && (
                <Menu.Items
                  as="div"
                  static
                  className="dropdown dropdown-right z-50 min-w-full max-w-none bg-white shadow-xl"
                >
                  <div className="flex flex-col pt-4 pb-4">
                    <NavigationLinks view="mobile" />
                    <div className="w-full h-px my-4 bg-black opacity-5"></div>
                    <div onClick={logoutUser} className="sidebar-links">
                      <IconLogout className="w-5 h-5 ml-1 mr-5" />
                      Logout
                    </div>
                  </div>
                </Menu.Items>
              )}
            </Menu>
          </div>
        </div>
      </header>
    </div>
  );
};

const Breadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter(Boolean);
  // console.log(paths);
  return (
    <div className="text-shiga-dark flex-shrink-0 hidden md:flex space-x-3 *:space-x-3 capitalize">
      {paths.map((path, idx) =>
        idx < paths.length - 1 ? (
          <div key={path}>
            <Link to={"/" + path} className="hover:underline">
              {path.replaceAll("-", " ")}
            </Link>
            <span>/</span>
          </div>
        ) : (
          <div key={path}>
            <span className="text-shiga-black">
              {path.replaceAll("-", " ")}
            </span>
          </div>
        )
      )}
      {/* <Link to="/" className="hover:underline">Section 1</Link>
			<span>/</span>
			<span className="text-shiga-black">Section 1.1</span> */}
    </div>
  );
};

const Notification = () => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        as="div"
        className="w-10 h-10 flex relative rounded-md hover:bg-gray-100 cursor-pointer overflow-hidden"
      >
        <div className="w-3 h-3 bg-shiga-warn border-2 border-white absolute top-1.5 right-2 rounded-full overflow-hidden"></div>
        <img
          src={IconNotification}
          alt="Notifications"
          className="w-6 h-6 m-auto object-contain"
        />
      </Menu.Button>
      <Menu.Items
        as="div"
        className="w-96 dropdown dropdown-right !text-shiga-black !bg-white !shadow-lg"
      >
        <Menu.Item as="div">
          <div className="p-6 bg-white shadow-xl rounded-2xl">
            <div className="pb-3 flex items-center justify-between border-b border-gray-100">
              <div className="text-2xl font-ojah font-medium">
                Notifications
              </div>
              <div className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer">
                <IconClose className="m-auto text-white svg-stroke-gray" />
              </div>
            </div>
            <div className="py-10 flex flex-col items-center justify-center">
              <IconEllipse className="mx-auto" />
              <div className="mt-4 text-center text-sm text-shiga-gray">
                There are currently no notifications for this business account.
                Please check again later.
              </div>
            </div>
          </div>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export default Header;
