import TransferHeader from "components/transfer/TransferHeader";
import AmountInput from "components/transfer/AmountInput";
import { ChangeEvent, useEffect, useState } from "react";
import substract from "assets/images/icons/Subtract.png";
import userIcon from "assets/images/icons/users_icon.png";
import { ButtonLoader } from "components/common/form";
import { ReactComponent as IconWallet } from "assets/images/icons/transfer-wallet.svg";
import TwoFactorAuthenticationCard from "pages/misc/TwoFactorAuthenticationCard";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getWalletsAndBalance } from "store/ui/dashboard/action";
import { useSelector } from "react-redux";
import { getTransferFee, getTransferLimit, updateSelectedWaletForTransfer, updateTransferAmount, updateTransferNote } from "store/transfer/action";
import { getCurrencies } from "store/ui/currencies/action";
import PATHS from "NavigationRoute";
import { useSharedStore } from "zustand-store";
import { getCurrenciesStore } from "store/ui/currencies/reducer";
import { useAppSelector } from "store/hooks";
import { getRate } from "store/convert/action";
import useToolkit from "hooks/useToolkit";
import { ReactComponent as WarningGrey } from "assets/images/icons/warning-gray.svg";
import { ReactComponent as WarningRed } from "assets/images/icons/warning-red.svg";

interface State {
    amount: string;
    iconLogo: string
}

const AmountToSend: React.FC = () => {
    const [state, setState] = useState<State>({
        amount: "",
        iconLogo: ""
    });

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { currenciesList } = useAppSelector(getCurrenciesStore);
    const { initialWallet, } = useSelector((allState: any) => allState.ui.dashboard)
    const {
        accountName,
        selectedWallat,
        amount,
        transferNote,
        currency,
        transferFeeData,
        transferLimitData: limit,
        transferFeeLoading: feeLoading
    } = useSelector((allStates: any) => allStates.transfer)
    const { currencies } = useSharedStore();
    const { formatCurrency, refetchProfiles, mfaEnabled } = useToolkit()
    const [summedAmount, setSummedAmount] = useState('0.00');
    const [amountToPay, setamountToPay] = useState(0);
    const [twoFacPromptOpen, setTwoFacPromptOpen] = useState(false);


    const maxNarrationLength = 200;
    const insufficientBalance = amount?.length > 0 && (selectedWallat?.balance < Number(amount) || selectedWallat?.balance < amountToPay);

    const minimumInvalid = Number(amount) > 0 && (limit?.data?.min > 0 && limit?.data?.min > Number(amount));
    const minAmountMsg = `The minimum amount you can send is ${limit?.data?.min || '...'} ${selectedWallat.currency}`
    const invalidAmount = minimumInvalid || insufficientBalance
    const proceedBtnDisabled = amount < 1 || feeLoading || invalidAmount || transferNote.length < 1;

    const setupTwoFac = () => {
        setTwoFacPromptOpen(false);
        navigate(PATHS.SETTINGS.USER_PROFILE)
    }

    useEffect(() => {
        if (amount > 0) {
            const payload = {
                feeServiceType: 'TRANSFER',
                amount: amount,
                currency: currency
            }
            dispatch(getTransferFee(payload));
        }
        // eslint-disable-next-line
    }, [amount]);

    useEffect(() => {
        if (transferFeeData?.success === true) {
            const fee = transferFeeData?.data?.feeAmount
            const totalAmount = transferFeeData?.data?.amountToPay - fee

            setSummedAmount(totalAmount?.toString());
            setamountToPay(transferFeeData?.data?.amountToPay)
        }
        // eslint-disable-next-line
    }, [transferFeeData]);

    useEffect(() => {
        if (accountName.length < 1) {
            navigate(PATHS.TRANSFER)
        }
        dispatch(getWalletsAndBalance())
        dispatch(getCurrencies())
        dispatch(getTransferLimit(selectedWallat?.currency, 'transfer'));
        refetchProfiles();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        try {
            const result = currenciesList?.data.data.find((cur: any) => cur.currency === selectedWallat.currency)
            setState({
                ...state,
                iconLogo: result?.icon || ""
            })
        } catch (e: any) {
            // 
        }
        // eslint-disable-next-line
    }, [currenciesList])


    useEffect(() => {
        dispatch(updateSelectedWaletForTransfer(initialWallet))
        try {
            const result = currenciesList?.data.data.find((cur: any) => cur.currency === initialWallet.currency)
            setState({
                ...state,
                iconLogo: result?.icon || ""
            })
        } catch (e: any) {
            // 
        }
        // eslint-disable-next-line
    }, [initialWallet])

    useEffect(() => {
        if (Object.keys(selectedWallat).length > 0) {
            dispatch(getRate("1", selectedWallat.currency, currency))
        }
        // eslint-disable-next-line
    }, [selectedWallat])

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value.length > 0) {
            dispatch(updateTransferAmount(event.target.value))
            const value = event.target.value
            const formattedValue = formatCurrency(value)

            setSummedAmount(formattedValue)
        } else {
            dispatch(updateTransferAmount(""))
            setSummedAmount('0.00');
        }
    };

    const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;

        if (value?.length <= maxNarrationLength) {
            dispatch(updateTransferNote(event.target.value))
        }
    };

    const onProceed = () => {
        if (mfaEnabled) {
            navigate(PATHS.TRANSFER_CONFIRM)
        } else {
            setTwoFacPromptOpen(true);
        }
    }

    return (
        <div className="transfer-container">

            <TwoFactorAuthenticationCard
                isOpen={twoFacPromptOpen}
                onBtnClick={setupTwoFac}
                onClose={() => navigate(PATHS.TRANSFER_CONFIRM)}
            />

            <TransferHeader step={2} onClose={() => { }} />
            <div className="transfer-wrapper">
                <div className="w-full sm:max-w-lg md:max-w-xl mt-16">
                    <div className="flex mb-4 justify-between">
                        <p className="text-shiga-gray-100">You send<span className="form-input-required">*</span></p>

                        <p className="text-shiga-dark flex items-center font-inter">
                            <span className="mr-1"><IconWallet /></span>
                            You have&nbsp;<span className="text-shiga-purple font-medium">{`${Number(selectedWallat.balance).toLocaleString()} ${selectedWallat.currency ?? ''}`}</span>&nbsp;available
                        </p>
                    </div>

                    <div className="h-[100px] bg-shiga-gray-25 flex items-start rounded-b-2xl">

                        <div className="w-full">
                            <AmountInput
                                amount={amount}
                                disabled={false}
                                amountError={invalidAmount}
                                onCurrencySelect={() => { }}
                                onAmountChange={handleAmountChange}
                                selectedCurrencies={selectedWallat.currency}
                                currencies={[selectedWallat.currenciesLoading]}
                                iconUrl={currencies.find((curr) => curr.currency === selectedWallat.currency)?.icon || ""}
                            />

                            <MinimumAmountLabel
                                error={invalidAmount}
                                text={`${minimumInvalid ? minAmountMsg
                                    : insufficientBalance ? 'You cannot send more than your available balance' : minAmountMsg}`}
                            />
                        </div>
                    </div>

                    <div className="flex gap-2 items-center px-8 relative">
                        <span className="border h-24 bg-[#FAFAFA]"></span>
                        <div className="absolute flex gap-2 left-[22px] items-center">
                            <img src={substract} alt="subtract icon" className="w-4 h-4" />
                            <p className="text-shiga-gray text-sm">
                                <span className="text-shiga-dark-100 font-medium">
                                    {!amount ? 0 : feeLoading ? '...' : transferFeeData?.data?.feeAmount} {currency}&nbsp;
                                </span>
                                Transfer fee
                            </p>
                        </div>
                    </div>

                    <div className="ml-[8px]">
                        <div className="flex items-center gap-2.5">
                            <img src={userIcon} alt="user icon" className="w-12 h-12" />
                            <div className="flex flex-col">
                                <span className="text-sm text-shiga-blackk capitalize">{accountName?.toLowerCase()} <span className="text-shiga-gray lowercase">gets</span></span>
                                <span className="text-shiga-blacks text-lg font-medium">
                                    {!Number(summedAmount) ? amount || 0.00 : Number(summedAmount)?.toLocaleString()}&nbsp;{currency}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex mt-10 justify-between text-sm">
                        <p className="text-shiga-gray-100">Add a note<span className="form-input-required">*</span></p>
                        <p>{transferNote?.length}/{maxNarrationLength}</p>
                    </div>
                    <div className="w-full mt-2 text-shiga-gray">
                        <textarea
                            placeholder="Enter note"
                            className="w-full border-0 py-3 px-5 rounded-2xl  bg-[#FAFAFB] text-shiga-dark-100 placeholder:text-shiga-gray-100 placeholder:font-normal font-medium"
                            maxLength={200}
                            value={transferNote}
                            onChange={handleNoteChange}
                            style={{ minHeight: '80px' }}
                        />
                    </div>

                    <ButtonLoader
                        type="submit"
                        onClick={onProceed}
                        className="btn bg-shiga-black text-white btn-lg btn-block mt-11"
                        disabled={proceedBtnDisabled}>
                        Continue
                    </ButtonLoader>
                </div>
            </div>
        </div>
    );
};

const MinimumAmountLabel = ({ text, error }: {
    error: boolean;
    text: string;
}) => {

    return (
        <div className={` transition-colors duration-150 text-xs flex items-center w-full z-5 bg-gray-100 px-3 pt-2 pb-2.5 rounded-b-2xl ${error ? 'bg-shiga-red-25 text-shiga-red-50' : 'bg-shiga-gray-25 text-shiga-gray-300'}`}>
            {error ? <WarningRed /> : <WarningGrey />}
            <span className="font-medium ml-2">
                {text}
            </span>
        </div>
    )
}

export default AmountToSend;