import * as actions from "./actionTypes";
import * as config from "data/config";

export function getBanks(currency = "USD") {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetBanks + currency,
            method: "get",
            data: {},
            onStart: actions.GET_BANKS_START,
            onSuccess: actions.GET_BANKS,
            onError: actions.GET_BANKS_FAILED,
        },
    }
}
