import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";



interface Account {
    id: string;
    name: string;
    number: string;
    bank: string;
    bankCode: string;
    currency: string;
    isVirtual: boolean;
    createdAt: string;
    updatedAt: string;
    businessId: string;
    reference: string;
    customerId: string;
}

interface State {
    loadingAccounts: boolean;
    accounttList: Account[];
}

const initialState: State = {
    loadingAccounts: false,
    accounttList: [],
};



const accountDemos = [
    {
        "id": "d88343f6-f1c2-4e04-8762-39c7add0c1f8",
        "name": "Shiga Technologies LTD / test testing",
        "number": "",
        "bank": "Testing Bank",
        "bankCode": "000017",
        "currency": "NGN",
        "isVirtual": true,
        "createdAt": "2024-05-01T22:37:54.322Z",
        "updatedAt": "2024-05-01T22:37:54.322Z",
        "businessId": "0bb1dfc6-a74c-4e53-b41a-4a0c1eab188e",
        "reference": "tobi9whdhnw012bd9dh2w",
        "customerId": ""
    },
    {
        "id": "d88343f6-f1c2-4e04-8762-39c7add0c1f8",
        "name": "Shiga Technologies LTD / test testing",
        "number": "",
        "bank": "Jerry Bank",
        "bankCode": "000017",
        "currency": "NGN",
        "isVirtual": true,
        "createdAt": "2024-05-01T22:37:54.322Z",
        "updatedAt": "2024-05-01T22:37:54.322Z",
        "businessId": "0bb1dfc6-a74c-4e53-b41a-4a0c1eab188e",
        "reference": "sRichardnw012bd9dh2w",
        "customerId": ""
    },
    {
        "id": "d88343f6-f1c2-4e04-8762-39c7add0c1f8",
        "name": "Shiga Technologies LTD / test testing",
        "number": "",
        "bank": "Mind Bank",
        "bankCode": "000017",
        "currency": "NGN",
        "isVirtual": false,
        "createdAt": "2024-05-01T22:37:54.322Z",
        "updatedAt": "2024-05-01T22:37:54.322Z",
        "businessId": "0bb1dfc6-a74c-4e53-b41a-4a0c1eab188e",
        "reference": "hsifhddd9whdhnw012bd9dh2w",
        "customerId": ""
    }
]


export default function reducer(state: State = initialState, action: any) {
    switch (action.type) {
        case actions.GET_ISSUED_ACCOUNTS:
            state = {
                ...state,
                loadingAccounts: false,
                accounttList: [
                    ...action.payload.data.payload,
                    ...accountDemos
                ]
            }
            return state
        case actions.GET_ISSUED_ACCOUNTS_START:
            state = {
                ...state,
                loadingAccounts: true,

            }
            return state
        case actions.GET_ISSUED_ACCOUNTS_FAILED:
            errorReducer(action.payload)
            state = {
                ...state,
                loadingAccounts: false
            }
            return state
        default:
            return state

    }
}