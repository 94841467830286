import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonLoader, Input } from "components/common/form";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { ReactComponent as IconCopy } from "assets/images/icons/Copy.svg";
import { ReactComponent as IconGlobe } from "assets/images/icons/Globe.svg";
import useLoginInfo from "hooks/useLoginInfo";
import PageContent from "components/common/PageContent";
import Maybe from "components/common/Maybe";
import validate from "utils/validate";
import * as settingsActions from "store/entities/settings/action";
import * as userActions from "store/auth/user/action";


const APIKeysWebhooks = () => {
    const dispatch = useDispatch();
    const { userInfo, getBusinessProfile } = useLoginInfo();
    const { businessProfileLoading } = useSelector((s:any) => s.auth.account);
    const { keysLoading, keys, webhooksUpdateLoading, webhooksUpdate } = useSelector((s: any) => s.entities.settings);

    const [form, setForm] = useState({
        webhookUrl: "",
        webhookCallback: "",
    })

    const copyText = (text:string, label?: string | undefined) => {
        copy(text);
        toast.success("Copied: " + (label ?? text));
    }

    const getKeys = () => {
        dispatch(settingsActions.getKeys());
    }

    const generateSecretKey = () => {
        dispatch(settingsActions.getKeys({generate: true}));
    }

    const populateForm = () => {
        setForm({
            webhookUrl: userInfo?.businessProfile?.webhookUrl ?? "",
            webhookCallback: userInfo?.businessProfile?.webhookCallback ?? "",
        });
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        const errors:any = validate(form);
        if (errors){
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            dispatch(settingsActions.updateWebhooks(form));
        }
    }

    useEffect(() => {
        getKeys();
        if (userInfo?.businessProfile === undefined){
            getBusinessProfile();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userInfo?.businessProfile !== undefined){
            populateForm();
        }
        // eslint-disable-next-line
    }, [userInfo]);

    useEffect(() => {
        if (webhooksUpdate?.success !== undefined){
            if (webhooksUpdate?.success === true){
                dispatch(userActions.updateUserInfo({
                    businessProfile: {
                        ...webhooksUpdate?.data ?? {},
                    },
                }));
            }
            dispatch(settingsActions.resetUpdateFeesEarnings());
        }
        // eslint-disable-next-line
    }, [webhooksUpdate]);

    return (
        <div>
            <DynamicHeadTag
                headerText="API Keys & Webhooks"
            />

            <div className="page-title pb-6 mb-6 border-b border-gray-100">
                API Keys & Webhooks
            </div>

            <PageContent
                loading={businessProfileLoading}
                data={userInfo?.businessProfile}
                loadingText="Loading API Keys & Webhooks..."
            />

            <Maybe condition={businessProfileLoading === false}>
                <form onSubmit={submitForm}>
                    <div className="w-full px-3 py-3 text-shiga-orange text-sm font-medium flex items-center border border-gray-200 rounded-xl">
                        <FontAwesomeIcon icon="exclamation-triangle" className="w-6 h-4 flex-shrink-0 mr-2" />
                        <div>
                            These API keys are for testing only. Ensure you or your team does not use them while in production mode
                        </div>
                    </div>

                    <div className="mt-10 sm:flex sm:space-x-8">
                        <div className="sm:w-2/5 font-medium">
                            Test Secret Key
                        </div>
                        <div className="mt-3 sm:mt-0 sm:w-3/5 space-y-6 font-medium">
                            <Maybe condition={keysLoading === true}>
                                <Input
                                    type="text"
                                    label=" "
                                    value="Loading secret key..."
                                    disabled={true}
                                />
                            </Maybe>
                            <Maybe condition={keysLoading === false}>
                                <Input
                                    type="password"
                                    label=" "
                                    value={keys?.data?.secretKey ?? ""}
                                    readOnly={true}
                                    disabled={true}
                                    rightIcon={<IconCopy />}
                                    rightIconClass="px-2.5 hover:bg-gray-200 rounded-tr-2xl rounded-br-2xl"
                                    rightIconClicked={() => copyText((keys?.data?.secretKey ?? ""), "Test Secret Key")}
                                />
                                <div onClick={generateSecretKey} className="inline-block relative -top-10 left-4 text-sm text-shiga-purple border-b-2 border-transparent hover:border-shiga-purple cursor-pointer">
                                    Generate new secret key
                                </div>
                            </Maybe>
                        </div>
                    </div>

                    <div className="mt-2 sm:mt-0 sm:flex sm:space-x-8">
                        <div className="sm:w-2/5 font-medium">
                            Test Public Key
                        </div>
                        <div className="mt-3 sm:mt-0 sm:w-3/5 space-y-6 font-medium">
                            <Maybe condition={keysLoading === true}>
                                <Input
                                    type="text"
                                    label=" "
                                    value="Loading public key..."
                                    disabled={true}
                                />
                            </Maybe>
                            <Maybe condition={keysLoading === false}>
                                <Input
                                    type="text"
                                    label=" "
                                    value={keys?.data?.publicKey ?? ""}
                                    inputClass="ellipsis"
                                    readOnly={true}
                                    disabled={true}
                                    rightIcon={<IconCopy />}
                                    rightIconClass="px-2.5 hover:bg-gray-200 rounded-tr-2xl rounded-br-2xl"
                                    rightIconClicked={() => copyText(keys?.data?.publicKey ?? "")}
                                />
                            </Maybe>
                        </div>
                    </div>

                    <div className="mt-10 sm:mt-6 sm:flex sm:space-x-8">
                        <div className="sm:w-2/5 font-medium">
                            Webhook URL
                        </div>
                        <div className="mt-3 sm:mt-0 sm:w-3/5 space-y-6 font-medium">
                            <Input
                                type="text"
                                label=" "
                                placeholder="www.example.com"
                                value={form.webhookUrl}
                                onChange={(e:any) => setForm({...form, webhookUrl: e.target.value})}
                                leftIcon={<IconGlobe />}
                                leftIconClass="px-2.5"
                            />
                        </div>
                    </div>

                    <div className="mt-10 sm:mt-6 sm:flex sm:space-x-8">
                        <div className="sm:w-2/5 font-medium">
                            Callback URL
                        </div>
                        <div className="mt-3 sm:mt-0 sm:w-3/5 space-y-6 font-medium">
                            <Input
                                type="text"
                                label=" "
                                placeholder="www.example.com"
                                value={form.webhookCallback}
                                onChange={(e:any) => setForm({...form, webhookCallback: e.target.value})}
                                leftIcon={<IconGlobe />}
                                leftIconClass="px-2.5"
                            />
                        </div>
                    </div>

										<div className="mt-2 sm:mt-0 sm:flex sm:space-x-8">
                        <div className="sm:w-2/5 font-medium">
                            WebHook Secret
                        </div>
                        <div className="mt-3 sm:mt-0 sm:w-3/5 space-y-6 font-medium">
                            <Maybe condition={keysLoading === true}>
                                <Input
                                    type="text"
                                    label=" "
                                    value="Loading public key..."
                                    disabled={true}
                                />
                            </Maybe>
                            <Maybe condition={keysLoading === false}>
                                <Input
                                    type="text"
                                    label=" "
																		value={keys?.data?.webhookSecret ?? ""}
                                    inputClass="ellipsis"
                                    readOnly={true}
                                    disabled={true}
                                    rightIcon={<IconCopy />}
                                    rightIconClass="px-2.5 hover:bg-gray-200 rounded-tr-2xl rounded-br-2xl"
                                    rightIconClicked={() => copyText(keys?.data?.webhookSecret ?? "")}
                                />
                            </Maybe>
                        </div>
                    </div>

                    <div className="mt-10 flex space-x-2 items-center justify-end">
                        <ButtonLoader type="submit" loading={webhooksUpdateLoading} className="max-w-[12rem] btn btn-block btn-lg btn-primary">
                            Save Changes
                        </ButtonLoader>
                    </div>

                </form>
            </Maybe>
        </div>
    )
}


export default APIKeysWebhooks;