import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Button } from "components/ui/button";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "components/ui/form";
import { useNavigate } from "react-router-dom";
import { Upload } from "components/common/Upload";
import { useInvoiceStore } from "zustand-store/payments/invoice";
import { useEffect, useMemo, useState } from "react";
import { PAYMENT_PATHS } from "pages/payments/paths";
import { phoneRegex } from "constants/phone_nuber.regxp";
import TextArea from "components/common/form/TextArea";
import { Input } from "components/common/form";
import { googleApiKey } from "data/config";
import CommandSelect from "components/ui/command-select";
import { CircleCheck } from "lucide-react";
import { CommandInput } from "components/ui/command";
import { getAddressObject } from "lib/format.address";

const formSchema = z.object({
  name: z.string().min(2, {
    message: "Name must be at least 2 characters.",
  }),

  note: z.string().optional(),
  phone: z.string().regex(phoneRegex, "Invalid Number!"),
  address: z.object({
    description: z.string(),
    place_id: z.string(),
  }),
  email: z.string().email(),
});

export function ReciepientForm() {
  const [location, setLocation] = useState("");
  const { invoice, setInvoice, setCompleted } = useInvoiceStore();
  const form = useForm<z.infer<typeof formSchema>>({
    // resolver: zodResolver(formSchema),
    defaultValues: {
      name: invoice?.reciepient?.name || "",
      note: invoice?.reciepient?.note || "",
      phone: invoice?.reciepient?.phone || "",
      address: {
        description: "",
        place_id: "",
      },
      email: invoice?.reciepient?.email || "",
    },
  });
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleApiKey,
  });

  console.log("placesService", placesService);
  console.log("placePredictions", placePredictions);
  useEffect(() => {
    if (invoice.imageUrl) {
      setPreviewUrl(invoice.imageUrl);
    }
  }, []);

  useEffect(() => {
    if (previewUrl.length) {
      setInvoice({
        ...invoice,
        imageUrl: previewUrl,
      });
    }
  }, [previewUrl]);

  const navigate = useNavigate();
  function onSubmit(values: z.infer<typeof formSchema>) {
    setCompleted("receipient");
    if (!values.address.place_id) {
      form.setError("address.description", {
        message: "Please select an address",
      });
    }
    return placesService.getDetails(
      {
        placeId: values.address.place_id,
        fields: ["address_components", "adr_address", "formatted_address"],
      },
      (placeDetails: any) => {
        const addresObject = getAddressObject(placeDetails.address_components);
        setInvoice({
          ...invoice,
          reciepient: {
            ...invoice.reciepient,
            ...values,
            address: {
              address: values.address.description,
              city: addresObject.city,
              country: addresObject.country,
              postCode: addresObject.postal_code,
              state: addresObject.region,
            },
          },
        });
        navigate(`${PAYMENT_PATHS.CREATE_INVOICE}?tab=add_items`);
      }
    );
  }

  const addressError = useMemo(
    () => form.formState.errors["address"],
    [form.formState.errors]
  );

  return (
    <div className="flex flex-col-reverse lg:flex-row p-6 justify-between items-center lg:items-start">
      <div className="mt-5 lg:0 w-full lg:w-[412px] space-y-4 ">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      label="Reciepient's Name"
                      placeholder="Enter a name"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-between gap-4 w-full">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        required
                        label="Email address"
                        placeholder="Enter email address"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        required
                        label="Phone number"
                        placeholder="Enter phone number"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem className="flex flex-col space-y-3">
                  <CommandSelect
                    filterOption={() => 1}
                    loading={isPlacePredictionsLoading}
                    SearchInput={
                      <CommandInput
                        placeholder="Search Address"
                        value={location}
                        onValueChange={(e) => {
                          setLocation(e);
                          getPlacePredictions({ input: e });
                        }}
                      />
                    }
                    notFound="No Billing Address found."
                    label="Billing Address"
                    options={placePredictions.map((item) => ({
                      ...item,
                      id: item.place_id + "",
                      value: item.description,
                    }))}
                    SelectTrigger={() => (
                      <span className="flex items-center gap-4 overflow-hidden">
                        {field.value.description}
                      </span>
                    )}
                    RenderOption={({ item }) => (
                      <>
                        <span className="flex text-left">
                          {item.description}
                        </span>
                        {field.value === item.description && (
                          <CircleCheck className="size-5 stroke-2 shrink-0 fill-white stroke-black" />
                        )}
                      </>
                    )}
                    handleSelect={(select) => {
                      form.setValue("address", {
                        description: select.description,
                        place_id: select.place_id,
                      });
                    }}
                  />
                  {addressError?.description?.message?.length ? (
                    <p className={"text-sm font-medium text-warn"}>
                      {addressError.description.message}
                    </p>
                  ) : (
                    <></>
                  )}
                </FormItem>
              )}
            />
            <TextArea
              form={form as any}
              placeholder="Say a bit more about this invoice"
              label="Add a Note (optional)"
            />
            <Button
              className="w-full"
              // disabled={!placesService.getDetails}
              type="submit"
            >
              Continue
            </Button>
          </form>
        </Form>
      </div>
      <Upload
        previewUrl={previewUrl || invoice.imageUrl}
        setPreviewUrl={setPreviewUrl}
        file={file}
        setFile={setFile}
      />
    </div>
  );
}
