import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    country: "",
    currency: "",
    accountName: "",
    bankList: [],
    loadingBank: false,
    loadingName: false,
    selectedWallat: {},
    amount: "",
    beneficairyListLoading: false,
    beneficiaryList: {},
    transferAmount: "0",
    transferNote: "",
    transferStatus: -1,
    transferLoading: false,
    saveBeneficiary: true,
    mfaCode: "",
    transferMessage: "",
    transferResponse: null,
    transferFields: null,
    transferFiledLoading: false,
    transferFieldStatus: false,
    formFields: {},

    transferFeeLoading: false,
    transferFeeData: null,
    bankTransferForm: null,

    transferLimitLoading: false,
    transferLimitData: null,
};


export default function reducer(state = initialState, action: any) {
    switch (action.type) {


        case actions.GET_TRANSFER_LIMIT_FAILED:
            state = {
                ...state,
                transferLimitLoading: false,
                transferLimitData: null
            }
            return state;

        case actions.GET_TRANSFER_LIMIT_DONE:
            state = {
                ...state,
                transferLimitLoading: false,
                transferLimitData: action.payload
            }
            return state;

        case actions.GET_TRANSFER_LIMIT_START:
            state = {
                ...state,
                transferLimitLoading: true,
                transferLimitData: null
            }
            return state;

        case actions.CLEAR_BANK_TRANSFER_FORM:
            state = {
                ...state,
                bankTransferForm: null
            }
            return state;

        case actions.SAVE_BANK_TRANSFER_FORM:
            state = {
                ...state,
                bankTransferForm: action.payload
            }
            return state;

        case actions.GET_TRANSFER_FEE_FAILED:
            state = {
                ...state,
                transferFeeLoading: false,
                transferFeeData: null
            }
            return state;

        case actions.GET_TRANSFER_FEE_DONE:
            state = {
                ...state,
                transferFeeLoading: false,
                transferFeeData: action.payload
            }
            return state;

        case actions.GET_TRANSFER_FEE_START:
            state = {
                ...state,
                transferFeeLoading: true,
                transferFeeData: null
            }
            return state;

        case actions.TRANSFER_GET_BANK:
            state = {
                ...state,
                loadingBank: false,
                bankList: action.payload.data
            }
            return state;

        case actions.TRANSFER_GET_BANK_START:
            state = {
                ...state,
                loadingBank: true,
                bankList: []
            }
            return state;

        case actions.TRANSFER_GET_BANK_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loadingBank: false
            }
            return state;

        case actions.UPDATE_TRANSFER_CURERENCY:
            state = {
                ...state,
                country: action.payload.country,
                currency: action.payload.currency,
            }
            return state
        case actions.TRANSFER_NAME_ENQUIRY:
            state = {
                ...state,
                loadingName: false,
                accountName: action.payload.data.accountName
            }
            return state
        case actions.TRANSFER_NAME_ENQUIRY_START:
            state = {
                ...state,
                loadingName: true,
                // accountName: "Loading..."
            }
            return state
        case actions.UPDATE_SELECTED_WALLET_FOR_TRANSFER:
            state = {
                ...state,
                selectedWallat: action.payload
            }
            return state
        case actions.UPDATE_TRANSFER_MFA_CODE:
            state = {
                ...state,
                mfaCode: action.payload
            }
            return state
        case actions.TRANSFER_NAME_ENQUIRY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loadingName: false,
                accountName: ""
            }
            return state
        case actions.TRANSFER_BENEFICIARY:
            state = {
                ...state,
                beneficairyListLoading: false,
                beneficiaryList: action.payload
            }
            return state;
        case actions.TRANSFER_BENEFICIARY_START:
            state = {
                ...state,
                beneficairyListLoading: true
            }
            return state;
        case actions.TRANSFER_BENEFICIARY_FAILED:
            errorReducer(action.payload)
            state = {
                ...state,
                beneficairyListLoading: false
            }
            return state;
        case actions.UPDATE_TRANSFER_AMOUNT:
            state = {
                ...state,
                amount: action.payload
            }
            return state
        case actions.UPDATE_TRANSFER_NOTE:
            state = {
                ...state,
                transferNote: action.payload
            }
            return state
        case actions.TRANSFER_PAYMENT_START:
            state = {
                ...state,
                transferLoading: true,
                transferResponse: null,
                transferStatus: -1
            }
            return state
        case actions.TRANSFER_PAYMENT:
            state = {
                ...state,
                transferLoading: false,
                transferResponse: action.payload,
                transferStatus: 0
            }
            return state
        case actions.TRANSFER_PAYMENT_FAILED:
            errorReducer(action.payload)
            state = {
                ...state,
                transferLoading: false,
                transferResponse: null,
                transferStatus: 2,
                transferMessage: action.payload.message
            }
            return state
        case actions.UPDATE_TRANSFER_FIELDS_FROM_SAVED_BENENEFIARY:
            state = {
                ...state,
                accountName: action.payload.accountName,
                currency: action.payload.currency,
                formFields: {
                    ...state.formFields,
                    ...action.payload
                }
            }
            return state;
        case actions.RESET_TRANSFER_DATA:
            state = initialState
            return state;
        case actions.TRANSFER_GET_FIELDS:
            state = {
                ...state,
                transferFiledLoading: false,
                transferFields: action.payload.data,
                transferFieldStatus: true,
            }
            return state
        case actions.TRANSFER_GET_FIELDS_START:
            state = {
                ...state,
                transferFiledLoading: true,
                transferFieldStatus: false,
            }
            return state
        case actions.TRANSFER_GET_FIELDS_FAILED:
            errorReducer(action.payload)
            state = {
                ...state,
                transferFiledLoading: false,
                transferFieldStatus: false,
                transferFields: null,
            }
            return state
        case actions.UPDATE_TRANSFER_DYNAMIC_FIELD:
            state = {
                ...state,
                formFields: {
                    ...state.formFields,
                    ...action.payload
                }
            }
            return state
        default:
            return state;
    }
}