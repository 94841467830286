import { useMemo, useState } from "react";
import { DataTable } from "components/data-table/data-table";
import { RefundColumns } from "./table/RefundColumn";
import { Refund, defaultRefund } from "types/refund";
import { RefundDetails } from "./RefundDetails";
import { DataTableHeader } from "components/data-table/data-table-header";
import { currencyFormat } from "lib/format.amount";
import { formatDate } from "lib/format.date";

export interface IMeta {
    nextPage: number;
    previousPage: number;
    size: number;
    totalItems: number
}

export const RefundsTable: React.FC<{
    refunds: Refund[];
}> = ({ refunds }) => {
    const [selectedRefund, setSelectedRefund] = useState<Refund>(defaultRefund);

    const formattedRefund = useMemo(() => refunds.map((item, idx) => ({
        ...item,
        formattedName: `${item.customer.firstName} ${item.customer.lastName}`,
        formattedAmount: currencyFormat(Number(item.amount), item.currency),
        formattedDate: formatDate(item.createdAt as unknown as string)
    })),[refunds])

    return (
        <div className="mt-6">
            <DataTable
                data={formattedRefund}
                columns={RefundColumns}
                renderToolbar={table => <DataTableHeader
                    filterOptions={["Successful", "Pending"]}
                       placeholder="Search Refund" table={table} />}
                meta={{
                    handleSelected: setSelectedRefund
                }}
            />
             <RefundDetails
                selectedRefund={selectedRefund}
                isOpen={!!selectedRefund.id.length}
                setIsOpen={() => {
                    setSelectedRefund(defaultRefund)
                }} />
        </div>
    );
};
