import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { FormControl, FormLabel, FormMessage, useFormField } from "./form";
import { Button } from "./button";
import React, { useEffect, useRef, useState } from "react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./command";
import ChevronDown from "assets/images/icons/ChevronDown";
import { CommandLoading } from "cmdk";
import { PopoverClose } from "@radix-ui/react-popover";
import { Tooltip } from "react-tooltip";

interface SelectOption {
    value: string;
    id: string
}

interface CommandSelectProps<K extends SelectOption> {
    SelectTrigger?: React.FC<{}>;
    RenderOption: React.FC<{
        item: K;
    }>;
    handleSelect: (arg: K) => void;
    options: K[];
    disableInput?: boolean;
    label?: string;
    notFound: string;
    loading?: boolean
    removeSearchInput?: boolean;
    SearchInput?: React.ReactNode;
    filterOption?: ((value: string, search: string, keywords?: string[] | undefined) => number);
    buttonSelectClass?: string;
    searchPlaceholder?: string
}

export function CommandSelect<K extends SelectOption>({ RenderOption, filterOption, searchPlaceholder, loading, SelectTrigger, buttonSelectClass, SearchInput, notFound, label, disableInput = false, handleSelect, options }: CommandSelectProps<K>) {
    const componentRef = useRef<HTMLButtonElement>(null);
    const [width, setWidth] = useState(0);
    const [search, setSearch] = React.useState('')
    const { error, name } = useFormField();
    useEffect(() => {
        if (componentRef.current) {
            const { current } = componentRef;
            const boundingBox = current.getBoundingClientRect();
            setWidth(boundingBox.width);
        }
    }, []);

    return (
        <>
            {
                label?.length ?
                    <FormLabel>
                        <span className="text-zinc-400 text-sm font-normal font-['Inter'] leading-tight">
                            {label}
                            <span className="text-red-500  ml-1">*</span>
                        </span>
                    </FormLabel> : <></>
            }
            <Popover>
                {
                    error?.message ?
                        <Tooltip isOpen anchorSelect={`#select-error-${name}`}
                            place="top" className="custom-tooltip-ui">
                            <FormMessage />
                        </Tooltip> : <></>
                }
                <PopoverTrigger asChild 
                // disabled={disableInput}
                style={{ color: disableInput ? 'black !important' : 'inherit', pointerEvents: disableInput ? 'none' : 'auto' }}
                 id={`select-error-${name}`}>
                    <FormControl>
                        <Button 
                        // disabled={disableInput}
                            className={`flex justify-between ${buttonSelectClass}`}
                            variant="form"
                            role="combobox"
                            ref={componentRef}
                            style={{ color: disableInput ? 'black !important' : 'inherit' }}
                        >
                            {SelectTrigger && <SelectTrigger />}
                            <ChevronDown className="ml-2 size-4 shrink-0 opacity-50" />
                        </Button>
                    </FormControl>
                </PopoverTrigger>
                <PopoverContent className={`p-0 w-[${width}px]`}>
                    <Command className={`w-full`}
                        filter={filterOption}
                    >
                        {
                            SearchInput ? SearchInput :
                                <CommandInput placeholder={searchPlaceholder}
                                    value={search} onValueChange={setSearch} disabled={disableInput}    style={{ color: disableInput ? 'black' : 'inherit' }} />
                        }
                        <PopoverClose>
                            <CommandList>
                                {loading && <CommandLoading />}
                                {
                                    options &&
                                    <CommandGroup className={`overflow-auto custom-scrollbar`}>
                                        {options.map((item, idx) => (
                                            <CommandItem
                                                className={`justify-between`}
                                                value={item.value}
                                                key={item.id}
                                                onSelect={() => {
                                                    handleSelect(item)
                                                }}
                                                style={{ color: disableInput ? 'black !important' : 'inherit', pointerEvents: disableInput ? 'none' : 'auto' }}
                                            >
                                                <RenderOption item={item} />
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                }
                                <CommandEmpty>{notFound}</CommandEmpty>
                            </CommandList>
                        </PopoverClose>
                    </Command>
                </PopoverContent>
            </Popover>
        </>
    )
}

export default CommandSelect;