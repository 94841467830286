import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonLoader, Input } from "components/common/form";
import AuthPageTitle from "components/common/AuthPageTitle";
import DynamicHeadTag from "components/common/DynamicHeadTag";
import useLoginInfo from "hooks/useLoginInfo";
import getPasswordStrength from "utils/getPasswordStrength";
import PasswordStrengthIndicator from "components/common/PasswordStrengthIndicator";
import { ReactComponent as IconEyeOpen } from "assets/images/icons/eyeopen.svg";
import { ReactComponent as IconEyeClose } from "assets/images/icons/eyeclose.svg";
import { ReactComponent as IconLock } from "assets/images/icons/payment/lock.svg";
import { ReactComponent as IconTick } from "assets/images/icons/Check-Circle.svg";
import { ReactComponent as IconPhone } from "assets/images/icons/phone.svg";
import { ReactComponent as IconKey } from "assets/images/icons/Key.svg";
import { ReactComponent as IconMail } from "assets/images/icons/Mail-Send-Envelope.svg";
import { ReactComponent as IconUserProfile } from "assets/images/icons/user-profile.svg";
import * as settingsActions from "store/entities/settings/action";
import Maybe from "components/common/Maybe";


const AcceptInvite = () => {
    const { userLogin, logoutUser } = useLoginInfo();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line
    const { id } = useParams();
    const { inviteAcceptLoading, inviteAccept } = useSelector((s: any) => s.entities.settings);
    const [currentStep, setCurrentStep] = useState(2);

    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [form, setForm] = useState({
        bvn: "",
        nin: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
    })

    const onFinish = async (e: React.FormEvent) => {
        e.preventDefault();

        if (currentStep === 1) {
            setCurrentStep(2)
        } else if (currentStep === 2) {
            console.log('form', form)
        }
    }


    useEffect(() => {
        if (inviteAccept?.success === true || inviteAccept?.status === true) {
            dispatch(settingsActions.resetMemberAcceptInvite());
            if (userLogin === true) {
                logoutUser();
            }
            else if (!userLogin) {
                navigate("/login");
            }
        }
        // eslint-disable-next-line
    }, [inviteAccept]);

    const stepOneDisabled = !form.bvn?.length || !form.nin?.length || form?.phoneNumber?.length !== 10

    const stepTwoDisabled = !getPasswordStrength(form.password).hasCharacterLength
        || !getPasswordStrength(form.password).hasLowerCase
        || !getPasswordStrength(form.password).hasUpperCase
        || !getPasswordStrength(form.password).hasNumber
        || !getPasswordStrength(form.password).hasSpecialCharacter
        || form.confirmPassword !== form.password;


    return (
        <>
            <DynamicHeadTag
                headerText="Join Team"
            />

            <AuthPageTitle
                title="Join Payshiga Technol..."
                description="user@payshiga.com, please complete this form to create a Payshiga account and join this team"
            />

            <Maybe condition={currentStep === 1}>

                <div>
                    <form onSubmit={onFinish}>
                        <div className="mt-10">

                            <Input
                                type="number"
                                label="Bank verification number"
                                required={true}
                                placeholder="Enter your BVN"
                                value={form.bvn || ''}
                                rightIcon={
                                    <IconTick className="h-4 w-4" />
                                }
                                onChange={(e: any) => setForm({ ...form, bvn: e.target.value })}
                            />

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6">
                                <Input
                                    disabled
                                    type="text"
                                    value='John'
                                    label="First Name"
                                    rightIconClass="pr-1"
                                    placeholder="First Name"
                                    rightIcon={<IconLock />}
                                />
                                <Input
                                    disabled
                                    value='Doe'
                                    type="text"
                                    label="Last Name"
                                    rightIconClass="pr-1"
                                    placeholder="Last Name"
                                    rightIcon={<IconLock />}
                                />
                            </div>

                            <Input
                                type="text"
                                label="National Identity Number"
                                placeholder="Enter your NIN"
                                required={true}
                                value={form.nin}
                                onChange={(e: any) => setForm({ ...form, nin: e.target.value })}
                            />

                            <Input
                                type="text"
                                label="Phone Number"
                                placeholder="08108235584"
                                inputClass="!pl-14"
                                required={true}
                                value={form.phoneNumber}
                                onChange={(e: any) => setForm({ ...form, phoneNumber: e.target.value })}
                                leftIcon={
                                    <div className="pl-3 pt-px text-sm text-shiga-dark-100 font-medium">
                                        +234
                                    </div>
                                }
                                rightIcon={<IconTick className="h-4 w-4" />}
                            />

                            <ButtonLoader disabled={stepOneDisabled} type="submit" loading={inviteAcceptLoading} className="btn btn-lg btn-block btn-primary mt-8">
                                Continue
                            </ButtonLoader>

                        </div>
                    </form>
                </div>
            </Maybe>


            <Maybe condition={currentStep === 2}>

                <div>
                    <form onSubmit={onFinish}>
                        <div className="mt-10">

                            <div className="w-full border-b pb-6 mb-6">

                                <div className="flex items-start justify-start min-h-[120px]">
                                    <div className="w-max h-full mr-6">
                                        <IconUserProfile className="h-[124px] w-[124px]" />
                                    </div>


                                    <div className="w-2/4">
                                        <p className="mb-4 font-medium whitespace-nowrap text-shiga-dark-100 text-lg lg:text-2xl font-inter">
                                            Bidemi Fadekemi
                                        </p>

                                        <div className="flex items-center space-x-2 mb-3">
                                            <IconPhone className="w-5 h-5" />

                                            <p className="mb-0 text-shiga-gray-300 text-sm font-medium font-inter">
                                                +2348134323883
                                            </p>
                                        </div>

                                        <div className="flex items-center space-x-2 mb-3">
                                            <IconMail className="w-5 h-5" />

                                            <p className="mb-0 text-shiga-gray-300 text-sm font-medium font-inter">
                                                user@gmail.com
                                            </p>
                                        </div>

                                        <div className="flex items-center space-x-2 mb-3">
                                            <IconKey className="w-5 h-5" />

                                            <p className="mb-0 text-shiga-gray-300 text-sm font-medium font-inter">
                                                <span className="text-shiga-gray-100">
                                                    Role:&nbsp;
                                                </span>
                                                Developer
                                            </p>
                                        </div>


                                    </div>
                                </div>

                            </div>

                            <Input
                                type={showPassword1 ? "text" : "password"}
                                autoComplete="false"
                                label="Current Password"
                                placeholder="Password"
                                required={true}
                                value={form.password} onChange={(e: any) => setForm({ ...form, password: e.target.value })}
                                rightIcon={showPassword1 ? <IconEyeOpen /> : <IconEyeClose />}
                                rightIconClicked={() => setShowPassword1(!showPassword1)}
                                rightIconClass="px-3"
                            />

                            <div className="-mt-2 mb-5">
                                <PasswordStrengthIndicator
                                    password={form.password}
                                />
                            </div>

                            <Input
                                type={showPassword2 ? "text" : "password"}
                                autoComplete="false"
                                label="Confirm Password"
                                placeholder="Password"
                                required={true}
                                value={form.confirmPassword} onChange={(e: any) => setForm({ ...form, confirmPassword: e.target.value })}
                                rightIcon={showPassword2 ? <IconEyeOpen /> : <IconEyeClose />}
                                rightIconClicked={() => setShowPassword2(!showPassword2)}
                                rightIconClass="px-3"
                            />

                            <ButtonLoader disabled={stepTwoDisabled} type="submit" loading={inviteAcceptLoading} className="btn btn-lg btn-block btn-primary mt-8">
                                Join this Team
                            </ButtonLoader>

                        </div>
                    </form>
                </div>
            </Maybe>

        </>
    )
}


export default AcceptInvite;