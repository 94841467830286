import * as actions from "./actionTypes";
import * as config from "data/config";

export function getIssuedAccounts() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetIssuedAccounts,
            method: "get",
            data: {},
            onStart: actions.GET_ISSUED_ACCOUNTS_START,
            onSuccess: actions.GET_ISSUED_ACCOUNTS,
            onError: actions.GET_ISSUED_ACCOUNTS_FAILED,
        }
    }
}