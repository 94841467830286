import { toast } from "react-toastify";
import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";


const initialState = {
    businessVerificationUpdateLoading: false,
    businessVerificationUpdate: {},
    sampleDocumentsAllLoading: false,
    sampleDocumentsAll: [],
    directorsAddedLoading: false,
    directorsAdded: [],
};


export default function reducer(state = initialState, action) {
    switch (action.type) {


        case actions.UPDATE_BUSINESS_VERIFICATION:
            toast.success(action.payload.message);
            state = {
                ...state,
                businessVerificationUpdateLoading: false,
                businessVerificationUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_BUSINESS_VERIFICATION_START:
            state = {
                ...state,
                businessVerificationUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_BUSINESS_VERIFICATION_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                businessVerificationUpdateLoading: false,
                businessVerificationUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_BUSINESS_VERIFICATION_RESET:
            state = {
                ...state,
                businessVerificationUpdateLoading: false,
                businessVerificationUpdate: {},
            }
            return state;


        case actions.ADD_DIRECTOR:
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: [
                    ...state.directorsAdded,
                    action.payload
                ],
            }
            return state;


        case actions.ADD_DIRECTOR_START:
            state = {
                ...state,
                directorsAddedLoading: true,
            }
            return state;


        case actions.ADD_DIRECTOR_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: [
                    ...state.directorsAdded,
                    action.payload
                ],
            }
            return state;


        case actions.ADD_DIRECTOR_RESET:
            state = {
                ...state,
                directorsAddedLoading: false,
                directorsAdded: [],
            }
            return state;


        case actions.DOWNLOAD_SAMPLE_DOCUMENTS:
            state = {
                ...state,
                sampleDocumentsAllLoading: false,
                sampleDocumentsAll: [
                    ...state.sampleDocumentsAll,
                    action.payload?.data?.file,
                ],
            }
            return state;


        case actions.DOWNLOAD_SAMPLE_DOCUMENTS_START:
            state = {
                ...state,
                sampleDocumentsAllLoading: true,
            }
            return state;


        case actions.DOWNLOAD_SAMPLE_DOCUMENTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                sampleDocumentsAllLoading: false,
                // sampleDocumentsAll: action.payload,
            }
            return state;


        default:
            return state;
    }
}