import BackBtnIcon from "assets/images/icons/BackBtn";
import ChevronRightIcon from "assets/images/icons/ChevronRight";
import CloseBtnIcon from "assets/images/icons/CloseBtn";
import MoneyIcon from "assets/images/icons/Money";
import PaperPlaneIcon from "assets/images/icons/PaperPlane";
import { ReactComponent as IconClose } from "assets/images/icons/Close.svg";

interface StepHeaderProps {
  step: number;
  goBack: () => void;
  close: () => void;
  title?: boolean;
}

const AddMoneyModalHeader = ({
  step,
  goBack,
  close,
  title,
}: StepHeaderProps) => {
  return (
    <header
      className={`pb-7 border-b border-outline-grey flex justify-between items-center relative`}
    >
      <div>
        {step >= 2 ? (
          <BackBtnIcon className="cursor-pointer" onClick={goBack} />
        ) : (
          <div className="text-2xl font-ojah font-medium">Add Money</div>
        )}
      </div>

      <div
        onClick={() => close()}
        className="w-8 h-8 bg-[#FAFAFB] hover:bg-[#eee] flex rounded-full cursor-pointer"
      >
        <IconClose className="m-auto text-white svg-stroke-gray" />
      </div>
      {/* <CloseBtnIcon className="cursor-pointer"  /> */}
      {/* {step >= 2 && (
        <div className=" flex justify-between place-items-center border-4">
          <BackBtnIcon className="cursor-pointer" onClick={goBack} />
          <CloseBtnIcon className="cursor-pointer" />
        </div>
      )} */}
    </header>
  );
};

export default AddMoneyModalHeader;
