import { ReactComponent as BackButton } from "assets/images/icons/ArrowLeft.svg";
import UserIcon from "assets/images/icons/user_profile_icon.png";
import TransHeaderItem from "./TransHeaderItem";
import CloseButton from "assets/images/icons/close.png";
import DollarIconGray from "assets/images/icons/dollar_icon_gray.png";
import DollarIcon from "assets/images/icons/dollar_step_2.png";
import SendMoneyIconGray from "assets/images/icons/send_icon_gray.png";
import React from "react";
import { useNavigate } from "react-router-dom";


const ConvertMoneyHeader: React.FC<{
    step: number;
    onClose: () => void;
}> = ({ step, onClose }) => {
    const navigator = useNavigate()
    return (
        <div className="flex w-full justify-between items-center max-w-full">
            <div onClick={() => {navigator(-1)}}>
                <BackButton />
            </div>

            <div className="flex flex-grow justify-center space-x-8 sm:space-x-12 md:space-x-16 lg:space-x-20">
                <TransHeaderItem
                    image={DollarIcon}
                    image2={DollarIconGray}
                    title="Amount to Convert"
                    selected={step <= 2}
                />

                <TransHeaderItem
                    image={UserIcon}
                    image2={SendMoneyIconGray}
                    title="Review & Convert"
                    selected={step >= 2}
                />
            </div>

            <div>
                <img src={CloseButton} alt="Close Button" className="cursor-pointer" onClick={onClose} />
            </div>
        </div>
    );
};

export default ConvertMoneyHeader;
