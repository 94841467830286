import { useEffect, useRef } from 'react'
import PATHS from 'NavigationRoute';
import Table from 'components/common/Table';
import { TableContent } from 'components/common/TableContent';
import ShigaButton from 'components/common/form/ShigaButton';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { getTransactionsStore } from 'store/transactions/reducer';
import EmptyTransactions from './components/EmptyTransactions';
import Maybe from 'components/common/Maybe';
import { currencyFormat } from 'utils/CurrencyFormat';
import { ReactComponent as CopyIcon } from "assets/images/icons/copy-gray.svg";
import useToolkit from 'hooks/useToolkit';
import moment from 'moment';
import { TransactionType, TransactionNarration } from './TransactionUtils';
import TableDropdown from 'components/common/TableDropdown';
import { ReactComponent as IconShare } from "assets/images/icons/Share.svg";
import { ReactComponent as IconRefund } from "assets/images/icons/refresh-gray.svg";
import { useReactToPrint } from 'react-to-print';
import { Transaction, defaultTransaction } from 'types/transaction';
import PaymentRefundModal from './components/table/PaymentRefundModal';
import { getTransactions } from 'store/transactions/action';
import TransactionDetailsModal from './components/table/TransactionDetailsModal';
import DynamicHeadTag from 'components/common/DynamicHeadTag';
import TransactionPdfReceipt from './components/transaction-detai-types/TransactionPdfReceipt';
import processParams from 'utils/processParams';
import useUrlQueryParams from 'utils/useUrlQueryParams';
import CustomTableFilter from 'components/common/CustomTableFilter';
import Pagination from 'components/common/Pagination';
import SearchBox from 'components/common/SearchBox';


const TransactionsHomepage = () => {
   const navigate = useNavigate();
   const { copyItem, useState } = useToolkit();
   const dispatch = useAppDispatch();
   const receiptRef = useRef<HTMLDivElement>(null);
   const [selected, setSelected] = useState<Transaction>();

   const [refundOpen, setRefundOpen] = useState(false);
   const [detailsOpen, setDetailsOpen] = useState(false);
   const [receiptDate, setReceiptDate] = useState('');
   const location = useLocation();
   const { urlQueryParams } = useUrlQueryParams();

   const [listParams, setListParams] = useState({
      "page": 1,
      "limit": 9,
   });

   const { transactions, isLoadingTransactions: transactionsLoading } = useSelector(getTransactionsStore);
   const transactionsAreValid = transactionsLoading === false &&
      transactions?.data?.payload && transactions?.data?.payload?.length > 0;

   const handlePrint = useReactToPrint({
      content: () => receiptRef?.current,
      documentTitle: 'Shiga Business Transaction receipt - ' + receiptDate,
   });

   const generateReceipt = () => {
      if (receiptRef) {
         handlePrint();
      }
   }

   const triggerRefund = () => {
      setRefundOpen(true);
   }

   const fetchTransactions = () => {
      let params = { ...listParams };

      params = processParams(urlQueryParams, params, "page", "number", 1, "page");
      params = processParams(urlQueryParams, params, "limit", "number", 9, "limit");
      params = processParams(urlQueryParams, params, "currency", "string", "", "currency");
      params = processParams(urlQueryParams, params, "channel", "string", "", "channel");
      params = processParams(urlQueryParams, params, "status", "string", "", "status");
      params = processParams(urlQueryParams, params, "search", "string", "", "search");
      params = processParams(urlQueryParams, params, "startDate", "string", "", "startDate");
      params = processParams(urlQueryParams, params, "endDate", "string", "", "endDate");

      setListParams(params);
      dispatch(getTransactions(params));
   }

   useEffect(() => {
      fetchTransactions();
      // eslint-disable-next-line
   }, [location]);


   useEffect(() => {
      if (selected) {
         const formattedDate = moment(selected?.createdAt).format('MMM D, YYYY HH:mm:ss')
         setReceiptDate(formattedDate)
      }
   }, [selected])

   return (
      <div className='dashboard-page-container'>

         <DynamicHeadTag
            headerText="Transactions"
         />

         <TransactionDetailsModal
            isOpen={detailsOpen}
            receiptRef={receiptRef}
            setIsOpen={setDetailsOpen}
            generateReceipt={generateReceipt}
            triggerRefund={triggerRefund}
            transaction={selected || defaultTransaction}
         />

         <PaymentRefundModal
            isOpen={refundOpen}
            setIsOpen={setRefundOpen}
            transaction={selected}
         />

         <div className="hidden">
            <TransactionPdfReceipt
               receiptRef={receiptRef}
               transaction={selected || defaultTransaction}
            />
         </div>

         <div className="w-full flex items-center justify-start lg:justify-between flex-wrap lg-flex-nowrap lg:border-b lg:border-shiga-gray-50 pb-10">
            <div className="page-title w-full lg:w-max">
               Transactions
            </div>

            <div className="w-full lg:w-max pt-5 lg:pt-0">
               <ShigaButton
                  darkBg
                  text="Make a Transfer"
                  onClick={() => navigate(PATHS.TRANSFERS.MAKE_A_TRANSFER)}
               />
            </div>
         </div>

         <div className="pb-24 pt-6">

            <Maybe condition={!transactionsLoading}>
               <div className="my-6 flex items-center justify-start lg:justify-between flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-2/4">
                     <CustomTableFilter
                        dropdownClass=''
                        filters={[
                           {
                              title: "Status",
                              name: "status",
                              dataType: "select",
                              options: [
                                 {
                                    display: "Show all",
                                    payload: "-",
                                 },
                                 {
                                    display: "Successful",
                                    payload: "successful",
                                 },
                                 {
                                    display: "Failed",
                                    payload: "failed",
                                 },
                                 {
                                    display: "Refunded",
                                    payload: "refunded",
                                 },
                                 {
                                    display: 'Reversed',
                                    payload: 'reversed',
                                 }
                              ],
                           },
                           {
                              title: "Currency",
                              name: "currency",
                              dataType: "select",
                              options: [
                                 {
                                    display: "Show all",
                                    payload: "-",
                                 },
                                 {
                                    display: "NGN",
                                    payload: "ngn",
                                 },
                                 {
                                    display: "USD",
                                    payload: "usd",
                                 },
                                 {
                                    display: 'CAD',
                                    payload: 'cad',
                                 },
                                 {
                                    display: 'GBP',
                                    payload: 'gbp',
                                 }
                              ],
                           },
                           {
                              title: "Channel",
                              name: "channel",
                              dataType: "select",
                              options: [
                                 {
                                    display: "Show all",
                                    payload: "-",
                                 },
                                 {
                                    display: "Card",
                                    payload: "card",
                                 },
                                 {
                                    display: "Bank transfer",
                                    payload: "bank_transfer",
                                 },
                                 {
                                    display: 'Direct debit',
                                    payload: 'direct_debit',
                                 },
                                 {
                                    display: 'Bill payment',
                                    payload: 'bill_payment',
                                 },
                                 {
                                    display: 'Tution payment',
                                    payload: 'tution_payment',
                                 },
                                 {
                                    display: 'Giftcards',
                                    payload: 'gifcards',
                                 },
                                 {
                                    display: 'API: Card debit',
                                    payload: 'api_card_debit',
                                 },
                                 {
                                    display: 'API: Bank Transfer',
                                    payload: 'api_bank_transfer',
                                 },
                                 {
                                    display: 'API: Direct debit',
                                    payload: 'api_direct_debit',
                                 },
                                 {
                                    display: 'API: Shiga QR',
                                    payload: 'api_shiga_qr',
                                 }
                              ],
                           },
                           {
                              title: "Date Range",
                              dataType: "date-range",
                              options: {
                                 from: {
                                    name: "startDate",
                                    placeholder: "Start Date",
                                 },
                                 to: {
                                    name: "endDate",
                                    placeholder: "End Date",
                                 },
                              },
                           },
                        ]}
                     />
                  </div>

                  <div className="w-full lg:w-2/4 flex items-center justify-between pt-5 lg:pt-0 lg:space-x-5 lg:justify-end">
                     <Pagination meta={transactions?.data?.meta} />
                     <SearchBox
                        name='search'
                        extraUrlParams='page=1'
                        placeholder="Search transactions"
                     />
                  </div>
               </div>
            </Maybe>

            <Table>

               <Maybe condition={(transactionsLoading === false && !transactions) || (transactionsAreValid)}>
                  <Table.Head>
                     <th>Narration</th>
                     <th>Amount</th>
                     <th>Type</th>
                     <th>Reference</th>
                     <th>Date</th>
                     <th></th>
                  </Table.Head>
               </Maybe>

               <Table.Body>

                  <TableContent
                     total={transactions?.data?.payload?.length || 0}
                     loading={transactionsLoading}
                     loadingText="Fetching transactions"
                     data={transactions?.data?.payload}
                     colspan={6}
                     emptyUI={
                        <EmptyTransactions />
                     }
                  // customLoader={<Skeleton />}
                  />

                  {transactionsLoading === false && transactions?.data?.payload?.map((transaction) => {
                     const isCredit = transaction?.type === 'CREDIT';
                     const isCurrencySwap = transaction?.method === 'SWAP';
                     const isApiTrx = transaction?.medium === 'API'
                     const isApiNgnTransfer = transaction?.currency === 'NGN' && isApiTrx && transaction?.channel === 'BANK_TRANSFER';
                     const isFailedTransaction = transaction?.status === 'FAILED'

                     const isNgnDirectDebit = transaction?.currency === 'NGN' && transaction?.channel === 'DIRECT_DEBIT' && transaction?.deposit?.depositType === 'CARD';

                     const isApiCardDebit = transaction?.type === 'DEBIT' && transaction?.deposit?.depositType === 'CARD';

                     const allowRefund = isApiNgnTransfer || isNgnDirectDebit || isApiCardDebit

                     return (
                        <tr className='cursor-pointer font-inter' key={transaction.id} onClick={() => {
                           setSelected(transaction);
                           setDetailsOpen(true);
                        }}>
                           <td>
                              <TransactionNarration transaction={transaction} />
                           </td>
                           <td className={`font-medium ${((isCredit || isCurrencySwap) && !isFailedTransaction) ? 'text-shiga-green-100' : 'text-shiga-gray-300'} ${isFailedTransaction && 'line-through'}`}>
                              {isCredit && "+ "}
                              {currencyFormat(transaction?.amount, transaction?.currency)}
                           </td>
                           <td>
                              <TransactionType transaction={transaction} />
                           </td>
                           <td>
                              <div className='flex items-center font-medium'>
                                 {transaction.reference?.substring(0, 17)}
                                 <button className='ml-1.5' onClick={(e: any) => {
                                    e.stopPropagation();
                                    copyItem(transaction?.reference, 'Transaction reference');
                                 }}>
                                    <CopyIcon width={20} />
                                 </button>
                              </div>
                           </td>
                           <td>
                              <div className='font-medium'>
                                 {moment(transaction?.createdAt).format('MMM D, YYYY [at] h:mma')}
                              </div>
                           </td>

                           <td>
                              <div className='hover:bg-gray-200 py-1 px-px rounded-md' onClick={(e: any) => e.stopPropagation()}>
                                 <TableDropdown
                                    onRowClick={() => setSelected(transaction)}
                                    options={[
                                       {
                                          permission: true,
                                          title: 'Share receipt',
                                          Icon: IconShare,
                                          onClick: () => generateReceipt(),
                                       },
                                       {
                                          permission: allowRefund,
                                          title: 'Refund payment',
                                          Icon: IconRefund,
                                          onClick: () => triggerRefund()
                                       },
                                    ]}
                                 />
                              </div>

                           </td>
                        </tr>
                     )
                  }
                  )}
               </Table.Body>
            </Table>
         </div>
      </div>
   )
}

// eslint-disable-next-line
const Skeleton = ({ count }: { count?: number }) => {

   return (
      <div className="w-full flex items-center justify-between animate-table">

         <td style={{ width: '22%', paddingLeft: 0 }}>
            <div className="w-full flex items-center justify-start">
               <div className='w-10 h-10 rounded-full bg-shiga-gray-50' />

               <div className='w-3/5 ml-6'>
                  <div className='w-4/5 h-4 rounded-lg bg-shiga-gray-50 mb-2' />
                  <div className='w-full h-6 rounded-lg bg-shiga-gray-50' />
               </div>
            </div>
         </td>


         <td style={{ width: '16%', paddingLeft: 0, marginLeft: '35px !important', border: '1px solid green' }}>
            <div className="w-4/5">
               <div className='w-full h-6 rounded-lg bg-shiga-gray-50' />
            </div>
         </td>

         <td style={{ width: '22%', paddingLeft: 0, }}>
            <div className="w-3/5">
               <div className='w-full h-6 rounded-lg bg-shiga-gray-50' />
            </div>
         </td>

         <td style={{ width: '22%', paddingLeft: 0, }}>
            <div className="w-3/5">
               <div className='w-full h-6 rounded-lg bg-shiga-gray-50' />
            </div>
         </td>

         <td style={{ width: '25%', paddingLeft: 0, }}>
            <div className="w-3/5">
               <div className='w-full h-6 rounded-lg bg-shiga-gray-50' />
            </div>
         </td>

         <td style={{ width: '15%', paddingLeft: 0, }}>
            <div className="w-2/5">
               <div className='w-6 h-6 rounded-lg bg-shiga-gray-50' />
            </div>
         </td>

         {/* {Array.from(Array(count).keys()).map((_, index) => (


               <div key={index} className={`animate-table py-1 px-2.5 rounded-lg flex items-center space-x-4 ${count > 1 && 'mb-4'}`}>



                  <div className="bg-shiga-gray-40 rounded-full h-5 w-5"></div>
                  <div className="flex-1 space-y-4 py-1">
                     <div className="h-4 bg-gray-200 rounded"></div>
                  </div>
               </div>
            ))} */}
      </div>
   )
}


export default TransactionsHomepage